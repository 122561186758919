import React, { FC } from "react";
import { ButtonFill } from "../../../react-components/Buttons/ButtonFill";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { SectionsNavbar } from "../../../react-components/SectionsNavbar/SectionsNavbar";
import { NavbarProps } from "../../../Common/Navbar/NavbarProps.csharp";

export const ProductPageMaritimeNavbar: FC<ComponentProps<NavbarProps>> = ({
  containerId,
  model,
}) => {
  const { sections, translations, contactLink } = model;

  return (
    <SectionsNavbar
      sections={sections}
      translations={translations}
      itemsColor="base"
      containerId={containerId}
    >
      {contactLink.href && (
        <ButtonFill
          as="a"
          color="base"
          title={contactLink.title}
          target={contactLink.target}
          href={contactLink.href}
        >
          {contactLink.text}
        </ButtonFill>
      )}
    </SectionsNavbar>
  );
};
