import React, { memo } from "react";
import { Heading, HeadingLevel } from "../../../../react-components/Heading";
import { OfficeLocationBadge as OfficeLocationBadgeType } from "./OfficeLocationsListItem.utils";
import { OfficeLocationBadge } from "./OfficeLocationBadge";

interface OfficeLocationsListItemHeaderProps {
  officeName: string;
  badges: OfficeLocationBadgeType[];
  headingLevel?: HeadingLevel;
}

export const OfficeLocationsListItemHeader = memo<OfficeLocationsListItemHeaderProps>(function OfficeLocationsListItemHeader({
  officeName,
  headingLevel,
  badges,
}) {
  return (
    <header className="OfficeLocationsListItem__header">
      <Heading
        level={headingLevel}
        className="OfficeLocationsListItem__name"
      >
        {officeName}
      </Heading>

      {badges.length > 0 && (
        <div className="OfficeLocationsListItem__badges">
          {badges.map(({ name, color }) => (
            <OfficeLocationBadge
              key={name}
              name={name}
              color={color}
            />
          ))}
        </div>
      )}
    </header>
  );
});
