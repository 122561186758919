import React, { FC } from "react";
import { ImageViewerItemViewModel } from "./ImageViewerItemViewModel.csharp";
import { PictureProfileDto } from "../../Images/Resizing/PictureProfileDto.csharp";
import { ImageViewerArrow } from "./ImageViewerArrow";
import { ResponsivePicture } from "../../react-components/responsivePicture/responsivePicture";
import classNames from "classnames";

interface ImageViewerThumbnailsProps {
  images: ImageViewerItemViewModel[];
  thumbnailProfile: PictureProfileDto;
  activeImageIdx: number;
  onThumbnailClick: (idx: number) => void;
  prevImage: () => void;
  nextImage: () => void;
}

export const ImageViewerThumbnails: FC<ImageViewerThumbnailsProps> = ({
  images,
  thumbnailProfile,
  activeImageIdx,
  onThumbnailClick,
  prevImage,
  nextImage,
}) => {
  return (
    <>
      {images && images.length > 0 && (
        <div className="ImageViewerThumbnails">
          <ImageViewerArrow direction="left" onClick={prevImage} />
          <ul className="ImageViewerThumbnails__list">
            {images.map((image, idx) => (
              <li
                className={classNames("ImageViewerThumbnails__item", {
                  "ImageViewerThumbnails__item--active": idx === activeImageIdx,
                })}
                key={`thumb-${idx}`}
              >
                <button onClick={() => onThumbnailClick(idx)}>
                  <ResponsivePicture profile={thumbnailProfile} model={image.picture} />
                </button>
              </li>
            ))}
          </ul>
          <ImageViewerArrow direction="right" onClick={nextImage} />
        </div>
      )}
    </>
  );
};
