import React, { ChangeEventHandler, FormEvent, MouseEventHandler, useState } from 'react';
import { Search } from '../../../../react-components/Discovery/Icons/Search';
import { Autocomplete } from '@react-google-maps/api';
import { OfficeLocationsListWithMapFiltersSearchBoxTranslations } from '../../OfficeLocationsListWithMapFiltersSearchBoxTranslations.csharp';

interface OfficeLocationsFiltersSearchInputProps {
  translations: OfficeLocationsListWithMapFiltersSearchBoxTranslations;
  onAutocompleteLocationSelection: (place: google.maps.places.PlaceResult) => void;
  searchQuery?: string;
  setSearchQuery?: (searchQuery: string) => void;
  handleSearchSubmit?: (event?: FormEvent<HTMLFormElement>) => void;
}

export const OfficeLocationsFiltersSearchInput = ({
  translations,
  onAutocompleteLocationSelection,
  searchQuery,
  setSearchQuery,
  handleSearchSubmit,
}: OfficeLocationsFiltersSearchInputProps) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete && handleSearchSubmit) {
      const place = autocomplete.getPlace();

      const placeKeysTotal = Object.keys(place).length;
      if (placeKeysTotal > 1) {
        onAutocompleteLocationSelection(place);
        return;
      }

      if (placeKeysTotal === 1 && place.name) {
        handleSearchSubmit();
      }
    }
  };

  const handleSearchQueryChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (setSearchQuery) {
      setSearchQuery(event.target.value);
    }
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (handleSearchSubmit) {
      handleSearchSubmit();
    }
  };

  return (
    <div className="OfficeLocationsFiltersSearchInput">
      <label
        className="OfficeLocationsFiltersSearchInput__label"
        htmlFor="OfficeLocationsFiltersSearchInput"
      >
        {translations.label}
      </label>
      <Autocomplete
        className="OfficeLocationsFiltersSearchInput__autocomplete"
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
      >
        <input
          id="OfficeLocationsFiltersSearchInput"
          className="OfficeLocationsFiltersSearchInput__field"
          type="search"
          name="q"
          placeholder={translations.placeholder}
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      </Autocomplete>
      <button
        type="submit"
        className="OfficeLocationsFiltersSearchInput__button"
        aria-label={translations.submit}
        onClick={handleClick}
      >
        <Search fill="currentColor" />
      </button>
    </div>
  );
};
