import React from "react";

interface HotSpotPreviewButtonProps {
  className: string;
  clickCallback: () => void;
  previewText: string;
}

export const HotSpotPreviewButton: React.FC<HotSpotPreviewButtonProps> = ({
  className,
  clickCallback,
  previewText,
}) => {
  return (
    <button className={className} onClick={clickCallback}>
      {previewText}
    </button>
  );
};
