import classNames from "classnames";
import React from "react";
import { Checkbox, CheckboxStyleProps } from "../Checkbox/Checkbox";
import { useCheckboxGroup } from "../hooks/useCheckboxGroup";

export interface CheckboxGroupOption {
  value: string;
  label: string;
}

interface CheckboxGroupProps {
  name: string;
  options: CheckboxGroupOption[];
  currentValues?: CheckboxGroupOption[];
  onChange?: (newValues: CheckboxGroupOption[]) => void;
  className?: string;
  inline?: boolean;
  hiddenLabel?: string;
  checkboxStyle?: CheckboxStyleProps;
}

export const CheckboxGroup = ({
  name,
  options,
  currentValues,
  onChange,
  className,
  inline = false,
  hiddenLabel,
  checkboxStyle,
}: CheckboxGroupProps) => {
  const { isChecked, handleChange } = useCheckboxGroup({ currentValues, options, onChange });

  return (
    <div className={classNames("CheckboxGroup", className)}>
      <fieldset className="CheckboxGroup__fieldset" aria-label={hiddenLabel}>
        <div
          className={classNames("CheckboxGroup__list", {
            "CheckboxGroup__list--inline": inline,
          })}
        >
          {options.map(({ value, label }) => (
            <div className="CheckboxGroup__listItem" key={value}>
              <Checkbox
                name={name}
                value={value}
                label={label}
                checked={isChecked(value)}
                onChange={handleChange}
                {...checkboxStyle}
              />
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};
