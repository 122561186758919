import classNames from "classnames";
import React from "react";
import { ContainerProps, GroupBase } from "react-select";
import { RadioSelectOption } from "./RadioSelect.types";

export const SelectContainer = ({ children, className, innerProps }: ContainerProps<RadioSelectOption, false, GroupBase<RadioSelectOption>>) => {
  return (
    <div className={classNames("RadioSelect", className)} {...innerProps}>
      {children}
    </div>
  )
};
