import * as React from "react";
import {ResponsivePicture} from "../../react-components/responsivePicture/responsivePicture";
import classNames from "classnames";
import {FrontCoverViewModelProps} from "./FrontCoverViewModelProps.csharp";
import { formatDate } from '../../react-components/dateUtil';


export class FrontCover extends React.Component<FrontCoverViewModelProps> {

    constructor(props: Readonly<FrontCoverViewModelProps>) {
        super(props);
    }

    public render() {
        const { model, pictureProps, readMoreText} = this.props;

        const renderShowMoreLink =
            (
                <div className="FrontCover__link">
                    <span className="FrontCover__link--text">{readMoreText}</span>
                </div>
            );

        const renderPicture =
            (
                <div className="FrontCover__pictureContainer">
                    <ResponsivePicture {...pictureProps}/>
                </div>
            );

        return (
            <div className="FrontCover wrapper RequireMargins" >
                <div className="FrontCover__body">
                    {renderPicture}
                    <div className="FrontCover__text">
                        <time dateTime={formatDate(model.publishDateFormat, 'en-gb', 'YYYY-MM')} className="FrontCover__PublishDate">{model.publishDateFormat}</time>
                        <h2 className={classNames('FrontCover__title FrontCover__TextSuperHeader FrontCover__title--capitalized')}>{model.superHeading}</h2>
                        <h2 className={classNames('FrontCover__title FrontCover__TextHeader')}>{model.heading}</h2>
                        <p>{model.teaser}</p>

                        <a href={model.contentUrl}>
                            {renderShowMoreLink}
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}