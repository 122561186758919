import { useEffect } from "react";

const BODY_OVERFLOW_CLASSNAME = "Body--overflowHidden";

export const useScrollLock = (instant = true) => {
  const enableLock = () => document.body.classList.add(BODY_OVERFLOW_CLASSNAME);
  const disableLock = () => document.body.classList.remove(BODY_OVERFLOW_CLASSNAME);

  useEffect(() => {
    if (!document || !instant) return;
    enableLock();
    return disableLock;
  }, [instant]);

  return { enableLock, disableLock };
};
