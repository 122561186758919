import React, { useState } from "react";
import { ImageViewerProps } from "../ImageViewerProps.csharp";
import { ImageViewerFullScreenThumbnail } from "./ImageViewerFullScreenThumbnail";
import { ButtonFill } from "../../../react-components/Buttons/ButtonFill";
import { GalleryModal } from "../../../react-components/Modal/GalleryModal/GalleryModal";

export const ImageViewerFullScreen = ({
  mainProfile,
  model: { images },
  thumbnailProfile,
}: ImageViewerProps) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const mainImage = images[0];
  const thumbnails = [...images].splice(1, 2);
  const isSingleImage = thumbnails.length === 0;

  const toggleModal = () => setModalOpened((opened) => !opened);

  const onThumbnailClick = (index: number) => {
    setActiveSlideIndex(index);
    toggleModal();
  };

  if (!mainImage) return null;

  return (
    <>
      <div className="ImageViewerFullScreen__inner">
        <div className="ImageViewerFullScreen__thumbnails">
          <ImageViewerFullScreenThumbnail
            image={mainImage}
            profile={mainProfile}
            fullWidth={isSingleImage}
            onClick={!isSingleImage ? () => onThumbnailClick(0) : undefined}
            mainImage
          />

          {thumbnails.map((thumbnail, index) => (
            <ImageViewerFullScreenThumbnail
              key={index}
              image={thumbnail}
              profile={thumbnailProfile}
              onClick={() => onThumbnailClick(index + 1)}
            />
          ))}
        </div>

        {!isSingleImage && (
          <ButtonFill
            color="blue"
            className="ImageViewerFullScreen__openGalleryButton"
            onClick={toggleModal}
          >
            See full gallery
          </ButtonFill>
        )}
      </div>

      {!isSingleImage && (
        <GalleryModal
          isOpen={modalOpened}
          onClose={toggleModal}
          images={images}
          mainProfile={mainProfile}
          thumbnailProfile={thumbnailProfile}
          activeSlideIndex={activeSlideIndex}
          setActiveSlideIndex={setActiveSlideIndex}
        />
      )}
    </>
  );
};
