import React, { FC } from "react";
import classNames from "classnames";

import { AnimatedComponent } from "../../../react-components/AnimatedComponent/AnimatedComponent";
import { BlurredBackgroundImage, animationsName } from "../../BlurredBackgroundImage/BlurredBackgroundImage";

import { LinkDto } from "../../LinkDto.csharp";
import { ResponsivePictureProps } from "../../../Images/ResponsivePictureProps.csharp";
import { OrchestratedChild } from "../../../react-components/AnimatedComponent/AnimatedComponent.types";
import { useMicrositeContext } from "../../Utils/micrositeContext";
import { AnimatedCloseButton } from "../../AnimatedCloseButton/AnimatedCloseButton";

interface MicrositeOverviewProps {
    heading: string;
    description: string;
    bottomLink: LinkDto;
    isOpen: boolean;
    overviewBackground: ResponsivePictureProps;
    toggle: () => void;
}

export const MicrositeOverview: FC<MicrositeOverviewProps> = ({
    heading,
    description,
    bottomLink,
    isOpen,
    toggle,
    overviewBackground
}) => {
    const { theme } = useMicrositeContext();
    const generateAnimationsObject = (delayedBy: number): { custom: number, orchestrationChild: OrchestratedChild } => ({
        custom: 0.3 + delayedBy,
        orchestrationChild: {
            entry: { name: animationsName.entry, target: 'fadeInDelayed' },
            exit: { name: animationsName.exit, target: 'fadeOutToRight' }
        }
    })
    return (
        <BlurredBackgroundImage
            className={classNames("MicrositeOverview", `MicrositeOverview--${theme}`)}
            isOpen={isOpen}
            backgroundImage={overviewBackground}
        >
            <div className="MicrositeOverview__outer">
                <AnimatedComponent tag='div' className="MicrositeOverview__inner">
                    <div className="MicrositeOverview__content">
                        <AnimatedComponent tag="h2" className="MicrositeOverview__heading" {...generateAnimationsObject(0.15)}>
                            {heading}
                        </AnimatedComponent>
                        <AnimatedComponent tag="div" dangerouslySetInnerHTML={{ __html: description }} {...generateAnimationsObject(0.2)} />
                        {bottomLink &&
                            <AnimatedComponent tag="a" className="MicrositeOverview__link" href={bottomLink.href} {...generateAnimationsObject(0.25)}>
                                {bottomLink.text}
                            </AnimatedComponent>
                        }
                    </div>
                </AnimatedComponent>
            </div>
            <AnimatedCloseButton
                label="Close modal"
                className="MicrositeOverview__button"
                onClick={toggle}
                {...generateAnimationsObject(0)}
            />
        </BlurredBackgroundImage>
    )
}
