import * as React from "react";
import {SearchResultsFacetFilters} from "./FacetFilters";
import {SearchHits} from "./SearchHits";
import {DropDownList} from "../DropDownList/DropDownList";
import {ComponentProps} from "../ComponentProps.csharp";
import {SearchResultsViewModel} from "./SearchResultsViewModel.csharp";
import {SearchHitViewModel} from "./SearchHitViewModel.csharp";
import {FindContentPaginationQueryResult} from "../../Pages/FindContentPaginationQueryResult.csharp";

export interface Dictionary<T> {
    [Key: string]: T;
}

export interface SearchResultsState {
    selectedFacet: string;
    selectedSortOption: number;
    fetchedSearchHits: Dictionary<SearchHitViewModel[]>;
    visibleSearchHits: SearchHitViewModel[];
    pageNumbers: Dictionary<number>;
    hasMoreResults: Dictionary<boolean>;
    isLoading: boolean;
}

export class SearchResults extends React.Component<ComponentProps<SearchResultsViewModel>, SearchResultsState> {

    constructor(props: ComponentProps<SearchResultsViewModel>) {
        super(props);

        this.state = {
            selectedFacet: this.props.model.selectedFacet || "",
            selectedSortOption: 0,
            fetchedSearchHits: {},
            visibleSearchHits: [],
            pageNumbers: {},
            hasMoreResults: {},
            isLoading: false
        };

        this.handleLoadMoreButtonClick = this.handleLoadMoreButtonClick.bind(this);
        this.handleFacetChanged = this.handleFacetChanged.bind(this);
        this.handleSortOptionChanged = this.handleSortOptionChanged.bind(this);
    }

    render() {
        return (<>
            <div className="SearchResults__filters">
                <div className="SearchResults__filtersContainer wrapper">
                    <SearchResultsFacetFilters facets={this.props.model.facets}
                                               onFacetChanged={this.handleFacetChanged}/>
                    <DropDownList placeholder={""}
                                  options={this.props.model.sortOptions.map(option => ({
                                      value: String(option.value),
                                      text: option.localizedName
                                  }))}
                                  selectedValue={String(this.state.selectedSortOption)}
                                  onValueChanged={this.handleSortOptionChanged}/>
                </div>
            </div>
            <SearchHits hits={this.state.visibleSearchHits}
                        hasMoreHits={this.state.hasMoreResults[this.state.selectedFacet]}
                        isLoading={this.state.isLoading}
                        onLoadMoreHits={this.handleLoadMoreButtonClick} loadMoreText={this.props.model.loadMoreText}/>
        </>);
    }

    componentDidMount() {
        this.fetchMoreSearchResults(this.state.selectedFacet);
    }

    private handleSortOptionChanged(e: string) {
        this.setState({
            selectedSortOption: parseInt(e),
            fetchedSearchHits: {},
            pageNumbers: {},
            hasMoreResults: {}
        }, () => {
            this.fetchMoreSearchResults(this.state.selectedFacet);
        })
    }

    private handleLoadMoreButtonClick() {
        this.fetchMoreSearchResults(this.state.selectedFacet);
    }

    private fetchMoreSearchResults(facetName: string) {
        const pageNumber = this.state.pageNumbers[facetName] || 1;
        
        this.setState({
            isLoading: true
        }, () => {
            
            fetch(this.props.model.searchEndpointUrl, {
                method: "POST",
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    pageNumber: pageNumber,
                    pageSize: this.props.model.pageSize,
                    searchTerm: this.props.model.searchTerm,
                    facetName: facetName,
                    sortOption: this.state.selectedSortOption,
                    searchPageGuid: this.props.model.searchPageGuid
                })
            })
            .then(res => res.json())
            .then((response : FindContentPaginationQueryResult) => {
                let searchHits = this.state.fetchedSearchHits;

                if (searchHits[this.state.selectedFacet]) {
                    searchHits[this.state.selectedFacet] = searchHits[this.state.selectedFacet].concat(response.results);
                }
                else {
                    searchHits[this.state.selectedFacet] = response.results;
                }

                let hasMoreResults = this.state.hasMoreResults;
                hasMoreResults[this.state.selectedFacet] = response.hasMoreResults;
                
                let pageNumbers = this.state.pageNumbers;
                pageNumbers[this.state.selectedFacet] = pageNumber + 1;

                this.setState({
                    fetchedSearchHits: searchHits,
                    visibleSearchHits: searchHits[this.state.selectedFacet],
                    hasMoreResults: hasMoreResults,
                    pageNumbers: pageNumbers,
                    isLoading: false
                })
            }, reason => {
                this.setState({
                    isLoading: false
                })
            });
        });
    }

    private handleFacetChanged(e: string) {
        this.setState({
            selectedFacet: e,
            visibleSearchHits: this.state.fetchedSearchHits[e] || this.state.visibleSearchHits
        }, () => {
            if(this.state.hasMoreResults[this.state.selectedFacet] === undefined){
                this.fetchMoreSearchResults(this.state.selectedFacet);
            }
        })
    }
}