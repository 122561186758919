//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LabelColor {
	Default = "default",
	Neutral = "neutral",
	Success = "success",
	Alert = "alert",
	Error = "error"
}
