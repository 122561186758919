import React, { FC, useMemo } from "react";
import { ButtonOutlined } from "../../../react-components/Buttons/ButtonOutlined";
import { ButtonFill } from "../../../react-components/Buttons/ButtonFill";
import { ProductNavbarViewModel } from "./ProductNavbarViewModel.csharp";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { useIsBelowScreenSize } from "../../../react-components/useIsBelowScreenSize";
import { IProductNavbarItemViewModel } from "./Items/IProductNavbarItemViewModel.csharp";
import { SectionsNavbar } from "../../../react-components/SectionsNavbar/SectionsNavbar";

export const ProductNavbar: FC<ComponentProps<ProductNavbarViewModel>> = ({
  model: { whereToBuyLink, requestInformationLink, sections, translations },
  containerId,
}) => {
  const isMobile = useIsBelowScreenSize("tablet");

  const availableSections = useMemo(() => {
    const allSections = Object.values(sections) as IProductNavbarItemViewModel[];
    const existingSections = allSections
      .filter((section) => section.exists)
      .filter((section) => (!isMobile ? section.anchor !== "introduction" : true));

    return existingSections;
  }, [isMobile, sections]);

  return (
    <SectionsNavbar
      sections={availableSections}
      translations={translations}
      itemsColor="teal"
      containerId={containerId}
    >
      {requestInformationLink.href && requestInformationLink.text && (
        <ButtonOutlined
          as="a"
          title={requestInformationLink.title}
          target={requestInformationLink.target}
          href={requestInformationLink.href}
          mode="dark"
        >
          {requestInformationLink.text}
        </ButtonOutlined>
      )}
      <ButtonFill
        as="a"
        color="teal"
        title={whereToBuyLink.title}
        target={whereToBuyLink.target}
        href={whereToBuyLink.href}
      >
        {whereToBuyLink.text}
      </ButtonFill>
    </SectionsNavbar>
  );
};
