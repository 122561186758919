import React, { FC } from "react";
import { ComponentProps } from "../../ComponentProps.csharp";
import { CatalogListViewModel } from "./CatalogListViewModel.csharp";
import { CatalogListWithModal } from "./WithModal/CatalogItemListWithModal";
import { CatalogList } from "./CatalogList";

export const CatalogListBlock: FC<ComponentProps<CatalogListViewModel>> = ({ model }) => {
  const ListComponent = model.withModal ? CatalogListWithModal : CatalogList;

  return <ListComponent model={model} />;
};
