import React, { useRef, useState } from "react";
import ReactModal from "react-modal";
import { Splide as SplideInstance, Options as SplideOptions } from "@splidejs/splide";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import classNames from "classnames";
import { PictureProfileDto } from "../../../Images/Resizing/PictureProfileDto.csharp";
import { ImageViewerItemViewModel } from "../../../Partials/ImageViewer/ImageViewerItemViewModel.csharp";
import { ResponsivePicture } from "../../../react-components/responsivePicture/responsivePicture";
import { HamburgerClose } from "../../../react-components/Icons/HamburgerClose";
import { GalleryModalCaption } from "./GalleryModalCaption";
import { GalleryModalArrows } from "./GalleryModalArrows";

interface GalleryModalProps {
  isOpen: boolean;
  onClose: () => void;
  images: Omit<Optional<ImageViewerItemViewModel, "captionHtml">, "captionBackground">[];
  mainProfile: PictureProfileDto;
  thumbnailProfile: PictureProfileDto;
  activeSlideIndex?: number;
  setActiveSlideIndex?: (index: number) => void;
  label?: string;
  splideOptions?: SplideOptions;
  thumbnailsSplideOptions?: SplideOptions;
}

export const GalleryModal = ({
  isOpen,
  onClose,
  images,
  mainProfile,
  thumbnailProfile,
  activeSlideIndex = 0,
  setActiveSlideIndex,
  label = "Gallery",
  splideOptions,
  thumbnailsSplideOptions,
}: GalleryModalProps) => {
  const mainSliderRef = useRef<Splide>(null);
  const thumbnailsSliderRef = useRef<Splide>(null);

  const [slidesCount, setSlidesCount] = useState(0);

  const singleImageMode = images.length <= 1;

  const options = {
    type: "slide",
    perPage: 1,
    speed: 300,
    pagination: false,
    gap: "3rem",
    start: activeSlideIndex,
    arrows: !singleImageMode,
    drag: !singleImageMode,
    ...splideOptions,
  } as SplideOptions;

  const thumbnailsOptions = {
    perPage: 7,
    speed: 300,
    pagination: false,
    arrows: true,
    isNavigation: true,
    focus: 0,
    start: activeSlideIndex,
    waitForTransition: true,
    ...thumbnailsSplideOptions,
  } as SplideOptions;

  const renderSlides = (profile: PictureProfileDto, captions = false) =>
    images.map((image, index) => (
      <SplideSlide key={index}>
        <figure className="GalleryModal__figure">
          <ResponsivePicture model={image.picture} profile={profile} />

          {captions && <GalleryModalCaption captionHtml={image.captionHtml} />}
        </figure>
      </SplideSlide>
    ));

  const handleMounted = (splide: SplideInstance) => {
    setSlidesCount(splide.length);
  };

  const handleMovedMainSlider = (
    _splide: SplideInstance,
    _index: number,
    _prev: number,
    dest: number,
  ) => {
    if (setActiveSlideIndex) {
      setActiveSlideIndex(dest);
    }

    if (thumbnailsSliderRef.current?.splide) {
      thumbnailsSliderRef.current.splide.go(dest);
    }
  };

  const handleMovedThumbnailsSlider = (
    _splide: SplideInstance,
    _index: number,
    _prev: number,
    dest: number,
  ) => {
    if (mainSliderRef.current?.splide) {
      mainSliderRef.current.splide.go(dest);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName="GalleryModal"
      className={classNames("GalleryModal__modal", {
        "GalleryModal__modal--isSingleImage": singleImageMode,
      })}
      ariaHideApp={false}
      onRequestClose={onClose}
      contentLabel={label}
      shouldCloseOnOverlayClick
    >
      <button className="GalleryModal__closeButton" aria-label="Close" onClick={onClose}>
        <HamburgerClose aria-hidden="true" />
      </button>

      <Splide
        className="GalleryModal__slider"
        options={options}
        hasTrack={false}
        tag="div"
        onMounted={handleMounted}
        onMoved={handleMovedMainSlider}
        ref={mainSliderRef}
      >
        <SplideTrack>{renderSlides(mainProfile, true)}</SplideTrack>

        <GalleryModalArrows />
      </Splide>

      {!singleImageMode && (
        <Splide
          className="GalleryModal__thumbnailsSlider"
          options={thumbnailsOptions}
          hasTrack={false}
          tag="nav"
          onMoved={handleMovedThumbnailsSlider}
          ref={thumbnailsSliderRef}
        >
          <div className="GalleryModal__sliderContainer">
            <SplideTrack>{renderSlides(thumbnailProfile)}</SplideTrack>

            <GalleryModalArrows />
          </div>

          {slidesCount > 0 && (
            <div className="GalleryModal__slidesCount">
              {`${activeSlideIndex + 1} / ${slidesCount}`}
            </div>
          )}
        </Splide>
      )}
    </ReactModal>
  );
};
