import React, { forwardRef } from "react";
import { TileProps } from "../TileProps";
import { ResponsivePicture } from "../../../responsivePicture/responsivePicture";
import { LabelViewModel } from "../../../../Common/Labels/LabelViewModel.csharp";
import { Label } from "../../../Atoms/Label/Label";
import classNames from "classnames";
import { useIsBelowScreenSize } from "../../../useIsBelowScreenSize";

interface SimpleTileProps extends TileProps {
  className?: string;
  labels: LabelViewModel[];
}

export const SimpleTile = forwardRef<HTMLAnchorElement, SimpleTileProps>(function SimpleTile(
  { className, href, picture, labels, title, description },
  ref,
) {
  const isBelowLaptop = useIsBelowScreenSize("laptop");
  return (
    <a className={classNames("SimpleTile", className)} href={href} ref={ref}>
      {picture && (
        <div className="SimpleTile__imageWrapper">
          <ResponsivePicture profile={picture.profile} model={picture.model} />
        </div>
      )}
      {!!labels?.length && (
        <div className="SimpleTile__labels">
          {labels.map((label, idx) => (
            <Label key={idx} color={label.color} size={isBelowLaptop ? "small" : "large"}>
              {label.text}
            </Label>
          ))}
        </div>
      )}
      {title && <h3 className="SimpleTile__name">{title}</h3>}
      {description && <p className="SimpleTile__tagline">{description}</p>}
    </a>
  );
});
