//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ContentCardVariant {
	Standard = 0,
	Wide = 1,
	Featured = 2,
	WideAlignBottom = 3
}
