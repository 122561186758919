import React from "react";
import { MenuHandle } from "./MenuHandle";
import { AnimatedComponent } from "../../../AnimatedComponent/AnimatedComponent";
import { AnimatePresence } from "framer-motion";
import { DRAWER_TRANSITION } from "../SelectOptions";
import { GroupBase, MenuListProps } from "react-select";
import { RadioSelectOption } from "./RadioSelect.types";

type DrawerProps = MenuListProps<RadioSelectOption, false, GroupBase<RadioSelectOption>>;

export const Drawer = ({
  children,
  innerProps,
  innerRef,
  selectProps: {
    isDrawerVisible,
    currentDeltaY,
    setDeltaY,
    handleMenuClose
  }
}: DrawerProps) => {
  const getTransforms = (): React.CSSProperties | undefined => {
    if (typeof currentDeltaY === `undefined` || currentDeltaY < 0) {
      return undefined;
    }

    return {
      transform: `translate3d(0, ${currentDeltaY}px, 0)`,
    };
  };

  return (
    <AnimatePresence>
      <AnimatedComponent
        tag="div"
        className="RadioSelect__drawer"
        initial="drawerClosed"
        isOn={isDrawerVisible}
        entry="openDrawer"
        exit="closeDrawer"
        overrideTransition={DRAWER_TRANSITION}
      >
        <div
          className="RadioSelect__drawerInner"
          style={getTransforms()}
          ref={innerRef}
          {...innerProps}
        >
          <MenuHandle handleMenuClose={handleMenuClose} setDeltaY={setDeltaY} />
          <div className="RadioSelect__drawerContent">
            {children}
          </div>
        </div>
      </AnimatedComponent>
    </AnimatePresence>
  );
};
