import classNames from "classnames";
import React, { useRef, useState } from "react";
import { OfficeLocationViewModel } from "../../OfficeLocationViewModel.csharp";
import { formatAddress, getOfficeLocationBadges, getOfficeLocationDepartments, getProductCategories, useScrollIntoListItem } from "./OfficeLocationsListItem.utils";
import { HeadingLevel } from "../../../../react-components/Heading";
import { OfficeLocationsListItemFooter } from "./OfficeLocationsListItemFooter";
import { OfficeLocationsListItemHeader } from "./OfficeLocationsListItemHeader";
import { OfficeLocationsListItemContactInfo } from "./OfficeLocationsListItemContactInfo";
import { OfficeLocationsListItemContactDetails } from "./OfficeLocationsListItemContactDetails";
import { OfficeLocationsListWithMapListItemTranslations } from "../../OfficeLocationsListWithMapListItemTranslations.csharp";

interface OfficeLocationsListItemProps {
  location: OfficeLocationViewModel;
  listRef: HTMLUListElement | null;
  translations: OfficeLocationsListWithMapListItemTranslations;
  isSelected?: boolean;
  onClick?: (selectedOffice: OfficeLocationViewModel) => void;
  headingLevel?: HeadingLevel;
}

export const OfficeLocationsListItem = ({
  location,
  listRef,
  translations,
  isSelected,
  onClick,
  headingLevel = 2,
}: OfficeLocationsListItemProps) => {
  const ref = useRef<HTMLLIElement>(null);
  const [isExpanded, setExpanded] = useState(false);

  const { badges, destination, departments, categories } = {
    badges: getOfficeLocationBadges(location),
    destination: formatAddress(location.address.street, location.address.city, location.address.country),
    departments: getOfficeLocationDepartments(location),
    categories: getProductCategories(location),
  };

  const isExpandable =
    !!(
      location.websiteUrl ||
      location.siteManager ||
      departments.length > 0 ||
      categories.length > 0
    )

  const handleExpandButtonClick = () => setExpanded(isExpanded => !isExpanded);

  useScrollIntoListItem(ref, listRef, isSelected);

  return (
    <li
      className={classNames("OfficeLocationsListItem", {
        "OfficeLocationsListItem--selected": isSelected,
        "OfficeLocationsListItem--expanded": isExpanded,
      })}
      onClick={() => onClick ? onClick(location) : undefined}
      ref={ref}
    >
      <article className="OfficeLocationsListItem__article">
        <div className="OfficeLocationsListItem__inner" aria-live="polite">
          <OfficeLocationsListItemHeader
            officeName={location.officeName}
            headingLevel={headingLevel}
            badges={badges}
          />

          <OfficeLocationsListItemContactInfo
            emailAddress={location.emailAddress}
            phoneNumber={location.phoneNumber}
            address={location.address}
            lat={location.lat}
            lng={location.lng}
            destination={destination}
            translations={translations}
          />

          {isExpandable && (
            <OfficeLocationsListItemContactDetails
              isVisible={isExpanded}
              websiteUrl={location.websiteUrl}
              siteManager={location.siteManager}
              departments={departments}
              categories={categories}
              translations={translations}
            />
          )}
        </div>

        {isExpandable && (
          <OfficeLocationsListItemFooter
            isExpanded={isExpanded}
            handleExpandButtonClick={handleExpandButtonClick}
            translations={translations}
          />
        )}
      </article>
    </li>
  );
};
