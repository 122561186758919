import React, { FC } from "react";
import { RichVideoViewModel } from "./RichVideoViewModel.csharp";
import { ComponentProps } from "../ComponentProps.csharp";
import { RichVideo } from "./RichVideo";

export const RichVideoIntegrator: FC<ComponentProps<RichVideoViewModel>> = ({ model }) => {
  if (typeof window === "undefined") return null;

  return <RichVideo model={model} />;
};
