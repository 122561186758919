import { camelCase } from "lodash";
import { CheckboxGroupOption } from "../../../react-components/Inputs/CheckboxGroup/CheckboxGroup";
import { ProductFilterViewModel } from "../../Category/ProductFilterViewModel.csharp";
import { FilterGroup, Filters, FiltersState } from "./ProductsListing.types";
import { GroupType } from "../../../react-components/Inputs/InputGroupAccordion/InputGroupAccordion";
import { ParamType, Params } from "../../../react-components/useSearchParams";

export const PRODUCT_FILTERS_SEARCH_PARAM = "productFilters";

export const getFiltersDefaultState = (filters: Filters) => {
  return Object.keys(filters).reduce<Record<keyof Filters, CheckboxGroupOption[]>>(
    (obj, current) => ({ ...obj, [current]: [] }),
    {},
  );
};

export const mapFiltersToFiltersState = (filters: ProductFilterViewModel[]): Filters => {
  return filters.reduce((acc, currentItem) => {
    const filterGroup: FilterGroup = {
      label: currentItem.label,
      groupType: GroupType.Checkbox,
      options: currentItem.options.map((opt) => ({ label: opt, value: camelCase(opt) })),
    };

    acc[camelCase(currentItem.label)] = filterGroup;

    return acc;
  }, {} as Filters);
};

export const mapFiltersStateToUrlParams = (filters: FiltersState): Record<string, string[]> => {
  return Object.entries(filters).reduce<Record<string, string[]>>((acc, [key, values]) => {
    acc[key] = values.map(({ value }) => value);

    return acc;
  }, {});
};

export const mapUrlParamsToFiltersState = (
  params: Params<Record<string, ParamType>>,
  filters: Filters,
): FiltersState => {
  const defaultFiltersState = getFiltersDefaultState(filters);
  const entries = Object.entries(params);
  const newState = { ...defaultFiltersState };

  entries.forEach(([key, values]) => {
    if (Array.isArray(values)) {
      newState[key] = values.map((value) => ({
        label: filters[key].options.find((option) => option.value === value)?.label ?? "",
        value,
      }));
    }
  });

  return newState;
};
