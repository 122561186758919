import * as React from "react";
import {ReportViewModel} from "./ReportViewModel.csharp";
import {ReportTab, ReportType} from "./ReportTab";
import {ReportContainerTranslations} from "./ReportContainerTranslations.csharp";

export interface ReportTabContainerProps {
    reportType: ReportType,
    selectedDate: Date,
    report: ReportViewModel | undefined,
    calendarVisible: boolean
    onReportChanged: (date: Date, report: ReportViewModel | undefined) => void;
    translations: ReportContainerTranslations;
}

export interface ReportTabContainerState {
    selectedDate: Date;
    selectedReport: ReportViewModel | undefined,
    isLoading: boolean,
    hasError: boolean,
}

export enum ResponseStatus {
    ok,
    notFound,
    error
}

export class ReportTabContainer extends React.Component<ReportTabContainerProps, ReportTabContainerState> {
    constructor(props: Readonly<ReportTabContainerProps>) {
        super(props);

        this.state = {
            selectedDate: props.selectedDate,
            selectedReport: props.report,
            isLoading: false,
            hasError: false
        }
    }

    shouldComponentUpdate(nextProps: ReportTabContainerProps, nextState: ReportTabContainerState): boolean {
        if (this.state.isLoading !== nextState.isLoading) {
            return true;
        }

        if (this.state.hasError !== nextState.hasError) {
            return true;
        }

        return this.state.selectedDate !== nextProps.selectedDate || this.state.selectedReport !== nextProps.report;
    }
    
    render() {
        return (
            <ReportTab reportType={this.props.reportType} selectedDate={this.state.selectedDate}
                       calendarVisible={this.props.calendarVisible}
                       report={this.state.selectedReport} hasError={false} isLoading={this.state.isLoading}
                       errorOccuredText={this.props.translations.errorOccured} notFoundText={this.props.translations.notFound}
                       selectDateText={this.getSelectDateText()} culture={this.props.translations.currentCulture}
                       onSelectedDateChanged={(date) => {
                           this.onDateChanged(date)
                       }}/>
        )
    }

    private onDateChanged(date: Date): void {
        this.setState({
            selectedDate: date,
            isLoading: true
        }, () => {
            this.fetchReport(date)
        });
    }

    private fetchReport(date: Date): void {
        ReportTabContainer.fetch(this.getEndpointUrl(), date)
            .then(response => {
                if (response.ok) {
                    response.json().then((result: ReportViewModel) => this.onReportFetchingCompleted(result));
                } else {
                    response.status === 404 ?
                        this.onReportFetchingCompleted(undefined, ResponseStatus.notFound) :
                        this.onReportFetchingCompleted(undefined, ResponseStatus.error);
                }
            })
    }

    private getEndpointUrl(): string {
        switch (this.props.reportType) {
            case ReportType.DailyReport:
                return "/cosa/dailyReport";
            case ReportType.WeeklyReport:
                return "/cosa/weeklyReport";
        }
    }

    private getSelectDateText(): string {
        switch (this.props.reportType) {
            case ReportType.DailyReport:
                return this.props.translations.selectDate;
            case ReportType.WeeklyReport:
                return this.props.translations.selectWeek;
        }
    }

    private onReportFetchingCompleted(report: ReportViewModel | undefined, status: ResponseStatus = ResponseStatus.ok) {
        this.setState({
            selectedReport: report,
            hasError: status !== ResponseStatus.ok,
            isLoading: false
        }, () => {
            this.props.onReportChanged(this.state.selectedDate, this.state.selectedReport);
        })
    }

    protected static fetch(url: string, date: Date) {
        return fetch(`${url}?date=${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, {
            method: "GET",
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
    }
}