import React, { FC } from "react";
import { ButtonFill } from "../../../../react-components/Buttons/ButtonFill";
import { ProductCampaignSubPageHeaderButtonsProps } from "./ProductCampaignSubPageHeaderButtonsProps.csharp";
import { ComponentProps } from "../../../../Partials/ComponentProps.csharp";

export const ProductCampaignSubPageHeaderButtons: FC<
  ComponentProps<ProductCampaignSubPageHeaderButtonsProps>
> = ({ model: { primaryLink } }) => {
  return (
    <div className="ProductCampaignSubPageHeader__bottomRow">
      {primaryLink && primaryLink.href && primaryLink.text && (
        <ButtonFill
          as="a"
          color="blue"
          title={primaryLink.title}
          target={primaryLink.target}
          href={primaryLink.href}
        >
          {primaryLink.text}
        </ButtonFill>
      )}
    </div>
  );
};
