import React from "react";

interface GalleryModalCaptionProps {
  captionHtml?: string;
}

export const GalleryModalCaption = ({ captionHtml }: GalleryModalCaptionProps) =>
  captionHtml ? (
    <figcaption
      className="GalleryModal__caption"
      dangerouslySetInnerHTML={{ __html: captionHtml }}
    />
  ) : null;
