import { lowerFirst, upperFirst } from "lodash";
import { CheckboxGroupOption } from "../../../react-components/Inputs/CheckboxGroup/CheckboxGroup";
import { OfficeLocationAffiliationType } from "../OfficeLocationAffiliationType.csharp";
import { OfficeLocationContactType } from "../OfficeLocationContactType.csharp";
import { OfficeLocationsFilterItemViewModel } from "../OfficeLocationsFilterItemViewModel.csharp";
import { OfficeLocationViewModel } from '../OfficeLocationViewModel.csharp';
import { RadioSelectOption } from "../../../react-components/Discovery/Inputs/RadioSelect/RadioSelect.types";
import { OfficeContactInfoViewModel } from "../OfficeContactInfoViewModel.csharp";
import { LatLng, calculateGeoDistanceBetween } from "../../../react-components/distanceUtils";

export enum OfficeLocationsURLSearchParam {
  Query = "q",
  Type = "type",
  CategoryID = "category",
  SelectedOfficeID = "selectedOffice",
}

export enum OfficeLocationsDisplayType {
  Default,
  Nearby,
  NoOffices,
}

enum AddressComponentType {
  Locatity = "locality",
  Country = "country",
  Route = "route",
}

export const getAllOfficeLocationCategories = (
  items: OfficeLocationsFilterItemViewModel[],
): RadioSelectOption[] => {
  return items.map((item) => ({
    value: item.value,
    label: item.text,
  }));
};

export const officeLocationAffiliationTypes = new Map([
  [OfficeLocationAffiliationType[OfficeLocationAffiliationType.Maritime], "Maritime offices"],
  [OfficeLocationAffiliationType[OfficeLocationAffiliationType.Discovery], "Discovery offices"],
]);

export const officeLocationContactTypes = new Map([
  [OfficeLocationContactType[OfficeLocationContactType.Sales], "Sales"],
	[OfficeLocationContactType[OfficeLocationContactType.Support], "Support"],
	[OfficeLocationContactType[OfficeLocationContactType.TrainingCenter], "Training"],
	[OfficeLocationContactType[OfficeLocationContactType.Rental], "Rental"],
]);

export const getAllOfficeLocationTypes = () => {
  return new Map([...officeLocationContactTypes, ...officeLocationAffiliationTypes]);
};

export const getAllOfficeLocationTypesAsFormValues = (): CheckboxGroupOption[] => {
  return Array.from(getAllOfficeLocationTypes()).map(([key, value]) => ({
    value: lowerFirst(key),
    label: value,
  }))
};

export const getSortedAllOfficeLocationTypesAsFormValues = (): CheckboxGroupOption[] => {
  let i = 0;

  const getOrder = (value: string) => {
    i++;
    if (i >= 3) i++;
    if (upperFirst(value) === OfficeLocationAffiliationType[OfficeLocationAffiliationType.Maritime])
      i = 3;
    if (upperFirst(value) === OfficeLocationAffiliationType[OfficeLocationAffiliationType.Discovery])
      i = 5;
    return i;
  };

  return getAllOfficeLocationTypesAsFormValues().map(type => ({
    ...type,
    order: getOrder(type.value),
  }))
  .sort((a, b) => a.order - b.order)
  .map(type => ({
    value: type.value,
    label: type.label,
  }));
};

export const sortOfficeLocations = (
  locations: OfficeLocationViewModel[],
  featuredLocationsIds: string[]
) => ([...locations]).sort((a, b) => {
  const indexA = featuredLocationsIds.indexOf(a.id);
  const indexB = featuredLocationsIds.indexOf(b.id);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) {
    return -1;
  }

  if (indexB !== -1) {
    return 1;
  }

  return 0;
});

export const filterOfficeLocations = (
  locations: OfficeLocationViewModel[],
  filters: {
    selectedCategory?: RadioSelectOption;
    selectedTypes: CheckboxGroupOption[];
    searchedPlace?: google.maps.places.PlaceResult;
    searchQuery?: string;
  },
) => {
  let officeLocations = [...locations];

  const { selectedCategory, selectedTypes, searchedPlace, searchQuery } = filters;

  /* Filter by category */
  if (selectedCategory && selectedCategory.value) {
    officeLocations = officeLocations.filter((location) =>
      location.relatedProductCategories.find(
        (category) => category.id.toString() === selectedCategory.value,
      ),
    );
  }

  /* Filter by types */
  if (selectedTypes.length > 0) {
    selectedTypes.forEach(({ value }) => {
      value = upperFirst(value);

      if (officeLocationAffiliationTypes.has(value)) {
        officeLocations = officeLocations.filter(
          (location) =>
            !!location.affiliationTypes.includes(
              OfficeLocationAffiliationType[value as keyof typeof OfficeLocationAffiliationType],
            ),
        );
        return;
      }

      if (officeLocationContactTypes.has(value)) {
        officeLocations = officeLocations.filter((location) => {
          const property = location[lowerFirst(value) as keyof OfficeLocationViewModel] as
            | OfficeContactInfoViewModel
            | undefined;

          return property && (property.email || property.phoneNumber);
        });
      }
    });
  }

  const locationsByName = getLocationsWithSearchName(officeLocations, searchQuery).filter(
    (l) => !officeLocations.every((ol) => ol.id === l.id),
  );

  if (locationsByName.length > 0) return locationsByName;

  /* Filter by search address */
  if (searchedPlace) {
    officeLocations = filterLocationsByType(
      searchedPlace,
      officeLocations,
      AddressComponentType.Country,
    );

    officeLocations = filterLocationsByType(
      searchedPlace,
      officeLocations,
      AddressComponentType.Locatity,
    );

    officeLocations = filterLocationsByType(
      searchedPlace,
      officeLocations,
      AddressComponentType.Route,
    );

    if (officeLocations.length > 0 && searchedPlace.geometry?.location) {
      const searchedPlaceCoords = {
        lat: searchedPlace.geometry.location.lat(),
        lng: searchedPlace.geometry.location.lng(),
      };

      const maximumStreetDistanceRadius = 2;
      const maximumCityDistanceRadius = 60;

      const streetComponent = getAddressComponentByType(searchedPlace, AddressComponentType.Route);
      const cityComponent = getAddressComponentByType(searchedPlace, AddressComponentType.Locatity);

      officeLocations = officeLocations.filter((location) => {
        const distance = calculateGeoDistanceBetween(searchedPlaceCoords, location);
        if (streetComponent.long_name && location.address.street)
          return distance <= maximumStreetDistanceRadius;
        if (cityComponent.long_name && location.address.city)
          return distance <= maximumCityDistanceRadius;
        return true;
      });
    }
  }

  return officeLocations;
};

export function getLocationsWithSearchName(
  locations: OfficeLocationViewModel[],
  searchQuery?: string,
) {
  if (!searchQuery || searchQuery.length < 3) return [];

  return locations.filter((l) => {
    return l.officeName.toLowerCase().includes(searchQuery.toLowerCase());
  });
}

function filterLocationsByType(
  searchedPlace: google.maps.places.PlaceResult,
  officeLocations: OfficeLocationViewModel[],
  type: AddressComponentType,
) {
  const addressComponent = getAddressComponentByType(searchedPlace, type);

  if (addressComponent.long_name.length === 0 && addressComponent.short_name.length === 0)
    return officeLocations;

  return officeLocations.filter((location) => {
    switch (type) {
      case AddressComponentType.Locatity:
        return (
          location.address.city.includes(addressComponent.long_name) ||
          location.address.city.includes(addressComponent.short_name)
        );
      case AddressComponentType.Country:
        return (
          location.address.country.includes(addressComponent.long_name) ||
          location.address.country.includes(addressComponent.short_name)
        );
      case AddressComponentType.Route:
        return (
          location.address.street.includes(addressComponent.long_name) ||
          location.address.street.includes(addressComponent.short_name)
        );
      default:
        return false;
    }
  });
}

function getAddressComponentByType(
  searchedPlace: google.maps.places.PlaceResult,
  type: AddressComponentType,
): Omit<google.maps.GeocoderAddressComponent, "types"> {
  if (!searchedPlace.address_components) return getDefaultAddressComponent();

  const addressComponent = searchedPlace.address_components.find((address) => {
    return address.types && address.types[0] === type.toString();
  });

  return addressComponent ?? getDefaultAddressComponent();
}

function getDefaultAddressComponent(): Omit<google.maps.GeocoderAddressComponent, "types"> {
  return { long_name: "", short_name: "" };
}

export const getPlaceLatLng = (place: google.maps.places.PlaceResult): LatLng | undefined => {
  if (place.geometry?.location) {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    return {
      lat,
      lng,
    };
  }

  return undefined;
};

export const getPlacesByLatLng = async ({ lat, lng }: LatLng) => {
  const geocoder = new google.maps.Geocoder();

  const response = await geocoder.geocode({
    location: { lat, lng },
  });

  return response.results;
};

interface PlaceIdResponse {
  placeId: string | undefined;
}

export const getPlaceIdFromQuery = async (
  map: google.maps.Map,
  query: string
) => {
  const request = {
    query,
    fields: ["place_id"]
  };

  const service = new google.maps.places.PlacesService(map);

  const findPlace = new Promise<PlaceIdResponse>((resolve, reject) => {
    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results?.length) {
        resolve({ placeId: results[0].place_id });
      } else {
        reject({ status });
      }
    });
  });

  return findPlace;
};

interface PlaceDetailsResponse {
  result: google.maps.places.PlaceResult | null;
}

export const getPlaceDetailsById = async (
  map: google.maps.Map,
  placeId: string
) => {
  const service = new google.maps.places.PlacesService(map);

  const placeDetails = new Promise<PlaceDetailsResponse>((resolve, reject) => {
    service.getDetails({
      placeId,
      fields: ["address_components", "geometry", "formatted_address"]
    }, (result, status) => {
      if (
        status === google.maps.places.PlacesServiceStatus.OK &&
        result?.geometry?.location
      ) {
        resolve({ result });
      } else {
        reject({ status });
      }
    });
  });

  return placeDetails;
};

export const getDistancesToOfficeLocations = async (
  officesSelectedForRequest: { office: OfficeLocationViewModel, distance: number }[],
  userCoords: LatLng,
) => {
  const distanceMatrixService = new google.maps.DistanceMatrixService();

  const destinations = officesSelectedForRequest.map<google.maps.LatLngLiteral>(({ office }) => ({ lat: office.lat, lng: office.lng }));

  const distances = new Promise<OfficeLocationViewModel[]>((resolve, reject) => {
    distanceMatrixService.getDistanceMatrix({ origins: [userCoords], destinations, travelMode: google.maps.TravelMode.DRIVING }, (response, status) => {
      if (status !== 'OK') {
        reject(status);
      }

      let sorted = [] as OfficeLocationViewModel[];

      const distancesBetweenUserAndDestinations = response!.rows[0].elements
      .filter(elem => elem.status === google.maps.DistanceMatrixElementStatus.OK)
      .map((elem, index) => ({
        distance: elem.distance,
        office: officesSelectedForRequest[index].office, //Distance Matrix responses returns elements in the same order as destinations
      }));

      if (distancesBetweenUserAndDestinations && distancesBetweenUserAndDestinations.length > 0) {
        sorted = distancesBetweenUserAndDestinations.sort((a,b) => a.distance.value - b.distance.value).map(({ office }) => office);
      }

      resolve(sorted);
    });
  });

  return distances;
};

export const getNearbyOfficeLocations = async (
  locations: OfficeLocationViewModel[],
  userCoords: LatLng,
): Promise<OfficeLocationViewModel[]> => {
  const maxCoordsPerRequest = 25;
  const officesSelectedForRequest = locations.map(location => ({
    office: location,
    distance: calculateGeoDistanceBetween(userCoords, location)
  }))
  .sort((a, b) => a.distance - b.distance)
  .slice(0, maxCoordsPerRequest);

   return await getDistancesToOfficeLocations(officesSelectedForRequest, userCoords);
};

export const compareCoords = (coords1: LatLng, coords2: LatLng) => {
  if (
    coords1.lat.toString() === coords2.lat.toString() &&
    coords1.lng.toString() === coords2.lng.toString()
  ) {
    return true;
  }

  return false;
};
