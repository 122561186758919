import * as React from 'react';
import {ComponentProps} from "../../Partials/ComponentProps.csharp";
import {ContentCardViewModel} from "../../Partials/ContentCard/ContentCardViewModel.csharp";
import ContentBar from "../../Partials/ContentBar/ContentBar";

const renderCourses = () => {
    let courses = [];

    for (let i = 0; i < 3; i++) {
        courses.push(
            <li className="CourseSection__item">
                <ContentBar
                    title='Automation'
                    color='white'
                >
                    <p className="CourseSection__description">Our mission is to provide quality training and solutions
                        for safe navigation and operations at sea in the commercial offshore, maritime, subsea and
                        merchant shipping industries.</p>
                    <a className="CourseSection__link" href="https://www.kongsberg.com/">Read more</a>

                    <h4 className="CourseSection__schedule-heading">Scheduled courses</h4>

                    <ul className="CourseSection__schedule">
                        <li className="CourseSection__row CourseSection__row--head">
                            <span className="CourseSection__label">Date</span>
                            <span className="CourseSection__label">Duration</span>
                            <span className="CourseSection__label">Location</span>
                            <span className="CourseSection__label">Price</span>
                        </li>
                        {renderSchedule()}
                    </ul>
                </ContentBar>
            </li>);
        }

    return courses;
};

const renderSchedule = () => {
    let schedule = [];

    for (let i = 0; i < 3; i++) {
        schedule.push(
            <li className="CourseSection__row">
                <span className="CourseSection__value">
                    <span className="CourseSection__label CourseSection__label--inline">Date: </span>
                    14/01/2019–16/01/2019
                </span>
                <span className="CourseSection__value">
                    <span className="CourseSection__label CourseSection__label--inline">Duration: </span>
                    3days
                </span>
                <span className="CourseSection__value">
                    <span className="CourseSection__label CourseSection__label--inline">Location: </span>
                    Aberdeen
                </span>
                <span className="CourseSection__value">
                    <span className="CourseSection__label CourseSection__label--inline">Price: </span>
                    GBP 2,000.00
                </span>
                <span className="CourseSection__value">
                    <a className="Btn Btn--link"  href="https://www.kongsberg.com/">Book</a>
                </span>
            </li>
        )
    }

    return schedule;
};


export const CourseSection = (props: ComponentProps<ContentCardViewModel>) => {
    const items = renderCourses();
    
    return (
        <section className="Section--dark-tint ">
            <div className="wrapper grid">
                <h1 className="Section__title">Training</h1>
                <section>
                    <h2 className="Section__subtitle">Subheader or category</h2>
                    <ul className="CourseSection ">
                        { items }
                    </ul>
                </section>
            </div>
        </section>
    )
};