import * as React from 'react';
import classNames from 'classnames';

interface ContentBarProps {
    title: string,
    text?: string,
    children?: React.ReactNode,
    url?: string,
    color?: string,
}

interface ContentBarState {
    isExpanded: boolean
}

const contentBarColorClass : { [k: string] : string } = {
    'grey': 'ContentBar--kog_blue_tint_5',
    'white': 'ContentBar--white'
};

const contentColorClass : { [k: string] : string } = {
    'white': 'ContentBar__children--white'
};

export default class ContentBar extends React.Component<ContentBarProps, ContentBarState> {
    private static defaultProps = {
        color: 'grey'
    };
    
    constructor (props: ContentBarProps) {
        super(props);
        
        this.state = {
            isExpanded: false
        };
    }
    
    private onExpand = () => {
        this.setState(state => ({
            isExpanded: !state.isExpanded
        }));
    };
    
    render() {
        return (
            <>
                <div className={classNames(
                    "ContentBar", 
                    this.props.color && contentBarColorClass[this.props.color],
                    !this.props.children && 'ContentBar--link',
                    this.state.isExpanded && 'ContentBar--expanded'
                )}
                >
                    <div>
                        {this.props.title && <h2 className="ContentBar__title">{this.props.title}</h2>}
                        {this.props.text && <p className="ContentBar__text">{this.props.text}</p>}
                    </div>
                    { this.props.children ?
                        <>
                            <i className="ContentBar__icon" />
                            <button className="ContentBar__btn" onClick={this.onExpand} />
                        </>
                        :
                        this.props.url && <a className="ContentBar__btn" href={this.props.url} />
                    }

                </div>
                
                { this.props.children &&
                    <div className={classNames("ContentBar__children",
                        !this.state.isExpanded && 'ContentBar__children--hide',
                        this.props.color && contentColorClass[this.props.color])}
                    >
                        {this.props.children}
                    </div>
                }
            </>
        );
    }
}