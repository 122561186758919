import { ParamType, useSearchParams } from "../../../react-components/useSearchParams";
import { useCallback, useEffect, useReducer, useState } from "react";
import { OfficeLocationViewModel } from "../OfficeLocationViewModel.csharp";
import { RadioSelectOption } from "../../../react-components/Discovery/Inputs/RadioSelect/RadioSelect.types";
import { CheckboxGroupOption } from "../../../react-components/Inputs/CheckboxGroup/CheckboxGroup";
import {
  OfficeLocationsDisplayType,
  OfficeLocationsURLSearchParam,
  compareCoords,
  filterOfficeLocations,
  getLocationsWithSearchName,
  getNearbyOfficeLocations,
  getPlaceDetailsById,
  getPlaceIdFromQuery,
  getPlaceLatLng,
  getPlacesByLatLng,
  sortOfficeLocations,
} from "./OfficeLocationsWithMapListing.utils";
import { LatLng } from "../../../react-components/distanceUtils";
import {
  OfficeLocationsReducerActionType,
  officeLocationsReducer,
  officeLocationsReducerInitialState,
} from "./OfficeLocationsWithMapListing.reducer";

export const useOfficeLocations = (
  locations: OfficeLocationViewModel[],
  categories: RadioSelectOption[],
  types: CheckboxGroupOption[],
  featuredOfficeLocationsIds: string[],
) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [state, dispatch] = useReducer(officeLocationsReducer, officeLocationsReducerInitialState);

  const { params, setParams } = useSearchParams({
    [OfficeLocationsURLSearchParam.Query]: ParamType.String,
    [OfficeLocationsURLSearchParam.Type]: ParamType.Array,
    [OfficeLocationsURLSearchParam.CategoryID]: ParamType.String,
    [OfficeLocationsURLSearchParam.SelectedOfficeID]: ParamType.String,
  });

  const handleDeselectOfficeLocation = () => {
    dispatch({
      type: OfficeLocationsReducerActionType.CLEAR_SELECTED_OFFICE,
    });
    setParams({ selectedOffice: undefined });
  };

  const handleSelectOfficeLocation = (selectedOffice: OfficeLocationViewModel) => {
    if (state.officeLocations.find((location) => location.id === selectedOffice.id)) {
      dispatch({
        type: OfficeLocationsReducerActionType.SET_SELECTED_OFFICE,
        selectedOfficeId: selectedOffice.id,
      });
      setParams({ selectedOffice: selectedOffice.id });

      if (map) {
        map.panTo({ lat: selectedOffice.lat, lng: selectedOffice.lng });
      }
    }
  };

  const handleUserCoordsChange = (userCoords: LatLng, removeSearchedPlace = true) => {
    dispatch({
      type: OfficeLocationsReducerActionType.LOCATE_USER,
      userCoords: userCoords,
      removeSearchedPlace,
    });
  };

  const getFilteredLocations = useCallback(async () => {
    let officeLocations = sortOfficeLocations([...locations], featuredOfficeLocationsIds);

    officeLocations = filterOfficeLocations(officeLocations, {
      selectedCategory: state.filters.selectedCategory,
      selectedTypes: state.filters.selectedTypes,
      searchedPlace: state.filters.searchedPlace,
      searchQuery: state.filters.searchQuery,
    });

    if (state.filters.userCoords && officeLocations.length === 0) {
      try {
        officeLocations = await getNearbyOfficeLocations(locations, state.filters.userCoords);
      } catch {
        if (state.selectedOfficeId) {
          handleDeselectOfficeLocation();
        }

        dispatch({
          type: OfficeLocationsReducerActionType.SHOW_NO_OFFICES,
        });

        return [];
      }

      officeLocations = filterOfficeLocations(officeLocations, {
        selectedCategory: state.filters.selectedCategory,
        selectedTypes: state.filters.selectedTypes,
      });

      dispatch({
        type: OfficeLocationsReducerActionType.SHOW_NEAREST,
      });
    } else if (state.displayType !== OfficeLocationsDisplayType.Default) {
      dispatch({
        type: OfficeLocationsReducerActionType.SHOW_DEFAULT,
      });
    }

    if (officeLocations.length > 0) {
      if (
        state.filters.userCoords &&
        (state.latestAction === OfficeLocationsReducerActionType.LOCATE_USER ||
          state.latestAction === OfficeLocationsReducerActionType.SEARCH_PLACE ||
          state.latestAction === OfficeLocationsReducerActionType.SHOW_DEFAULT ||
          state.latestAction === OfficeLocationsReducerActionType.SHOW_NEAREST) &&
        state.initialSearch &&
        state.filters.searchedPlace &&
        !officeLocations.find((location) => location.id === state.selectedOfficeId)
      ) {
        handleSelectOfficeLocation(officeLocations[0]);

        dispatch({
          type: OfficeLocationsReducerActionType.CLEAR_INITIAL_SEARCH,
        });
      } else if (
        state.selectedOfficeId &&
        !officeLocations.find((location) => location.id === state.selectedOfficeId)
      ) {
        handleDeselectOfficeLocation();
      }
    } else {
      if (state.selectedOfficeId) {
        handleDeselectOfficeLocation();
      }

      dispatch({
        type: OfficeLocationsReducerActionType.SHOW_NO_OFFICES,
      });
    }

    return officeLocations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    locations,
    state.filters.selectedCategory,
    state.filters.selectedTypes,
    state.filters.searchedPlace,
    state.filters.searchQuery,
    featuredOfficeLocationsIds,
  ]);

  const handleSelectMarker = (selectedOffice: OfficeLocationViewModel) =>
    handleSelectOfficeLocation(selectedOffice);

  const handleDeselectMarker = handleDeselectOfficeLocation;

  const handleSearchPlace = (
    place?: google.maps.places.PlaceResult,
    query?: string,
    updateQuery = true,
  ) => {
    dispatch({
      type: OfficeLocationsReducerActionType.SEARCH_PLACE,
      searchedPlace: place,
      searchQuery: query || place?.formatted_address || "",
    });

    if (updateQuery) {
      setParams({ q: place?.formatted_address ?? undefined });
    }
  };

  const handleAutocompleteLocationSelection = (place: google.maps.places.PlaceResult) => {
    const coords = getPlaceLatLng(place);

    if (coords) {
      handleUserCoordsChange(coords);
    }

    handleSearchPlace(place);
  };

  const handleLocateMe = () => {
    return new Promise<void>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        async (position) => {
          const userCoords = { lat: position.coords.latitude, lng: position.coords.longitude };

          if (!(state.filters.userCoords && compareCoords(userCoords, state.filters.userCoords))) {
            handleUserCoordsChange(userCoords);

            if (map) {
              const results = await getPlacesByLatLng(userCoords);

              if (results.length > 0) {
                handleSearchPlace(results[0]);
              }
            }
          }

          dispatch({ type: OfficeLocationsReducerActionType.LOAD });

          resolve();
        },
        () => {
          dispatch({ type: OfficeLocationsReducerActionType.DISABLE_USER_LOCATION });
          dispatch({ type: OfficeLocationsReducerActionType.LOAD });

          reject();
        },
      );
    });
  };

  const handleSearchLocation = async (query: string) => {
    if (map) {
      setParams({ q: query });

      if (state.loaded) {
        dispatch({ type: OfficeLocationsReducerActionType.CLEAR_CURRENT_LOCATION });
      }

      if (getLocationsWithSearchName(locations, query)?.length > 0) return;

      const { placeId } = await getPlaceIdFromQuery(map, query);

      if (placeId) {
        const { result } = await getPlaceDetailsById(map, placeId);

        if (result) {
          const coords = getPlaceLatLng(result);
          if (coords) {
            handleUserCoordsChange(coords, false);
          }

          handleSearchPlace(result, query, false);
        }
      }
    }
  };

  const handleTypesSelect = (types: CheckboxGroupOption[] = []) => {
    dispatch({
      type: OfficeLocationsReducerActionType.SET_SELECTED_TYPES,
      selectedTypes: types,
    });

    setParams({
      type: types ? types.map(({ value }) => value) : undefined,
    });
  };

  const handleCategorySelect = (category?: RadioSelectOption) => {
    dispatch({
      type: OfficeLocationsReducerActionType.SET_SELECTED_CATEGORY,
      selectedCategory: category ?? categories[0],
    });

    setParams({
      category: category ? category.value : undefined,
    });
  };

  const handleSearchQueryChange = (query: string) => {
    dispatch({
      type: OfficeLocationsReducerActionType.SET_SEARCH_QUERY,
      searchQuery: query,
    });

    if (state.filters.userCoords && !query) {
      dispatch({
        type: OfficeLocationsReducerActionType.CLEAR_CURRENT_LOCATION,
      });
      setParams({ q: undefined, selectedOffice: undefined });
    }
  };

  useEffect(() => {
    if (map) {
      if (params.selectedOffice) {
        dispatch({
          type: OfficeLocationsReducerActionType.SET_SELECTED_OFFICE,
          selectedOfficeId: params.selectedOffice,
        });
      }

      if (params.q && params.q.length > 0) {
        dispatch({
          type: OfficeLocationsReducerActionType.SET_SEARCH_QUERY,
          searchQuery: params.q,
        });

        void handleSearchLocation(params.q);
      }

      if (params.type && params.type.length > 0) {
        const newTypes = [] as CheckboxGroupOption[];

        params.type.forEach((paramValue) => {
          const typeToFind = types.find(({ value }) => value === paramValue);

          if (typeToFind) {
            newTypes.push(typeToFind);
          }
        });

        dispatch({
          type: OfficeLocationsReducerActionType.SET_SELECTED_TYPES,
          selectedTypes: newTypes,
        });
      }

      if (params.category) {
        const categoryToFind = categories.find(({ value }) => value === params.category);

        if (categoryToFind) {
          dispatch({
            type: OfficeLocationsReducerActionType.SET_SELECTED_CATEGORY,
            selectedCategory: categoryToFind,
          });
        }
      }

      dispatch({ type: OfficeLocationsReducerActionType.LOAD });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (state.loaded) {
      void getFilteredLocations().then((officeLocations) => {
        dispatch({
          type: OfficeLocationsReducerActionType.SET_OFFICE_LOCATIONS,
          officeLocations: officeLocations,
        });
      });
    }
  }, [getFilteredLocations, state.loaded]);

  const onMapLoad = (map: google.maps.Map) => setMap(map);

  return {
    onMapLoad,
    loaded: state.loaded,
    filteredLocations: state.officeLocations,
    filters: state.filters,
    displayType: state.displayType,
    selectedOfficeId: state.selectedOfficeId,
    userLocationDisabled: state.userLocationDisabled,
    handleSearchQueryChange,
    handleAutocompleteLocationSelection,
    handleTypesSelect,
    handleCategorySelect,
    handleLocateMe,
    handleSearchLocation,
    handleSelectMarker,
    handleDeselectMarker,
    handleSelectOfficeLocation,
  };
};
