import React, { FC, useState } from "react";
import { ComponentProps } from "../../../ComponentProps.csharp";
import { CatalogList } from "../CatalogList";
import { CatalogListViewModel } from "../CatalogListViewModel.csharp";
import { CatalogItemViewModel } from "../CatalogItemViewModel.csharp";
import { useModal } from "../../../../react-components/Modal/useModal";
import { SolutionModal } from "../../../../react-components/Modal/SolutionModal/SolutionModal";

export const CatalogListWithModal: FC<ComponentProps<CatalogListViewModel>> = ({ model }) => {
  const [activeItem, setActiveItem] = useState<CatalogItemViewModel | null>(null);
  const { isOpen, toggleModal } = useModal();

  const handleClick = (item: CatalogItemViewModel) => {
    setActiveItem(item);
    toggleModal();
  };

  return (
    <>
      <CatalogList model={model} onClick={handleClick} />
      {activeItem && <SolutionModal isOpen={isOpen} onClose={toggleModal} {...activeItem} />}
    </>
  );
};
