function rot13(text: string): string {
  const input = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const output = "NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm";
  const index = function (x: string) {
    return input.indexOf(x);
  };
  const translate = function (x: string) {
    return index(x) > -1 ? output[index(x)] : x;
  };
  return text.split("").map(translate).join("");
}

document.addEventListener("DOMContentLoaded", () => {
  const protectedEmailLinks: NodeListOf<HTMLAnchorElement> =
    document.querySelectorAll("a[data-phref]");

  for (let i = 0; i < protectedEmailLinks.length; i++) {
    const link = protectedEmailLinks[i];

    const protectedEmailAddress = link.getAttribute("data-phref") || "";
    const protectedHtmlContent = link.getAttribute("data-phtml") || "";
    const plainEmailAddress = rot13(protectedEmailAddress);
    const plainHtmlContent = rot13(protectedHtmlContent);

    link.href = "mailto:" + plainEmailAddress;
    link.innerHTML = plainHtmlContent;
  }
});
