import React, { memo, useState } from "react";
import { KogGridProperties } from "../../../../../typings/kog-grid";
import { RelatedProductCategory } from "../../RelatedProductCategory.csharp";
import { OfficeLocationTag } from "./OfficeLocationTag";
import { OfficeLocationsListWithMapListItemTranslations } from "../../OfficeLocationsListWithMapListItemTranslations.csharp";

interface OfficeLocationsListItemPartnerCategoriesProps {
  categories: RelatedProductCategory[];
  translations: OfficeLocationsListWithMapListItemTranslations;
}

export const OfficeLocationsListItemPartnerCategories =
  memo<OfficeLocationsListItemPartnerCategoriesProps>(
    function OfficeLocationsListItemPartnerCategories({ categories, translations }) {
      const [showAllCategories, setShowAllCategories] = useState(false);

      const categoriesToShow = showAllCategories ? categories : categories.slice(0, 3);

      const handleToggleCategories = () =>
        setShowAllCategories((showAllCategories) => !showAllCategories);

      return (
        <div
          className="OfficeLocationsListItem__detailsSection kog-grid"
          style={{ "--gap": "0.5rem" } as KogGridProperties}
        >
          <p>
            <strong>{translations.partnerCategories}</strong>
          </p>

          <div
            className="OfficeLocationsListItem__productsCategories"
            aria-live="polite"
            aria-relevant="additions"
          >
            {categoriesToShow.map((category) => (
              <OfficeLocationTag key={category.id} label={category.name} />
            ))}

            {categories.length > 3 && (
              <button
                className="OfficeLocationsListItem__showAllCategoriesButton"
                onClick={handleToggleCategories}
              >
                {showAllCategories ? (
                  <span className="OfficeLocationsListItem__buttonLink">
                    {translations.showLessProductsCategories}
                  </span>
                ) : (
                  <OfficeLocationTag
                    label={`+ ${categories.length - 3} ${translations.showMoreProductsCategories}`}
                  />
                )}
              </button>
            )}
          </div>
        </div>
      );
    },
  );
