import classNames from 'classnames';
import React, { FC } from 'react';
import { useMicrositeContext } from '../Utils/micrositeContext';

interface MicrositeLogoProps {
  className?: string;
  laptopOnly?: boolean;
  mobileOnly?: boolean;
}

export const MicrositeLogo: FC<MicrositeLogoProps> = ({
  className,
  mobileOnly,
  laptopOnly,
}) => {
  const { isProductVisible, isProductBackButtonSticky, theme } = useMicrositeContext();
  return (
    <a href="/" className={classNames(
      "MicrositeLogo",
      `MicrositeLogo--${theme}`,
      className, {
      "MicrositeLogo--mobileOnly": mobileOnly,
      "MicrositeLogo--laptopOnly": laptopOnly,
      "MicrositeLogo--hidden": isProductVisible || isProductBackButtonSticky
    })}>
      <span className="visuallyHidden">Kongsberg Maritime</span>
    </a>
  )
};
