import React, { ComponentType, HTMLAttributes, useEffect, useState } from "react";
import { MotionConfig, motion } from "framer-motion";
import { useSynchronizedAnimations } from "./AnimatedComponent.utils";
import { AnimatedComponentProps, AnimationComponentTag } from "./AnimatedComponent.types";

export const AnimatedComponent = <Tag extends AnimationComponentTag>({
  tag,
  entry,
  exit,
  whileInView,
  viewport,
  isOn,
  className,
  children,
  conductor,
  orchestrationChild,
  initial = false,
  animationProps,
  overrideTransition,
  onAnimationStart,
  onAnimationComplete,
  ...additionalProps
}: AnimatedComponentProps<Tag>) => {
  const initialState =
    typeof conductor === "object"
      ? initial
        ? conductor.customAnimation.entry.name
        : conductor.customAnimation.exit.name
      : initial
        ? entry
        : exit;

  const [currentAnimation, setCurrentAnimation] = useState<string | undefined>(initialState);
  const synchronizedVariants = useSynchronizedAnimations(conductor, orchestrationChild);

  useEffect(() => {
    if (isOn !== undefined) {
      if (entry && exit) {
        isOn ? setCurrentAnimation(entry) : setCurrentAnimation(exit);
      }
      if (conductor && typeof conductor === "object") {
        isOn
          ? setCurrentAnimation(conductor.customAnimation.entry.name)
          : setCurrentAnimation(conductor.customAnimation.exit.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOn]);

  const ComponentToRender = motion[tag] as ComponentType<HTMLAttributes<HTMLOrSVGElement>>;

  return (
    <MotionConfig reducedMotion="user">
      <ComponentToRender
        className={className}
        variants={synchronizedVariants}
        custom={animationProps}
        initial={initial}
        animate={currentAnimation}
        exit={exit}
        whileInView={whileInView}
        viewport={viewport}
        transition={overrideTransition}
        onAnimationComplete={onAnimationComplete}
        onAnimationStart={onAnimationStart}
        {...additionalProps}
      >
        {children}
      </ComponentToRender>
    </MotionConfig>
  );
};
