import React from "react";
import { OfficeLocationsListNoOfficesReason } from "./OfficeLocationsListNoOfficesReason";
import { OfficeLocationsListProps } from "./OfficeLocationsList";

export const OfficeLocationsListNoOffices = ({
  translations,
}: OfficeLocationsListProps) => (
  <OfficeLocationsListNoOfficesReason
    title={translations.noResults.noOfficesMatchingYourCriteria.title}
    description={translations.noResults.noOfficesMatchingYourCriteria.description}
  />
);
