import React, { FC, useMemo, useState } from "react";
import classNames from "classnames";
import { ButtonOutlined } from "../../../react-components/Buttons/ButtonOutlined";
import { Chevron } from "../../../react-components/Icons/Chevron";
import { ProductContainsItem } from "./ProductContainsItem";
import { uniqueId } from "lodash";
import { useRect } from "../../../react-components/hooks/useRect";
import { useIsBelowScreenSize } from "../../../react-components/useIsBelowScreenSize";
import { ProductContainsProps } from "../ProductContainsProps.csharp";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { ResponsivePicture } from "../../../react-components/responsivePicture/responsivePicture";

const MAX_ITEMS_COUNT_COLLAPSED = 2;
const MAX_ITEMS_COUNT_COLLAPSED_TABLET = 4;

export const ProductContains: FC<ComponentProps<ProductContainsProps>> = ({
  model: { mainImage, items },
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [listRef, listRect] = useRect<HTMLUListElement>();
  const [firstItemRef, firstItemRect] = useRect<HTMLLIElement>();
  const isMobile = useIsBelowScreenSize("mobile");

  const handleToggle = () => {
    const newState = !isExpanded;
    setExpanded(newState);

    if (!newState && isMobile) {
      window.requestAnimationFrame(() => {
        if (listRef.current) {
          listRef.current.scrollIntoView();
        }
      });
    }
  };

  const htmlId = useMemo(() => uniqueId("ProductContains"), []);

  const maxHeight = useMemo(() => {
    return isExpanded ? listRect.scrollHeight : firstItemRect.height;
  }, [isExpanded, listRect, firstItemRect]);

  const showMoreButton = (isMobile && items.length > 2) || (!isMobile && items.length > 4);

  return (
    <div
      className={classNames("ProductContains", {
        "ProductContains--isExpanded": isExpanded,
      })}
    >
      <div className="ProductContains__products">
        {mainImage && (
          <div className="ProductContains__mainImage">
            <ResponsivePicture profile={mainImage.profile} model={mainImage.model} />
          </div>
        )}

        <ul
          id={`${htmlId}List`}
          className="ProductContains__list"
          aria-live="polite"
          ref={listRef}
          style={{ maxHeight: maxHeight || "auto" }}
        >
          {items.map((item, index) => (
            <ProductContainsItem
              key={`product-contains-${index}`}
              url={item.url}
              title={item.title}
              description={item.description}
              image={item.image}
              isHidden={
                !isExpanded &&
                index >= (isMobile ? MAX_ITEMS_COUNT_COLLAPSED : MAX_ITEMS_COUNT_COLLAPSED_TABLET)
              }
              isFocused={
                isExpanded &&
                index === (isMobile ? MAX_ITEMS_COUNT_COLLAPSED : MAX_ITEMS_COUNT_COLLAPSED_TABLET)
              }
              ref={index === 0 ? firstItemRef : null}
            />
          ))}
        </ul>
      </div>

      {showMoreButton && (
        <ButtonOutlined
          className="ProductContains__showMoreButton"
          icon={Chevron}
          iconPosition="right"
          onClick={handleToggle}
          aria-controls={`${htmlId}List`}
          aria-expanded={isExpanded}
        >
          {isExpanded ? "Show less" : "Show more"}
        </ButtonOutlined>
      )}
    </div>
  );
};
