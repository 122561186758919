import {
  UnauthorizedError,
  ForbiddenError,
  NotFoundError,
  ConflictError,
  InternalServerError,
} from "./errors";

export type RequestURL = RequestInfo | URL;
export type RequestMethod = "GET" | "POST";

export class APIClient {
  private defaultOptions: RequestInit = {
    credentials: "same-origin",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
  };

  constructor(options?: RequestInit) {
    if (options) {
      this.defaultOptions = options;
    }
  }

  protected async request<Response>(
    url: RequestURL,
    method?: RequestMethod,
    options?: RequestInit,
  ): Promise<Response> {
    const response = await fetch(url, {
      ...this.defaultOptions,
      method: method,
      ...options,
    });

    if (!response.ok) {
      if (response.status === 401) throw new UnauthorizedError(response.statusText);
      if (response.status === 403) throw new ForbiddenError(response.statusText);
      if (response.status === 404) throw new NotFoundError(response.statusText);
      if (response.status === 409) throw new ConflictError(response.statusText);
      if (response.status === 500) throw new InternalServerError(response.statusText);

      const error: Error = new Error(response.statusText);
      throw error;
    }

    return response.json() as Promise<Response>;
  }

  protected get<Response>(url: RequestURL, options?: RequestInit) {
    return this.request<Response>(url, "GET", options);
  }
}
