import React, { SVGProps, memo } from "react";

export const CheckmarkMedium = memo(function CheckmarkMedium(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5304 7.52941L10.2375 16.8223C9.55408 17.5057 8.44604 17.5057 7.76262 16.8223L3.46973 12.5294L4.53039 11.4688L8.82328 15.7616C8.92091 15.8593 9.0792 15.8593 9.17683 15.7616L18.4697 6.46875L19.5304 7.52941Z"
        fill="currentColor"
      />
    </svg>
  );
});
