import React from 'react';
import ReactModal from 'react-modal';
import ReactPlayer from 'react-player';
import { ComponentProps } from '../../Partials/ComponentProps.csharp';
import { HeroVideoViewModel } from '../../Partials/Hero/HeroVideoViewModel.csharp';

interface FullScreenVideoModalProps extends ComponentProps<HeroVideoViewModel> {
  isOpen: boolean;
  onClose(event: React.MouseEvent | React.KeyboardEvent): void;
}

const modalStyles = {
  overlay: {
    zIndex: 200,
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
  },
};

export const FullScreenVideoModal = ({ 
  model: { 
    url, 
    showControls, 
    autoplay, 
    loop 
  }, 
  isOpen, 
  onClose 
}: FullScreenVideoModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      className="FullScreenVideoModal"
      style={modalStyles}
    >
      <button className="FullScreenVideoModal__closeBtn" onClick={onClose}>
        <span className="visuallyHidden">Close</span>
      </button>
      <ReactPlayer 
        className="FullScreenVideoModal__player"
        url={url}
        playing={autoplay}
        controls={showControls}
        loop={loop}
        width="auto"
        height="auto"
      />
    </ReactModal>
  );
};
