import React, { ReactNode, memo } from "react";
import { AnimatedComponent } from "./AnimatedComponent/AnimatedComponent";
import { DRAWER_TRANSITION } from "./Discovery/Inputs/SelectOptions";
import { AnimatePresence } from "framer-motion";

interface CollapsibleProps {
  className?: string;
  isCollapsed?: boolean;
  children?: ReactNode;
}

export const Collapsible = memo(function Collapsible({
  className,
  isCollapsed = true,
  children
}: CollapsibleProps) {
  return (
    <AnimatePresence>
      {!isCollapsed && (
        <AnimatedComponent
          tag="div"
          className={className}
          initial="collapsed"
          entry="expand"
          exit="collapse"
          overrideTransition={DRAWER_TRANSITION}
          aria-hidden={isCollapsed}
        >
          {children}
        </AnimatedComponent>
      )}
    </AnimatePresence>
  );
});
