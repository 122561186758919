import React, { FC } from "react";
import { UpperContentProps } from "./UpperContentProps.csharp";
import { ComponentProps } from "../../ComponentProps.csharp";

export const UpperContent: FC<ComponentProps<UpperContentProps>> = ({
  model: { subtitle, leftSegmentText, rightSegmentText },
}) => {
  return (
    <div className="UpperContent">
      {subtitle && <h3 className="UpperContent__subtitle">{subtitle}</h3>}
      <div className="UpperContent__additionalInfo">
        {leftSegmentText && (
          <div className="UpperContent__segment">
            <span className="UpperContent__icon UpperContent__clock" /> {leftSegmentText}
          </div>
        )}
        {rightSegmentText && (
          <div className="UpperContent__segment">
            <span className="UpperContent__icon UpperContent__marker" /> {rightSegmentText}
          </div>
        )}
      </div>
    </div>
  );
};
