import * as React from "react";
import classNames from "classnames";
import { FacetFilterViewModel } from './FacetFilterViewModel.csharp';

export interface FacetFilterProps {
    facet: FacetFilterViewModel;
    text: string;
    index: number;
    isSelected: boolean;
    onChange: (facet: FacetFilterViewModel, i: number) => void;
}

export class FacetFilter extends React.Component<FacetFilterProps> {
    constructor(props: FacetFilterProps) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    }

    render() {
        return (
            <label className={classNames("FacetFilter", {"FacetFilter--active": this.props.isSelected})} htmlFor={this.props.facet.facetName}>
                {this.props.text}
                <input
                    type="checkbox"
                    value={this.props.facet.facetName}
                    id={this.props.facet.facetName}
                    onChange={this.handleOnChange}
                    onKeyDown={this.handleOnKeyDown}
                    className="visuallyHidden"
                />
            </label>
        )
    }

    private handleOnChange() {
        this.props.onChange(this.props.facet, this.props.index);
    }

    private handleOnKeyDown = (event: React.KeyboardEvent) => (
        event.key === 'Enter' ? this.props.onChange(this.props.facet, this.props.index) : undefined
    )
}