import React, { FC } from "react";
import classNames from "classnames";
import { useMicrositeContext } from "../Utils/micrositeContext";
import { ResponsivePictureProps } from "../../Images/ResponsivePictureProps.csharp";

interface ImageWithBlurProps {
  backgroundImage: ResponsivePictureProps;
  className?: string;
  imageClassName?: string;
}

export const ImageWithBlur: FC<ImageWithBlurProps> = ({
  backgroundImage,
  className,
  imageClassName,
}) => {
  const url = backgroundImage.model.url;
  const { theme } = useMicrositeContext();

  return (
    <div className={classNames("ImageWithBlur", `ImageWithBlur--${theme}`, className)}>
      <div
        className={classNames("ImageWithBlur__blur", imageClassName)}
        style={{ backgroundImage: `url(${url})` }}
      />
    </div>
  );
};
