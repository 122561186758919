import classNames from 'classnames';
import React, { FC } from 'react';
import { MicrositeLogo } from '../../Logo/MicrositeLogo';
import { useMicrositeContext } from '../../Utils/micrositeContext';

interface MicrositeSideBarProps {
  isOpen: boolean,
  setIsOpen: (value: boolean) => void;
}

export const MicrositeSideBar: FC<MicrositeSideBarProps> = ({
  isOpen,
  setIsOpen
}) => {
  const { theme } = useMicrositeContext();

  return (
    <div className={classNames("MicrositeSideBar", `MicrositeSideBar--${theme}`)}>
      <MicrositeLogo laptopOnly/>
      <div className="MicrositeSideBar__menuButtonOuter">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="MicrositeSideBar__menuButton"
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          <span className="visuallyHidden">Menu</span>
          <span className={classNames("MicrositeSideBar__hamburger", {
            "MicrositeSideBar__hamburger--open": isOpen
          })}>
            <span className="MicrositeSideBar__patty" />
          </span>
          <span className="MicrositeSideBar__outline"/>
        </button>
      </div>
    </div>
  )
};
