import React, { FC } from "react";
import classNames from "classnames";
import { ComponentProps } from "../ComponentProps.csharp";
import { NavigationViewModel } from "../../Navigation/NavigationViewModel.csharp";
import { NavigationColor } from "../../Navigation/NavigationColor.csharp";
import { ExternalLinkIcon } from "../../react-components/Discovery/Icons/ExternalLinkIcon";

interface TopNavProps extends ComponentProps<NavigationViewModel> {
  topNavTitle?: string;
}

export const TopNav: FC<TopNavProps> = ({ model }) => {
  return (
    <div
      className={classNames("Header__topNav", {
        "Header__topNav--blue":
          model.navigationColor === NavigationColor.Blue ||
          model.navigationColor === NavigationColor.Undefined,
        "Header__topNav--green": model.navigationColor === NavigationColor.Green,
        "Header__topNav--hiddenOnMobile": !model.hasTopNavTitle,
      })}
    >
      <div className="Header__topNavContent">
        <span className="Header__topNavTitle">
          {!!model.microMenuTitle && model.microMenuTitle}
        </span>
        {(!!model.microMenuItems.length || model.headerLink) && (
          <ul className="Header__topNavMenu">
            {model.microMenuItems.map((item, idx) => (
              <li key={idx} className="Header__topNavMenuMicroItem">
                <a href={item.linkUrl}>{item.title}</a>
              </li>
            ))}
            {model.headerLink && (
              <li>
                <a className="Header__topNavHeaderItemLink" href={model.headerLink.linkUrl}>
                  <span>{model.headerLink.title}</span>
                  <ExternalLinkIcon />
                </a>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};
