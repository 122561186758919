import * as React from "react";
import classNames from "classnames";
import { NavigationItemViewModel } from "../../Navigation/NavigationItemViewModel.csharp";

interface NavSubmenuKsatProps {
  submenuItems: NavigationItemViewModel[];
  isSubmenuOpen: boolean;
  additionalLinks: NavigationItemViewModel[];
  additionalLinksHeader: string;
  additionalLinksDescription: string;
}

const SubmenuItem = (menuItem: NavigationItemViewModel) => (
  <li className="NavSubmenuKsat__item">
    <a href={menuItem.linkUrl}>
      {menuItem.title}
      {menuItem.description && (
        <>
          <span className="visuallyHidden">:</span>
          <span>{menuItem.description}</span>
        </>
      )}
    </a>
  </li>
);

export const NavSubmenuKsat = (props: NavSubmenuKsatProps) => {
  const {
    additionalLinks,
    additionalLinksHeader,
    additionalLinksDescription,
    submenuItems,
    isSubmenuOpen,
  } = props;
  const [MenuItemHeader] = submenuItems;

  return (
    <div className={classNames("NavSubmenuKsat", { "NavSubmenuKsat--visible": isSubmenuOpen })}>
      <div className="NavSubmenuKsat__content">
        <div className="NavSubmenuKsat__links">
          <div className="NavSubmenuKsat__navBar">
            {MenuItemHeader && (
              <div className="NavSubmenuKsat__title">
                <a href={MenuItemHeader.linkUrl}>{MenuItemHeader.title}</a>
              </div>
            )}
          </div>
          {!!submenuItems?.length && (
            <ul className="NavSubmenuKsat__list">
              {submenuItems
                .filter((_, z) => z > 0)
                .map((submenuItem, j) => (
                  <SubmenuItem key={`submenuItem${j}`} {...submenuItem} />
                ))}
            </ul>
          )}
        </div>
        <div
          className={classNames("NavSubmenuKsat__articles", {
            "NavSubmenuKsat__articles--withPadding":
              additionalLinksHeader || additionalLinksDescription || !!additionalLinks?.length,
          })}
        >
          {additionalLinksHeader && (
            <div className="NavSubmenuKsat__title">
              <span>{additionalLinksHeader}</span>
            </div>
          )}
          {additionalLinksDescription && (
            <div className="NavSubmenuKsat__description">
              <span>{additionalLinksDescription}</span>
            </div>
          )}
          {!!additionalLinks?.length && (
            <ul className="NavSubmenuKsat__additionalLinks">
              {additionalLinks.map((link, idx) => (
                <li key={`link-${idx}`}>
                  <a href={link.linkUrl}>{link.description}</a>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
