import * as React from "react";
import {ReportViewModel} from "./ReportViewModel.csharp";
import {ReportSection} from "./ReportSection";
import {DownloadsSection} from "./DownloadsSection";
import {Loader} from "../Search/Loader";
import {ReactNode} from "react";
import {DatePicker} from "../DatePicker/DatePicker";
import {WeekPicker} from "../DatePicker/WeekPicker";

export enum ReportType {
    DailyReport,
    WeeklyReport
}

export interface ReportTabProps {
    reportType: ReportType,
    selectedDate: Date,
    onSelectedDateChanged: (date: Date) => void | undefined;
    report: ReportViewModel | undefined,
    calendarVisible: boolean,
    selectDateText: string,
    errorOccuredText: string,
    notFoundText: string,
    culture: string,
    hasError: boolean,
    isLoading: boolean
}

export class ReportTab extends React.PureComponent<ReportTabProps> {
    constructor(props: Readonly<ReportTabProps>) {
        super(props);
    }

    render() {
        return (
            <div className="Report__tab">
                {this.props.calendarVisible && this.renderCalendar()}
                {this.props.isLoading && <div className="Report__loader"><Loader/></div>}
                {this.renderReport()}
            </div>
        );
    }

    private renderCalendar(): ReactNode {
        return (
            <div className="Report__date">
                <label className="Report__dateLabel">{this.props.selectDateText}</label>
                <div className="Report_dateValue">
                    {this.renderDatePicker()}
                </div>
            </div>
        )
    }

    private renderDatePicker(): ReactNode {
        switch (this.props.reportType) {
            case ReportType.DailyReport:
                return (<DatePicker selectedValue={this.props.selectedDate}
                                    onValueChanged={(selectedDate) => this.props.onSelectedDateChanged(selectedDate)}
                                    culture={this.props.culture}/>);
            case ReportType.WeeklyReport:
                return (<WeekPicker selectedValue={this.props.selectedDate}
                                    onValueChanged={(selectedDate) => this.props.onSelectedDateChanged(selectedDate)}
                                    culture={this.props.culture}/>);
        }
    }

    private renderReport() {
        if (this.props.hasError) {
            return (<div> {this.props.errorOccuredText} </div>);
        }

        if (this.props.report) {
            return (

                <div>
                    <p>{this.props.report.description}</p>
                    {this.props.report.sections && this.props.report.sections.map((section, index) => (
                        <ReportSection key={`section-${index}`} model={section}/>
                    ))}
                    <DownloadsSection model={this.props.report}/>
                </div>
            );
        }

        return (<div> {this.props.notFoundText} </div>);
    }
}