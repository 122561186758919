import React, {
  FC,
  useState,
  ChangeEventHandler,
  KeyboardEventHandler,
  FormEventHandler,
  useRef,
} from "react";
import { ProductListingResult } from "./ProductListingResult.csharp";
import { FocusOn } from "react-focus-on";
import ProductSuggestions from "./ProductSuggestions/ProductSuggestions";
import { useIsBelowScreenSize } from "../../../../react-components/useIsBelowScreenSize";
import { ArrowRight } from "../../../../react-components/Icons/ArrowRight";
import { NoResultsFound } from "./NoResults/NoResultsFound";
import { SearchSuggestion } from "./SearchSuggestion/SearchSuggestion";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

export const MinimalSearchTermLength = 2;

export interface ProductListingSearchProps {
  onChange: (value: string) => void;
  onSubmit: FormEventHandler;
  searchResults?: ProductListingResult[];
  value: string;
}

const ProductListingSearch: FC<ProductListingSearchProps> = ({
  onSubmit,
  onChange,
  searchResults,
  value,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useIsBelowScreenSize("tablet");
  const inputRef = useRef<HTMLInputElement>(null);
  const nodeRef = useRef(null);

  const openModal = () => {
    const top = inputRef.current?.getBoundingClientRect().top;
    document?.body.style.setProperty("--top", top + "px");
    inputRef.current?.focus();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    inputRef.current?.blur();
    onChange("");
    setIsModalOpen(false);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value);
  };

  const handleFocus = () => {
    openModal();
  };

  const handleReset = () => {
    onChange("");
    inputRef.current?.focus();
  };

  const handleSubmit: FormEventHandler<HTMLInputElement | HTMLButtonElement> = (e) => {
    openModal();
    onSubmit(e);
  };

  const onKeyUp: KeyboardEventHandler<HTMLInputElement | HTMLUListElement> = (e) => {
    switch (e.key) {
      case "Escape":
        closeModal();
        if (!isMobile) inputRef.current?.focus();
        break;
      default:
        break;
    }
  };

  const noSearchTerm = !!value && value.length >= MinimalSearchTermLength;
  const noResults = noSearchTerm && !searchResults?.length && isModalOpen;
  const isDropdownExpanded = !!searchResults?.length || noResults;

  return (
    <FocusOn
      enabled={isModalOpen}
      onClickOutside={closeModal}
      scrollLock={isMobile}
      returnFocus={!isMobile}
      as={"div"}
      className="ProductListingSearch"
    >
      <CSSTransition
        nodeRef={nodeRef}
        in={isModalOpen}
        timeout={500}
        classNames="ProductListingSearch"
      >
        <form className={classNames("ProductListingSearch__searchBar")} ref={nodeRef}>
          <div className="ProductListingSearch__header">
            <button type="button" className="ProductListingSearch__close" onClick={closeModal}>
              <ArrowRight /> Back
            </button>
            <SearchSuggestion
              value={value}
              onChange={handleChange}
              onSubmit={handleSubmit}
              onReset={handleReset}
              onClick={handleFocus}
              onKeyUp={onKeyUp}
              focusable={!!value?.length}
              placeholderText="Search for products in this category..."
              className="ProductListingSearch__searchInput"
              aria-expanded={isDropdownExpanded}
              aria-controls="suggestionDropdown"
              ref={inputRef}
            />
          </div>
          <div
            className="ProductListingSearch__results"
            aria-hidden={!(isDropdownExpanded || noResults)}
          >
            {!noResults && (
              <ProductSuggestions
                searchResults={searchResults}
                isExpanded={isDropdownExpanded}
                onKeyUp={onKeyUp}
              />
            )}
            {!!noResults && <NoResultsFound />}
          </div>
        </form>
      </CSSTransition>
    </FocusOn>
  );
};

export default ProductListingSearch;
