import classNames from "classnames";
import React, { ReactNode, memo } from "react";

interface OfficeLocationsListItemLinkProps {
  href: string;
  children: ReactNode;
  isSmall?: boolean;
  isExternal?: boolean;
}

export const OfficeLocationsListItemLink = memo<OfficeLocationsListItemLinkProps>(function OfficeLocationsListItemLink({
  href,
  children,
  isSmall = false,
  isExternal = false,
}) {
  if (!children) return null;

  return (
    <a
      className={classNames("OfficeLocationsListItem__link", {
        "OfficeLocationsListItem__link--small": isSmall,
        "OfficeLocationsListItem__link--websiteLink": isExternal,
      })}
      href={href}
      {...isExternal ? { target: "_blank" } : {}}
      {...isExternal ? { rel: "noreferrer" } : {}}
    >
      {children}
    </a>
  );
});
