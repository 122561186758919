import { immer } from "zustand/middleware/immer";
import { Immer } from "./types";

interface LayoutHeaderState {
  height: number;
  setHeight: (height: number) => void;
}

interface LayoutState {
  header: LayoutHeaderState;
}

export interface LayoutSlice {
  layout: LayoutState;
}

export const createLayoutSlice: Immer<LayoutSlice> = immer((set) => ({
  layout: {
    header: {
      height: 0,
      setHeight: (height) =>
        set((state) => {
          state.layout.header.height = height;
        }),
    },
  },
}));
