import React, { forwardRef, MouseEventHandler } from "react";
import { TileProps } from "../TileProps";
import { ArrowRight } from "../../../Icons/ArrowRight";
import { ResponsivePicture } from "../../../responsivePicture/responsivePicture";

export const HeroTile = forwardRef<HTMLAnchorElement | HTMLButtonElement, TileProps>(
  function HeroTile({ href, picture, title, description, onClick }, ref) {
    const isButton = onClick !== undefined;

    const handleClick: MouseEventHandler = (event) => {
      if (onClick) {
        event.preventDefault();

        onClick();
      }
    };

    const content = (
      <>
        <div className="HeroTile__imageContainer">
          {picture && (
            <ResponsivePicture
              className="HeroTile__image"
              model={picture.model}
              profile={picture.profile}
            />
          )}
        </div>
        <div className="HeroTile__overlay">
          <div className="HeroTile__content">
            <h2 className="HeroTile__title">{title}</h2>
            {description && (
              <div className="HeroTile__descriptionWrapper">
                <p className="HeroTile__description">{description}</p>
              </div>
            )}
          </div>
          <div className="HeroTile__icon">
            <ArrowRight />
          </div>
        </div>
      </>
    );

    return isButton ? (
      <button
        className="HeroTile"
        onClick={handleClick}
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
      >
        {content}
      </button>
    ) : (
      <a
        href={href}
        className="HeroTile"
        onClick={handleClick}
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
      >
        {content}
      </a>
    );
  },
);
