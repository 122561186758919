import classNames from "classnames";
import React from "react";
import { useCheckbox } from "../hooks/useCheckbox";

interface PillProps {
  name: string;
  value: string;
  label: string;
  checked?: boolean;
  onChange?: (value: string, checked: boolean) => void;
  className?: string;
}

export const Pill = ({ name, value, label, checked, onChange, className }: PillProps) => {
  const { handleChange } = useCheckbox({ value, onChange });

  return (
    <div className={classNames("Pill", className)}>
      <label className="Pill__label">
        <input
          className="Pill__input"
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={handleChange}
        />
        <span className="Pill__inner">
          <span className="Pill__labelText">{label}</span>
        </span>
      </label>
    </div>
  );
};
