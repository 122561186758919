import React from "react";
import { Chevron } from "../Icons/Chevron";
import { ButtonGhost } from "../Buttons/ButtonGhost";

interface SectionsNavbarSliderArrowsProps {
  color: "base" | "teal";
}

export const SectionsNavbarSliderArrows = ({ color }: SectionsNavbarSliderArrowsProps) => (
  <div className="SectionsNavbar__arrows splide__arrows">
    <ButtonGhost
      className={`SectionsNavbar__arrow SectionsNavbar__arrow--${color} splide__arrow splide__arrow--prev`}
      type="icon"
      color={color}
      icon={Chevron}
      mode={color === "base" ? "light" : "dark"}
    />
    <ButtonGhost
      className={`SectionsNavbar__arrow SectionsNavbar__arrow--${color} splide__arrow splide__arrow--next`}
      type="icon"
      color={color}
      icon={Chevron}
      mode={color === "base" ? "light" : "dark"}
    />
  </div>
);
