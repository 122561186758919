import React from "react";
import { LabeledHotSpotBlockViewModel } from "./LabeledHotSpotBlockViewModel.csharp";
import { HotSpot } from "./HotSpot";

interface LabeledHotSpotProps {
  hotSpots: LabeledHotSpotBlockViewModel[];
}

export const LabeledHotSpot: React.FC<LabeledHotSpotProps> = ({
  hotSpots, 
}) => {
  console.log(hotSpots);
  return (
    <>
{hotSpots.map((hotSpot, index) => (
        <div
          className="LabeledHotSpot"
          key={index}
          style={{
                  left: `${hotSpot.x}%`,
                  top: `${hotSpot.y}%`,
                }}
        >
          <HotSpot {...hotSpot} />
        </div>
      ))}
</>);
};