import * as React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from 'react-day-picker/moment';
import {formatDate, mapCultureFromEpiServer} from "../../react-components/dateUtil";
import {RefObject} from "react";

export interface DatePickerProps {
    selectedValue: Date;
    onValueChanged: (value: Date) => void,
    culture: string
}

export interface DatePickerState {
    selectedDay: Date | undefined
}

export class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
    private readonly inputRef: RefObject<HTMLInputElement>;

    constructor(props: DatePickerProps) {
        super(props);
        this.inputRef = React.createRef();
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.showDatePicker = this.showDatePicker.bind(this);
        this.state = {
            selectedDay: props.selectedValue
        };
    }

    componentDidMount() {
        this.setState({});
    }

    handleDayChange(selectedDay: Date) {
        this.setState({ selectedDay });
        this.props.onValueChanged(selectedDay);
    }

    render() {
        return (
            <div className="DatePicker">
                <DayPickerInput
                    component={this.renderInput}
                    value={this.state.selectedDay}
                    formatDate={(date) => formatDate(date, this.props.culture)}
                    format="DD.MM.YYYY"
                    onDayChange={this.handleDayChange}
                    dayPickerProps={{
                        selectedDays: this.state.selectedDay,
                        firstDayOfWeek: 1,
                        localeUtils: MomentLocaleUtils,
                        locale: mapCultureFromEpiServer(this.props.culture),
                        onMonthChange: this.handleMonthChange
                    }}
                />
            </div>
        );
    }

    private renderInput = React.forwardRef(({...props}, ref) => {
        return (
            <div className="DatePicker-Component">
                <input className="DatePicker-Input" ref={this.inputRef} {...props} readOnly />
                <i className="DatePicker-Icon" onClick={this.showDatePicker}/>
            </div>
        );
    });

    private handleMonthChange() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };

    private showDatePicker() {
        if (this.inputRef.current) {
            this.inputRef.current.click();
            this.inputRef.current.focus();
        }
    };
}