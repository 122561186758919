import React, { FC } from "react";
import { AgendaItem } from "./AgendaItem";
import { AgendaItemViewModel } from "./AgendaItemViewModel.csharp";

interface AgendaDayProps {
  items: AgendaItemViewModel[];
  speakerLabel: string;
  readLessLabel: string;
  readMoreLabel: string;
  visibleItemsCount?: number;
  timeZoneToDisplay: string | null;
}
export const AgendaDay: FC<AgendaDayProps> = ({
  items,
  speakerLabel,
  readLessLabel,
  readMoreLabel,
  visibleItemsCount,
  timeZoneToDisplay,
}) => {
  return (
    <ul className="Agenda__events">
      {items.slice(0, visibleItemsCount).map((item, i) => (
        <AgendaItem
          key={item.startAtString + i}
          title={item.title}
          speakerName={item.speakerName}
          description={item.description}
          startAtString={item.startAtString}
          endAtString={item.endAtString}
          speakerLabel={speakerLabel}
          readLessLabel={readLessLabel}
          readMoreLabel={readMoreLabel}
          timeZoneToDisplay={timeZoneToDisplay}
        />
      ))}
    </ul>
  );
};
