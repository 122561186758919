import React from "react";
import { GroupBase, MenuProps } from "react-select";
import { RadioSelectOption } from "./RadioSelect.types";

export const Menu = ({ children, innerProps, innerRef }: MenuProps<RadioSelectOption, false, GroupBase<RadioSelectOption>>) => {
  return (
    <div
      className="RadioSelect__menu"
      ref={innerRef}
      {...innerProps}
    >
      {children}
    </div>
  )
};
