import React from "react";
import { InputGroupAccordion } from "../../../../react-components/Inputs/InputGroupAccordion/InputGroupAccordion";
import { Filters, FiltersState } from "../ProductsListing.types";
import { upperFirst, camelCase } from "lodash";
import { CheckboxGroupOption } from "../../../../react-components/Inputs/CheckboxGroup/CheckboxGroup";

interface ProductsListingFiltersListProps {
  isMobile: boolean;
  filters: Filters;
  filtersState: FiltersState;
  onFiltersChange: (key: string, newValues: CheckboxGroupOption[]) => void;
}

export const ProductsListingFiltersList = ({
  isMobile,
  filters,
  filtersState,
  onFiltersChange,
}: ProductsListingFiltersListProps) => {
  const handleChange = (key: string) => {
    return (newValues: CheckboxGroupOption[]) => {
      return onFiltersChange(key, newValues);
    };
  };

  const renderFilters = () =>
    Object.entries(filters)
      .filter(([, filter]) => filter.options.length > 0)
      .map(([key, filter]) => (
        <InputGroupAccordion
          key={key}
          id={upperFirst(camelCase(key))}
          name={key}
          label={filter.label}
          currentValues={filtersState[key]}
          options={filter.options}
          groupType={filter.groupType}
          onChange={handleChange(key)}
        />
      ));

  return isMobile ? (
    <>{renderFilters()}</>
  ) : (
    <div className="ProductsListingFilters__content">{renderFilters()}</div>
  );
};
