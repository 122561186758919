import classNames from "classnames";
import React from "react";

export enum OfficeLocationBadgeColor {
  Blue = 'blue',
  Teal = 'teal',
}

interface OfficeLocationBadgeProps {
  name: string;
  color?: OfficeLocationBadgeColor;
}

export const OfficeLocationBadge = ({ name, color }: OfficeLocationBadgeProps) => {
  return (
    <div className={classNames("OfficeLocationBadge", {
      [`OfficeLocationBadge--${color}`]: color,
    })}>{name}</div>
  );
};
