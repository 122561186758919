import { ComponentConstructor, EventInterface, EventMap, Options } from "@splidejs/splide";
import { ContentListBlockSliderBase } from "./ContentListBlockSliderBase";
import { ArrowsPosition } from "./ArrowsPosition.csharp";

const ARROW_TRANSITION_CLASSNAME = "ContentListSlider__arrows--hasTransition";

export class ContentListBlockSlider<T extends HTMLElement> extends ContentListBlockSliderBase<T> {
  protected inRow = 2;

  protected options: Options = {
    gap: 12,
    autoWidth: true,
    breakpoints: {
      768: {
        gap: 20,
      },
    },
  };

  protected inRowOptions = {
    1: {
      perPage: 1,
      autoWidth: false,
    },
    2: {
      perPage: 1,
      breakpoints: {
        520: {
          perPage: 2,
        },
        768: {
          perPage: 1,
        },
        1040: {
          perPage: 2,
        },
      },
    },
    3: {
      perPage: 1,
      breakpoints: {
        520: {
          perPage: 2,
        },
        768: {
          perPage: 3,
        },
      },
    },
    4: {
      perPage: 1,
      breakpoints: {
        520: {
          perPage: 3,
        },
        1040: {
          perPage: 4,
        },
      },
    },
  };

  public constructor(splideElement: T | null, inRow?: number, arrowsPosition?: ArrowsPosition) {
    super(splideElement, arrowsPosition);

    if (inRow) {
      this.inRow = inRow;
    }

    this.saveOptions(this.getSpecificInRowOptions(this.inRow));

    this.initializeSlider();
  }

  protected getComponent(slider: this): ComponentConstructor {
    return (splide) => {
      if (!slider.isInitialized()) {
        slider.initializeSlider(splide);
      }

      const { on } = EventInterface(splide);

      const handleOverflow: EventMap["overflow"] = (isOverflow) => {
        slider.saveOptions({
          drag: isOverflow,
        });
      };

      const handlePaginationUpdated = () => {
        const paginationItems = splide.Components.Pagination.items;

        if (paginationItems.length > 1) {
          slider.showArrows();
        } else {
          slider.hideArrows();
        }

        if (slider.arrowsPosition === ArrowsPosition.Center) {
          slider.enableArrowsTransition();
        }
      };

      on("overflow", handleOverflow);
      on("pagination:mounted", handlePaginationUpdated);
      on("pagination:updated", handlePaginationUpdated);

      return {};
    };
  }

  private getSpecificInRowOptions(inRow: number) {
    const options = this.inRowOptions[inRow as keyof typeof this.inRowOptions];

    return options ?? {};
  }

  public showArrows() {
    const { arrows } = this.splide.Components.Elements;

    if (arrows) {
      arrows.style.display = "";
    }
  }

  public hideArrows() {
    const { arrows } = this.splide.Components.Elements;

    if (arrows) {
      arrows.style.display = "none";
    }
  }

  private enableArrowsTransition() {
    const { Elements } = this.splide.Components;

    if (Elements.arrows && !Elements.arrows.classList.contains(ARROW_TRANSITION_CLASSNAME)) {
      Elements.arrows.classList.add(ARROW_TRANSITION_CLASSNAME);
    }
  }
}
