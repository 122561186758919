import React, { FC, FormEvent, useState } from "react";
import { ComponentProps } from "../ComponentProps.csharp";
import { SearchInputViewModel } from "../Search/SearchInputViewModel.csharp";
import { SearchField } from "../../react-components/Inputs/SearchField/SearchField";
import { UrlHelper } from "../../react-components/urlUtils";
import { Redirect } from "../../react-components/Redirect";

interface NavSearchInputProps extends ComponentProps<SearchInputViewModel> {
  color: "light" | "dark";
  a11yHidden?: boolean;
}

export const NavSearchInput: FC<NavSearchInputProps> = ({
  model: { searchPageUrl, translations },
  color,
  a11yHidden = false,
}) => {
  const [inputValue, setInputValue] = useState("");

  const onSubmit = (event: FormEvent<HTMLFormElement | HTMLInputElement | HTMLButtonElement>) => {
    event.preventDefault();

    if (inputValue && searchPageUrl) {
      const searchPage = UrlHelper.constructUrl(searchPageUrl);

      if (searchPage) {
        searchPage.searchParams.set("searchTerm", inputValue);

        Redirect.to(searchPage);
      }
    }
  };

  return (
    <div className="NavSearchInput">
      <form className="NavSearchInput__form" onSubmit={onSubmit}>
        <SearchField
          className="NavSearchInput__searchField"
          placeholderText={translations.search}
          searchButtonLabel={translations.search}
          a11yHidden={a11yHidden}
          color={color}
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          onSubmit={onSubmit}
        />
      </form>
    </div>
  );
};
