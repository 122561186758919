/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from "react";
import { AccordionViewModel } from "../AccordionViewModel.csharp";
import classNames from "classnames";

interface LegacyAccordionListState<T> {
  openedAccordion: T | null;
}

interface LegacyAccordionListProps<T> {
  accordions: AccordionViewModel<T>[];
  children: (accordion: T) => JSX.Element;
}

export const LegacyAccordionList = <T,>({ accordions, children }: LegacyAccordionListProps<T>) => {
  const [{ openedAccordion }, setState] = React.useState<
    LegacyAccordionListState<AccordionViewModel<T>>
  >({ openedAccordion: null });
  const isOpened = (accordion: AccordionViewModel<T>) => openedAccordion === accordion;
  const onAccordionClick = (accordion: AccordionViewModel<T>) =>
    setState((prevState: LegacyAccordionListState<AccordionViewModel<T>>) => ({
      openedAccordion: accordion === prevState.openedAccordion ? null : accordion,
    }));

  return (
    <section className="LegacyAccordionList grid wrapper">
      {accordions &&
        accordions.map((x: AccordionViewModel<T>, i: number) => (
          <div
            key={x.heading || i}
            className={classNames(
              "LegacyAccordionList__accordion",
              isOpened(x) ? "LegacyAccordionList__accordion--opened" : "",
            )}
          >
            <h2
              className="LegacyAccordionList__title"
              onClick={() => onAccordionClick(x)}
              onKeyDown={() => onAccordionClick(x)}
            >
              <span>{x.heading}</span>
              <i className="LegacyAccordionList__arrow" />
            </h2>
            <div className="LegacyAccordionList__itemsWrapper">
              <div className="LegacyAccordionList__items">{children(x.model)}</div>
            </div>
          </div>
        ))}
    </section>
  );
};
