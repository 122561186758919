import React, { FC } from "react";

interface OfficeLocationDepartmentEmailProps {
  email: string;
}

const OfficeLocationDepartmentEmail: FC<
  OfficeLocationDepartmentEmailProps
> = ({ email }) => {
  const emailParts =
    email != undefined && email !== "" ? email?.split(".") : [];

  return (
    <>
      {emailParts?.slice(0, emailParts.length - 1).join(".")}
      .<wbr />
      {emailParts[emailParts?.length - 1]}
    </>
  );
};

export default OfficeLocationDepartmentEmail;
