function calculateProfileHeight() {
    const profiles: NodeListOf<HTMLElement> = document.querySelectorAll(".Profile__inner") || null;

    calculateCaptionHeight(profiles);

    window.addEventListener('resize', () => {
        calculateCaptionHeight(profiles)
    });

    function calculateCaptionHeight(profiles: NodeListOf<HTMLElement>) {
        profiles.forEach(profile => {
            let gridHeight: number = getBackgroundImgSize(profile);
            profile.style.height = "auto";

            window.requestAnimationFrame(() => {
                profile.style.height = `${Math.ceil((profile.offsetHeight) / gridHeight) * gridHeight + 1}px`;
            })
        })
    }
}

function getIntValue(strValue: string): number {
    return parseInt(strValue, 10);
}

function getBackgroundImgSize(element: any): number {
    let style = element.currentStyle || window.getComputedStyle(element);
    return getIntValue(style.backgroundSize);
}

document.addEventListener("DOMContentLoaded", calculateProfileHeight);