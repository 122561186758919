import { RefObject, useCallback, useLayoutEffect, useRef, useState } from "react";

type RectResult = {
  x: number;
  y: number;
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  scrollHeight: number;
  scrollWidth: number;
};

function getRect<T extends HTMLElement>(element?: T): RectResult {
  let rect: RectResult = {
    x: 0,
    y: 0,
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    scrollHeight: 0,
    scrollWidth: 0,
  };

  if (element) {
    const { x, y, bottom, height, left, right, top, width } = element.getBoundingClientRect();
    const { scrollHeight, scrollWidth } = element;

    rect = {
      x,
      y,
      bottom,
      height,
      left,
      right,
      top,
      width,
      scrollHeight,
      scrollWidth,
    };
  }

  return rect;
}

export const useRect = <T extends HTMLElement>(): [RefObject<T>, RectResult] => {
  const ref = useRef<T>(null);
  const [rect, setRect] = useState<RectResult>(ref.current ? getRect(ref.current) : getRect());

  const handleResize = useCallback(() => {
    if (!ref.current) return;

    setRect(getRect(ref.current));
  }, [ref]);

  useLayoutEffect(() => {
    const element = ref.current;

    if (!element) return;

    handleResize();

    let resizeObserver: ResizeObserver | null = new ResizeObserver(() => handleResize());
    resizeObserver.observe(element);
    resizeObserver.observe(document.documentElement);

    return () => {
      if (!resizeObserver) return;
      resizeObserver.disconnect();
      resizeObserver = null;
    };
  }, [ref.current]);

  return [ref, rect];
};
