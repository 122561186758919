import React, { memo, useMemo } from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";
import { AnimatedComponent } from "../../react-components/AnimatedComponent/AnimatedComponent";
import { easeTransition } from "../../react-components/AnimatedComponent/AnimatedComponent.animations";
import { Plus } from "../../react-components/Icons/Plus";

export interface ImageWithFactsSingleFactProps {
  id?: string;
  title: string;
  description: string;
  isSelected?: boolean;
  onSelect?: () => void;
  onDeselect?: () => void;
}

export const ImageWithFactsSingleFact = memo<ImageWithFactsSingleFactProps>(
  function ImageWithFactsSingleFact({
    id,
    title,
    description,
    isSelected = false,
    onSelect,
    onDeselect,
  }) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const htmlId = useMemo(() => id || uniqueId("ImageWithFactsViewerSingleFact"), []);

    return (
      <article
        className={classNames("ImageWithFactsViewer__singleFact", {
          "ImageWithFactsViewer__singleFact--expanded": isSelected,
        })}
      >
        <div className="ImageWithFactsViewer__singleFactHeader">
          <div className="ImageWithFactsViewer__buttonContainer">
            <button
              className="ImageWithFactsViewer__button"
              onClick={isSelected ? onDeselect : onSelect}
              aria-label={isSelected ? `Hide section: ${title}` : `Expand section: ${title}`}
              aria-controls={`${htmlId}Content`}
              aria-expanded={isSelected}
            >
              <Plus aria-hidden="true" />
            </button>
          </div>

          <h3 id={`${htmlId}Label`} className="ImageWithFactsViewer__singleFactTitle">
            {title}
          </h3>
        </div>

        <AnimatedComponent
          id={`${htmlId}Content`}
          className="ImageWithFactsViewer__singleFactContent"
          tag="div"
          role="region"
          aria-labelledby={`${htmlId}Label`}
          entry="expand"
          exit="collapse"
          isOn={isSelected}
          overrideTransition={easeTransition}
          aria-hidden={!isSelected}
          aria-live="polite"
        >
          <p className="ImageWithFactsViewer__singleFactDescription">{description}</p>
        </AnimatedComponent>
      </article>
    );
  },
);
