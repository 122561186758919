import { RefObject, useEffect } from "react";

export const useResizeObserver = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: ResizeObserverCallback,
  isEnabled = true,
) => {
  useEffect(() => {
    if (ref.current && isEnabled) {
      const observer = new ResizeObserver(callback);
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled, ref]);
};
