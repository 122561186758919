import { ContentListBlockSliderBase } from "./ContentListBlockSliderBase";
import { ContentListBlockSliderCarousel } from "./ContentListBlockSliderCarousel";
import { ContentListBlockSlider } from "./ContentListBlockSlider";
import { ArrowsPosition } from "./ArrowsPosition.csharp";

document.addEventListener("DOMContentLoaded", () => {
  const sliderElements = document.querySelectorAll<HTMLElement>(
    ContentListBlockSliderBase.SLIDER_ELEMENT,
  );

  sliderElements.forEach((element) => {
    const splideElement = element.querySelector<HTMLElement>(
      ContentListBlockSliderBase.SPLIDE_ELEMENT,
    );

    if (splideElement && !splideElement.classList.contains("is-initialized")) {
      const inRow = splideElement.dataset.inRow;
      const isCarousel = splideElement.dataset.carousel === "true";
      const arrowsPosition = splideElement.dataset.arrowsPosition as ArrowsPosition;

      let slider;

      if (isCarousel) {
        slider = new ContentListBlockSliderCarousel(splideElement);
      } else {
        slider = new ContentListBlockSlider(
          splideElement,
          inRow ? parseInt(inRow) : undefined,
          arrowsPosition,
        );
      }

      if (slider) {
        splideElement.id = slider.getSliderId();
        slider.mount();
      }
    }
  });
});
