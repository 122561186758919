import React, { FC } from "react";
import { ButtonFill } from "../../../../react-components/Buttons/ButtonFill";
import { ProductCampaignPageHeaderButtonsProps } from "./ProductCampaignPageHeaderButtonsProps.csharp";
import { ComponentProps } from "../../../../Partials/ComponentProps.csharp";
import { ButtonOutlined } from "../../../../react-components/Buttons/ButtonOutlined";

export const ProductCampaignPageHeaderButtons: FC<
  ComponentProps<ProductCampaignPageHeaderButtonsProps>
> = ({ model: { primaryLink, secondaryLink } }) => {
  return (
    <div className="ProductCampaignPageHeader__bottomRow">
      {primaryLink && primaryLink.href && primaryLink.text && (
        <ButtonFill
          as="a"
          color="blue"
          title={primaryLink.title}
          target={primaryLink.target}
          href={primaryLink.href}
        >
          {primaryLink.text}
        </ButtonFill>
      )}
      {secondaryLink && secondaryLink.href && secondaryLink.text && (
        <ButtonOutlined
          as="a"
          title={secondaryLink.title}
          target={secondaryLink.target}
          href={secondaryLink.href}
          mode="dark"
        >
          {secondaryLink.text}
        </ButtonOutlined>
      )}
    </div>
  );
};
