import * as React from "react";
import { ProductsAccordionListViewModel } from "./ProductsAccordionListViewModel.csharp";
import { ProductsAccordionItemViewModel } from "./ProductsAccordionItemViewModel.csharp";
import { ProductCatalogItemViewModel } from "../FilterableProductCatalogItemList/ProductCatalogItemViewModel.csharp";
import { ComponentProps } from "../ComponentProps.csharp";
import { ProductsAccordionItem } from "./ProductsAccordionItem";
import { ProductCatalogItemList } from "../FilterableProductCatalogItemList/ProductCatalogItemList";
import { LegacyAccordionList } from "../AccordionList/LegacyAccordionList/LegacyAccordionList";

export class ProductsAccordionList extends React.Component<
  ComponentProps<ProductsAccordionListViewModel>
> {
  render() {
    const model = this.props.model;
    return (
      <section className="ProductsAccordionList">
        {this.renderAccordions(model)}
        {model.products && (
          <ul className="cardsList__2col ProductList">
            <ProductCatalogItemList
              catalogItems={model.products}
              vesselTypeFilters={[]}
              solutionFilters={[]}
            />
          </ul>
        )}
      </section>
    );
  }

  private renderAccordions(model: ProductsAccordionListViewModel) {
    if (model.accordions !== undefined) {
      return (
        <LegacyAccordionList<ProductsAccordionItemViewModel[]> accordions={model.accordions}>
          {(items) => (
            <ul className="cardsList__2col">
              {items.sort(ProductsAccordionList.sortProductAccordionItems).map((item, i) => (
                <ProductsAccordionItem
                  item={item}
                  index={i}
                  sortFunction={ProductsAccordionList.sortCategoriesBeforeProducts}
                  key={i}
                />
              ))}
            </ul>
          )}
        </LegacyAccordionList>
      );
    }
  }

  private static sortProductAccordionItems(
    a: ProductsAccordionItemViewModel,
    b: ProductsAccordionItemViewModel,
  ) {
    const hasCards = (item: ProductsAccordionItemViewModel) => item.cards && item.cards.length > 0;

    //item without cards as first from top
    if (!hasCards(a) && hasCards(b)) return -1;
    if (hasCards(a) && !hasCards(b)) return 1;
    if (!hasCards(a) && !hasCards(b)) return 0;
    // then extended with its cards
    if (a.extended && b.extended) return 0;
    if (a.extended && !b.extended) return -1;
    if (!a.extended && b.extended) return 1;

    return ProductsAccordionList.sortCategoriesBeforeProducts(a.cards[0], b.cards[0]);
  }

  private static sortCategoriesBeforeProducts(
    a: ProductCatalogItemViewModel,
    b: ProductCatalogItemViewModel,
  ) {
    return a.isProduct === b.isProduct ? 0 : a.isProduct ? 1 : -1;
  }
}
