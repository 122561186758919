import classNames from "classnames";
import React, { FC } from "react";

export interface CountProps {
  count: number;
  variant: "pill" | "bold";
  label?: string;
  ariaLabel?: string;
  className?: string;
}

export const Count: FC<CountProps> = ({ count, label, ariaLabel, className, variant }) =>
  count > 0 ? (
    <div
      className={classNames("Count", `Count--${variant}`, className)}
      aria-label={ariaLabel && `${ariaLabel}: ${count}`}
    >
      {count} {label}
    </div>
  ) : null;
