import * as React from "react";
import {Loader} from "./Loader";
import {SearchHitViewModel} from "./SearchHitViewModel.csharp";
import classNames from "classnames";

export interface SearchHitsProps {
    hits: SearchHitViewModel[];
    hasMoreHits: boolean;
    onLoadMoreHits: () => void;
    loadMoreText: string;
    isLoading: boolean;
}

export interface SearchHitProps {
    model: SearchHitViewModel;
}

export class SearchHit extends React.Component<SearchHitProps> {
    constructor(props: SearchHitProps) {
        super(props)
    }

    render() {
        return (
            <div className="SearchHit">
                <a href={this.props.model.contentLink.url} className="SearchHit__link">
                    <h2 className="SearchHit__title">
                        {this.props.model.title}
                    </h2>
                    <span>{this.props.model.contentLink.shortUrl}</span>
                </a>
                <p dangerouslySetInnerHTML={{ __html: this.props.model.excerptHtml}}/>
            </div>
        )
    }
}

export const SearchHits = (props: SearchHitsProps) => (
    <div className="SearchHits wrapper">
        <div className="SearchHits__inner">
            <ul className="SearchHits__list" aria-relevant="additions" aria-atomic="true">
                {props.hits.map((hit, index) => <li key={index}><SearchHit model={hit}/></li>)}
            </ul>
            {props.isLoading && <Loader/>}
            {props.hasMoreHits &&
                <button
                    className={classNames('Btn--secondary', { 'isVisuallyHidden': props.isLoading })}
                    onClick={props.onLoadMoreHits}>
                    {props.loadMoreText}
                </button>
            }
        </div>
    </div>
);
