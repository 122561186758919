import React, { SVGProps, memo } from "react";
export const MapPin = memo(function MapPin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 9.95455C19.5 15.3636 12.5 20 12.5 20C12.5 20 5.5 15.3636 5.5 9.95455C5.5 8.11009 6.2375 6.34117 7.55025 5.03694C8.86301 3.73271 10.6435 3 12.5 3C14.3565 3 16.137 3.73271 17.4497 5.03694C18.7625 6.34117 19.5 8.11009 19.5 9.95455Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 12C13.6046 12 14.5 11.1046 14.5 10C14.5 8.89543 13.6046 8 12.5 8C11.3954 8 10.5 8.89543 10.5 10C10.5 11.1046 11.3954 12 12.5 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
