import React, { ElementType, FC } from "react";
import { LinkDto } from "../../Microsite/LinkDto.csharp";
import { ArrowRight } from "../Icons/ArrowRight";
import { MapPin } from "../Icons/MapPin";

type LinkArrowBlockListItem = LinkDto & {
  location?: string;
};

interface LinkArrowBlockListProps {
  items: LinkArrowBlockListItem[];
  itemTitleTag?: ElementType;
}

export const LinkArrowBlockList: FC<LinkArrowBlockListProps> = ({
  items,
  itemTitleTag = "span",
}) => {
  const ItemTitleTag = itemTitleTag;

  return (
    <ul className="LinkArrowBlockList">
      {items.map((item, idx) => {
        return (
          <li key={idx}>
            <a href={item.href} className="LinkArrowBlockList__itemLink">
              <div className="LinkArrowBlockList__itemTextsWrapper">
                <ItemTitleTag className="LinkArrowBlockList__itemTitle">{item.text}</ItemTitleTag>
                {item.location && (
                  <address className="LinkArrowBlockList__itemLocationWrapper">
                    <span className="visuallyHidden">Location:</span>
                    <MapPin aria-hidden />
                    <div className="LinkArrowBlockList__itemLocationText">{item.location}</div>
                  </address>
                )}
              </div>
              <div className="LinkArrowBlockList__itemArrowWrapper">
                <span className="LinkArrowBlockList__itemReadMore" aria-hidden>
                  read more
                </span>
                <ArrowRight aria-hidden />
              </div>
            </a>
          </li>
        );
      })}
    </ul>
  );
};
