import React from "react";
import { SwipeEventData } from "react-swipeable";
import { Swipeable } from "../../../Swipeable/Swipeable";

interface MenuHandleProps {
  handleMenuClose: () => void;
  setDeltaY?: (deltaY: number) => void;
}

export const MenuHandle = ({ handleMenuClose, setDeltaY }: MenuHandleProps) => {
  const onSwipedDown = ({ velocity }: SwipeEventData) => {
    setDeltaY && setDeltaY(0);
    if (velocity > 0.4) {
      handleMenuClose();
    }
  };

  const onSwiping = ({ deltaY }: SwipeEventData) => {
    setDeltaY && setDeltaY(deltaY);
  };

  const onTap = () => setDeltaY && setDeltaY(0);

  return (
    <Swipeable
      onSwipedDown={onSwipedDown}
      onSwiping={onSwiping}
      onTap={onTap}
      trackMouse
      trackTouch
    >
      <div className="RadioSelect__menuHandle">
        <span className="RadioSelect__handle" />
      </div>
    </Swipeable>
  );
};
