import classNames from 'classnames';
import React, { FC } from 'react';

import { AnimatedComponent } from '../../../react-components/AnimatedComponent/AnimatedComponent';
import { animationsName } from '../../BlurredBackgroundImage/BlurredBackgroundImage';
import { MicrositeSectionViewModel } from '../../Section/MicrositeSectionViewModel.csharp';
import { useMicrositeContext } from '../../Utils/micrositeContext';

interface MicrositeMenuModalItemProps {
  isOpen: boolean;
  itemIndex: number;
  section: MicrositeSectionViewModel;
  goToSection: (id: number) => void;
  itemSingularLabel: string;
  itemPluralLabel: string;
}

export const MicrositeMenuModalItem: FC<MicrositeMenuModalItemProps> = ({
  isOpen,
  itemIndex,
  section,
  goToSection,
  itemSingularLabel,
  itemPluralLabel,
}) => {
  const { theme } = useMicrositeContext();
  return (
    <>
      <AnimatedComponent
        tag="button"
        className={classNames(
          "MicrositeMenuModalItem__link",
          `MicrositeMenuModalItem__link--${theme}`
        )}
        aria-hidden={!isOpen}
        tabIndex={!isOpen ? -1 : undefined}
        animationProps={0.2 + 0.1 * itemIndex}
        orchestrationChild={{
          entry: { name: animationsName.entry, target: 'fadeInDelayed' },
          exit: { name: animationsName.exit, target: 'fadeOutToLeft' }
        }}
        onClick={() => goToSection(section.id)}
      >
        <span className="MicrositeMenuModalItem__linkLabel">
          {section.heading}
        </span>
        {section.products.length != 0 && (
          <span className="MicrositeMenuModalItem__linkSubLabel">
            {`${section.products.length} ${section.products.length == 1 ? itemSingularLabel : itemPluralLabel}`}
          </span>
        )}
      </AnimatedComponent>
      <AnimatedComponent
        tag="div"
        className={classNames(
          "MicrositeMenuModalItem__linkBorder",
          `MicrositeMenuModalItem__linkBorder--${theme}`
        )}
        animationProps={0.2 + (itemIndex * .15)}
        orchestrationChild={{
          entry: { name: animationsName.entry, target: 'rollOutDelayed' },
          exit: { name: animationsName.exit, target: 'rollIn' }
        }}
      />
    </>
  );
};
