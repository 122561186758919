import * as React from "react";
import { LightBoxProps } from "./LightBoxProps.csharp";
import Lightbox from "react-image-lightbox";
import LightBoxScrollList from "./LightBoxScrollList";
import { getResizedImageUrl } from "../../react-components/responsivePicture/responsivePicture";

export interface LightBoxState {

    isOpen: boolean,
    prevImgUrl: string,
    openedImgUrl: string,
    nextImgUrl: string,
    openedImgIndex: number,
}

export class LightBox extends React.Component<LightBoxProps, LightBoxState> {
    constructor(props: LightBoxProps) {
        super(props);

        this.state = {
            isOpen: false,
            prevImgUrl: "",
            openedImgUrl: "",
            nextImgUrl: "",
            openedImgIndex: 0
        };

        this.onImageOpen = this.onImageOpen.bind(this);
        this.onImageClose = this.onImageClose.bind(this);
    }

    private onImageOpen(url: string, index: number) : void {
        this.setState({
            isOpen: true,
            prevImgUrl: this.getPrevImgUrl(index),
            openedImgUrl: getResizedImageUrl(url, null, window.innerHeight, null, null, 50),
            nextImgUrl: this.getNextImgUrl(index),
            openedImgIndex: index
        });
    }

    private onImageClose(): void {
        this.setState({
            isOpen: false,
            prevImgUrl: "",
            openedImgUrl: "",
            nextImgUrl: "",
            openedImgIndex: 0
        });
    }
    
    private getPrevImgUrl(openedImgIndex: number) {
        const prevImgIndex = this.getPrevImgIndex(openedImgIndex);
        
        return prevImgIndex !== openedImgIndex ? this.props.model.images[prevImgIndex].url : "";
    }

    private getNextImgUrl(openedImgIndex: number) {
        const nextImgIndex = this.getNextImgIndex(openedImgIndex);
        
        return nextImgIndex !== openedImgIndex ? this.props.model.images[nextImgIndex].url : "";
    }

    private getPrevImgIndex(openedImgIndex: number) {
        return (openedImgIndex - 1) >= 0 ? openedImgIndex - 1 : this.props.model.images.length - 1;
    }

    private getNextImgIndex(openedImgIndex: number) {
        return (openedImgIndex + 1) % this.props.model.images.length;
    }

    render() {
        const { isOpen, prevImgUrl, openedImgUrl, nextImgUrl, openedImgIndex } = this.state;
        const { noImagesMessage } = this.props;
        const { images, pageSize, maxHeight } = this.props.model;
        
        return (
            <>
                {isOpen && (
                    <Lightbox
                        enableZoom={false}
                        mainSrc={openedImgUrl}
                        onCloseRequest={this.onImageClose}
                        prevSrc={prevImgUrl}
                        nextSrc={nextImgUrl}
                        onMovePrevRequest={() => this.onImageOpen(prevImgUrl, this.getPrevImgIndex(openedImgIndex))}
                        onMoveNextRequest={() => this.onImageOpen(nextImgUrl, this.getNextImgIndex(openedImgIndex))}
                    />

                )}
                <LightBoxScrollList pageSize={pageSize} 
                                    onImageOpen={this.onImageOpen} 
                                    images={images} 
                                    noImagesMessage={noImagesMessage} 
                                    maxHeight={maxHeight}/>
            </>
        )
    }
}