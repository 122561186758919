import { RelatedProductCategory } from '../../RelatedProductCategory.csharp';
import { OfficeLocationAffiliationType } from "../../OfficeLocationAffiliationType.csharp";
import { OfficeLocationViewModel } from "../../OfficeLocationViewModel.csharp";
import { OfficeLocationBadgeColor } from "./OfficeLocationBadge";
import { useIsElementVisible } from '../../../../react-components/useIsElementVisible';
import { RefObject, useEffect } from 'react';

export interface OfficeLocationBadge {
  name: string;
  color?: OfficeLocationBadgeColor;
}

const availableBadges = {
  partner: {
    name: "Partner",
  },
  [OfficeLocationAffiliationType.Maritime]: {
    name: "Maritime",
    color: OfficeLocationBadgeColor.Blue,
  },
  [OfficeLocationAffiliationType.Discovery]: {
    name: "Discovery",
    color: OfficeLocationBadgeColor.Teal,
  },
};

export const getOfficeLocationBadges = ({
  isPartner,
  affiliationTypes
}: OfficeLocationViewModel): OfficeLocationBadge[] => {
  const badges = [] as OfficeLocationBadge[];

  if (isPartner) {
    badges.push(availableBadges.partner);
  } else {
    affiliationTypes.sort((a, b) => b - a).forEach((type) => {
      badges.push(availableBadges[type]);
    });
  }

  return badges;
};

export const formatAddress = (...address: Array<string | undefined>) => address
  .filter((part): part is string => part !== undefined)
  .map(part => part.charAt(part.length - 1) === `,` ? part.substring(0, part.length - 1) : part)
  .join(', ');

const isHttpOrHttps = new RegExp(/^(http|https):\/\//);

export const getFullWebsiteUrl = (url: string) => {
  if (isHttpOrHttps.test(url))
    return url;

  return `http://${url}`;
};

export const formatWebsiteUrl = (url: string | null) => {
  return url?.replace(isHttpOrHttps, ``);
};

export interface OfficeLocationDepartment {
  title: string;
  email: string;
  phoneNumber: string;
}

export const getOfficeLocationDepartments = (location: OfficeLocationViewModel) => {
  const arr = [];

  if (location.sales.email || location.sales.phoneNumber) {
      arr.push({
        title: 'Sales',
        email: location.sales.email,
        phoneNumber: location.sales.phoneNumber,
      });
    }

    if (location.support.email || location.support.phoneNumber) {
      arr.push({
        title: 'Support',
        email: location.support.email,
        phoneNumber: location.support.phoneNumber,
      });
    }

    if (location.rental.email || location.rental.phoneNumber) {
      arr.push({
        title: 'Rental',
        email: location.rental.email,
        phoneNumber: location.rental.phoneNumber,
      });
    }

    if (location.trainingCenter.email || location.trainingCenter.phoneNumber) {
      arr.push({
        title: 'Training',
        email: location.trainingCenter.email,
        phoneNumber: location.trainingCenter.phoneNumber,
      });
    }

    return arr;
};

export const getProductCategories = (location: OfficeLocationViewModel) => {
  const sortFn = (a: RelatedProductCategory, b: RelatedProductCategory) => {
    return a.name.localeCompare(b.name);
  };

  let categories = location.relatedProductCategories.sort(sortFn);

  return categories;
};

export const useScrollIntoListItem = (
  ref: RefObject<HTMLLIElement | null>,
  listRef: HTMLUListElement | null,
  isSelected?: boolean
) => {
  const isVisible = useIsElementVisible(ref.current, listRef, [ref, isSelected]);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (ref.current && isSelected && !isVisible) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    });
  }, [ref, isSelected, isVisible]);
};
