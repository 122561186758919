import React, { FC } from "react";
import { Modal } from "../Modal";
import { ResponsivePictureProps } from "../../../Images/ResponsivePictureProps.csharp";
import classNames from "classnames";

interface SidePanelModalProps {
  picture: ResponsivePictureProps;
  title: string;
  lead: string;
  className?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

export const SidePanelModal: FC<SidePanelModalProps> = ({
  picture,
  title,
  lead,
  className,
  isOpen = false,
  onClose,
}) => {
  return (
    <Modal
      className={classNames("SidePanelModal", className)}
      dialogClassName="SidePanelModal__dialog"
      isOpen={isOpen}
      onClose={onClose}
      transitionMs={300}
    >
      <Modal.Body className="SidePanelModal__body">
        <Modal.Header className="SidePanelModal__header" picture={picture}>
          <Modal.Lead className="SidePanelModal__lead">{lead}</Modal.Lead>
          <Modal.Title className="SidePanelModal__title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Content className="SidePanelModal__content">test</Modal.Content>
      </Modal.Body>
      <Modal.Close className="SidePanelModal__close" />
    </Modal>
  );
};
