//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum NavigationRootType {
	Unknown = 0,
	HomeCorporate = 1,
	Discovery = 2,
	Maritime = 3,
	Kda = 4,
	Ksat = 5
}
