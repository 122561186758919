import React from "react";
import classNames from "classnames";
import { ResourceTileProps } from "./ResourceTile.types";
import { Skeleton } from "../../react-components/Skeleton/Skeleton";

type ResourceTileSkeletonProps = Pick<ResourceTileProps, "size">;

export const ResourceTileSkeleton = function ResourceTileSkeleton({
  size = "medium",
}: ResourceTileSkeletonProps) {
  const isMediumSize = size === "medium";

  return (
    <div className={classNames("ResourceTile", `ResourceTile--${size}`)}>
      <div className="ResourceTile__label">
        <Skeleton
          width={isMediumSize ? "3.125rem" : "5.8125rem"}
          height="0.9375rem"
          inline
          rounded
        />
      </div>

      <div className="ResourceTile__link">
        <Skeleton className="ResourceTile__thumbnail" />

        <Skeleton
          className="ResourceTile__title"
          width={isMediumSize ? "100%" : "4.375rem"}
          height="0.8125rem"
          inline
          rounded
        />
      </div>

      <Skeleton
        className="ResourceTile__excerpt"
        width={isMediumSize ? "100%" : "60rem"}
        height="3.25rem"
        inline
        rounded
      />

      {isMediumSize && (
        <div className="ResourceTile__tags">
          <Skeleton width="1.8125rem" height="0.375rem" fontSize="0.375rem" inline rounded />
          <Skeleton width="1.8125rem" height="0.375rem" fontSize="0.375rem" inline rounded />
          <Skeleton width="1.8125rem" height="0.375rem" fontSize="0.375rem" inline rounded />
        </div>
      )}
    </div>
  );
};
