import React, { createContext, useContext, FC, useState } from 'react';
import { MicrositeTheme } from '../../../Models/Microsite/MicrositeTheme.csharp';

type MicrositeContext = {
  isProductVisible: boolean;
  isProductBackButtonSticky: boolean;
  isMenuVisible: boolean;
  setIsMenuVisible: (value: boolean) => void;
  setIsProductVisible: (value: boolean) => void;
  setIsProductBackButtonSticky: (value: boolean) => void;
  theme: string;
}

const Context = createContext<MicrositeContext | null>(null);

type MicrositeContextProps = {
  theme: MicrositeTheme;
}

export const MicrositeContext: FC<MicrositeContextProps> = ({ children, theme }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isProductVisible, setIsProductVisible] = useState(false);
  const [isProductBackButtonSticky, setIsProductBackButtonSticky] = useState(false);

  const initialValue: MicrositeContext = {
    isProductVisible,
    isProductBackButtonSticky,
    isMenuVisible,
    setIsMenuVisible,
    setIsProductVisible,
    setIsProductBackButtonSticky,
    theme: MicrositeTheme[theme].toLocaleLowerCase(),
  }

  return <Context.Provider value={initialValue}>{children}</Context.Provider>
}

export const useMicrositeContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Context has not been initialized');
  }

  return context;
};
