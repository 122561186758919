/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC } from "react";
import { ComponentProps } from "../../ComponentProps.csharp";
import { OfficeLocationsMap } from "./OfficeLocationsMap";
import { OfficeLocationsList } from "./OfficeLocationsList/OfficeLocationsList";
import { OfficeLocationsFilters } from "./OfficeLocationsFilters/OfficeLocationsFilters";
import { GoogleMapsLoader } from "../../../react-components/GoogleMaps/GoogleMapsLoader";
import {
  getAllOfficeLocationCategories,
  getAllOfficeLocationTypesAsFormValues,
  getSortedAllOfficeLocationTypesAsFormValues,
} from "./OfficeLocationsWithMapListing.utils";
import { useIsBelowScreenSize } from "../../../react-components/useIsBelowScreenSize";
import { useOfficeLocations } from "./useOfficeLocations";
import { OfficeLocationsListWithMapViewModel } from "../OfficeLocationsListWithMapViewModel.csharp";
import { ToastContextProvider } from "../../../react-components/Toast/ToastContextProvider";
import classNames from "classnames";

export const OfficeLocationsWithMapListing: FC<
  ComponentProps<OfficeLocationsListWithMapViewModel>
> = ({
  model: {
    officeLocations,
    featuredOfficeLocationsIds,
    googleMapsApiKey,
    mapId,
    googleMapsMarkerUrl,
    googleMapsSelectedMarkerUrl,
    productCategoryFilter,
    listWithMapTranslations,
    useAdvancedFilters,
  },
}) => {
  const isMobile = useIsBelowScreenSize("mobile");

  const categories = getAllOfficeLocationCategories(productCategoryFilter.items);

  const types = isMobile
    ? getSortedAllOfficeLocationTypesAsFormValues()
    : getAllOfficeLocationTypesAsFormValues();

  const {
    onMapLoad,
    loaded,
    filteredLocations,
    filters,
    displayType,
    selectedOfficeId,
    userLocationDisabled,
    handleAutocompleteLocationSelection,
    handleSearchQueryChange,
    handleCategorySelect,
    handleTypesSelect,
    handleSelectMarker,
    handleDeselectMarker,
    handleSearchLocation,
    handleLocateMe,
    handleSelectOfficeLocation,
  } = useOfficeLocations(officeLocations, categories, types, featuredOfficeLocationsIds);

  return (
    <ToastContextProvider>
      <div
        className={classNames(
          "OfficeLocationsWithMapListing",
          useAdvancedFilters && "OfficeLocationsWithMapListing--hasAdvancedFilters",
          "kog-grid",
        )}
      >
        <GoogleMapsLoader apiKey={googleMapsApiKey} libraries={["places"]}>
          <OfficeLocationsFilters
            loaded={loaded}
            userLocationDisabled={userLocationDisabled}
            types={types}
            categories={categories}
            onAutocompleteLocationSelection={handleAutocompleteLocationSelection}
            selectedCategory={filters.selectedCategory}
            setSelectedCategory={handleCategorySelect}
            selectedTypes={filters.selectedTypes}
            setSelectedTypes={handleTypesSelect}
            searchQuery={filters.searchQuery}
            setSearchQuery={handleSearchQueryChange}
            locateMeHandler={handleLocateMe}
            locationSearchHandler={handleSearchLocation}
            translations={listWithMapTranslations.filters}
            useAdvancedFilters={useAdvancedFilters}
          />

          <OfficeLocationsList
            displayType={displayType}
            locations={filteredLocations}
            selectedOfficeId={selectedOfficeId}
            onListItemClick={handleSelectOfficeLocation}
            translations={listWithMapTranslations}
          />

          <OfficeLocationsMap
            locations={filteredLocations}
            mapId={mapId}
            markerUrl={googleMapsMarkerUrl}
            selectedMarkerUrl={googleMapsSelectedMarkerUrl}
            selectedOfficeId={selectedOfficeId}
            onMarkerSelect={handleSelectMarker}
            onMarkerDeselect={handleDeselectMarker}
            onLoad={onMapLoad}
          />
        </GoogleMapsLoader>
      </div>
    </ToastContextProvider>
  );
};
