import classNames from "classnames";
import React, { FC } from "react";
import { AnimatedComponent } from "../../react-components/AnimatedComponent/AnimatedComponent";
import {
  EntryAnimationsType,
  ExitAnimationsType,
} from "../../react-components/AnimatedComponent/AnimatedComponent.types";
import { useMicrositeContext } from "../Utils/micrositeContext";

interface AnimatedCloseButtonProps {
  onClick: () => void;
  overrideTheme?: "dark" | "light";
  label?: string;
  className?: string;
  entry?: EntryAnimationsType;
  exit?: ExitAnimationsType;
  isOn?: boolean;
  animationProps?: unknown;
}

export const AnimatedCloseButton: FC<AnimatedCloseButtonProps> = ({
  onClick,
  label = "Close",
  overrideTheme,
  className,
  ...animation
}) => {
  const { theme } = useMicrositeContext();
  return (
    <AnimatedComponent
      tag="button"
      className={classNames(
        "MicrositeAnimatedCloseButton",
        `MicrositeAnimatedCloseButton--${overrideTheme ?? theme}`,
        className,
      )}
      onClick={onClick}
      {...animation}
    >
      <span className="MicrositeAnimatedCloseButton__outline" />
      <span className="visuallyHidden">{label}</span>
      <span className="MicrositeAnimatedCloseButton__cross">
        <span className="MicrositeAnimatedCloseButton__crossInner" />
      </span>
    </AnimatedComponent>
  );
};
