import { useMemo } from "react";

export const uniqueId = (() => {
  let id = 0;

  return () => `${++id}`;
})();

export const useUniqueId = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(uniqueId, []);
};
