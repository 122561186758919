import React, { FC } from "react";
import { ComponentProps } from "../../Partials/ComponentProps.csharp";
import { HttpErrorViewModel } from "./HttpErrorViewModel.csharp";

export const ErrorPage: FC<ComponentProps<HttpErrorViewModel>> = ({ model }) => {
  return (
    <div className="ErrorPage">
      <section className="ErrorPage__hero">
        <div className="ErrorPage__sectionContent">
          <h1 className="ErrorPage__title">{model.message}</h1>
          <p className="ErrorPage__description">{model.description}</p>
        </div>
      </section>
      {!!model.links?.length && (
        <section className="ErrorPage__linksSection">
          <div className="ErrorPage__sectionContent">
            {model.linksTitle && <h2 className="ErrorPage__linksTitle">{model.linksTitle}</h2>}
            <ul className="ErrorPage__linksList">
              {model.links.map((link, idx) => (
                <li key={idx} className="ErrorPage__linksListItem">
                  <a href={link.href}>{link.text}</a>
                </li>
              ))}
            </ul>
          </div>
        </section>
      )}
    </div>
  );
};
