import * as React from "react";
import {ChartTileViewModel} from "./ChartTileViewModel.csharp";
import {ComponentProps} from "../ComponentProps.csharp";

export class ChartTile extends React.Component<ComponentProps<ChartTileViewModel>> {
    constructor(props: Readonly<ComponentProps<ChartTileViewModel>>) {
        super(props);
    }

    render() {
        let {label, comment} = this.props.model;
        
        return (
            <div className="ChartTile">
                <p>{label}</p> 
                {this.props.children}
                {
                    <span>{comment}</span>
                }
            </div>
        );
    }
}