import { RefObject, useCallback, useEffect, useState } from "react";
import { isBrowser } from "../../environmentHelpers";
import { useMutationObserver } from "./useMutationObserver";
import { useResizeObserver } from "./useResizeObserver";

interface ComputedStyleHookOptions {
  observeMutations?: boolean;
  observeResizes?: boolean;
}

export const useComputedStyle = <T extends HTMLElement>(
  element: RefObject<T>,
  callback?: (styles: CSSStyleDeclaration) => void,
  options: ComputedStyleHookOptions = { observeMutations: true, observeResizes: true },
) => {
  const [computedStyle, setComputedStyle] = useState<CSSStyleDeclaration | null>(null);

  const getComputedStyle = useCallback(
    () => (isBrowser && element.current ? window.getComputedStyle(element.current) : null),
    [element],
  );

  useMutationObserver<T>(
    element,
    () => setComputedStyle(getComputedStyle()),
    options.observeMutations,
  );
  useResizeObserver<T>(element, () => setComputedStyle(getComputedStyle()), options.observeResizes);

  useEffect(() => {
    if (!isBrowser || !element.current) return;

    setComputedStyle(getComputedStyle());
  }, [element, getComputedStyle]);

  useEffect(() => {
    if (callback && computedStyle) {
      callback(computedStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedStyle]);

  return computedStyle;
};
