import * as React from "react";
import classNames from "classnames";
import { Chevron } from "../../react-components/Icons/Chevron";

export interface ImageViewerArrowProps {
  direction: "left" | "right";
  onClick: () => void;
  onMainImage?: boolean;
}

export const ImageViewerArrow = ({
  direction,
  onClick,
  onMainImage = false,
}: ImageViewerArrowProps) => {
  return (
    <button
      className={classNames("ImageViewerArrow", `ImageViewerArrow--${direction}`, {
        "ImageViewerArrow--onMainImage": onMainImage,
      })}
      aria-label={`Go to ${direction === "left" ? "previous" : "next"} image`}
      onClick={onClick}
    >
      <Chevron />
    </button>
  );
};
