import * as React from "react";
import { FC } from "react";
import { Search } from "../../react-components/Icons/Search";

export interface VacancyFilterInputProps {
  label?: string;
  value: string | string[];
  onValueChanged: (value: string) => void;
}

export const VacancyFilterInput: FC<VacancyFilterInputProps> = ({
  label,
  value,
  onValueChanged,
}) => {
  const handleValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChanged(e.target.value);
  };
  const elementId = label ? label.replace(/\s+/g, "-").toLowerCase() : "filter-by";

  return (
    <div className="VacancyFilterInput">
      {label && (
        <label className="DropDownList__label VacancyFilter__label" htmlFor={elementId}>
          {label}
        </label>
      )}
      <div className="VacancyFilterInput__input">
        <input
          type="text"
          value={value}
          placeholder="Type keywords..."
          onChange={handleValueChanged}
        />
        <Search fill="currentColor" />
      </div>
    </div>
  );
};
