//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DiscoveryThemeColor {
	Teal = "teal",
	Blue = "blue",
	Green = "green",
	Photo = "photo",
	Brown = "brown",
	Grey = "grey",
	Success = "success",
	Alert = "alert",
	Error = "error",
	AlertLighter = "alertLighter",
	AccentGreen = "accentGreen",
	AccentBlue = "accentBlue",
	AccentTeal = "accentTeal"
}
