import { useCallback, useEffect, useState } from "react";

export enum ParamType {
  Boolean,
  String,
  Array,
}

export type ParamValueBoolean = boolean | null;
export type ParamValueSingle = string | null;
export type ParamValueMultiple = string[] | null;

export type ParamValue<Type extends ParamType> = Type extends ParamType.Array
  ? ParamValueMultiple
  : Type extends ParamType.Boolean
    ? ParamValueBoolean
    : ParamValueSingle;

export type Params<T extends Record<string, ParamType>> = {
  [K in keyof T]: ParamValue<T[K]>;
};

export const useSearchParams = <AvailableParams extends Record<string, ParamType>>(
  availableParams: AvailableParams,
  onFirstLoad?: (params: Params<AvailableParams>) => void,
) => {
  const initUrl = () =>
    typeof window !== `undefined` ? new URL(window.location.toString()) : null;

  const [url, setUrl] = useState<URL | null>(initUrl());

  const uniqueParams = (value: string, index: number, arr: string[]) =>
    arr.indexOf(value) === index;

  const getParams = useCallback((): Params<AvailableParams> => {
    const params: Partial<Params<AvailableParams>> = {};

    if (url) {
      for (const key in availableParams) {
        const results = url.searchParams.getAll(key);
        const type = availableParams[key];

        if (type === ParamType.Array) {
          params[key] = results.filter(uniqueParams) as ParamValue<AvailableParams[typeof key]>;
        } else if (type === ParamType.String) {
          params[key] = (results[0] ?? null) as ParamValue<AvailableParams[typeof key]>;
        } else if (type === ParamType.Boolean) {
          params[key] = (results[0] === "true") as ParamValue<AvailableParams[typeof key]>;
        } else {
          params[key] = null as ParamValue<AvailableParams[typeof key]>;
        }
      }
    }

    return params as Params<AvailableParams>;
  }, [availableParams, url]);

  const updateBrowserUrl = (url: URL) => history.replaceState(null, "", url);

  const setParams = useCallback((params: Partial<Params<AvailableParams>>) => {
    const newUrl = initUrl();

    if (newUrl) {
      for (const key in params) {
        const param = params[key];

        if (param) {
          if (Array.isArray(param)) {
            const oldParams = newUrl.searchParams.getAll(key);
            const paramsToRemove = oldParams.filter((oldParam) => !param.includes(oldParam));
            let newParams = [...param];

            if (paramsToRemove.length) {
              newUrl.searchParams.delete(key);
            } else {
              newParams = newParams.filter((newParam) => !oldParams.includes(newParam));
            }

            newParams = newParams.filter(uniqueParams);

            newParams.forEach((item) => newUrl.searchParams.append(key, item));
          } else {
            newUrl.searchParams.set(key, param.toString());
          }
        } else {
          newUrl.searchParams.delete(key);
        }
      }

      setUrl(newUrl);
      updateBrowserUrl(newUrl);
    }
  }, []);

  const clearParams = () => {
    const newUrl = initUrl();

    if (newUrl) {
      for (const key of newUrl.searchParams.keys()) {
        newUrl.searchParams.delete(key);
      }

      setUrl(newUrl);
      updateBrowserUrl(newUrl);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onFirstLoad && onFirstLoad(getParams()), []);

  return {
    params: getParams(),
    setParams,
    clearParams,
  };
};
