import React, { FC, useState } from "react";
import { Chevron } from "../../../../../react-components/Icons/Chevron";
import DownloadIcon from "../../../../../../wwwroot/Static/Images/Icons/Download.svg";
import classNames from "classnames";
import {
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { LinkViewModel } from "../../../../../Links/LinkViewModel.csharp";

interface DownloadsMultipleItemsProps {
  title: string;
  links: LinkViewModel[];
}

export const DownloadsMultipleItems: FC<DownloadsMultipleItemsProps> = ({
  title,
  links,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: (open) => {
      setIsOpen(open);
    },
  });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const toggleDropdown = () => setIsOpen((open) => !open);

  return (
    <div className="DownloadsMultipleItems">
      <button
        className="DownloadsSection__linksItemLink DownloadsMultipleItems__button"
        title={title}
        onClick={toggleDropdown}
        ref={refs.setReference}
        {...getReferenceProps}
      >
        {children}
        <Chevron isOpen={isOpen} />
      </button>

      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} order={["reference", "content"]} modal={false}>
            <ul
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                width: refs.reference.current?.getBoundingClientRect().width,
              }}
              className={classNames("DownloadsMultipleItems__dropdown", {
                "DownloadsMultipleItems__dropdown--hidden": !isOpen,
              })}
              {...getFloatingProps}
            >
              {links.map((link, index) => {
                return (
                  <li key={index} className="DownloadsMultipleItems__dropdownItem">
                    <a
                      className="DownloadsMultipleItems__dropdownItemLink"
                      href={link.href}
                      target={link.target}
                      title={link.title}
                    >
                      <span>{link.text}</span>
                      <DownloadIcon />
                    </a>
                  </li>
                );
              })}
            </ul>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
};
