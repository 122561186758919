import { useState } from "react";

export const useGalleryModal = () => {
  const [isModalOpened, setModalOpened] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const toggleModal = () => setModalOpened((opened) => !opened);

  const onThumbnailClick = (index: number) => {
    setActiveSlideIndex(index);
    toggleModal();
  };

  return { isModalOpened, toggleModal, activeSlideIndex, setActiveSlideIndex, onThumbnailClick };
};
