import React, { FC } from "react";
import { VacancyBlockViewModel } from "./VacancyBlockViewModel.csharp";
import { LinkArrowBlockList } from "../../react-components/LinkArrowBlockList/LinkArrowBlockList";
import { ButtonOutlined } from "../../react-components/Buttons/ButtonOutlined";
import { ComponentProps } from "../ComponentProps.csharp";

export const VacancyBlock: FC<ComponentProps<VacancyBlockViewModel>> = ({
  model: { title, seeMoreUrl, vacancies },
}) => {
  return (
    <div className="VacancyBlock">
      <h4 className="VacancyBlock__heading">{title}</h4>
      <div className="VacancyBlock__vacancies">
        <LinkArrowBlockList
          items={vacancies.map((vacancy) => ({
            text: vacancy.title,
            href: vacancy.detailsUrl,
            location: vacancy.location,
          }))}
        />
      </div>
      {seeMoreUrl && (
        <ButtonOutlined className="VacancyBlock__seeAllBtn" color="blue">
          <a href={seeMoreUrl}>See all</a>
        </ButtonOutlined>
      )}
    </div>
  );
};
