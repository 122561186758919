import React, { SVGProps, memo } from "react";
export const Checkmark = memo(function Checkmark(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 17 17"
      {...props}
    >
      <path
        d="M5.90792 13.4428C5.38384 13.4353 4.89204 13.1736 4.57386 12.7328L2.69004 10.4666C2.40158 10.0876 2.44632 9.53549 2.79154 9.21401C3.13677 8.89253 3.66001 8.91576 3.97856 9.26672L5.74385 11.3929C5.78355 11.4407 5.84033 11.4686 5.9004 11.47C5.96047 11.4714 6.01835 11.4461 6.05997 11.4002L13.018 3.72624C13.2295 3.49079 13.5436 3.3926 13.842 3.46866C14.1404 3.54471 14.3778 3.78346 14.4648 4.09497C14.5517 4.40648 14.4751 4.74342 14.2636 4.97887L7.21793 12.8264C6.89127 13.2302 6.41002 13.4566 5.90792 13.4428Z"
        fill="currentColor"
      />
    </svg>
  );
});
