import React, { useRef } from "react";
import { OfficeLocationsListItem } from "../OfficeLocationsListItem/OfficeLocationsListItem";
import { HeadingLevel } from "../../../../react-components/Heading";
import { OfficeLocationsListProps } from "./OfficeLocationsList";

interface OfficeLocationsListItems extends OfficeLocationsListProps{
  headingLevel?: HeadingLevel;
}

export const OfficeLocationsListItems = ({
  locations,
  translations,
  selectedOfficeId,
  onListItemClick,
  headingLevel,
}: Omit<OfficeLocationsListItems, 'nearbyLocations'>) => {
  const listRef = useRef<HTMLUListElement>(null);

  return (
    <ul className="OfficeLocationsList__list" ref={listRef}>
      {locations.map((location) => (
        <OfficeLocationsListItem
          key={location.id}
          location={location}
          isSelected={location.id === selectedOfficeId}
          onClick={onListItemClick}
          listRef={listRef.current}
          headingLevel={headingLevel}
          translations={translations.listItem}
        />
      ))}
    </ul>
  );
}
