import * as React from "react";
import {DropDownList} from "../DropDownList/DropDownList";
import {ProductCatalogItemList} from "./ProductCatalogItemList";
import {FilterableProductCatalogItemListViewModel} from "./FilterableProductCatalogItemListViewModel.csharp";
import {ComponentProps} from "../ComponentProps.csharp";

export interface FilterableProductCatalogItemListState {
    selectedVesselTypeId?: number;
    selectedSolutionId?: number;
}

export class FilterableProductCatalogItemList extends React.Component<ComponentProps<FilterableProductCatalogItemListViewModel>, FilterableProductCatalogItemListState> {
    constructor(props: ComponentProps<FilterableProductCatalogItemListViewModel>) {
        super(props);

        this.state = {
            selectedVesselTypeId: this.props.model.filters.selectedVesselTypeId,
            selectedSolutionId: this.props.model.filters.selectedSolutionId,
        };

        this.onVesselTypeChanged = this.onVesselTypeChanged.bind(this);
        this.onSolutionChanged = this.onSolutionChanged.bind(this);
    }

    private onVesselTypeChanged(value: string) {
        this.setState({
            selectedVesselTypeId: parseInt(value)
        });
    }

    private onSolutionChanged(value: string) {
        this.setState({
            selectedSolutionId: parseInt(value)
        });
    }

    render() {
        const { model } = this.props;
        
        const solutionFilterVisible = model.filters.availableSolutions.length > 0;
        const vesselTypeFilterVisible = model.filters.availableVesselTypes.length > 0;
        
        return (
            <div>
                <div className="FilterableProductCatalog">
                    {
                        (solutionFilterVisible || vesselTypeFilterVisible) &&
                        <div className="FilterableProductCatalog__filters Filters">
                            <div className="grid wrapper">
                                <ul className="cardsList__2col col-12 d-col-8">
                                    <li>
                                        {
                                            vesselTypeFilterVisible &&
                                            <DropDownList placeholder={"All vessel types"}
                                                          options={model.filters.availableVesselTypes.map(i => ({ value: i.id.toString(), text: i.text}))}
                                                          selectedValue={(this.state.selectedVesselTypeId && this.state.selectedVesselTypeId.toString()) || ""}
                                                          onValueChanged={this.onVesselTypeChanged} />
                                        }
                                    </li>
                                    <li>
                                        {
                                            solutionFilterVisible &&
                                            <DropDownList placeholder={"All solutions"}
                                                          options={model.filters.availableSolutions.map(i => ({ value: i.id.toString(), text: i.text}))}
                                                          selectedValue={(this.state.selectedSolutionId && this.state.selectedSolutionId.toString()) || ""}
                                                          onValueChanged={this.onSolutionChanged} />
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>        
                    }
                    <ProductCatalogItemList catalogItems={model.catalogItems}
                        vesselTypeFilters={this.state.selectedVesselTypeId ? [this.state.selectedVesselTypeId] : []}
                        solutionFilters={this.state.selectedSolutionId ? [this.state.selectedSolutionId] : []} />
                </div>
            </div>
        )
    }
}