import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useToast } from "../Toast/useToast";

export const useErrorHandler = () => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  useEffect(() => {
    return queryClient.getQueryCache().subscribe((event) => {
      if (event.type === "updated" && event.action.type === "pause") {
        toast("No network connection.", "no-network-connection");
      }
    });
  }, [queryClient, toast]);
};
