import * as React from "react";
import {StoriesSearchResultsProps} from "./StoriesSearchResultsProps.csharp";
import {ContentCardViewModel} from "../../../Partials/ContentCard/ContentCardViewModel.csharp";
import {ReactNode} from "react";
import {ContentCard} from "../../../Partials/ContentCard/ContentCard";
import classNames from "classnames";
import {ContentCardVariant} from "../../../Partials/ContentCard/ContentCardVariant.csharp";
import {Loader} from "../../../Partials/Search/Loader";
import {PagingModel} from "../../../Common/PagingModel.csharp";

export interface StoriesSearchResultsState {
    stories: ContentCardViewModel[]
    nextPageNumber: number,
    isLoading: boolean,
    hasMore: boolean
}

export class StoriesSearchResults extends React.Component<StoriesSearchResultsProps, StoriesSearchResultsState> {
    constructor(props: StoriesSearchResultsProps) {
        super(props);

        this.state = {
            stories: props.model.firstPage.items,
            nextPageNumber: props.model.firstPage.pageNumber + 1,
            isLoading: false,
            hasMore: props.model.firstPage.hasNext
        }

        this.onLoadMoreClick = this.onLoadMoreClick.bind(this);
    }

    render(): ReactNode {
        const translations = this.props.model.translations;
        let storiesList = this.state.stories.length !== 0
            ? this.state.stories.map((cardProps: ContentCardViewModel, index: number) => {
                const pictureProps = {
                    profile: this.props.pictureProfile,
                    model: cardProps.picture
                };
                return (
                    <li key={index}>
                        <ContentCard
                            model={cardProps}
                            picture={pictureProps}
                            isDateVisible={true}
                            isMoreButtonVisible={true}
                            isExcerptVisible={false}
                            isTitleCapitalized={false}
                            readMoreText={translations.readMoreText}
                            variant={this.props.variant}
                            isFocused={false}
                            isLinkVisible={false}
                            isPictureVisible={true}
                        />
                    </li>
                );
            })
            : <p className="ArticlesArchive__resultsPlaceholder">{translations.noStoriesFoundText}</p>;

        return <div className="ArticlesArchive__content">
            <ul className={classNames({
                "ArticlesArchive__list": true,
                "cardsList__3col": this.props.variant === ContentCardVariant.Standard,
                "ArticlesArchive__list--1col": this.props.variant !== ContentCardVariant.Standard
            })} aria-live="polite" aria-relevant="additions">
                {storiesList}
            </ul>
            {this.state.isLoading && <Loader/>}
            {this.state.hasMore && <div className="ArticlesArchive__loadMore">
                <button onClick={this.onLoadMoreClick}
                        disabled={this.state.isLoading}
                        className="Btn--readMore Btn--transparent">
                    {translations.loadMoreText}
                </button>
            </div>}
        </div>
    }

    private onLoadMoreClick() {
        this.loadMoreStories(this.state.nextPageNumber);
    }

    private loadMoreStories(pageNumber: number): void {
        this.setState({isLoading: true});
        this.fetchContent(pageNumber)
            .then(response => {
                if (response.ok) {
                    response.json().then((result: PagingModel<ContentCardViewModel>) => {
                        let newStories = (this.state.stories).concat(result.items);
                        this.setState(() => ({
                            stories: newStories,
                            isLoading: false,
                            nextPageNumber: result.pageNumber + 1,
                            hasMore: result.hasNext
                        }));
                    })
                } else {
                    this.setState({isLoading: false});
                }
            })
    }

    private fetchContent(pageNumber: number) {
        const url = this.buildQueryUrl(pageNumber);
        return fetch(url, {
            method: "GET",
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
    }

    private buildQueryUrl(pageNumber: number): string {
        let url = `${this.props.model.searchEndpointUrl}`;
        url = url + `?searchTerm=${this.props.model.searchTerm}&pageNumber=${pageNumber}&pageSize=${this.props.model.firstPage.pageSize}`;
        return url;
    }
}