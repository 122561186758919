import { useCallback, useState } from "react";
import { SelectOption } from "../SelectOption";

interface CheckboxSelectHookOptions {
  onChange?: (newValues: SelectOption[]) => void;
}

export const useCheckboxSelect = (options?: CheckboxSelectHookOptions) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);

  const changeOptions = useCallback(
    (newValues: SelectOption[]) => {
      if (setSelectedOptions) setSelectedOptions(newValues);

      if (options?.onChange) {
        options.onChange(newValues);
      }
    },
    [options],
  );

  return [selectedOptions, changeOptions] as const;
};
