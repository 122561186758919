import React, { SVGProps, memo } from "react";
export const ChevronDesktopMenu = memo(function ChevronDesktopMenu(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.44531 1.06348L4.98129 4.59946C5.76234 5.38051 7.02867 5.38051 7.80972 4.59946L11.3457 1.06348"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
});
