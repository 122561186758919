//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum BlockColorVariantExtended {
	Default = "default",
	BlueFive = "blueFive",
	SandFour = "sandFour",
	None = "none",
	TealTwo = "tealTwo",
	TealFive = "tealFive",
	YellowFour = "yellowFour",
	GreenTwo = "greenTwo",
	BlueTwo = "blueTwo",
	SandTwo = "sandTwo",
	ForestTwo = "forestTwo",
	GreenFour = "greenFour",
	GreenOne = "greenOne",
	GreenFive = "greenFive",
	ForestFive = "forestFive",
	BlueThree = "blueThree",
	YellowTwo = "yellowTwo",
	YellowFive = "yellowFive",
	RedFour = "redFour",
	GreyTwo = "greyTwo",
	GreyFive = "greyFive"
}
