import React, { useEffect, useRef, useState } from "react";
import { AccordionViewModel } from "./AccordionViewModel.csharp";
import classNames from "classnames";
import { Plus } from "../../react-components/Icons/Plus";
import { Minus } from "../../react-components/Icons/Minus";

interface AccordionListProps<T> {
  accordions: AccordionViewModel<T>[];
  children: (accordion: T) => JSX.Element;
}

export const AccordionList = <T,>({ accordions, children }: AccordionListProps<T>) => {
  const [openedItemIdx, setOpenedItemIdx] = useState<number | null>(null);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  const onAccordionClick = (itemIdx: number) => {
    setOpenedItemIdx((prevState) => (itemIdx === prevState ? null : itemIdx));
  };

  useEffect(() => {
    contentRefs.current.forEach((contentRef, idx) => {
      if (!contentRef) return;
      const links = contentRef.querySelectorAll("a");
      links.forEach((link) => {
        link.tabIndex = openedItemIdx === idx ? 0 : -1;
      });
    });
  }, [openedItemIdx]);

  return (
    <div className="AccordionList">
      {accordions.length && (
        <ul className="AccordionList__list">
          {accordions.map((item, idx) => {
            const headingWithoutWhiteSpace = item.heading.split(/\s+/).join("");
            const headerId = `AccordionList_header_${headingWithoutWhiteSpace}_${idx}`;
            const panelId = `AccordionList_panel_${headingWithoutWhiteSpace}_${idx}`;
            const isOpened = idx === openedItemIdx;

            return (
              <li
                key={idx}
                className={classNames("AccordionList__item", {
                  "AccordionList__item--opened": isOpened,
                })}
              >
                <h3 className="AccordionList__itemTitle">
                  <button
                    onClick={() => onAccordionClick(idx)}
                    className="AccordionList__itemButton"
                    id={headerId}
                    aria-expanded={isOpened}
                    aria-controls={panelId}
                  >
                    <span>{item.heading}</span>
                    <div className="AccordionList__itemIconWrapper">
                      <Plus className="AccordionList__itemIcon AccordionList__itemIcon--plus" />
                      <Minus className="AccordionList__itemIcon AccordionList__itemIcon--minus" />
                    </div>
                  </button>
                </h3>
                <div
                  className="AccordionList__itemContentWrapper"
                  id={panelId}
                  aria-labelledby={headerId}
                  hidden={!isOpened}
                >
                  <div
                    className="AccordionList__itemContent"
                    ref={(el) => (contentRefs.current[idx] = el)}
                  >
                    <div className="AccordionList__itemContentInner">{children(item.model)}</div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
