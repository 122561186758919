import React, { FC } from 'react';
import classNames from 'classnames';
import { ButtonOutlined } from '../../Buttons/ButtonOutlined';
import { Chevron } from '../../Icons/Chevron';
import { useExpandableSection } from './useExpandableSection';

export interface TableContentSectionType {
  title: string;
  renderContent: () => React.ReactNode;
}

interface TableContentProps {
  title: string;
  sections: TableContentSectionType[];
  expandableInViewport: 'mobile' | 'all';
  expandableSectionId: string;
  size?: 'narrow' | 'wide';
  renderButton?: () => React.ReactNode;
}

export const TableContent: FC<TableContentProps> = ({ title, sections, expandableInViewport, expandableSectionId, size = 'wide', renderButton }) => {
  const {
    isExpanded,
    setIsExpanded,
    isExpandable,
    sectionRef
  } = useExpandableSection({
    viewport: expandableInViewport
  });

  const handleShowMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`TableContent TableContent--${size}`}>
      <div className="TableContent__header">
        {title && (
          <h2 className={classNames("TableContent__title", {
            "TableContent__title--alignBottom": !renderButton
          })}>
            {title}
          </h2>
        )}
        {renderButton && (
          <div className="TableContent__buttonWrapperHeader">
            {renderButton()}
          </div>
        )}
      </div>
      <div
        className={classNames("TableContent__sections", "TableContent__expandableSection", {
          "TableContent__expandableSection--expanded": isExpanded,
          "TableContent__expandableSection--expandable": isExpandable,
        })}
        id={expandableSectionId}
        ref={sectionRef}
      >
        {sections.map((section, idx) => (
          <section className="TableContent__section" key={idx}>
            <h3 className="TableContent__sectionTitle">{section.title}</h3>
            <div className="TableContent__sectionContent">{section.renderContent()}</div>
          </section>
        ))}
      </div>
      <ButtonOutlined
        className={classNames("TableContent__showMoreBtn", "TableContent__expandBtn", {
          "TableContent__expandBtn--expanded": isExpanded,
          "TableContent__expandBtn--hidden": !isExpandable,
        })}
        iconPosition="right"
        icon={Chevron}
        onClick={handleShowMoreClick}
        aria-expanded={isExpanded}
        aria-controls={expandableSectionId}
      >
        {isExpanded ? "Show less" : "Show more"}
      </ButtonOutlined>
      {renderButton && (
        <div className="TableContent__buttonWrapperFooter">
          {renderButton()}
        </div>
      )}
    </div>
  );
};
