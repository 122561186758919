import React from "react";
import { Line } from "./Line";
import classNames from "classnames";
import { SideTextHotSpotVariantAViewModel } from "./SideTextHotSpotVariantAViewModel.csharp";
import { ArrowDirection } from "../../../../../Models/Blocks/ImageHotSpots/HotSpots/ArrowDirection.csharp";
import { HotSpotPreviewButton } from "./HotSpotPreviewButton";

interface HotSpotProps {
  viewModel: SideTextHotSpotVariantAViewModel;
  onClick: (description: string) => void;
  isActive: boolean;
  mobilePreviewText: string;
  mobileOnClick: () => void;
}

export const HotSpot: React.FC<HotSpotProps> = ({
  viewModel: { x, y, previewText, arrowDirection, arrowColor, description },
  onClick,
  isActive,
  mobilePreviewText,
  mobileOnClick,
}) => {
  const invertContainer =
    arrowDirection == ArrowDirection.LeftUp ||
    arrowDirection == ArrowDirection.RightUp;

  let textPositioningClass = "";
  switch (arrowDirection) {
    case ArrowDirection.LeftUp:
      textPositioningClass = "SideTextHotSpotVariantA__PreviewText--left";
      break;
    case ArrowDirection.LeftDown:
      textPositioningClass = `SideTextHotSpotVariantA__PreviewText--left
          SideTextHotSpotVariantA__PreviewText--leftDown`;
      break;
    case ArrowDirection.RightUp:
      textPositioningClass = "SideTextHotSpotVariantA__PreviewText--right";
      break;
    case ArrowDirection.RightDown:
      textPositioningClass = `SideTextHotSpotVariantA__PreviewText--right
        SideTextHotSpotVariantA__PreviewText--rightDown`;
      break;
  }

  const clickCallback = () => {
    if (isActive) {
      onClick(description);
    }
  };

  const mobileClickCallback = () => {
    if (isActive) {
      mobileOnClick();
    }
  };

  const invertContainerClass = invertContainer
    ? "SideTextHotSpotVariantA__Container--inverted"
    : "SideTextHotSpotVariantA__Container";
  const inactiveClass = isActive ? null : "SideTextHotSpotVariantA--inactive";
  return (
    <div
      className={classNames(invertContainerClass, inactiveClass)}
      style={{ left: `${x}%`, top: `${y}%` }}
    >
      <Line direction={arrowDirection} color={arrowColor} />

      <HotSpotPreviewButton
        className={classNames(
          "SideTextHotSpotVariantA__PreviewText",
          textPositioningClass
        )}
        clickCallback={clickCallback}
        previewText={previewText}
      />

      <HotSpotPreviewButton
        className={classNames(
          "SideTextHotSpotVariantA__PreviewText--mobile",
          textPositioningClass
        )}
        clickCallback={mobileClickCallback}
        previewText={mobilePreviewText}
      />
    </div>
  );
};
