import * as React from "react";
import classNames from 'classnames';

export interface DropDownListProps {
    placeholder: string;
    label?: string;
    options: DropDownListItem[];
    selectedValue: string | string[];
    onValueChanged?: (value: string) => void;
    onValuesChanged?: (value: string[]) => void;
    className?: string;
    disabled?: boolean;
}

export interface DropDownListItem {
    value: string;
    text: string;
    values?: string[];
    className?: string;
}

export class DropDownList extends React.Component<DropDownListProps> {
    constructor(props: DropDownListProps) {
        super(props);
        this.handleValueChanged = this.handleValueChanged.bind(this);
    }

    private makeOption = function (item: DropDownListItem) {
        const option: { key: string, val: string | string[] } = (item.values && item.values.length > 0) ?
            {val: item.values, key: item.values.join(';')} :
            {val: item.value, key: item.value};
        return (<option key={option.key} value={option.val} className={item.className}>{item.text}</option>);
    };

    private handleValueChanged(e: React.ChangeEvent<HTMLSelectElement>) {
        if (this.props.onValuesChanged) {
            const arr = !e.target.value ? [] : e.target.value.split(',');
            this.props.onValuesChanged(arr);
        }
        if (this.props.onValueChanged) {
            this.props.onValueChanged(e.target.value);
        }

    }

    render() {
        const {label, placeholder} = this.props;
        let elementId = label ? label.replace(/\s+/g, '-').toLowerCase() : "filter-by";
        return (
            <div className={classNames("DropDownList DropDownList--dark", this.props.className)}>
                {label && <label className="DropDownList__label" htmlFor={elementId}>{label}</label>}
                <div className={classNames("DropDownList__select", {"disabled": this.props.disabled})}>
                    <select value={this.props.selectedValue || ''}
                            onChange={this.handleValueChanged}
                            disabled={this.props.disabled}
                            id={elementId}
                            aria-label={elementId}>
                        {placeholder && <option value="">{placeholder}</option>}
                        {this.props.options.map(this.makeOption)}
                    </select>
                </div>
            </div>
        );
    }
}




