import React from "react";
import { ArrowDirection } from "../../../../../Models/Blocks/ImageHotSpots/HotSpots/ArrowDirection.csharp";
import { ArrowColor } from "../../../../../Models/Blocks/ImageHotSpots/HotSpots/ArrowColor.csharp";
import {Pointer} from "./Pointer";

interface VesselHotSpotLineProps {
  direction: ArrowDirection;
  color: ArrowColor;
}

export const Line: React.FC<VesselHotSpotLineProps> = ({
  direction,
  color,
}) => {
  let pointerColorClass = "";
  switch (color) {
    case ArrowColor.Red:
      pointerColorClass = "SideTextHotSpotVariantA__LineSvg--red";
      break;
    case ArrowColor.Gold:
      pointerColorClass = "SideTextHotSpotVariantA__LineSvg--gold";
      break;
    case ArrowColor.Blue:
      pointerColorClass = "SideTextHotSpotVariantA__LineSvg--blue";
      break;
  }
  
  return (
    <>
      {direction == ArrowDirection.LeftUp && (
        <div className={`
          SideTextHotSpotVariantA__LineSvg
          SideTextHotSpotVariantA__LineSvg--leftUp
          ${pointerColorClass}
        `}>
          <Pointer />
        </div>
      )}

      {direction == ArrowDirection.RightUp && (
        <div className={`
          SideTextHotSpotVariantA__LineSvg
          SideTextHotSpotVariantA__LineSvg--rightUp
          ${pointerColorClass}
        `}>
          <Pointer />
        </div>
      )}

      {direction == ArrowDirection.LeftDown && (
        <div className={`
          SideTextHotSpotVariantA__LineSvg
          SideTextHotSpotVariantA__LineSvg--leftDown
          ${pointerColorClass}
        `}>
          <Pointer />
        </div>
      )}

      {direction == ArrowDirection.RightDown && (
        <div className={`
          SideTextHotSpotVariantA__LineSvg
          SideTextHotSpotVariantA__LineSvg--rightDown
          ${pointerColorClass}
        `}>
          <Pointer />
        </div>
      )}
    </>
  );
};
