import * as React from "react";
import {OfficeLocationsList} from "./OfficeLocationsList";
import {DropDownList, DropDownListItem} from "../DropDownList/DropDownList";
import {OfficeLocationsContactTypes} from "./OfficeLocationsContactTypes";
import {ComponentProps} from "../ComponentProps.csharp";
import {OfficeLocationsViewModel} from "./OfficeLocationsViewModel.csharp";
import {OfficeLocationsFilterItemViewModel} from "./OfficeLocationsFilterItemViewModel.csharp";
import {OfficeLocationViewModel} from "./OfficeLocationViewModel.csharp";

export interface OfficeLocationsContainerState {
    selectedCountry: string;
    selectedProductCategory: number | undefined;
    selectedBusinessArea: string;
    selectedContactTypes: number[];
    batchNumber: number;
}

export class OfficeLocationsContainer extends React.Component<ComponentProps<OfficeLocationsViewModel>, OfficeLocationsContainerState> {
    
    constructor(props: ComponentProps<OfficeLocationsViewModel>) {
        super(props);

        this.state = OfficeLocationsContainer.getInitialState();

        this.onShowMore = this.onShowMore.bind(this);
        this.onCountryChanged = this.onCountryChanged.bind(this);
        this.onProductCategoryChanged = this.onProductCategoryChanged.bind(this);
        this.onBusinessAreaChanged = this.onBusinessAreaChanged.bind(this);
        this.onContactTypesSelectionChanged = this.onContactTypesSelectionChanged.bind(this);
    }

    private static getInitialState(): OfficeLocationsContainerState {
        return {
            selectedCountry: "",
            selectedProductCategory: undefined,
            selectedBusinessArea: "",
            selectedContactTypes: [],
            batchNumber: 1
        }
    }
    
    private onShowMore() {
        this.setState({
            batchNumber: this.state.batchNumber + 1
        })
    }

    private onCountryChanged(country: string) {
        this.setState({
            batchNumber: 1,
            selectedCountry: country
        })
    }

    private onProductCategoryChanged(product: string) {
        this.setState({
            batchNumber: 1,
            selectedProductCategory: product ? parseInt(product) : undefined
        })
    }

    private onBusinessAreaChanged(businessArea: string) {
        this.setState({
            selectedBusinessArea: businessArea
        })
    }

    private onContactTypesSelectionChanged(selection: number[]) {
        this.setState({
            selectedContactTypes: selection || []
        })
    }

    private getOfficeLocationsMatchingSelectedFilters() {
        let officeLocations = this.props.model.officeLocations;

        if (this.state.selectedContactTypes.length > 0) {
            officeLocations = officeLocations.filter(location => {
                return this.state.selectedContactTypes.every(selectedContactType => {
                    let categoriesByContactType = location.relatedProductCategoriesPerType[selectedContactType];
                    return categoriesByContactType !== undefined && categoriesByContactType.length > 0;
                })
            })
        }

        if (this.state.selectedCountry) {
            officeLocations = officeLocations.filter(location => {
                return location.address.country === this.state.selectedCountry
            })
        }

        if (this.state.selectedProductCategory) {

            if (this.state.selectedContactTypes.length > 0) {
                officeLocations = officeLocations.filter(location => {
                    return this.state.selectedContactTypes.every(selectedContactType => {
                        let categoriesByContactType = location.relatedProductCategoriesPerType[selectedContactType];
                        return categoriesByContactType !== undefined && categoriesByContactType.map(c => c.id).indexOf(this.state.selectedProductCategory || -1) !== -1;
                    })
                })

            } else {
                officeLocations = officeLocations.filter(location => {
                    let locationRelatedProductCategories = OfficeLocationsContainer.getOfficeLocationRelatedCategories(location);
                    return locationRelatedProductCategories.indexOf(this.state.selectedProductCategory || -1) !== -1
                });    
            }
        }

        if (this.state.selectedBusinessArea) {
            officeLocations = officeLocations.filter(location => {
                return location.businessAreas.indexOf(this.state.selectedBusinessArea) !== -1;
            })
        }

        return officeLocations;
    }

    private static getOfficeLocationRelatedCategories(officeLocation: OfficeLocationViewModel): number[] {
        let locationRelatedProductCategories: Array<number> = [];

        for (let contactType in officeLocation.relatedProductCategoriesPerType) {
            locationRelatedProductCategories.push(...officeLocation.relatedProductCategoriesPerType[contactType].map(c => c.id));
        }

        return locationRelatedProductCategories;
    }

    private static createDropDownListItems(items: OfficeLocationsFilterItemViewModel[]): DropDownListItem[] {
        return items.map(item => {
            return {value: item.value, text: item.text};
        })
    }

    componentDidMount() {
        this.setState(OfficeLocationsContainer.getInitialState());
    }

    render() {
        const {model} = this.props;
        const numberOfVisibleOfficeLocations = this.state.batchNumber * 10;
        const officeLocationsMatchingFilters = this.getOfficeLocationsMatchingSelectedFilters();
        const officeLocationsToRender = officeLocationsMatchingFilters.slice(0, numberOfVisibleOfficeLocations);
        const showLoadMoreButton = numberOfVisibleOfficeLocations < officeLocationsMatchingFilters.length;

        return <div className="OfficeLocations">
            <div className="Filters">
                {
                    model.contactTypeFilter.items.length > 1 &&
                    <OfficeLocationsContactTypes
                        contactTypes={model.contactTypeFilter.items.map(type => {
                            return {value: parseInt(type.value), displayName: type.text}
                        })}
                        onSelectionChanged={selectedValues => this.onContactTypesSelectionChanged(selectedValues)}/>
                }
                <ul className="OfficeLocationsFiltersList">
                    <li className="OfficeLocationsFiltersList__item">
                        <DropDownList
                            placeholder={model.countryFilter.placeholder}
                            options={OfficeLocationsContainer.createDropDownListItems(model.countryFilter.items)}
                            selectedValue={this.state.selectedCountry}
                            onValueChanged={this.onCountryChanged}
                            className="DropDownList--dark"/>
                    </li>
                    {
                        model.productCategoryFilter.items.length > 0 &&
                        <li className="OfficeLocationsFiltersList__item">
                            <DropDownList
                                placeholder={model.productCategoryFilter.placeholder}
                                options={OfficeLocationsContainer.createDropDownListItems(model.productCategoryFilter.items)}
                                selectedValue={String(this.state.selectedProductCategory)}
                                onValueChanged={this.onProductCategoryChanged}
                                className="DropDownList--dark"/>
                        </li>
                    }
                    <li className="OfficeLocationsFiltersList__item">
                        {
                            model.businessAreaFilter.items.length > 0 &&
                            <DropDownList
                                placeholder={model.businessAreaFilter.placeholder}
                                options={OfficeLocationsContainer.createDropDownListItems(model.businessAreaFilter.items)}
                                selectedValue={String(this.state.selectedBusinessArea)}
                                onValueChanged={this.onBusinessAreaChanged}
                                className="DropDownList--dark"/>
                        }
                    </li>
                </ul>
            </div>

            <OfficeLocationsList
                translations={model.listTranslations}
                officeLocations={officeLocationsToRender}
                loadMoreButtonVisible={showLoadMoreButton}
                onShowMore={this.onShowMore}/>

        </div>;
    }
}