//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ScaleModeDto {
	Default = 0,
	Crop = 1,
	Pad = 2,
	BoxPad = 3,
	Max = 4,
	Min = 5,
	Stretch = 6
}
