import React from "react";

interface OfficeLocationTagProps {
    label: string;
}

export const OfficeLocationTag = ({ label }: OfficeLocationTagProps) => {
  return (
    <span className="OfficeLocationTag">
      {label}
    </span>
  );
};
