import classNames from "classnames";
import React, { ReactNode } from "react";

interface BackgroundOverlayProps {
  className?: string;
  children?: ReactNode;
}

export const BackgroundOverlay = ({ className, children }: BackgroundOverlayProps) => {
  return (
    <div className={classNames("BackgroundOverlay", className)}>
      <div className="BackgroundOverlay__layer" />
      {children}
    </div>
  );
};
