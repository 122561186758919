import React from "react";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { ProductIntroductionSectionViewModel } from "../ProductIntroductionSectionViewModel.csharp";

export const ProductIntroductionSection = ({
  model,
}: ComponentProps<ProductIntroductionSectionViewModel>) => {
  return (
    <div className="ProductIntroductionSection__container">
      <div className="ProductIntroductionSection__introWrapper">
        {model.introduction && (
          <p className="ProductIntroductionSection__introduction">{model.introduction}</p>
        )}
      </div>
      <div className="ProductIntroductionSection__dataGroups">
        {!!model.awards?.length && (
          <section className="ProductIntroductionSection__dataGroup">
            <h2 className="ProductIntroductionSection__dataGroupHeading">Awards</h2>
            <ul className="ProductIntroductionSection__awardsList">
              {model.awards.map((award, idx) => (
                <li key={`award_${idx}`} className="ProductIntroductionSection__awardsItem">
                  <div className="ProductIntroductionSection__awardsItemImageWrapper">
                    {award.logoUrl && (
                      <img
                        src={award.logoUrl}
                        alt={award.title}
                        className="ProductIntroductionSection__awardsItemImage"
                      />
                    )}
                  </div>
                  <div className="ProductIntroductionSection__awardsItemInfo">
                    <span className="ProductIntroductionSection__awardsItemName">
                      {award.title}
                    </span>
                    {award.subtitle && (
                      <span className="ProductIntroductionSection__awardsItemDate">
                        {award.subtitle}
                      </span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </section>
        )}
        {/* {!!model.tags?.length && (
          <section className="ProductIntroductionSection__dataGroup">
            <h2 className="ProductIntroductionSection__dataGroupHeading">Tags</h2>
            <ul className="ProductIntroductionSection__tagsList">
              {model.tags.slice(0,8).map((tag, idx) => (
                <li key={`tag_${idx}`} className="ProductIntroductionSection__tagsItem">
                  {tag}
                </li>
              ))}
            </ul>
          </section>
        )} */}
      </div>
    </div>
  );
};
