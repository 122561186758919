import { ReactElement, memo, useMemo } from "react";
import { createPortal } from "react-dom";

export const PORTAL_ROOT_ID = "Portal";

export const getPortalRootElement = () => document.getElementById(PORTAL_ROOT_ID);

interface PortalProps {
  children: ReactElement;
  container?: Element;
}

export const Portal = memo<PortalProps>(function Portal({ children, container }) {
  const containerElement = useMemo(() => {
    if (typeof document === 'undefined') return null;
    if (container) return container;

    return getPortalRootElement();
  }, [container]);

  return containerElement ? createPortal(children, containerElement) : children;
});
