import isAboveTablet from "./isAboveTablet";

document.addEventListener("DOMContentLoaded", function () {
        
        document.body.classList.remove("no-js");

        function createObserver() {
            const options = {
                root: null,
                rootMargin: "0%",
                threshold: [0, 1]
            };

            const observer = new IntersectionObserver(handleIntersect, options);
            const elements = document.querySelectorAll(".animate");

            for (let i = 0; i < elements.length; i++) {
                observer.observe(elements[i]);
            }

            return observer;
        }

        function handleIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
            entries.forEach((entry: IntersectionObserverEntry) => {
                if (entry.isIntersecting) {
                    let classToAdd = entry.target.getAttribute("observer") || "fadeInMove";
                    entry.target.classList.add(classToAdd);
                    entry.target.addEventListener("animationend", () => {
                        entry.target.classList.remove("animate", classToAdd);
                    }, {
                        once: true,
                    });
                    observer.unobserve(entry.target);
                }
            });
        }

        let observer: IntersectionObserver;

        function toggleAnimations() {
            if (isAboveTablet().matches) {
                observer = createObserver();
            } else {
                if (observer) observer.disconnect();
            }
        }

        toggleAnimations();

        isAboveTablet().addListener(toggleAnimations);

    }, false
);
