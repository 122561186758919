import * as React from "react";
import { ImageViewerItemViewModel } from "./ImageViewerItemViewModel.csharp";
import { ResponsivePicture } from "../../react-components/responsivePicture/responsivePicture";
import classNames from "classnames";
import { ImageViewerVariant } from "./ImageViewerVariant.csharp";
import { PictureProfileDto } from "../../Images/Resizing/PictureProfileDto.csharp";

export interface ImageViewerItemProps {
  model: ImageViewerItemViewModel[];
  profile: PictureProfileDto;
  activeImageIndex: number;
  variant: ImageViewerVariant;
}

const getTextSizeClass = (htmlChunk: string): string => {
  const regex = /<\/?[^>]+(>|$)/g; // removes html tags
  const text = htmlChunk.replace(regex, "").trim() || "";
  if (text.length < 100) return "ImageViewerMainImage__legend--xLarge";
  if (text.length < 400) return "ImageViewerMainImage__legend--large";
  if (text.length < 500) return "ImageViewerMainImage__legend--medium";
  if (text.length < 700) return "ImageViewerMainImage__legend--small";
  return "ImageViewerMainImage__legend--xSmall";
};

export const ImageViewerMainImage = ({
  model,
  profile,
  activeImageIndex,
}: ImageViewerItemProps) => {
  return (
    <div className="ImageViewerMainImage">
      {model.map((image, index) => (
        <div
          key={`ImageViewerMainImage-${index}`}
          className={classNames("ImageViewerMainImage__container", {
            "ImageViewerMainImage__container--active": index === activeImageIndex,
          })}
        >
          <ResponsivePicture profile={profile} model={image.picture} />

          {image.captionHtml && (
            <div
              className={classNames(
                "ImageViewerMainImage__legend",
                getTextSizeClass(image.captionHtml),
                `ImageViewerMainImage__legend--backgroundColor${image.captionBackground}`,
              )}
              dangerouslySetInnerHTML={{ __html: image.captionHtml }}
            />
          )}
        </div>
      ))}
    </div>
  );
};
