import React, { FC, useState } from "react";
import FocusTrap from "focus-trap-react";
import classNames from "classnames";

import { AnimatedComponent } from "../../react-components/AnimatedComponent/AnimatedComponent";
import { MicrositeMenuModal } from "./MenuModal/MicrositeMenuModal";
import { MicrositeSideBar } from "./SideBar/MicrositeSideBar";
import { MicrositeSectionViewModel } from "../Section/MicrositeSectionViewModel.csharp";
import { useMicrositeContext } from "../Utils/micrositeContext";
import {ResponsivePictureProps} from "../../Images/ResponsivePictureProps.csharp";

interface MicrositeMenuBarProps {
  sections: MicrositeSectionViewModel[];
  backgroundImage: ResponsivePictureProps;
  goToSection: (id: number) => void;
  isLandingPage: boolean;
  itemSingularLabel: string;
  itemPluralLabel: string;
}

export const MicrositeMenuBar: FC<MicrositeMenuBarProps> = ({
  sections,
  backgroundImage,
  goToSection,
  isLandingPage,
  itemSingularLabel,
  itemPluralLabel,
}) => {
  const { isProductVisible, isMenuVisible, setIsMenuVisible } = useMicrositeContext();

  const navigateToSection = (id: number) => {
    setIsMenuVisible(false);
    goToSection(id);
  }

  return (
    <FocusTrap active={isMenuVisible}>
      <div className={classNames("MicrositeMenuBar", {
        "Microsite--mobileAndTabletHidden": !isLandingPage
      })}>
        <AnimatedComponent
          tag='div'
          initial="fadedIn"
          entry="fadeInToCustomDisplay"
          exit="fadeOutToDisplayNone"
          animationProps="flex"
          isOn={!isProductVisible}
          className="MicrositeMenuBar__sideBarContainer"
        >
          <MicrositeSideBar isOpen={isMenuVisible} setIsOpen={setIsMenuVisible} />
        </AnimatedComponent>
        <MicrositeMenuModal
          isOpen={isMenuVisible}
          sections={sections}
          backgroundImage={backgroundImage}
          goToSection={navigateToSection}
          itemSingularLabel={itemSingularLabel}
          itemPluralLabel={itemPluralLabel}
        />
      </div>
    </FocusTrap>
  );
};
