import * as React from "react";
import {ReactNode} from "react";
import {Arrow} from "./Arrow";
import {Slide} from "./Slide";
import {ImageViewerProps} from "../../../Partials/ImageViewer/ImageViewerProps.csharp";
import { Swipeable } from "../../../react-components/Swipeable/Swipeable";

// should use different profile with single picture profile, but it gets generated
// improper way: https://github.com/reinforced/Reinforced.Typings/issues/132
export class StoriesImageViewer extends React.Component<ImageViewerProps, {currentImageIndex: number}> {
    constructor(props: ImageViewerProps) {
        super(props);

        this.state = {
            currentImageIndex: 0
        };
        
        this.moveToPreviousImage = this.moveToPreviousImage.bind(this);
        this.moveToNextImage = this.moveToNextImage.bind(this);
    }
    render() {
        const slides = this.createSlides();
        const swipeConfig = {
            onSwipedLeft: this.moveToNextImage,
            onSwipedRight: this.moveToPreviousImage,
            preventDefaultTouchmoveEvent: true,
            trackMouse: true
        };
        const arrows = this.props.model.images.length > 1 
            ? <>
                <Arrow onClick={this.moveToPreviousImage}
                     className="StoriesImageViewer__changeImageButton StoriesImageViewer__changeImageButton--previous"/>
                <Arrow onClick={this.moveToNextImage} className="StoriesImageViewer__changeImageButton StoriesImageViewer__changeImageButton--next"/>
            </>
            : <></>;
        const maxWidthModifier = this.props.model.containToLayout ? 'StoriesImageViewer--limited' : '';
        
        return (
            <Swipeable {...swipeConfig}>
                <div className={`StoriesImageViewer ${maxWidthModifier} animate`}>
                    <div className="StoriesImageViewer__inner"
                         style={{transform: `translateX(-${this.getTranslateValue()}%)`}}>
                        {slides}
                    </div>
                    {arrows}
                </div>
            </Swipeable>);
    }

    private createSlides(): ReactNode[] {
        return this.props.model.images.map((image, i)=> <Slide key={i} {...image} profile={this.props.mainProfile}/>)
    }

    private moveToPreviousImage() {
        const currentImageIndex = this.state.currentImageIndex;
        const imagesCount = this.props.model.images.length;
        
        this.setState({currentImageIndex: ((imagesCount+currentImageIndex-1)%this.props.model.images.length)});
    }

    private moveToNextImage() {
        this.setState({currentImageIndex: (this.state.currentImageIndex+1)%this.props.model.images.length});
    }

    private getTranslateValue(): number {
        return this.state.currentImageIndex * 100;
    }
}
