import { useEffect, useRef } from "react";
import { isBrowser } from "../../environmentHelpers";

export const useReactWrapper = (containerId?: string) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isBrowser && containerId) {
      elementRef.current = document.getElementById(containerId) as HTMLDivElement;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return elementRef;
};
