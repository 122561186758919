import React from "react";
import { DropdownIndicatorProps, GroupBase } from "react-select";
import { Chevron } from "../../../Icons/Chevron";
import classNames from "classnames";
import { RadioSelectOption } from "./RadioSelect.types";

export const DropdownIndicator = ({ innerProps, selectProps: { isDrawerVisible } }: DropdownIndicatorProps<RadioSelectOption, false, GroupBase<RadioSelectOption>>) => {
  return (
    <div
      className={classNames(
        "RadioSelect__dropdownIndicator",
        { "RadioSelect__dropdownIndicator--inverted": isDrawerVisible }
      )}
      {...innerProps}
    >
      <Chevron />
    </div>
  )
};
