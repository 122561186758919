import {
  Placement,
  autoUpdate,
  useFloating,
  arrow,
  offset,
  shift,
  flip,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useClick,
} from "@floating-ui/react";
import { createContext, useContext, useMemo, useRef, useState } from "react";
import { useDismissAfterDelay } from "./useDismissAfterDelay";

export interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  trigger?: "hover" | "click";
  dismissAfterMs?: number;
}

export function useTooltip({
  initialOpen = false,
  placement = "top",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  trigger = "hover",
  dismissAfterMs,
}: TooltipOptions = {}) {
  const arrowRef = useRef(null);

  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      arrow({ element: arrowRef }),
      offset(13),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift(),
    ],
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    enabled: !controlledOpen && trigger === "hover",
  });
  const focus = useFocus(context, {
    enabled: !controlledOpen && trigger === "hover",
  });
  const click = useClick(context, {
    enabled: !controlledOpen && trigger === "click",
    toggle: false,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  const dismissAfterDelay = useDismissAfterDelay(context, {
    enabled: !!dismissAfterMs,
    delayMs: 3000,
  });

  const interactions = useInteractions([click, hover, focus, dismiss, role, dismissAfterDelay]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      arrowRef,
    }),
    [open, setOpen, interactions, data],
  );
}

export type ContextType = ReturnType<typeof useTooltip> | null;

export const TooltipContext = createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context === null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }

  return context;
};
