import React from "react";
import { VesselListProps } from "./VesselListProps.csharp";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import {
  formatVesselsForAlphabet,
  formatVesselsForSegments,
} from "../../../react-components/vesselUtils";
import { Tab, TabsListing } from "../../../react-components/TabsListing/TabsListing";

const SEGMENT_TAB_ID = "VesselListing_Segment_Tab";
const ALPHABETICAL_TAB_ID = "VesselListing_Alphabetical_Tab";
const SEGMENT_SECTION_ID = "VesselListing_Segment_Section";
const ALPHABETICAL_SECTION_ID = "VesselListing_Alphabetical_Section";

export const VesselListing = ({ model }: ComponentProps<VesselListProps>) => {
  const vesselSectionsSegments = formatVesselsForSegments(model.segments, model.vessels);
  const vesselSectionsAlphabetical = formatVesselsForAlphabet(model.vessels);

  const tabs: Tab[] = [
    {
      id: SEGMENT_TAB_ID,
      sectionId: SEGMENT_SECTION_ID,
      label: model.segmentsTabName,
      sections: vesselSectionsSegments,
      isCollapsibleOnMobile: true,
    },
    {
      id: ALPHABETICAL_TAB_ID,
      sectionId: ALPHABETICAL_SECTION_ID,
      label: model.alphabeticalTabName,
      sections: vesselSectionsAlphabetical,
    },
  ];

  return <TabsListing tabs={tabs} />;
};
