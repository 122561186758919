import { CSSProperties, DependencyList, RefObject, useEffect, useRef } from "react";
import { isBrowser } from "../../environmentHelpers";

export const useStyles = <T extends CSSProperties>(
  element: RefObject<HTMLElement> | HTMLElement | null,
  properties?: Partial<T>,
  deps: DependencyList = [],
) => {
  const stylesElementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (stylesElementRef.current && properties) {
      for (const property in properties) {
        const value = properties[property];

        if (typeof value === `string`) {
          stylesElementRef.current.style.setProperty(property, value);
        } else {
          stylesElementRef.current.style.removeProperty(property);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    if (isBrowser) {
      if (element) {
        stylesElementRef.current = "current" in element ? element.current : element;
      }
    }
  }, [element]);
};
