import React, { ReactNode, useMemo } from "react";
import { Libraries, useJsApiLoader } from "@react-google-maps/api";

interface GoogleMapsLoaderProps {
  apiKey: string;
  libraries?: Libraries;
  children: ReactNode;
}

export const GoogleMapsLoader = ({ apiKey, libraries, children }: GoogleMapsLoaderProps) => {
  const memoLibraries = useMemo(() => libraries, []);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: memoLibraries,
  });

  return (
    <>
      {isLoaded ? children : null}
    </>
  );
};
