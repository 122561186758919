import React from "react";
import Calendar from "../../../../../wwwroot/Static/Images/Icons/Calendar.svg";
import Clock from "../../../../../wwwroot/Static/Images/Icons/Clock.svg";
import Pin from "../../../../../wwwroot/Static/Images/Icons/Marker.svg";

export const LectureTimePlace = () => {
  return (
    <section className="LectureTimePlace">
      <h4 className="LectureTimePlace__title">
        Uncrewed Vehicles & Vessels: Enabling Sensors and Software
      </h4>
      <time className="LectureTimePlace__timePlace">
        <Calendar aria-hidden="true" /> January 25-27, 2024
      </time>
      <time className="LectureTimePlace__timePlace">
        <Clock aria-hidden="true" /> Tuesday 10:00-11:40
      </time>
      <p className="LectureTimePlace__timePlace">
        <Pin aria-hidden="true" /> South gallery, room 3 & 4
      </p>
    </section>
  );
};
