import { CheckboxGroupOption } from "../CheckboxGroup/CheckboxGroup";

interface CheckboxGroupHookArgs {
  options: CheckboxGroupOption[];
  currentValues?: CheckboxGroupOption[];
  onChange?: (newValues: CheckboxGroupOption[]) => void;
}

export const useCheckboxGroup = ({ currentValues, options, onChange }: CheckboxGroupHookArgs) => {
  const isChecked = (value: string) =>
    currentValues ? !!currentValues.find((currentValue) => currentValue.value === value) : false;

  const handleChange = (value: string, checked: boolean) => {
    if (currentValues && onChange) {
      const newValues = [...currentValues];

      if (checked) {
        const option = options.find((option) => option.value === value);

        if (option) {
          newValues.push(option);
        }
      } else {
        const optionIndex = newValues.findIndex((option) => option.value === value);

        if (optionIndex !== -1) {
          newValues.splice(optionIndex, 1);
        }
      }

      onChange(newValues);
    }
  };

  return {
    isChecked,
    handleChange,
  };
};
