//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum LabeledHotSpotColor {
	Red = 0,
	Gold = 1,
	Aqua = 2,
	Blue = 3
}
