import React, { FC } from "react";
import { FiltersModal } from "../../react-components/Modal/FiltersModal/FiltersModal";
import { VacancyFilterInput } from "./VacancyFilterInput";
import { CountProps } from "../../react-components/Atoms/Count/Count";
import { RadioSelectOption } from "../../react-components/Discovery/Inputs/RadioSelect/RadioSelect.types";
import { RadioSelect } from "../../react-components/Discovery/Inputs/RadioSelect/RadioSelect";
import { OnChangeValue } from "react-select";
import { ButtonGhost } from "../../react-components/Buttons/ButtonGhost";
import { Reset } from "../../react-components/Discovery/Icons/Reset";

interface VacancyListFiltersModalProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  positionOptions: RadioSelectOption[];
  departmentOptions: RadioSelectOption[];
  regionOptions: RadioSelectOption[];
  onSearchPhraseChange: (value: string) => void;
  onSelectedRegion: (value: RadioSelectOption) => void;
  onSelectedPositionType: (value: RadioSelectOption) => void;
  onSelectedDepartment: (value: RadioSelectOption) => void;
  searchPhrase: string;
  department?: RadioSelectOption;
  position?: RadioSelectOption;
  region?: RadioSelectOption;
  filtersCount: CountProps;
  resetFilters: () => void;
  showResetFiltersButton: boolean;
}

export const VacancyListFiltersModal: FC<VacancyListFiltersModalProps> = ({
  isOpen,
  setOpen,
  positionOptions,
  departmentOptions,
  regionOptions,
  onSearchPhraseChange,
  onSelectedDepartment,
  onSelectedPositionType,
  onSelectedRegion,
  searchPhrase,
  department,
  position,
  region,
  filtersCount,
  showResetFiltersButton,
  resetFilters,
}) => {
  const handleModalClose = () => {
    setOpen(false);
  };

  const onRegionChange = (newValue: OnChangeValue<RadioSelectOption, false>) => {
    onSelectedRegion(newValue!);
  };

  const onDepartmentChange = (newValue: OnChangeValue<RadioSelectOption, false>) => {
    onSelectedDepartment(newValue!);
  };

  const onPositionChange = (newValue: OnChangeValue<RadioSelectOption, false>) => {
    onSelectedPositionType(newValue!);
  };

  const resetFiltersButton = (onClearFilters: () => void) => (
    <ButtonGhost
      iconPosition="left"
      icon={Reset}
      mode="light"
      onClick={onClearFilters}
      color="blue"
      className="VacancyFiltersList__resetBtn"
    >
      Reset filters
    </ButtonGhost>
  );

  return (
    <FiltersModal
      id="VacancyListFiltersModal"
      isOpen={isOpen}
      onClose={handleModalClose}
      resetFiltersButton={showResetFiltersButton && resetFiltersButton}
      onClearFilters={resetFilters}
      filtersCount={filtersCount}
    >
      <ul className="VacancyFiltersList">
        <li className="VacancyFiltersList__item">
          <VacancyFilterInput
            label="Search"
            value={searchPhrase}
            onValueChanged={onSearchPhraseChange}
          />
        </li>
        <li className="VacancyFiltersList__item">
          <label htmlFor="location" className="VacancyFilter__label">
            Location
          </label>
          <RadioSelect
            options={regionOptions}
            hiddenLabel="location"
            title={region ? region.label : "All Locations"}
            currentValue={region}
            placeholder="All Locations"
            onChange={onRegionChange}
            name="location"
          />
        </li>
        <li className="VacancyFiltersList__item">
          <label htmlFor="position" className="VacancyFilter__label">
            Position
          </label>
          <RadioSelect
            options={positionOptions}
            hiddenLabel="position"
            title={position ? position.label : "All Positions"}
            currentValue={position}
            placeholder="All Positions"
            onChange={onPositionChange}
            name="position"
          />
        </li>

        <li className="VacancyFiltersList__item">
          <label htmlFor="department" className="VacancyFilter__label">
            Business Area
          </label>
          <RadioSelect
            options={departmentOptions}
            hiddenLabel="department"
            title={department ? department.label : "All Business Areas"}
            currentValue={department}
            placeholder="All Business Areas"
            onChange={onDepartmentChange}
            name="department"
          />
        </li>
      </ul>
    </FiltersModal>
  );
};
