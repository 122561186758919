import React from "react";
import { CheckboxSelectControlsBar } from "./CheckboxSelectControlsBar";
import { SelectOption } from "../SelectOption";
import { CheckboxSelectItem } from "./CheckboxSelectItem";
import { camelCase, upperFirst } from "lodash";

interface CheckboxSelectDropdownProps {
  id: string;
  name: string;
  values: SelectOption[];
  options: SelectOption[];
  handleChange: (option: SelectOption, checked: boolean) => void;
  handleClear?: () => void;
  handleSave?: () => void;
  singleSelection?: boolean;
}

export const CheckboxSelectDropdown = ({
  id,
  name,
  values,
  options,
  handleChange,
  handleClear,
  handleSave,
  singleSelection,
}: CheckboxSelectDropdownProps) => {
  const isChecked = (value: string) => !!values.find((option) => option.value === value);

  return (
    <div id={id} className="CheckboxSelect__dropdown" aria-labelledby={`${id}Label`}>
      <ul className="CheckboxSelect__list">
        {options.map((option, index) => (
          <CheckboxSelectItem
            key={`${option.value}_${index}`}
            id={`${id}InputValue${upperFirst(camelCase(option.value))}`}
            name={name}
            isChecked={isChecked(option.value)}
            onChange={handleChange}
            {...option}
          />
        ))}
      </ul>
      {!singleSelection && (
        <CheckboxSelectControlsBar handleClear={handleClear} handleSave={handleSave} />
      )}
    </div>
  );
};
