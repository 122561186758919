import {ImageViewerItemViewModel} from "../../../Partials/ImageViewer/ImageViewerItemViewModel.csharp";
import {ResponsivePicture} from "../../../react-components/responsivePicture/responsivePicture";
import * as React from "react";
import {PictureProfileDto} from "../../../Images/Resizing/PictureProfileDto.csharp";

export const Slide = (props: ImageViewerItemViewModel & {profile: PictureProfileDto}) => {
    return (<div className="StoriesImageViewerSlide">
        <ResponsivePicture model={props.picture} profile={props.profile}/>
        {props.captionHtml && <div className="StoriesImageViewerSlide__label animate">
            <div className="StoriesImageViewerSlide__labelInner" dangerouslySetInnerHTML={{ __html: props.captionHtml}}/>
        </div>}
    </div>);
};