export const extractNumberFromString = (
  text: string,
): {
  number: number | null;
  restOfString: string;
} => {
  const match = text.match(/\d+/);
  if (match) {
    const number = parseInt(match[0], 10);
    const restOfString = text.replace(match[0], "");
    return { number, restOfString };
  } else {
    return { number: null, restOfString: text };
  }
};
