import React from "react";
import { ControlProps, GroupBase } from "react-select";
import classNames from "classnames";
import { RadioSelectOption } from "./RadioSelect.types";

export const Control = ({ children, innerRef, innerProps, isFocused, selectProps: { isKeyboardUsed } }: ControlProps<RadioSelectOption, false, GroupBase<RadioSelectOption>>) => {
  return (
    <div className={classNames("RadioSelect__control", {
      "RadioSelect__control--focused": isKeyboardUsed && isFocused,
    })} ref={innerRef} {...innerProps}>
      {children}
    </div>
  )
};
