import * as React from "react";
import {ProductCatalogItemViewModel} from "../FilterableProductCatalogItemList/ProductCatalogItemViewModel.csharp";
import {ComponentProps} from "../ComponentProps.csharp";

export const ProductCatalogItemCard = ({ model } : ComponentProps<ProductCatalogItemViewModel>) => {
    const additionalClass = model.isProduct ? 'ProductCatalogItemCard--productItem' : 'ProductCatalogItemCard--productCategoryItem';

    return (<div className={`ProductCatalogItemCard ${additionalClass}`}>
        <a className="ProductCatalogItemCard__link" href={model.productCatalogItemUrl}>
            <h3 className="ProductCatalogItemCard__title">
                {model.title}
            </h3>
            <i className="ProductCatalogItemCard__arrow"></i>
        </a>
    </div>);
};