import { InfoWindow } from "@react-google-maps/api";
import React from "react";
import { OfficeLocationViewModel } from "../OfficeLocationViewModel.csharp";

interface OfficeLocationsMapInfoWindowProps {
  isOpen: boolean;
  location: OfficeLocationViewModel;
  onDeselect?: () => void;
  anchor?: google.maps.MVCObject;
}

export const OfficeLocationsMapInfoWindow = ({
  isOpen,
  location: { officeName },
  onDeselect,
  anchor /* only passed by a <Marker> component that mutates the child component */,
}: OfficeLocationsMapInfoWindowProps) => {
  const options: google.maps.InfoWindowOptions = {
    maxWidth: 300,
    disableAutoPan: true,
  };

  return isOpen && anchor ? (
    <InfoWindow onCloseClick={onDeselect} anchor={anchor} options={options}>
      <div>{officeName}</div>
    </InfoWindow>
  ) : null;
};
