import React, { FC } from "react";
import classNames from "classnames";
import { useMicrositeContext } from "../../Utils/micrositeContext";
import { AnimatedComponent } from "../../../react-components/AnimatedComponent/AnimatedComponent";

type MicrositeFooterProps = {
  qrCodeUrl: string;
  isLandingPage: boolean;
};

export const MicrositeFooter: FC<MicrositeFooterProps> = ({ qrCodeUrl, isLandingPage }) => {
  const { isProductVisible, theme } = useMicrositeContext();

  return (
    <AnimatedComponent
      tag="div"
      entry="fadeIn"
      exit="fadeOutToDisplayNone"
      initial="fadedIn"
      isOn={!isProductVisible}
      className={classNames("MicrositeFooter", `MicrositeFooter--${theme}`, {
        "Microsite--mobileAndTabletHidden": !isLandingPage,
      })}
    >
      {qrCodeUrl && <img className="MicrositeFooter__qr" src={qrCodeUrl} alt="QR code" />}
      <p className="MicrositeFooter__copyright">&copy; KONGSBERG MARITIME</p>
    </AnimatedComponent>
  );
};
