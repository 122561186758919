import React, { ReactNode, useMemo } from "react";

interface GetDirectionsProps {
  lat?: string | number;
  lng?: string | number;
  destination?: string;
  placeId?: string;
  className?: string;
  children?: ReactNode;
}

const GOOGLE_MAPS_API_URL = "https://www.google.com/maps/dir/?api=1";

export const GetDirections = ({ lat, lng, destination, placeId, className, children }: GetDirectionsProps) => {
  const directionsLink = useMemo(() => {
    if (placeId) {
      return `${GOOGLE_MAPS_API_URL}&destination=${placeId}`;
    } else if (lat && lng) {
      return `${GOOGLE_MAPS_API_URL}&destination=${lat},${lng}`;
    } else if (destination) {
      return `${GOOGLE_MAPS_API_URL}&destination=${encodeURIComponent(destination)}`
    }
  }, [placeId, destination]);

  return (
    <a className={className} href={directionsLink} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};
