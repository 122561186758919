import React, { memo } from "react";
import { Chevron } from "../../../../react-components/Icons/Chevron";
import { OfficeLocationsListWithMapListItemTranslations } from "../../OfficeLocationsListWithMapListItemTranslations.csharp";

interface OfficeLocationsListItemFooterProps {
  isExpanded: boolean;
  handleExpandButtonClick: () => void;
  translations: OfficeLocationsListWithMapListItemTranslations;
}

export const OfficeLocationsListItemFooter = memo<OfficeLocationsListItemFooterProps>(function OfficeLocationsListItemFooter({
  isExpanded,
  handleExpandButtonClick,
  translations,
}) {
  return (
    <footer className="OfficeLocationsListItem__footer">
      <button className="OfficeLocationsListItem__controlButton" onClick={handleExpandButtonClick}>
        <span>{isExpanded ? translations.hide : translations.expand}</span>
        <Chevron className="OfficeLocationsListItem__controlButtonChevron" />
      </button>
    </footer>
  );
});
