//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum OfficeLocationContactType {
	Sales = 0,
	Support = 1,
	TrainingCenter = 2,
	Rental = 3,
	General = 4
}
