import React, { ChangeEventHandler, FormEvent, forwardRef, KeyboardEvent, useState } from "react";
import classNames from "classnames";
import { Search } from "../../../../../react-components/Icons/Search";
import { CloseCircle } from "../../../../../react-components/Icons/CloseCircle";

interface SearchSuggestionProps {
  value: string;
  onSubmit?: (event: FormEvent<HTMLInputElement | HTMLButtonElement>) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onReset?: (event: FormEvent<HTMLInputElement | HTMLButtonElement>) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onClick?: () => void;
  searchButtonLabel?: string;
  resetButtonLabel?: string;
  placeholderText?: string;
  focusable?: boolean;
  keyboardFocus?: boolean;
  className?: string;
}

export const SearchSuggestion = forwardRef<HTMLInputElement, SearchSuggestionProps>(
  function SearchSuggestion(
    {
      value,
      onSubmit,
      onChange,
      onReset,
      onKeyUp,
      onBlur,
      onFocus,
      onClick,
      searchButtonLabel,
      resetButtonLabel,
      placeholderText,
      focusable = false,
      keyboardFocus = false,
      className,
    },
    ref,
  ) {
    const [isFocusedByKeyboard, setIsFocusedByKeyboard] = useState(keyboardFocus);

    const handleKeyboardNavigation = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Tab") setIsFocusedByKeyboard(true);
    };

    const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
      handleKeyboardNavigation(e);
      onKeyUp?.(e);
    };

    return (
      <div className={classNames("SearchSuggestion", className)}>
        <div className="SearchSuggestion__icon SearchSuggestion__icon--prefix">
          <Search fill="currentColor" aria-hidden="true" />
        </div>
        <input
          className={classNames("SearchSuggestion__input", {
            "SearchSuggestion__input--keyboardFocus": isFocusedByKeyboard,
          })}
          type="search"
          enterKeyHint="search"
          placeholder={placeholderText}
          value={value}
          onChange={onChange}
          onKeyUp={handleKeyUp}
          onBlur={() => {
            setIsFocusedByKeyboard(false);
            onBlur?.();
          }}
          onFocus={onFocus}
          onSubmit={onSubmit}
          onClick={onClick}
          ref={ref}
        />
        {onReset && (
          <button
            className="SearchSuggestion__button SearchSuggestion__button--reset"
            type="reset"
            aria-label={resetButtonLabel || "Reset input"}
            tabIndex={focusable ? 0 : -1}
            onClick={onReset}
          >
            <CloseCircle className="SearchSuggestion__icon" />
          </button>
        )}
        <button
          className="SearchSuggestion__button SearchSuggestion__button--search"
          type="submit"
          aria-label={searchButtonLabel || "Search"}
          tabIndex={focusable ? 0 : -1}
          onClick={onSubmit}
        >
          <Search fill="currentColor" className="SearchSuggestion__icon" />
        </button>
      </div>
    );
  },
);
