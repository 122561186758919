import React from "react";

interface CheckboxSelectValuesCountIndicatorProps {
  selectedOptions?: number;
}

export const CheckboxSelectValuesCountIndicator = ({
  selectedOptions,
}: CheckboxSelectValuesCountIndicatorProps) => {
  if (!selectedOptions) return null;

  return <span className="CheckboxSelect__valuesCount">{selectedOptions}</span>;
};
