import React from "react";
import classNames from "classnames";
import { AnimatedComponent } from "../../AnimatedComponent/AnimatedComponent";
import { useAccordion } from "../hooks/useAccordion";
import { PillGroup } from "../PillGroup/PillGroup";
import { easeTransition } from "../../AnimatedComponent/AnimatedComponent.animations";
import { CheckboxGroup, CheckboxGroupOption } from "../CheckboxGroup/CheckboxGroup";
import { Plus } from "../../Icons/Plus";
import { Count } from "../../Atoms/Count/Count";

export enum GroupType {
  Checkbox,
  Pill,
}

interface InputGroupAccordionProps {
  id: string;
  name: string;
  label: string;
  currentValues: CheckboxGroupOption[];
  options: CheckboxGroupOption[];
  groupType: GroupType;
  onChange: (newValues: CheckboxGroupOption[]) => void;
}

const getGroupTypeComponent = (groupType: GroupType) => {
  switch (groupType) {
    case GroupType.Pill:
      return PillGroup;
    case GroupType.Checkbox:
    default:
      return CheckboxGroup;
  }
};

export const InputGroupAccordion = ({
  id,
  name,
  label,
  currentValues,
  options,
  groupType,
  onChange,
}: InputGroupAccordionProps) => {
  const { isExpanded, toggle } = useAccordion();

  const GroupType = getGroupTypeComponent(groupType);

  return (
    <div
      id={id}
      className={classNames("InputGroupAccordion", {
        "InputGroupAccordion--expanded": isExpanded,
      })}
    >
      <div className="InputGroupAccordion__header">
        <div id={`${id}Label`} className="InputGroupAccordion__label">
          {label}
        </div>
        <div className="InputGroupAccordion__actions">
          <Count
            count={currentValues.length}
            ariaLabel="Number of selected filters"
            variant="pill"
          />

          <button
            className="InputGroupAccordion__toggleButton"
            aria-expanded={isExpanded}
            aria-controls={`${id}Content`}
            aria-label={isExpanded ? "Collapse" : "Expand"}
            onClick={toggle}
          >
            <Plus aria-hidden="true" />
          </button>
        </div>
      </div>

      <AnimatedComponent
        id={`${id}Content`}
        className="InputGroupAccordion__content"
        tag="div"
        role="region"
        aria-labelledby={`${id}Label`}
        entry="expand"
        exit="collapse"
        isOn={isExpanded}
        overrideTransition={easeTransition}
        aria-hidden={!isExpanded}
      >
        <GroupType
          className="InputGroupAccordion__fieldset"
          name={name}
          options={options}
          currentValues={currentValues}
          onChange={onChange}
        />
      </AnimatedComponent>
    </div>
  );
};
