import React, { ChangeEventHandler } from "react";
import { camelCase, upperFirst } from "lodash";
import { CheckmarkMedium } from "../../Icons/CheckmarkMedium";
import { DummyInput } from "../DummyInput";
import { SelectOption } from "../SelectOption";

interface CheckboxSelectItemProps {
  id: string;
  name: string;
  label: string;
  value: string;
  isChecked: boolean;
  onChange?: (option: SelectOption, checked: boolean) => void;
}

export const CheckboxSelectItem = ({
  id,
  name,
  label,
  value,
  isChecked,
  onChange,
}: CheckboxSelectItemProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (onChange) {
      onChange({ value, label }, event.target.checked);
    }
  };

  return (
    <li className="CheckboxSelect__item">
      <DummyInput
        id={id}
        className="CheckboxSelect__input"
        name={name}
        type="checkbox"
        value={value}
        checked={isChecked}
        onChange={handleChange}
      />
      <label htmlFor={id} className="CheckboxSelect__itemLabel">
        {label}
        <CheckmarkMedium className="CheckboxSelect__itemCheckmark" />
      </label>
    </li>
  );
};
