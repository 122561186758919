import React, { FC } from "react";
import classNames from "classnames";
import { ComponentProps } from "../ComponentProps.csharp";
import { NavigationRootType } from "../../Navigation/NavigationRootType.csharp";
import { NavigationViewModel } from "../../Navigation/NavigationViewModel.csharp";
import { NavigationSectionViewModel } from "../../Navigation/NavigationSectionViewModel.csharp";
import { ExternalLinkIcon } from "../../react-components/Discovery/Icons/ExternalLinkIcon";
import { NavSearchInput } from "./NavSearchInput";
import { NavList, NavListSection, NavListSectionItem } from "../NavList/NavList";

interface NavMobileProps extends ComponentProps<NavigationViewModel> {
  isOpened: boolean;
}

const formatSections = (
  sections: NavigationSectionViewModel[],
  isKsat = false,
): NavListSection[] => {
  return sections.map((section) => ({
    header: section.header,
    linkUrl: section.linkUrl,
    linkTarget: section.linkTarget,
    items: section.items.map(
      (sectionItem): NavListSectionItem => ({
        title: sectionItem.title,
        linkUrl: sectionItem.linkUrl,
        linkTarget: sectionItem.linkTarget,
        description: isKsat ? sectionItem.description : undefined,
      }),
    ),
    additionalLinksHeader: isKsat ? section.additionalLinksHeader : undefined,
    additionalLinksDescription: isKsat ? section.additionalLinksDescription : undefined,
    additionalLinks: isKsat ? section.additionalLinks : undefined,
  }));
};

export const NavMobile: FC<NavMobileProps> = ({ model, isOpened }) => {
  const { sections, microMenuItems, searchInput, headerLink, navigationRootType } = model;

  const formattedSections = formatSections(
    sections,
    navigationRootType === NavigationRootType.Ksat,
  );

  return (
    <div
      className={classNames("NavMobile", { "NavMobile--opened": isOpened })}
      aria-hidden={!isOpened}
      aria-modal
      aria-label="Mobile navigation"
    >
      <div className="NavMobile__logoPlaceholder" aria-hidden />
      <div
        className={classNames("NavMobile__content", {
          "NavMobile__content--withPaddingBottom": headerLink,
        })}
      >
        <div className="NavMobile__searchInputWrapper">
          <NavSearchInput model={searchInput} color="dark" a11yHidden={!isOpened} />
        </div>

        <NavList
          sections={formattedSections}
          isVisible={isOpened}
          textColor="dark"
          a11yHidden={!isOpened}
          className={classNames("NavMobile__navListWrapper", {
            "NavMobile__navListWrapper--withPaddingBottom": !microMenuItems.length,
          })}
        />
        {!!microMenuItems.length && (
          <div className="NavMobile__microMenuItemsWrapper">
            <ul className="NavMobile__microMenuItemsList">
              {microMenuItems.map((item, idx) => (
                <li key={idx} className="NavMobile__microMenuItem">
                  <a href={item.linkUrl}>{item.title}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {headerLink && (
        <div className="NavMobile__headerLinkWrapper">
          <a
            className="Header__topNavHeaderItemLink NavMobile__headerLink"
            href={headerLink.linkUrl}
            tabIndex={isOpened ? undefined : -1}
          >
            <span>{headerLink.title}</span>
            <ExternalLinkIcon />
          </a>
        </div>
      )}
    </div>
  );
};
