import { useCallback, useEffect, useState } from "react";

export const usePageLoaded = () => {
  const [isPageLoaded, setPageLoaded] = useState(false);

  const loadPage = useCallback(() => {
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", loadPage);

    return () => window.removeEventListener("DOMContentLoaded", loadPage);
  }, [loadPage]);

  return isPageLoaded;
};
