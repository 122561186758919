import React, { SVGProps, memo } from "react";
export const ExternalLinkIcon = memo(function ExternalLinkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 19 18"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.278 9.83021V12.5295C13.278 13.5236 12.4721 14.3295 11.478 14.3295L5.97041 14.3295C4.9763 14.3295 4.17041 13.5236 4.17041 12.5295L4.17041 7.02192C4.17041 6.02781 4.9763 5.22192 5.97041 5.22192H8.7116V6.57192H5.97041C5.72188 6.57192 5.52041 6.7734 5.52041 7.02192V12.5295C5.52041 12.778 5.72188 12.9795 5.97041 12.9795H11.478C11.7265 12.9795 11.928 12.778 11.928 12.5295L11.928 9.83021L13.278 9.83021Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8295 4.90791V8.69739H13.4795V5.02041L9.80251 5.02041V3.67041L13.592 3.67041C14.2754 3.67041 14.8295 4.22446 14.8295 4.90791Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64952 9.89578L13.6772 3.86811L14.6318 4.82271L8.60412 10.8504L7.64952 9.89578Z"
        fill="currentColor"
      />
    </svg>
  );
});
