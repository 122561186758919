import isIE from "../../../Animations/isIE";
import isAboveTablet from "../../../Animations/isAboveTablet";

document.addEventListener("DOMContentLoaded", function () {
    const overlays = document.querySelectorAll(".animate-overlay");
    const logoWrappers = document.querySelectorAll(".StoryHeaderInfo__logoWrapper");
    const hasDefaultOpacity = !!document.querySelector(".StoryHeaderInfo__background.animate-opacity");
    
    function createObserver() {
        const options = {
            root: null,
            rootMargin: '-130px 0px 0px 0px',
            threshold: 0
        };

        const observer = new IntersectionObserver(handleIntersect, options);

        for (let i = 0; i < logoWrappers.length; i++) {
            observer.observe(logoWrappers[i]);
        }
    }

    function handleIntersect(entries: IntersectionObserverEntry[]) {
        entries.forEach((entry: IntersectionObserverEntry) => {
            let logo = entry.target.querySelector(".StoryHeaderInfo__logo");

            if (logo) {
                if (entry.isIntersecting) {
                    logo.classList.remove("StoryHeaderInfo__logo--hide");
                } else {
                    logo.classList.add("StoryHeaderInfo__logo--hide");
                }
            }
        });
    }

    function darkenOnScroll() {
        for (let i = 0; i < overlays.length; i++) {
            let overlay = <HTMLElement>overlays[i];

            if (window.pageYOffset > 100 || (isIE() && document.body.scrollTop > 100)) {
                overlay.classList.add("animate-opacity");
            } else {
                overlay.classList.remove("animate-opacity");
            }
        }
    }

    createObserver();

    if(hasDefaultOpacity) return;

    if (isIE()) {
        document.body.addEventListener("scroll", darkenOnScroll, false);
    } else {
        document.addEventListener("scroll", darkenOnScroll, false);
    }
}, false);

window.addEventListener("load", function () {
    const addOnPlayHandler = () => {
        const figureVideo = document.querySelector('.StoryHeaderInfo__video figure');
        
        if (!figureVideo) {
            return;
        }

        // @ts-ignore
        figureVideo.onPlay = () => {
            const background = document.getElementById('StoryHeaderInfo__background');
            background && background.classList.add('StoryHeaderInfo__background--above');
        }
    }

    const loader = <HTMLElement>document.querySelector("#StoryHeaderInfo__loader");
    if (loader != null && loader.parentNode != null) {
        loader.parentNode.removeChild(loader);
    }

    addOnPlayHandler();
});