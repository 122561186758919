import * as React from "react";
import ContentBar from "../../Partials/ContentBar/ContentBar";
import {CourseSubcategorySummaryViewModel} from "./CourseSubcategorySummaryViewModel.csharp";
import {CourseLinkViewModel} from "./CourseLinkViewModel.csharp";
import {CourseListProps} from "./CourseListProps.csharp";

export class CourseList extends React.Component<CourseListProps> {
    constructor(props: CourseListProps) {
        super(props);

        this.createSubcategory = this.createSubcategory.bind(this);
    }
    
    render() {
        const items = this.props.model.map(item =>
                    
            <div className="CourseList__item" key={item.name}>
                <ContentBar
                    title={item.name}>
                    <div className="CourseList__item-description RichtextArea" dangerouslySetInnerHTML={{__html:item.description}}/>
                    {item.courses && <ul className="CourseList__item-courses">{item.courses.map(CourseList.createCourse)}</ul>}
                    {item.subcategories && item.subcategories.map(this.createSubcategory)}
                </ContentBar>
            </div>);

        return (
            <ul className="CourseList">
                {items}
            </ul>
        )
    }

    private createSubcategory(subcategory: CourseSubcategorySummaryViewModel) {
        return <div key={subcategory.name}>
            <h2 className="CourseList__itemHeading">{subcategory.name}</h2>
            <div dangerouslySetInnerHTML={{__html:subcategory.description}}/>
            <ul className="CourseList__item-courses">
                {subcategory.courses && subcategory.courses.length ? subcategory.courses.map(CourseList.createCourse) : this.props.noCoursesText}
            </ul>
        </div>
    }

    private static createCourse(course: CourseLinkViewModel) {
        return <li key={course.url} className="ContentBar__list-item">
            <ContentBar title={course.name} url={course.url}/>
        </li>
    }
}