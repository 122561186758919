import React, { FC } from "react";
import { ArrowRight } from "../../react-components/Icons/ArrowRight";
import { ContentListSlider } from "../../Partials/ContentList/Slider/ContentListSlider";
import { SplideSlide } from "@splidejs/react-splide";
import { ButtonGhost } from "../../react-components/Buttons/ButtonGhost";
import { formatMonthsPeriod } from "../../react-components/dateUtil";
import { EventViewModel } from "./ViewModels/EventViewModel.csharp";
import { LinkViewModel } from "../../Links/LinkViewModel.csharp";

export type UpcomingEventsItem = EventViewModel;

export interface UpcomingEventsProps {
  items: UpcomingEventsItem[];
  seeAllLink?: LinkViewModel;
}

const MAX_NUMBER_OF_UPCOMING_EVENTS_MOBILE = 8;
const MAX_NUMBER_OF_UPCOMING_EVENTS_DESKTOP = 5;

export const UpcomingEvents: FC<UpcomingEventsProps> = ({ items, seeAllLink }) => {
  if (!items.length) return null;

  const renderItem = (item: UpcomingEventsItem) => (
    <a href={item.url} className="UpcomingEvents__itemLink">
      <time className="UpcomingEvents__itemDate">
        {formatMonthsPeriod(item.startDate, item.endDate)}
      </time>
      <h3 className="UpcomingEvents__itemTitle">{item.title}</h3>
      <address className="UpcomingEvents__itemLocation">{item.location}</address>
      <div className="UpcomingEvents__itemIconWrapper">
        <ArrowRight className="UpcomingEvents__itemArrow" />
      </div>
    </a>
  );

  const renderSeeAllBtn = () => (
    <ButtonGhost
      mode="light"
      as="a"
      href={seeAllLink?.href}
      icon={ArrowRight}
      iconPosition="right"
      color="blue"
      title={seeAllLink?.title}
      target={seeAllLink?.target}
    >
      {seeAllLink?.text}
    </ButtonGhost>
  );

  const renderSliderContent = () => {
    const slides: React.JSX.Element[] = [];
    items.slice(0, MAX_NUMBER_OF_UPCOMING_EVENTS_MOBILE).forEach((item, idx) => {
      slides.push(
        <SplideSlide className="splide__slide UpcomingEvents__slide" key={idx}>
          {renderItem(item)}
        </SplideSlide>,
      );
    });
    if (seeAllLink?.text && seeAllLink?.href) {
      slides.push(
        <SplideSlide className="splide__slide UpcomingEvents__slide" key="see_all">
          <div className="UpcomingEvents__seeAllWrapperMobile">{renderSeeAllBtn()}</div>
        </SplideSlide>,
      );
    }
    return slides;
  };

  return (
    <section className="UpcomingEvents">
      <h2 className="UpcomingEvents__title">Upcoming events</h2>
      <ContentListSlider className="UpcomingEvents__slider">
        {renderSliderContent()}
      </ContentListSlider>
      <div className="UpcomingEvents__desktop">
        <ul className="UpcomingEvents__list">
          {items.slice(0, MAX_NUMBER_OF_UPCOMING_EVENTS_DESKTOP).map((item, idx) => (
            <li className="UpcomingEvents__item" key={idx}>
              {renderItem(item)}
            </li>
          ))}
        </ul>
        {seeAllLink?.text && seeAllLink?.href && renderSeeAllBtn()}
      </div>
    </section>
  );
};
