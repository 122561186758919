import React, { FC } from "react";
import classNames from "classnames";

export type AnchorNavigationItemType = {
  name: string;
  id: string;
};

interface AnchorNavigationProps {
  anchors: AnchorNavigationItemType[];
  hiddenOnMobile?: boolean;
  bigTouchArea?: boolean;
}

export const AnchorNavigation: FC<AnchorNavigationProps> = ({
  anchors,
  hiddenOnMobile = false,
  bigTouchArea = false,
}) => {
  return (
    <nav
      className={classNames("AnchorNavigation", {
        "AnchorNavigation--hiddenOnMobile": hiddenOnMobile,
      })}
    >
      <ul
        className={classNames("AnchorNavigation__list", {
          "AnchorNavigation__list--bigTouchArea": bigTouchArea,
        })}
      >
        {anchors.map((anchor, idx) => (
          <li key={`${anchor.id}_${idx}`}>
            <a href={`#${anchor.id}`}>
              <span>{anchor.name}</span>
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};
