import React from "react";
import { GroupBase, SingleValueProps } from "react-select";
import { RadioSelectOption } from "./RadioSelect.types";

export const SingleValue = ({ children, innerProps }: SingleValueProps<RadioSelectOption, false, GroupBase<RadioSelectOption>>) => {
  return (
    <div className="RadioSelect__singleValue" {...innerProps}>
      {children}
    </div>
  )
};
