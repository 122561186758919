import React, { memo } from "react";
import { OfficeLocationsListItemLink } from "./OfficeLocationsListItemLink";
import OfficeLocationDepartmentEmail from "./OfficeLocationDepartmentEmail";

interface OfficeLocationsListItemDepartmentProps {
  title: string;
  email?: string;
  phoneNumber?: string;
}

export const OfficeLocationsListItemDepartment = memo<OfficeLocationsListItemDepartmentProps>(function OfficeLocationsListItemDepartment({
  title,
  email,
  phoneNumber,
}) {

  return (
    <div className="OfficeLocationsListItem__department">
      <p><strong>{title}</strong></p>

      <OfficeLocationsListItemLink href={`mailto:${email}`} isSmall>
        {email && (<OfficeLocationDepartmentEmail email={email}/>)}
      </OfficeLocationsListItemLink>

      <OfficeLocationsListItemLink href={`tel:${phoneNumber}`} isSmall>
        {phoneNumber}
      </OfficeLocationsListItemLink>
    </div>
  );
});
