import React from "react";
import { OfficeLocationViewModel } from "../../OfficeLocationViewModel.csharp";
import { OfficeLocationsListItems } from "./OfficeLocationsListItems";
import { OfficeLocationsListNoOffices } from "./OfficeLocationsListNoOffices";
import { OfficeLocationsListWithMapTranslations } from "../../OfficeLocationsListWithMapTranslations.csharp";
import { OfficeLocationsDisplayType } from "../OfficeLocationsWithMapListing.utils";
import { OfficeLocationsListNearbyLocations } from "./OfficeLocationsListNearbyLocations";

export interface OfficeLocationsListProps {
  locations: OfficeLocationViewModel[];
  translations: OfficeLocationsListWithMapTranslations;
  selectedOfficeId?: string;
  displayType?: OfficeLocationsDisplayType;
  onListItemClick?: (selectedOffice: OfficeLocationViewModel) => void;
}

export const OfficeLocationsList = ({
  locations,
  displayType,
  translations,
  selectedOfficeId,
  onListItemClick,
}: OfficeLocationsListProps) => {
  const getComponentToRender = () => {
    switch (displayType) {
      case OfficeLocationsDisplayType.Nearby:
        return OfficeLocationsListNearbyLocations;

      case OfficeLocationsDisplayType.NoOffices:
        return OfficeLocationsListNoOffices;

      case OfficeLocationsDisplayType.Default:
      default:
        return OfficeLocationsListItems;
    }
  };

  const OfficeLocationsListComponent = getComponentToRender();

  return (
    <div className="OfficeLocationsList">
      <OfficeLocationsListComponent
        locations={locations}
        translations={translations}
        selectedOfficeId={selectedOfficeId}
        onListItemClick={onListItemClick}
      />
    </div>
  );
};
