import { useEffect, useState } from "react";

export const useIsEpiEditMode = () => {
  const [isEditMode, setEditMode] = useState(false);

  const update = () => setEditMode(!!window?.epi?.inEditMode);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      update();

      window.addEventListener("load", update);

      return () => window.removeEventListener("load", update);
    }

    return undefined;
  }, []);

  return isEditMode;
};
