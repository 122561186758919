export const UrlHelper = {
  isFileLink(url: string): boolean {
    return /\.(pdf|xls|xlsx|doc|docx|ppt|pptx|csv|txt|jpg|jpeg|png|gif|webp|svg|bmp|mp4|avi|mov|mkv|wmv|webm|vlf|ogg)$/i.test(
      url,
    );
  },
  isValidUrl(url: string): boolean {
    try {
      const validUrl = new URL(url);

      return !!validUrl;
    } catch {
      return false;
    }
  },
  getCurrentUrl(): URL {
    return new URL(window.location.href);
  },
  constructUrl(url: string): URL | null {
    let newUrl: URL | null;

    try {
      newUrl = new URL(url, document.baseURI); // if 'url' is a relative path, it will use document.baseURI
    } catch {
      newUrl = null;
    }

    return newUrl;
  },
  removeHashFromUrl(): void {
    const { protocol, host, pathname, search } = window.location;
    const newUrl = `${protocol}//${host}${pathname}${search}`;
    window.history.replaceState({}, "", newUrl);
  },
};
