import React, { FC } from "react";
import { InfoRevealTile } from "../InfoRevealTile/InfoRevealTile";
import { ContentListTileViewModel } from "../ContentList/Tiles/ContentListTileViewModel.csharp";

export interface InfoMosaicProps {
  title: string;
  description?: string;
  tiles: ContentListTileViewModel[];
  readMoreText?: string;
}

export const InfoMosaic: FC<InfoMosaicProps> = ({ title, description, tiles, readMoreText }) => {
  if (!tiles || !tiles.length) return null;

  const { url: imageUrl, alt } = tiles[0].image.model;

  return (
    <div className="InfoMosaic">
      <div className="InfoMosaic__titleWrapper wrapper">
        {title && <h2 className="InfoMosaic__title">{title}</h2>}
        {description && <p className="InfoMosaic__description">{description}</p>}
      </div>
      <ul className="InfoMosaic__list">
        {tiles.map((item, idx) => (
          <li key={idx} className="InfoMosaic__item">
            <InfoRevealTile model={item} readMoreText={readMoreText} renderImageAsBg />
          </li>
        ))}
        {imageUrl && (
          <img src={imageUrl} alt={alt || ""} className="InfoMosaic__image" aria-hidden />
        )}
      </ul>
    </div>
  );
};
