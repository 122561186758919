import { Transition, Tween, Variants } from "framer-motion";

const cubic = [0.87, 0.01, 0.65, 1];

const rootTransition: Transition = {
  transition: {
    duration: 0.3,
    ease: cubic,
  },
};

export const easeTransition: Transition = {
  transition: {
    duration: 0.25,
    ease: [0.25, 0.1, 0.25, 1.0],
  },
};

export const fadeInMoveTransition: Transition = {
  transition: {
    duration: 1.25,
    ease: [0.22, 0.44, 0, 1],
  },
};

export const InitialAnimations: Variants = {
  outOfScreen: {
    x: "100%",
    opacity: 0,
  },
  outOfScreenY: (value: string | number = "100%") => ({
    y: value,
    opacity: 0,
  }),
  drawerClosed: {
    y: "100dvh",
  },
  fadedOut: {
    opacity: 0,
  },
  fadedIn: {
    opacity: 1,
    display: "flex",
  },
  collapsed: {
    height: 0,
    visibility: "hidden",
  },
  expanded: {
    height: "auto",
    visibility: "visible",
  },
};

export const ExitAnimations: Variants = {
  fadeOutToBottom: {
    y: "10px",
    opacity: 0,
    ...rootTransition,
  },
  fadeOutToLeft: {
    x: "-10px",
    opacity: 0,
    ...rootTransition,
  },
  moveOutToLeftFull: {
    x: "-100%",
    transition: {
      duration: 1,
      ease: cubic,
    },
    transitionEnd: { display: "none" },
  },
  fadeOutToRight: {
    x: "10px",
    opacity: 0,
    ...rootTransition,
  },
  moveOutToBottom: {
    y: "0",
    ...rootTransition,
  },
  closeDrawer: {
    y: "100dvh",
  },
  moveOutToBottomCustom: (value: string | number) => ({
    y: value,
    ...rootTransition,
  }),
  moveOutToRightFull: {
    x: "100%",
    opacity: 0,
    transition: {
      duration: 1,
      ease: cubic,
    },
    transitionEnd: { display: "none" },
  },
  fadeOut: {
    opacity: 0,
    ...rootTransition,
  },
  fadeOutToDisplayNone: {
    opacity: 0,
    ...rootTransition,
    transitionEnd: { display: "none" },
  },
  rollIn: {
    width: "20%",
    opacity: 0,
    ...rootTransition,
  },
  collapse: {
    height: 0,
    transitionEnd: {
      visibility: "hidden",
    },
  },
};

export const EntryAnimations: Variants = {
  moveInOver: {
    x: "0",
    opacity: 1,
    zIndex: 1,
    ...rootTransition,
  },
  moveFromBottomToCustom: (value: string | number) => ({
    y: value,
    opacity: 1,
    ...rootTransition,
  }),
  moveFromBottom: {
    y: "0",
    opacity: 1,
    ...rootTransition,
  },
  openDrawer: {
    y: "0dvh",
  },
  moveInOverCustomZ: (value: string | number = 1) => ({
    x: "0",
    opacity: 1,
    zIndex: value,
    ...rootTransition,
  }),
  fadeInOpacity: {
    opacity: 1,
    ...rootTransition,
  },
  fadeIn: {
    x: "0",
    y: "0",
    opacity: 1,
    ...rootTransition,
  },
  fadeInToCustomDisplay: (display: string = "block") => ({
    x: "0",
    y: "0",
    opacity: 1,
    display,
    ...rootTransition,
  }),
  rollOut: {
    width: "100%",
    ...rootTransition,
  },
  fadeInDelayed: (value: number = 1) => ({
    x: "0",
    y: "0",
    opacity: 1,
    transition: {
      ...(rootTransition.transition as Tween),
      delay: value,
    },
  }),
  rollOutDelayed: (value: number = 1) => ({
    width: "100%",
    opacity: 1,
    transition: {
      ...(rootTransition.transition as Tween),
      delay: value,
    },
  }),
  expand: {
    height: "auto",
    visibility: "visible",
  },
};

export const SpecialAnimationsEntry: Variants = {
  blurredContainerEntry: {
    display: "block",
    transition: {
      duration: 0.001,
    },
  },
  blurredBackgroundEntry: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: cubic,
    },
  },
};

export const SpecialAnimationsExit: Variants = {
  blurredContainerExit: {
    transition: {
      duration: 0.001,
      when: "afterChildren",
    },
    transitionEnd: { display: "none" },
  },
  blurredBackgroundExit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: cubic,
      when: "afterChildren",
    },
  },
};
