import classNames from "classnames";
import React, { HTMLAttributes, ReactNode, memo } from "react";
import { LabelColor } from "../../../Common/Labels/LabelColor.csharp";

export type LabelSize = "small" | "large";
export type LabelAppearance = "light" | "dark";

export interface LabelProps extends HTMLAttributes<HTMLSpanElement> {
  size?: LabelSize;
  color?: LabelColor;
  appearance?: LabelAppearance;
  children: ReactNode;
}

export const Label = memo<LabelProps>(function Label({
  className,
  size = "large",
  color = LabelColor.Default,
  appearance = "dark",
  children,
  ...props
}) {
  const sizeClassModifier = `Label--${size}`;
  const colorClassModifier = `Label--${color}`;
  const appearanceClassModifier = `Label--${appearance}`;

  return (
    <span
      className={classNames(
        "Label",
        sizeClassModifier,
        colorClassModifier,
        appearanceClassModifier,
        className,
      )}
      {...props}
    >
      {children}
    </span>
  );
});
