import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from "react";
import { OfficeLocationViewModel } from "../OfficeLocationViewModel.csharp";
import { GoogleMap } from "@react-google-maps/api";
import classNames from "classnames";
import OfficeLocationsClusterer from "./OfficeLocationsClusterer/OfficeLocationsClusterer";

interface OfficeLocationsMapProps {
  locations: OfficeLocationViewModel[];
  mapId: string;
  markerUrl: string;
  selectedMarkerUrl: string;
  selectedOfficeId?: string;
  onMarkerSelect?: (selectedOffice: OfficeLocationViewModel, e?: google.maps.MapMouseEvent) => void;
  onMarkerDeselect?: () => void;
  className?: string;
  zoom?: number;
  onLoad?: (map: google.maps.Map) => void;
}

export const OfficeLocationsMap = forwardRef<google.maps.Map, OfficeLocationsMapProps>(
  function OfficeLocationsMap(
    {
      locations,
      mapId,
      markerUrl,
      selectedMarkerUrl,
      selectedOfficeId,
      onMarkerSelect,
      onMarkerDeselect,
      className,
      zoom = 10,
      onLoad,
    },
    ref,
  ) {
    const mapRef = useRef<google.maps.Map | null>(null);

    const center = useMemo<google.maps.LatLngLiteral | undefined>(() => {
      const firstPlace =
        locations.find((location) => location.id === selectedOfficeId) ?? locations[0];
      return firstPlace ? { lat: firstPlace.lat, lng: firstPlace.lng } : undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    const onMapLoad = useCallback(
      (map: google.maps.Map) => {
        mapRef.current = map;

        if (onLoad) {
          onLoad(map);
        }
      },
      [onLoad],
    );

    const options = useMemo<google.maps.MapOptions>(
      () => ({
        mapId,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
      }),
      [mapId],
    );

    useImperativeHandle<google.maps.Map | null, google.maps.Map | null>(ref, () => {
      return mapRef.current;
    });

    return (
      <GoogleMap
        zoom={zoom}
        options={options}
        center={center}
        mapContainerClassName={classNames("OfficeLocationsMap", className)}
        onLoad={onMapLoad}
      >
        <OfficeLocationsClusterer
          locations={locations}
          markerUrl={markerUrl}
          selectedMarkerUrl={selectedMarkerUrl}
          selectedOfficeId={selectedOfficeId}
          onMarkerSelect={onMarkerSelect}
          onMarkerDeselect={onMarkerDeselect}
        />
      </GoogleMap>
    );
  },
);
