import React, { ChangeEventHandler, FC, FormEvent, KeyboardEvent, useRef, useState } from "react";
import classNames from "classnames";
import { Search } from "../../Icons/Search";
import { Close } from "../../Icons/Close";

interface SearchFieldProps {
  value: string;
  onSubmit?: (event: FormEvent<HTMLInputElement | HTMLButtonElement>) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onReset?: (event: FormEvent<HTMLInputElement | HTMLButtonElement>) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  color?: "light" | "dark";
  searchButtonLabel?: string;
  resetButtonLabel?: string;
  placeholderText?: string;
  a11yHidden?: boolean;
  className?: string;
}

export const SearchField: FC<SearchFieldProps> = ({
  value,
  onSubmit,
  onChange,
  onReset,
  onKeyUp,
  onBlur,
  color = "black",
  searchButtonLabel,
  resetButtonLabel,
  placeholderText,
  a11yHidden = false,
  className,
}) => {
  const [isFocusedByKeyboard, setIsFocusedByKeyboard] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const returnFocusToInput = () => {
    inputRef?.current?.focus();
  };

  const handleReset = (e: FormEvent<HTMLInputElement | HTMLButtonElement>) => {
    onReset?.(e);
    returnFocusToInput();
  };

  return (
    <div className={classNames("SearchField", `SearchField--${color}`, className)}>
      <input
        className={classNames("SearchField__input", {
          "SearchField__input--hasKeyboardFocus": isFocusedByKeyboard,
        })}
        type="search"
        enterKeyHint="search"
        placeholder={placeholderText}
        value={value}
        onChange={onChange}
        tabIndex={a11yHidden ? -1 : undefined}
        onKeyUp={onKeyUp}
        onBlur={() => {
          setIsFocusedByKeyboard(false);
          onBlur?.();
        }}
        onSubmit={onSubmit}
        ref={inputRef}
      />
      {onReset && (
        <button
          className="SearchField__button"
          type="reset"
          aria-label={resetButtonLabel || "Reset input"}
          tabIndex={a11yHidden ? -1 : undefined}
          onClick={handleReset}
        >
          <Close className="SearchField__icon" />
        </button>
      )}
      <button
        className="SearchField__button"
        type="submit"
        aria-label={searchButtonLabel || "Search"}
        tabIndex={a11yHidden ? -1 : undefined}
        onClick={onSubmit}
      >
        <Search fill="currentColor" className="SearchField__icon" />
      </button>
    </div>
  );
};
