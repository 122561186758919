import React, { useEffect, useImperativeHandle, useRef } from "react";
import { GroupBase, OptionProps } from "react-select";
import { Radio } from "../Radio/Radio";
import classNames from "classnames";
import { RadioSelectOption } from "./RadioSelect.types";

export const Option = ({
  innerProps,
  innerRef,
  label,
  isFocused,
  isSelected,
  selectProps: {
    isKeyboardUsed,
    isMobile
  },
}: OptionProps<RadioSelectOption, false, GroupBase<RadioSelectOption>>) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMobile && isKeyboardUsed && isFocused && ref.current) {
      ref.current.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }, [isKeyboardUsed, isFocused]);

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(innerRef, () => ref.current);

  return (
    <div className={classNames("RadioSelect__menuOption", {
      "RadioSelect__menuOption--focused": isFocused && isKeyboardUsed,
    })} ref={ref} {...innerProps}>
      <div className="RadioSelect__menuOptionInner">
        <div className="RadioSelect__menuOptionLabel">
          {label}
        </div>
        <div className="RadioSelect__menuOptionRadioButton">
          <Radio hasInput={false} selected={isSelected} focused={isFocused && isKeyboardUsed} />
        </div>
      </div>
    </div>
  )
};
