import React from "react";
import { SideTextHotSpotViewModel } from "./SideTextHotSpotViewModel.csharp";
import { DescriptionLink } from "./DescriptionLink";
import classNames from "classnames";

interface MobileDescriptionBoxProps {
  hotSpots: SideTextHotSpotViewModel[];
  activeHotSpot: SideTextHotSpotViewModel | null;
  setActiveHotSpot: (hotSpot: SideTextHotSpotViewModel | null) => void;
}

export const MobileDescriptionBox: React.FC<MobileDescriptionBoxProps> = ({
  hotSpots,
  activeHotSpot,
  setActiveHotSpot,
}) => {
  const toggleCurrentHotSpot = (hotSpot: SideTextHotSpotViewModel) => {
    if (activeHotSpot == hotSpot) {
      setActiveHotSpot(null);
    } else {
      setActiveHotSpot(hotSpot);
    }
  };

  return (
    <div className="SideTextHotSpotsImage__MobileDescriptionBox">
      {hotSpots.map((h, i) => (
        <div className="SideTextHotSpotsImage__MobileDescriptionBoxItem" key={`SideTextHotSpotsImage__MobileDescriptionBoxItem${i}`}>
          <button
            onClick={() => toggleCurrentHotSpot(h)}
            className="SideTextHotSpotsImage__MobileDescriptionBoxItemTitle"
            key={i}
          >
            <span>{i + 1}. {h.previewText}</span>
          </button>
          <div
            className={classNames(
              "SideTextHotpotsImage__DescriptionInner",
              "SideTextHotSpotsImage__MobileDescriptionBoxItemDescription",
                {"SideTextHotSpotsImage__MobileDescriptionBoxItemDescription--hidden": h != activeHotSpot}
            )}
          >
            {h.description && (
              <div dangerouslySetInnerHTML={{ __html: h.description }} />
            )}
            {h.descriptionLink.href && <DescriptionLink {...h.descriptionLink} />}
          </div>
        </div>
      ))}
    </div>
  );
};
