import React, { SVGProps, memo } from "react";
export const Info = memo(function Info(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 25 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 19.6691C16.7578 19.6691 20.2006 16.2307 20.2006 12C20.2006 7.76935 16.7578 4.33093 12.5 4.33093C8.24221 4.33093 4.79939 7.76935 4.79939 12C4.79939 16.2307 8.24221 19.6691 12.5 19.6691ZM12.5 21.1691C17.5814 21.1691 21.7006 17.064 21.7006 12C21.7006 6.93607 17.5814 2.83093 12.5 2.83093C7.41864 2.83093 3.29939 6.93607 3.29939 12C3.29939 17.064 7.41864 21.1691 12.5 21.1691Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 9.53851C12.9142 9.53851 13.25 9.8743 13.25 10.2885V17.4874C13.25 17.9016 12.9142 18.2374 12.5 18.2374C12.0858 18.2374 11.75 17.9016 11.75 17.4874V10.2885C11.75 9.8743 12.0858 9.53851 12.5 9.53851Z"
        fill="currentColor"
      />
      <path
        d="M13.5087 7.52124C13.5087 8.07831 13.0571 8.52991 12.5 8.52991C11.9429 8.52991 11.4913 8.07831 11.4913 7.52124C11.4913 6.96417 11.9429 6.51257 12.5 6.51257C13.0571 6.51257 13.5087 6.96417 13.5087 7.52124Z"
        fill="currentColor"
      />
    </svg>
  );
});
