import React, { ReactNode, memo } from "react";
import { TooltipContext, TooltipOptions, useTooltip } from "./useTooltip";

interface TooltipProps extends TooltipOptions {
  children: ReactNode;
  trigger?: "hover" | "click";
  dismissAfterMs?: number;
}

export const Tooltip = memo<TooltipProps>(function Tooltip({ children, ...options }) {
  const tooltip = useTooltip(options);

  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
});
