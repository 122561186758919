import React, { FC } from 'react';
import { ComponentProps } from '../../ComponentProps.csharp';
import { BreadcrumbsViewModel } from '../BreadcrumbsViewModel.csharp';

export const MainBreadcrumbs: FC<ComponentProps<BreadcrumbsViewModel>> = ({ model }) => {
  if (!model.ancestors?.length) return null;

  return (
    <div className="MainBreadcrumbs" role="navigation" aria-label="Breadcrumbs">
      <div className="MainBreadcrumbs__wrapper">
        <ol className="MainBreadcrumbs__items">
          {model.ancestors.slice().reverse().map((item, idx) => (
            <li key={idx}>
              <a
                href={item.href}
                aria-current={idx === model.ancestors.length - 1 ? 'page' : undefined}
              >
                {item.text}
              </a>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}
