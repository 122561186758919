import React, { SVGProps, memo } from "react";

export const Plus = memo(function Plus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 25 25"
      {...props}
    >
      <path
        d="M12.4999 7.69336C12.9141 7.69336 13.2499 8.02915 13.2499 8.44336V11.6934H16.4999C16.9141 11.6934 17.2499 12.0291 17.2499 12.4434C17.2499 12.8576 16.9141 13.1934 16.4999 13.1934H13.2499V16.4434C13.2499 16.8576 12.9141 17.1934 12.4999 17.1934C12.0857 17.1934 11.7499 16.8576 11.7499 16.4434V13.1934H8.49992C8.08571 13.1934 7.74992 12.8576 7.74992 12.4434C7.74992 12.0291 8.08571 11.6934 8.49992 11.6934H11.7499V8.44336C11.7499 8.02915 12.0857 7.69336 12.4999 7.69336Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4999 21.6125C17.5813 21.6125 21.7005 17.5073 21.7005 12.4434C21.7005 7.37943 17.5813 3.27429 12.4999 3.27429C7.41857 3.27429 3.29932 7.37943 3.29932 12.4434C3.29932 17.5073 7.41857 21.6125 12.4999 21.6125ZM12.4999 20.1125C16.7577 20.1125 20.2005 16.6741 20.2005 12.4434C20.2005 8.21271 16.7577 4.77429 12.4999 4.77429C8.24214 4.77429 4.79932 8.21271 4.79932 12.4434C4.79932 16.6741 8.24214 20.1125 12.4999 20.1125Z"
        fill="currentColor"
      />
    </svg>
  );
});
