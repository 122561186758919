import React from "react";
import { HighlightsSectionViewModel } from "./HighlightsSectionViewModel.csharp";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { GalleryModal } from "../../../react-components/Modal/GalleryModal/GalleryModal";
import { useGalleryModal } from "../../../react-components/Modal/GalleryModal/useGalleryModal";
import { Highlight } from "./Highlight/Highlight";

export const HighlightsSection = ({
  model: { highlights, mainProfile, thumbnailProfile },
}: ComponentProps<HighlightsSectionViewModel>) => {
  const { isModalOpened, toggleModal, activeSlideIndex, setActiveSlideIndex, onThumbnailClick } =
    useGalleryModal();

  const images = highlights
    .filter((highlight) => highlight.picture.url)
    .map((highlight) => ({ picture: highlight.picture }));

  const renderHighlights = () => {
    let imageIndex = 0;

    return highlights.map((highlight, index) => (
      <Highlight
        key={index}
        {...highlight}
        index={highlight.picture.url ? imageIndex++ : index}
        onThumbnailClick={onThumbnailClick}
      />
    ));
  };

  return (
    <div className="HighlightsSection">
      {renderHighlights()}

      <GalleryModal
        isOpen={isModalOpened}
        onClose={toggleModal}
        images={images}
        mainProfile={mainProfile}
        thumbnailProfile={thumbnailProfile}
        activeSlideIndex={activeSlideIndex}
        setActiveSlideIndex={setActiveSlideIndex}
      />
    </div>
  );
};
