import { useCallback, useEffect, useState } from "react";

export const breakpoints = {
  mobile: 767,
  tablet: 1023,
  laptop: 1439,
};

type BreakpointsKeys = keyof typeof breakpoints;

export const useIsBelowScreenSize = (breakpoint: BreakpointsKeys) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleMediaQueryChange = useCallback(
    (event: MediaQueryListEvent) => setIsMobile(event.matches),
    [],
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      const mediaQuery = window.matchMedia(`(max-width: ${breakpoints[breakpoint]}px)`);
      setIsMobile(mediaQuery.matches);

      mediaQuery.addEventListener("change", handleMediaQueryChange);

      return () => mediaQuery.removeEventListener("change", handleMediaQueryChange);
    }
  }, [handleMediaQueryChange, breakpoint]);

  return isMobile;
};
