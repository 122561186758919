import React, { FC } from 'react';
import classNames from 'classnames';
import { Splide, SplideProps, SplideTrack } from '@splidejs/react-splide';

import { AnimatedCloseButton } from '../../AnimatedCloseButton/AnimatedCloseButton';
import { ResponsivePicture } from '../../../react-components/responsivePicture/responsivePicture';
import { ResponsivePictureViewModel } from "../../../Images/ResponsivePictureViewModel.csharp";
import {PictureProfileDto} from "../../../Images/Resizing/PictureProfileDto.csharp";

interface ProductGalleryProps {
  theme: string;
  pictures: ResponsivePictureViewModel[];
  profile: PictureProfileDto;
  onClose: () => void;
}

export const ProductGallery: FC<ProductGalleryProps> = ({
  theme,
  pictures,
  profile,
  onClose
}) => {
  const splideOptions: SplideProps = {
    options: {
      perPage: 1,
      pagination: true,
      cover: true,
      rewind: true,
    }
  }
  return (
    <div className={classNames("MicrositeProductGallery", `MicrositeProductGallery--${theme}`)}>
      <AnimatedCloseButton
        className="MicrositeProductGallery__button"
        onClick={onClose}
        label="Close modal"
        animationProps="flex"
      />
      {pictures.length > 0 && (
        <Splide hasTrack={false} {...splideOptions}>
          <SplideTrack>
            {pictures.map((image, id) => (
              <li className='splide__slide' key={`${id}-image`}>
                <ResponsivePicture model={image} profile={profile} />
              </li>
            ))}
          </SplideTrack>
          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev">
              Previous
            </button>
            <button className="splide__arrow splide__arrow--next">
              Next
            </button>
          </div>
        </Splide>
      )}
    </div>
  )
};
