import * as React from "react";
import YouTube, {PlayerVars} from 'react-youtube';
import classNames from "classnames";
import {VideoFigureViewModel} from "./VideoFigureViewModel.csharp";
import {ComponentProps} from "../ComponentProps.csharp";

interface VideoFigureState {
    isPlaying: boolean;
}

export class VideoFigure extends React.Component<ComponentProps<VideoFigureViewModel>, VideoFigureState> {
    _figureRef: React.RefObject<any> = React.createRef();
    constructor(props: ComponentProps<VideoFigureViewModel>) {
        super(props);

        this.state = {
            isPlaying: false
        };
    }

    onPlay = () => {
        this.setState({isPlaying: true}, () => {
            const { onPlay } = this._figureRef.current;
            onPlay && onPlay();
        });
    };

    onEnd = () => {
        this.setState({isPlaying: false});
    };

    render() {
        const {model} = this.props;
        let playerOptions: PlayerVars = {};
        if (model.isBackground) {
            playerOptions =
                {
                    autoplay: 0,
                    // @ts-ignore
                    mute: 0,
                    showinfo: 0,
                    controls: 0,
                    loop: 1,
                    playlist: model.youTubeVideoId
                };
        }
        playerOptions.rel = 0;
        return (
            <figure ref={this._figureRef} className={classNames("Figure", "VideoFigure", {"VideoFigure--playing": this.state.isPlaying})}>
                <h2 className={"Section__title"}>{model.title}</h2>
                <div className="VideoFigure__VideoContainer">
                    <YouTube
                        videoId={model.youTubeVideoId}
                        onPlay={this.onPlay}
                        onEnd={this.onEnd}
                        opts={{
                            playerVars: playerOptions
                        }}
                    />
                </div>
                {model.caption &&
                <figcaption className='Figure__caption'>{model.caption}</figcaption>}
            </figure>);
    }
}