import { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import { breakpoints } from '../../useIsBelowScreenSize';

interface UseExpandableSectionProps {
  viewport: 'mobile' | 'desktop' | 'all';
}

const DESKTOP_BREAKPOINT = breakpoints.tablet + 1;

export const useExpandableSection = ({ viewport }: UseExpandableSectionProps) => {
  const [isExpanded, _setIsExpanded] = useState<boolean>(false);
  const [isExpandable, setIsExpandable] = useState<boolean>(false);
  const isExpandedRef = useRef<boolean>(isExpanded);
  const setIsExpanded = (value: boolean) => {
    isExpandedRef.current = value;
    _setIsExpanded(value);
  };
  const sectionRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    const sectionsElement = sectionRef.current;
    if (sectionsElement) {
      const shouldNotCalculateBasedOnViewportWidth =
        viewport === 'all'
          ? false
          : viewport === 'mobile'
            ? window.innerWidth > DESKTOP_BREAKPOINT
            : window.innerWidth <= DESKTOP_BREAKPOINT;
      const maxHeight = viewport === 'mobile' || viewport === 'all' && window.innerWidth <= DESKTOP_BREAKPOINT ? 275 : 500;

      if (shouldNotCalculateBasedOnViewportWidth || sectionsElement.scrollHeight <= maxHeight * 1.33) {
        sectionsElement.style.maxHeight = 'none';
        setIsExpandable(false);
        setIsExpanded(false);
      } else {
        sectionsElement.style.maxHeight = isExpandedRef.current ? `${sectionsElement.scrollHeight}px` : `${maxHeight}px`;
        setIsExpandable(true);
      }
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    handleResize();
  }, [isExpanded]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('resize', throttle(handleResize, 200));
    return () => window.removeEventListener('resize', throttle(handleResize, 200));
  }, []);

  return {
    isExpanded,
    isExpandable,
    setIsExpanded,
    sectionRef,
  }
};
