import React, { useMemo, useState } from "react";
import ReactSelect, {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  SelectComponentsConfig,
} from "react-select";
import { useReducedMotion } from "framer-motion";
import { Control } from "./Control";
import { SelectContainer } from "./SelectContainer";
import { ValueContainer } from "./ValueContainer";
import { Placeholder } from "./Placeholder";
import { DropdownIndicator } from "./DropdownIndicator";
import { IndicatorsContainer } from "./IndicatorsContainer";
import { getPortalRootElement } from "../../../Portal";
import { Menu } from "./Menu";
import { MenuPortal } from "./MenuPortal";
import { MenuList } from "./MenuList";
import { Option } from "./Option";
import { MENU_TRANSITION_DURATION_MS } from "../SelectOptions";
import { SingleValue } from "./SingleValue";
import { useIsBelowScreenSize } from "../../../useIsBelowScreenSize";
import { useIsKeyboardUsed } from "../../../useIsKeyboardUsed";
import { RadioSelectOption } from "./RadioSelect.types";

interface RadioSelectProps {
  className?: string;
  name?: string;
  title?: string;
  options: RadioSelectOption[];
  currentValue?: RadioSelectOption;
  onChange?: (
    newValue: OnChangeValue<RadioSelectOption, false>,
    actionMeta: ActionMeta<RadioSelectOption>,
  ) => void;
  placeholder?: string;
  hiddenLabel: string;
}

export const RadioSelect = ({
  className,
  name,
  title,
  options: initialOptions,
  currentValue,
  onChange,
  placeholder,
  hiddenLabel,
}: RadioSelectProps) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [currentDeltaY, setDeltaY] = useState(0);
  const isMobile = useIsBelowScreenSize("mobile");
  const isKeyboardUsed = useIsKeyboardUsed();
  const reducedMotion = useReducedMotion();

  const handleMenuOpen = () => {
    setDeltaY(0);
    setMenuOpen(true);
    setDrawerVisible(true);
  };

  const handleMenuClose = () => {
    setDrawerVisible(false);
    if (isMobile && !reducedMotion) {
      setTimeout(() => setMenuOpen(false), MENU_TRANSITION_DURATION_MS);
    } else {
      setMenuOpen(false);
    }
  };

  const components = useMemo<
    SelectComponentsConfig<RadioSelectOption, false, GroupBase<RadioSelectOption>>
  >(
    () => ({
      SelectContainer,
      Control,
      ValueContainer,
      Placeholder,
      DropdownIndicator,
      IndicatorsContainer,
      IndicatorSeparator: null,
      Menu,
      MenuPortal,
      MenuList,
      Option,
      SingleValue,
    }),
    [],
  );

  const firstOption = { label: placeholder, value: "" } as RadioSelectOption;
  const options = [firstOption, ...initialOptions];
  const value = currentValue ?? options[0];

  return (
    <ReactSelect
      className={className}
      components={components}
      name={name}
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isMulti={false}
      unstyled
      aria-label={hiddenLabel}
      isSearchable={false}
      menuPortalTarget={isMobile ? getPortalRootElement() : undefined}
      menuIsOpen={isMenuOpen}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      menuShouldScrollIntoView={false}
      // Custom props
      title={title}
      isDrawerVisible={isDrawerVisible}
      setDrawerVisible={setDrawerVisible}
      handleMenuClose={handleMenuClose}
      currentDeltaY={currentDeltaY}
      setDeltaY={setDeltaY}
      isKeyboardUsed={isKeyboardUsed}
      isMobile={isMobile}
    />
  );
};
