import React, { SVGProps, memo } from "react";

export const Minus = memo(function Minus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66727 12.17C4.66727 8.02636 8.02636 4.66727 12.17 4.66727C16.3136 4.66727 19.6727 8.02636 19.6727 12.17C19.6727 16.3136 16.3136 19.6727 12.17 19.6727C8.02636 19.6727 4.66727 16.3136 4.66727 12.17ZM12.17 3C7.10555 3 3 7.10555 3 12.17C3 17.2345 7.10555 21.34 12.17 21.34C17.2345 21.34 21.34 17.2345 21.34 12.17C21.34 7.10555 17.2345 3 12.17 3ZM8.83545 11.3364C8.37505 11.3364 8.00182 11.7096 8.00182 12.17C8.00182 12.6304 8.37505 13.0036 8.83545 13.0036H15.5045C15.965 13.0036 16.3382 12.6304 16.3382 12.17C16.3382 11.7096 15.965 11.3364 15.5045 11.3364H8.83545Z"
        fill="currentColor"
      />
    </svg>
  );
});
