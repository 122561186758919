import React, { FC } from "react";
import { SpecificationsAndDownloadSectionViewModel } from "./SpecificationsAndDownloadSectionViewModel.csharp";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { TechnicalSpecifications } from "../../../Partials/TechnicalSpecifications/TechnicalSpecifications";
import { DownloadsSection } from "../../../Partials/Downloads/DownloadsSection/Discovery/DownloadsSection";
import { Chevron } from "../../../react-components/Icons/Chevron";
import { ButtonOutlined } from "../../../react-components/Buttons/ButtonOutlined";
import classNames from "classnames";
import { useExpandableSection } from "../../../react-components/Discovery/TableContent/useExpandableSection";

export const SpecificationsAndDownloadsSection: FC<
  ComponentProps<SpecificationsAndDownloadSectionViewModel>
> = ({ model }) => {
  const { isExpanded, setIsExpanded, isExpandable, sectionRef } = useExpandableSection({
    viewport: "desktop",
  });

  const {
    sectionId: mainSectionId,
    discoveryDownloadsSectionViewModel: { sectionId: downloadSectionId },
    technicalSpecificationsViewModel: {
      sectionId: techSpecSectionId,
      isVisible: isTechSpecVisible,
    },
  } = model;

  const handleShowMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  const renderSpecificationsSection = isTechSpecVisible;
  const renderDownloadsSection = !!model?.discoveryDownloadsSectionViewModel?.sections?.length;
  const sectionsLayout = renderSpecificationsSection && renderDownloadsSection ? "narrow" : "wide";

  return (
    <div className="wrapper">
      <div className="SpecificationsAndDownloadsSection__content">
        <div
          className={classNames(
            "SpecificationsAndDownloadsSection__sections TableContent__expandableSection kog-grid",
            {
              "TableContent__expandableSection--expanded": isExpanded,
              "TableContent__expandableSection--expandable": isExpandable,
            },
          )}
          id={mainSectionId}
          ref={sectionRef}
        >
          {renderSpecificationsSection && (
            <section
              id={techSpecSectionId}
              className={classNames(
                "SpecificationsAndDownloadsSection__specificationsSection kog-row",
                {
                  "SpecificationsAndDownloadsSection__specificationsSection--desktopFullWidth":
                    !renderDownloadsSection,
                },
              )}
            >
              <TechnicalSpecifications
                model={model.technicalSpecificationsViewModel}
                layout={sectionsLayout}
                inSeparateSection={false}
              />
            </section>
          )}
          {renderDownloadsSection && (
            <section
              id={downloadSectionId}
              className={classNames("SpecificationsAndDownloadsSection__downloadsSection kog-row", {
                "SpecificationsAndDownloadsSection__downloadsSection--desktopFullWidth":
                  !renderSpecificationsSection,
              })}
            >
              <DownloadsSection
                model={model.discoveryDownloadsSectionViewModel}
                layout={sectionsLayout}
                inSeparateSection={false}
              />
            </section>
          )}
        </div>
        <ButtonOutlined
          className={classNames("TableContent__expandBtn", {
            "TableContent__expandBtn--expanded": isExpanded,
            "TableContent__expandBtn--hidden": !isExpandable,
          })}
          iconPosition="right"
          icon={Chevron}
          onClick={handleShowMoreClick}
          aria-expanded={isExpanded}
          aria-controls={mainSectionId}
        >
          {isExpanded ? "Show less" : "Show more"}
        </ButtonOutlined>
      </div>
    </div>
  );
};
