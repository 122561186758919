import classNames from "classnames";
import React, { CSSProperties, PropsWithChildren, ReactNode } from "react";
import ReactModal from "react-modal";
import { Close as CloseIcon } from "../Icons/Close";
import { ResponsivePicture } from "../responsivePicture/responsivePicture";
import { ResponsivePictureProps } from "../../Images/ResponsivePictureProps.csharp";
import { useScrollLock } from "../useScrollLock";
import { ModalProvider, useModalContext } from "./ModalContext";

interface ModalProps {
  isOpen?: boolean;
  className?: string;
  dialogClassName?: string;
  children?: ReactNode;
  onClose?: () => void;
  transitionMs?: number;
  borderRadius?: number;
}

interface ModalCSSProperties extends CSSProperties {
  "--border-radius"?: number;
}

export const Modal = ({
  isOpen = false,
  className,
  dialogClassName,
  children,
  onClose,
  transitionMs = 300,
  borderRadius,
}: ModalProps) => {
  const { enableLock, disableLock } = useScrollLock(false);

  if (isOpen) {
    enableLock();
  }

  const handleClose = () => {
    disableLock();

    if (onClose) onClose();
  };

  return (
    <ModalProvider onClose={handleClose}>
      <ReactModal
        isOpen={isOpen}
        overlayClassName={classNames("Modal", className)}
        className={classNames("Modal__dialog", dialogClassName)}
        bodyOpenClassName={null}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        closeTimeoutMS={transitionMs}
        onRequestClose={handleClose}
        style={{
          overlay: {
            "--border-radius": borderRadius,
          } as ModalCSSProperties,
        }}
      >
        {children}
      </ReactModal>
    </ModalProvider>
  );
};

interface ModalCommonProps {
  className?: string;
}

const Body = ({ className, children }: PropsWithChildren<ModalCommonProps>) => (
  <div className={classNames("Modal__body", className)}>{children}</div>
);

interface HeaderProps extends PropsWithChildren<ModalCommonProps> {
  picture?: ResponsivePictureProps;
}

const Header = ({ className, picture, children }: HeaderProps) => (
  <div className={classNames("Modal__header", className)}>
    {picture && (
      <div className={classNames("Modal__headerPicture", className)}>
        <ResponsivePicture {...picture} />
      </div>
    )}

    <div className="Modal__headerInner">{children}</div>
  </div>
);

const Lead = ({ className, children }: PropsWithChildren<ModalCommonProps>) => (
  <div className={classNames("Modal__lead", className)}>{children}</div>
);

const Title = ({ className, children }: PropsWithChildren<ModalCommonProps>) => (
  <h2 className={classNames("Modal__title", className)}>{children}</h2>
);

interface CloseProps extends PropsWithChildren<ModalCommonProps> {
  label?: string;
}

const Close = ({ className, children, label = "Close" }: CloseProps) => {
  const { onClose } = useModalContext();

  return (
    <button className={classNames("Modal__close", className)} onClick={onClose} aria-label={label}>
      {children ? (
        children
      ) : (
        <>
          <span className="Modal__closeLabel">{label}</span>
          <CloseIcon />
        </>
      )}
    </button>
  );
};

const Content = ({ className, children }: PropsWithChildren<ModalCommonProps>) => (
  <div className={classNames("Modal__content", className)}>{children}</div>
);

Modal.Body = Body;
Modal.Header = Header;
Modal.Lead = Lead;
Modal.Title = Title;
Modal.Close = Close;
Modal.Content = Content;
