import * as React from "react";
import {ComponentProps} from "../ComponentProps.csharp";
import {ReportViewModel} from "./ReportViewModel.csharp";

export class DownloadsSection extends React.Component<ComponentProps<ReportViewModel>> {
    constructor(props: Readonly<ComponentProps<ReportViewModel>>) {
        super(props);
    }

    render() {
        let {attachments} = this.props.model;
        
        return (
            <div className="Downloads__container">
                <div className="Downloads__items">
                    <h2 className="Section__subtitle">Downloads</h2>

                    <ul>
                        {attachments && attachments.map((attachment, index) => (
                            <li key={`attachment-${index}`}>
                                <div className="Downloads__item">
                                    <a key={`attachment-${index}`}
                                       className="Downloads__itemLink"
                                       target={attachment.target}
                                       href={attachment.href}
                                       title={attachment.title}>
                                            <span
                                                className="Downloads__itemName Downloads__itemName--text">
                                                {attachment.text}
                                            </span>
                                    </a>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}