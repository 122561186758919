import {DropDownList, DropDownListItem} from "../../Partials/DropDownList/DropDownList";
import * as React from "react";
import {ReactNode} from "react";
import { isEqual } from "lodash";
import {ContentCard} from "../../Partials/ContentCard/ContentCard";
import {Loader} from "../../Partials/Search/Loader";
import {ContentCardViewModel} from "../../Partials/ContentCard/ContentCardViewModel.csharp";
import { PagingModel } from "../../Common/PagingModel.csharp";
import {ArticlesArchiveProps} from "../../Pages/NewsArchivePage/ArticlesArchiveProps.csharp";
import {ContentCardVariant} from "../../Partials/ContentCard/ContentCardVariant.csharp";
import classNames from "classnames";
import {ContentCardOptions} from "../../Partials/ContentCard/ContentCardOptions.csharp";
import { CategoryViewModel } from "../../Pages/NewsArchivePage/CategoryViewModel.csharp";

export interface ArticlesArchiveState {
    selectedYear: string,
    selectedMonth: string,
    selectedCategories: string[],
    articles: ContentCardViewModel[]
    nextPageNumber: number,
    isLoading: boolean,
    hasMore: boolean,
    pageSize: number,
    focusedItemIndex: number | undefined
}

export class ArticlesArchive extends React.Component<ArticlesArchiveProps, ArticlesArchiveState> {

    constructor(props: ArticlesArchiveProps) {
        super(props);

        this.state = {
            selectedYear: "",
            selectedMonth: "",
            selectedCategories: [],
            isLoading: false,
            articles: props.model.firstPage.items,
            hasMore: props.model.firstPage.hasNext,
            pageSize: props.model.firstPage.pageSize,
            nextPageNumber: props.model.firstPage.pageNumber + 1,
            focusedItemIndex: undefined
        };

        this.onYearChanged = this.onYearChanged.bind(this);
        this.onMonthChanged = this.onMonthChanged.bind(this);
        this.onCategoryChanged = this.onCategoryChanged.bind(this);
        this.onLoadMoreClick = this.onLoadMoreClick.bind(this);
    }

    isContentCardOptionSelected(options: ContentCardOptions, option: ContentCardOptions): boolean {
        return (options & option) > 0;
    }

    renderFilters = () => {
        let {translations} = this.props.model;
        
        return (<div className="Filters">
            <ul className="ArticlesArchiveFiltersList">
                <li className="ArticlesArchiveFiltersList__item">
                    <DropDownList placeholder="Any"
                                  label={translations.year}
                                  options={this.props.model.availableYears.map((x: any) => ArticlesArchive.convertToDropdownListItem(x))}
                                  selectedValue={this.state.selectedYear}
                                  onValueChanged={this.onYearChanged}
                                  className="DropDownList--dark"
                    />
                </li>
                <li className="ArticlesArchiveFiltersList__item">
                    <div className="DropDown">
                        <DropDownList placeholder="Any"
                                      label={translations.month}
                                      options={this.getAllMonths()}
                                      selectedValue={this.state.selectedMonth}
                                      onValueChanged={this.onMonthChanged}
                                      className="DropDownList--dark"
                                      disabled={(this.state.selectedYear === "")}
                        />
                    </div>
                </li>

                {this.props.showCategories &&
                    <li className="ArticlesArchiveFiltersList__item">
                        <div className="DropDown">
                            <DropDownList placeholder="Any"
                                        label={translations.category}
                                        options={this.getAllCategories()}
                                        selectedValue={this.state.selectedCategories.join(',')}
                                        onValueChanged={this.onCategoryChanged}
                                        className="DropDownList--dark"
                            />
                        </div>
                    </li>
                }
            </ul>

            {this.state.selectedCategories.length > 0 && (
                <ul className="ArticlesArchiveFiltersActive" aria-label={translations.activeFiltersText}>
                    {this.state.selectedCategories.map((category) => {
                        const id = parseInt(category);
                        if (!id) return null;
                        const title = this.props.model.categories.find((category: CategoryViewModel) => category.id === id)?.name;
                        if (!title) return null;

                        return (
                            <li key={id} className="ArticlesArchiveFiltersActive__chip">
                                <button 
                                    className="Btn--secondary ArticlesArchiveFiltersActive__chipClearBtn" 
                                    title={translations.clearFilterText} 
                                    aria-label={translations.clearFilterText}
                                    onClick={() => this.onClearCategoryFilterBtnClick(id)}
                                >
                                    {title.toLocaleLowerCase()}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>);
    }

    render(): ReactNode {
        let {translations} = this.props.model;
        let {contentCardOptions} = this.props;

        let articlesList = this.state.articles.length !== 0
            ? this.state.articles.map((cardProps: ContentCardViewModel, index: number) => {
                const pictureProps = {
                    profile: this.props.model.pictureProfile,
                    model: cardProps.picture
                };

                return (
                    <li key={index}>
                        <ContentCard
                            model={cardProps}
                            picture={pictureProps}
                            isDateVisible={this.isContentCardOptionSelected(contentCardOptions, ContentCardOptions.WithPublicationDate)}
                            isMoreButtonVisible={this.isContentCardOptionSelected(contentCardOptions, ContentCardOptions.WithMoreButton)}
                            isExcerptVisible={this.isContentCardOptionSelected(contentCardOptions, ContentCardOptions.WithExcerpt)}
                            isTitleCapitalized={this.isContentCardOptionSelected(contentCardOptions, ContentCardOptions.WithCapitalizedTitle)}
                            isPictureVisible={this.isContentCardOptionSelected(contentCardOptions, ContentCardOptions.WithHiddenImage) === false}
                            isLinkVisible={this.isContentCardOptionSelected(contentCardOptions, ContentCardOptions.WithVisibleLink)}
                            readMoreText={translations.readMoreText}
                            variant={this.props.variant}
                            isFocused={index === this.state.focusedItemIndex}
                            isCategoryVisible={this.props.showCategories}
                            onCategoryClick={(categoryId) => this.onCategoryClickInSingleArticle(categoryId)}
                            selectableCategoryIds={this.getSelectableCategoryIds()}
                        />
                    </li>
                );
            })
            : <p className="ArticlesArchive__resultsPlaceholder">{translations.noItemsFoundText}</p>;
        return (<>
            {this.props.showFilters && this.renderFilters()}
            <div className="ArticlesArchive__content">
                <ul className={classNames({
                    "ArticlesArchive__list": true,
                    "cardsList__3col": this.props.variant === ContentCardVariant.Standard,
                    "ArticlesArchive__list--1col": this.props.variant !== ContentCardVariant.Standard
                })} aria-live="polite" aria-relevant="additions">
                    {articlesList}
                </ul>
                {this.state.isLoading && <Loader/>}
                {this.state.hasMore && <div className="ArticlesArchive__loadMore">
                    <button onClick={this.onLoadMoreClick}
                            disabled={this.state.isLoading}>
                        {translations.loadMoreText}
                    </button>
                </div>}
            </div>
        </>);
    }

    private onCategoryClickInSingleArticle(categoryId: number) {
        if (
            this.state.selectedCategories.find((cat) => cat === categoryId.toString()) ||
            !this.props.model.categories.find((cat) => cat.selectable && cat.id === Number(categoryId))
        ) {
            return;
        }
        this.onCategoryChanged(categoryId.toString());
    }

    private static convertToDropdownListItem(x: any): DropDownListItem {
        return {
            text: x.toString(),
            value: x.toString()
        }
    }

    private onYearChanged(newYear: string) {
        const newMonth = newYear === "" ? "" : this.state.selectedMonth;
        this.loadResults(newYear, newMonth, this.state.selectedCategories, 1, true);
        this.setState({
            focusedItemIndex: undefined
        });
    }

    private onMonthChanged(newMonth: string) {
        this.loadResults(this.state.selectedYear, newMonth, this.state.selectedCategories, 1, true);
        this.setState({
            focusedItemIndex: undefined
        });
    }

    private onCategoryChanged(newCategory: string) {
        this.loadResults(this.state.selectedYear, this.state.selectedMonth, [...this.state.selectedCategories, newCategory], 1, true);
        this.setState({
            focusedItemIndex: undefined
        });
    }

    private onClearCategoryFilterBtnClick(categoryId: number) {
        this.loadResults(this.state.selectedYear, this.state.selectedMonth, this.state.selectedCategories.filter((category) => category !== categoryId.toString()), 1, true);
        this.setState({
            focusedItemIndex: undefined
        });
    }

    private onLoadMoreClick() {
        this.loadResults(this.state.selectedYear, this.state.selectedMonth, this.state.selectedCategories, this.state.nextPageNumber, false);
    }

    private loadResults(year: string, month: string, categories: string[], pageNumber: number, resetFocus: boolean): void {
        this.setState({isLoading: true});
        this.getNewResults(year, month, categories, pageNumber)
            .then(response => {
                if (response.ok) {
                    response.json().then((result: PagingModel<ContentCardViewModel>) => {
                        let newArticles = (this.shouldClearResults(year, month, categories) ? [] : this.state.articles).concat(result.items);
                        this.setState((prevState) => ({
                            articles: newArticles,
                            isLoading: false,
                            nextPageNumber: result.pageNumber + 1,
                            hasMore: result.hasNext,
                            selectedYear: year,
                            selectedMonth: month,
                            selectedCategories: categories,
                            focusedItemIndex: resetFocus ? undefined : prevState.articles.length
                        }));
                    })
                } else {
                    this.setState({isLoading: false});
                }
            })
    }

    private getAllMonths(): DropDownListItem[] {
        return this.props.model.monthsNames.map((monthName, index) => ({
            value: (index + 1).toString(),
            text: monthName
        }));
    }

    private getSelectableCategoryIds(): number[] {
        return this.props.model.categories
            .filter(category => category.selectable).map((cat) => cat.id);
    }

    private getAllCategories() {
        return this.props.model.categories
            .filter(category => category.selectable)
            .map((category: CategoryViewModel) => ({
                value: (category.id).toString(),
                text: category.name,
            }))
            .filter((category: DropDownListItem) => 
                !this.state.selectedCategories.includes(category.value));
    }

    private getNewResults(year: string, month: string, categories: string[], pageNumber: number) {
        const url = this.buildQueryUrl(year, month, categories, pageNumber);
        return fetch(url, {
            method: "GET",
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
    }

    private buildQueryUrl(year: string, month: string, categories: string[], pageNumber: number): string {
        let url = `${this.props.model.newsArchiveUrl}?year=${year}&month=${month}`;
        let categoriesParams = '';
        categories.forEach(category => {
            categoriesParams += '&categories=' + category
        });
        if (categoriesParams.length > 0) url += categoriesParams;
        url = url + `&newsArchive=${this.props.model.newsArchiveId}&pageNumber=${pageNumber}&pageSize=${this.state.pageSize}`;
        return url;
    }

    private shouldClearResults(year: string, month: string, categories: string[]): boolean {
        return year !== this.state.selectedYear || month !== this.state.selectedMonth || !isEqual(categories, this.state.selectedCategories);
    }
}