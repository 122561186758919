import classNames from "classnames";
import React, { FC, ReactNode } from "react";

interface TileGridProps {
  mainContent: ReactNode;
  topBar?: ReactNode;
  sidePanel?: ReactNode;
}

export const TileGrid: FC<TileGridProps> = ({ mainContent, topBar, sidePanel }) => {
  return (
    <div className="TileGrid">
      {topBar && <div className="TileGrid__TopBar">{topBar}</div>}
      {sidePanel && <div className="TileGrid__SidePanel">{sidePanel}</div>}
      <div
        className={classNames("TileGrid__MainContent", {
          ["TileGrid__MainContent--fullWidth"]: !sidePanel,
        })}
      >
        {mainContent}
      </div>
    </div>
  );
};
