import React, { CSSProperties, HTMLProps, forwardRef } from "react";
import { useTooltipContext } from "./useTooltip";
import { useMergeRefs, useTransitionStyles } from "@floating-ui/react";
import { Portal } from "../../Portal";
import { TooltipArrow } from "./TooltipArrow";
import classNames from "classnames";

interface TooltipContentProps extends HTMLProps<HTMLDivElement> {
  maxWidth?: number;
  zIndex?: number;
}

interface TooltipContentCSSProperties extends CSSProperties {
  "--max-width": string;
}

export const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
  function TooltipContent({ className, style, children, maxWidth, zIndex, ...props }, propRef) {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);
    const { isMounted, styles } = useTransitionStyles(context.context);

    return (
      <Portal>
        <>
          {isMounted && (
            <div
              ref={ref}
              className={classNames("Tooltip", className)}
              style={{
                ...context.floatingStyles,
                ...styles,
                ...style,
                ...(zIndex !== undefined ? { zIndex } : {}),
                ...(maxWidth
                  ? ({
                      "--max-width": `${maxWidth}px`,
                    } as TooltipContentCSSProperties)
                  : {}),
              }}
              {...context.getFloatingProps(props)}
            >
              <div className="Tooltip__content">{children}</div>
              <div className="Tooltip__arrow">
                <TooltipArrow ref={context.arrowRef} context={context.context} />
              </div>
            </div>
          )}
        </>
      </Portal>
    );
  },
);
