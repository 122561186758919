import React, { FC, useRef } from "react";
import classNames from "classnames";
import { Splide, SplideTrack } from "@splidejs/react-splide";
import { ContentListBlockSlider } from "./ContentListBlockSlider";
import { SplideArrows } from "../../../react-components/Splide/SplideArrows";
import { ArrowsPosition } from "./ArrowsPosition.csharp";

interface ContentListSliderProps {
  className?: string;
  title?: string;
  description?: string;
  arrowsPosition?: ArrowsPosition;
}

export const ContentListSlider: FC<ContentListSliderProps> = ({
  className,
  title,
  description,
  arrowsPosition = ArrowsPosition.Center,
  children,
}) => {
  const contentListBlockSliderRef = useRef(new ContentListBlockSlider(null, 2, arrowsPosition));

  return (
    <div className={classNames("ContentListSlider", className)}>
      <div className="ContentListSlider__inner">
        {(title || description) && (
          <div className="ContentListSlider__titleWrapper wrapper">
            {title && <h2 className="ContentListSlider__title">{title}</h2>}
            {description && <p className="ContentListSlider__description">{description}</p>}
          </div>
        )}
        <Splide
          className="splide ContentListSlider__slider"
          options={contentListBlockSliderRef.current.getOptions()}
          extensions={contentListBlockSliderRef.current.getExtensions()}
          hasTrack={false}
        >
          {arrowsPosition === ArrowsPosition.Center && (
            <SplideArrows
              className="ContentListSlider__arrows ContentListSlider__arrows--center"
              arrowClassName="ContentListSlider__arrow"
            />
          )}
          <div className="splide__slider ContentListSlider__sliderInner">
            <SplideTrack className="splide__track ContentListSlider__sliderTrack">
              {children}
            </SplideTrack>
            {arrowsPosition === ArrowsPosition.Bottom && (
              <SplideArrows
                className="ContentListSlider__arrows ContentListSlider__arrows--bottom"
                arrowClassName="ContentListSlider__arrow"
                renderPagination
              />
            )}
          </div>
        </Splide>
      </div>
    </div>
  );
};
