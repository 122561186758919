import React from "react";
import classNames from "classnames";
import ClickAwayListener from "react-click-away-listener";
import { DescriptionLink } from "./DescriptionLink";
import { LinkViewModel } from "../../../Links/LinkViewModel.csharp";

interface DescriptionBoxProps {
  text?: string;
  linkViewModel?: LinkViewModel;
  onClose: () => void;
  className: string;
}

export const DescriptionBox: React.FC<DescriptionBoxProps> = ({
  text,
  onClose,
  className,
  linkViewModel,
}) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div
        className={classNames("SideTextHotSpotsImage__Description", className)}
      >
        <button
          className="SideTextHotSpotsImage__DescriptionCloseButton"
          onClick={onClose}
        >
          Close
        </button>
        <div className="SideTextHotpotsImage__DescriptionInner">
          {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
          {linkViewModel?.href && <DescriptionLink {...linkViewModel} />}
        </div>
      </div>
    </ClickAwayListener>
  );
};
