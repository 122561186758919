import React, { FC } from "react";
import classNames from "classnames";

import { MicrositeHotSpot } from "../../HotSpot/MicrositeHotSpot";
import { ResponsivePicture } from "../../../react-components/responsivePicture/responsivePicture";

import { MicrositeHotSpotViewModel } from "./MicrositeHotSpotViewModel.csharp";
import { useMicrositeContext } from "../../Utils/micrositeContext";

interface MicrositeLandingHotSpotProps extends MicrositeHotSpotViewModel {
  goToSection: (id: number) => void;
  pulseDelay: number;
}

export const MicrositeLandingHotSpot: FC<MicrositeLandingHotSpotProps> = ({
  title,
  image,
  imagePictureProfile,
  linkText,
  sectionId,
  goToSection,
  pulseDelay
}) => {
  const { theme } = useMicrositeContext();
  return (
    <MicrositeHotSpot
      title={title}
      onPreviewClick={() => goToSection(sectionId)}
      popUpClassName={classNames(
        "MicrositeHotSpot__spotOuter",
        `MicrositeHotSpot__spotOuter--${theme}`
      )}
      popUpContentClassName="MicrositeHotSpot__spot"
      pulseDelay={`-${pulseDelay}s`}
      variant="clear"
      size="xSmall"
    >
      <span className="MicrositeHotSpot__content">
        <span className="MicrositeHotSpot__heading">{title}</span>
        <span className="MicrositeHotSpot__link">
          {linkText}
          <span className="MicrositeHotSpot__linkArrow" />
        </span>
      </span>
      <div className="MicrositeHotSpot__image">
        <ResponsivePicture model={{ ...image }} profile={imagePictureProfile} />
      </div>
    </MicrositeHotSpot>
  );
};
