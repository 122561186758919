import * as React from "react";
import * as Autosuggest from "react-autosuggest";
import {SuggestionsFetchRequestedParams} from "react-autosuggest";
import {SuggestionSelectedEventData} from "react-autosuggest";
import {debounce} from "throttle-debounce";
import {Dictionary} from "./SearchResults";
import {ComponentProps} from "../ComponentProps.csharp";
import {SearchInputViewModel} from "./SearchInputViewModel.csharp";
import {SearchSuggestionsDto} from "../../Pages/SearchSuggestionsDto.csharp";

export interface SearchInputProps extends ComponentProps<SearchInputViewModel> {
    autoFocus: boolean;
    onFocusLeave?: (event: React.KeyboardEvent) => void,
    onEscape?: (event: React.KeyboardEvent) => void
}

export interface SearchInputState {
    value: string;
    suggestions: string[];
}

export class SearchInput extends React.Component<SearchInputProps, SearchInputState> {
    private lastRequestedSearchTerm: string;
    private readonly suggestionsCache: Dictionary<string[]>;
    private input?: HTMLInputElement;

    constructor(props: SearchInputProps) {
        super(props);

        this.onSuggestionsFetchRequested = debounce(200, this.onSuggestionsFetchRequested.bind(this));
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            value: this.props.model.value || "",
            suggestions: []
        };

        this.lastRequestedSearchTerm = "";
        this.suggestionsCache = {};
    }

    componentDidMount(): void {
        if (this.props.autoFocus && this.input) {
            this.input.focus();
        }
    }

    private onChange = (event: any, {newValue, method}: any) => {
        this.setState({
            value: newValue
        })
    };

    private static renderSuggestion(suggestion: string) {
        return suggestion;
    }

    private onSuggestionsFetchRequested(params: SuggestionsFetchRequestedParams) {

        this.lastRequestedSearchTerm = params.value;

        let cachedSuggestions = this.suggestionsCache[params.value];
        if (cachedSuggestions) {
            this.setState({
                suggestions: cachedSuggestions
            });
            return;
        }
        
        let endpoint = this.props.model.searchSuggestionsEndpointUrl + "?searchTerm=" + encodeURIComponent(params.value);
        if (this.props.model.searchPageGuid) {
            endpoint +=  "&searchPageGuid=" + encodeURIComponent(this.props.model.searchPageGuid)
        }

        fetch(endpoint, {
            method: "GET",
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200 && params.value === this.lastRequestedSearchTerm) {
                    response.json().then((result: SearchSuggestionsDto) => {
                        this.suggestionsCache[params.value] = result.suggestions;
                        this.setState({
                            suggestions: result.suggestions
                        });
                    })
                }
            })
    }

    private onSuggestionsClearRequested() {
        this.setState({
            suggestions: []
        })
    }

    private onSuggestionSelected(event: React.FormEvent<any>, data: SuggestionSelectedEventData<string>) {
        this.executeSearch(data.suggestion);
    }

    private readonly onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === 13 && this.state.value) {
            event.preventDefault();
            this.executeSearch(this.state.value);
        }
    };

    private executeSearch(searchTerm: string) {
        window.location.href = this.props.model.searchPageUrl + "?searchTerm=" + encodeURIComponent(searchTerm);
    }

    private onSubmit(e: any) {
        e.preventDefault();
        if (this.state.value) {
            this.executeSearch(this.state.value);
        }
    }

    private storeInputReference = (autosuggest: any) => {
        if (autosuggest !== null) {
            this.input = autosuggest.input;
        }
    };

    render() {
        const inputProps = {
            placeholder: this.props.model.translations.placeholder,
            value: this.state.value,
            onChange: this.onChange,
            onKeyDown: this.onKeyDown,
            'aria-label': this.props.model.translations.search
        };
        return <div className="SearchInput" onKeyDown={this.props.onEscape}>
            <form className="SearchInput__form">
                <div className="SearchInput__inner">
                    <Autosuggest
                        suggestions={this.state.suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        onSuggestionSelected={this.onSuggestionSelected}
                        getSuggestionValue={s => s}
                        renderSuggestion={SearchInput.renderSuggestion}
                        inputProps={inputProps}
                        ref={this.storeInputReference}
                    />
                    <button className="SearchInput__button"
                            onClick={this.onSubmit}
                            onKeyDown={this.props.onFocusLeave}>
                        <span className="visuallyHidden">{this.props.model.translations.search}</span>
                    </button>
                </div>
            </form>
        </div>
    }
}



