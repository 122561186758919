import React, { useEffect, useState } from "react";
import { Chevron } from "../Icons/Chevron";
import classNames from "classnames";
import { throttle } from "lodash";

const THRESHOLD_FOR_SCROLL_TO_TOP_VISIBILITY = 2000;
const THROTTLE_CHECK_SCROLL_POS_MS = 300;

export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const scrollHandle = (): void => {
    let scrollTop;
    if (document.body && document.documentElement) {
      const scrollTopBody = document.body.scrollTop;
      const scrollTopDocumentElement = document.documentElement.scrollTop;
      scrollTop = Math.max(scrollTopBody, scrollTopDocumentElement);
    } else {
      scrollTop = (document.body || document.documentElement).scrollTop;
    }
    setIsVisible(scrollTop >= THRESHOLD_FOR_SCROLL_TO_TOP_VISIBILITY);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    window.addEventListener("scroll", throttle(scrollHandle, THROTTLE_CHECK_SCROLL_POS_MS));
    return (): void =>
      window.removeEventListener("scroll", throttle(scrollHandle, THROTTLE_CHECK_SCROLL_POS_MS));
  });

  return (
    <button
      className={classNames("ScrollToTopButton", { "ScrollToTopButton--visible": isVisible })}
      aria-label="Scroll to top of the page"
      onClick={scrollToTop}
      tabIndex={isVisible ? 0 : -1}
    >
      <Chevron />
    </button>
  );
};
