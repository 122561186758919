import * as React from "react";
import * as moment from "moment";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker from "react-day-picker";
import {RefObject} from "react";
import MomentLocaleUtils from 'react-day-picker/moment';
import {formatDate, mapCultureFromEpiServer} from "../../react-components/dateUtil";

export interface WeekPickerProps {
    selectedValue: Date;
    onValueChanged: (value: Date) => void,
    culture: string
}

export interface WeekPickerState {
    selectedDays: Date[];
    hoverRange: { from: Date, to: Date } | undefined;
    selectedMonth: Date;
    showOverlay: boolean;
}

export class WeekPicker extends React.Component<WeekPickerProps, WeekPickerState> {
    private readonly inputRef: RefObject<HTMLInputElement>;
    private readonly dayPickerRef: RefObject<DayPickerInput>;

    constructor(props: WeekPickerProps) {
        super(props);
        this.inputRef = React.createRef();
        this.dayPickerRef = React.createRef();
        this.renderOverlay = this.renderOverlay.bind(this);
        this.showDatePicker = this.showDatePicker.bind(this);
        this.state = {
            hoverRange: this.getWeekRange(props.selectedValue),
            selectedDays: this.getWeekDays(props.selectedValue),
            selectedMonth: props.selectedValue,
            showOverlay: false
        };
    }

    render() {
        return (
            <div>
                <DayPickerInput ref={this.dayPickerRef}
                                component={this.renderInput}
                                overlayComponent={this.renderOverlay}
                                value={this.getSelectedDays()}
                                format="DD.MM.YYYY"
                                hideOnDayClick={true}
                />
            </div>
        );
    }

    private renderOverlay({ ...props }) {
        const properties = { onFocus: props.onFocus };
        const { hoverRange, selectedDays, selectedMonth } = this.state;
        const daysAreSelected = selectedDays.length > 0;
        const modifiers: any = {
            hoverRange,
            selectedRange: daysAreSelected && { from: selectedDays[0], to: selectedDays[6] },
            hoverRangeStart: hoverRange && hoverRange.from,
            hoverRangeEnd: hoverRange && hoverRange.to,
            selectedRangeStart: daysAreSelected && selectedDays[0],
            selectedRangeEnd: daysAreSelected && selectedDays[6]
        };

        return (
            <div className="DayPickerInput-OverlayWrapper" onClick={this.resetFocus}>
                <div className="SelectedWeekExample DayPickerInput-Overlay" { ...properties }>
                    <DayPicker
                        selectedDays={selectedDays}
                        showOutsideDays
                        modifiers={modifiers}
                        onDayClick={this.handleDayChange}
                        onDayMouseEnter={this.handleDayEnter}
                        onDayMouseLeave={this.handleDayLeave}
                        onWeekClick={this.handleWeekClick}
                        onMonthChange={this.handleMonthChange}
                        firstDayOfWeek={1}
                        month={selectedMonth}
                        locale={mapCultureFromEpiServer(this.props.culture)}
                        localeUtils={MomentLocaleUtils}
                    />
                </div>
            </div>
        );
    }

    private renderInput = React.forwardRef(({...props}, ref) => {
        return (
            <div className="DatePicker-Component">
                <input className="DatePicker-Input" ref={this.inputRef} {...props} readOnly />
                <i className="DatePicker-Icon" onClick={this.showDatePicker}/>
            </div>
        );
    });

    private handleDayChange = (date: Date) => {
        const from = this.getWeekRange(date).from;
        this.setState({ selectedDays: this.getWeekDays(from), showOverlay: false });
        this.props.onValueChanged(from);

        if (this.dayPickerRef.current) {
            this.dayPickerRef.current.hideDayPicker();
        }
    };
    
    private showDatePicker() {
        if (this.inputRef.current) {
            this.inputRef.current.click();
            this.inputRef.current.focus();
        }
    };

    private handleDayEnter = (date: Date) => this.setState({ hoverRange: this.getWeekRange(date) });

    private handleDayLeave = () => this.setState({ hoverRange: undefined });

    private handleWeekClick = (weekNumber: number, days: any) => this.setState({ selectedDays: days });

    private handleMonthChange = (date: Date) => this.setState({ selectedMonth: date });

    private getSelectedDays = () => `${formatDate(this.state.selectedDays[0], this.props.culture)} - ${formatDate(this.state.selectedDays[6], this.props.culture)}`;

    private getWeekRange = (date: Date) => ({
        from: moment(date).startOf('week').toDate(),
        to: moment(date).endOf('week').toDate(),
    });

    private getWeekDays = (weekStart: any) => {
        const days = [weekStart];
        for (let i = 1; i < 7; i += 1) {
            days.push(moment(weekStart).add(i, 'days').toDate());
        }

        return days;
    };

    private resetFocus = () => {
        if (this.inputRef.current) {
            this.inputRef.current.focus()
        }
    };
}