import React from "react";
import { PictureProfileDto } from "../../../Images/Resizing/PictureProfileDto.csharp";
import { ImageViewerItemViewModel } from "../ImageViewerItemViewModel.csharp";
import classNames from "classnames";
import { ResponsivePicture } from "../../../react-components/responsivePicture/responsivePicture";
import { ImageViewerFullScreenCaption } from "./ImageViewerFullScreenCaption";

interface ImageViewerFullScreenThumbnailProps {
  image: ImageViewerItemViewModel;
  profile: PictureProfileDto;
  mainImage?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
}

export const ImageViewerFullScreenThumbnail = ({
  image,
  profile,
  mainImage = false,
  fullWidth = false,
  onClick,
}: ImageViewerFullScreenThumbnailProps) => {
  const Tag = fullWidth ? "figure" : "button";

  return (
    <Tag
      className={classNames("ImageViewerFullScreen__thumbnail", {
        ["ImageViewerFullScreen__thumbnail--mainImage"]: mainImage,
        ["ImageViewerFullScreen__thumbnail--fullWidth"]: fullWidth,
      })}
      onClick={onClick}
    >
      <ResponsivePicture profile={profile} model={image.picture} />
      <ImageViewerFullScreenCaption captionHtml={fullWidth ? image.captionHtml : undefined} />
    </Tag>
  );
};
