import React from "react";
import { OfficeLocationViewModel } from "../OfficeLocationViewModel.csharp";
import { Clusterer } from "@react-google-maps/marker-clusterer";
import { MarkerF } from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { OfficeLocationsMapInfoWindow } from "./OfficeLocationsMapInfoWindow";

interface OfficeLocationsMapMarkerProps {
  location: OfficeLocationViewModel;
  isSelected?: boolean;
  markerUrl: string;
  selectedMarkerUrl: string;
  onSelect?:
    | ((selectedOffice: OfficeLocationViewModel, e?: google.maps.MapMouseEvent) => void)
    | undefined;
  onDeselect?: () => void;
  clusterer?: Clusterer | MarkerClusterer | undefined;
}

export const OfficeLocationsMapMarker = ({
  location: { lat, lng, ...location },
  isSelected = false,
  markerUrl,
  selectedMarkerUrl,
  onSelect,
  onDeselect,
  clusterer,
}: OfficeLocationsMapMarkerProps) => {
  return (
    <MarkerF
      position={{ lat, lng }}
      icon={{
        url: isSelected ? selectedMarkerUrl : markerUrl,
      }}
      onClick={(event) => (onSelect ? onSelect({ lat, lng, ...location }, event) : undefined)}
      zIndex={isSelected ? 1000 : 0}
      clusterer={clusterer}
    >
      <OfficeLocationsMapInfoWindow
        isOpen={isSelected}
        location={{ lat, lng, ...location }}
        onDeselect={onDeselect}
      />
    </MarkerF>
  );
};
