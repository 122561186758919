import { FloatingArrow, FloatingArrowProps } from "@floating-ui/react";
import React, { forwardRef, memo } from "react";

export const TooltipArrow = memo(forwardRef<SVGSVGElement, FloatingArrowProps>(((props, ref) => (
  <FloatingArrow
    ref={ref}
    width={12}
    height={6}
    tipRadius={2}
    fill="currentColor"
    {...props}
  />
))));
