import React from "react";
import classNames from "classnames";
import { Label } from "../../react-components/Atoms/Label/Label";
import { ResponsivePictureProps } from "../../Images/ResponsivePictureProps.csharp";
import { useAutoFocus } from "../../react-components/hooks/useAutoFocus";
import { PictureThumbnail } from "../../react-components/PictureThumbnail/PictureThumbnail";
import { ResourceTileProps } from "./ResourceTile.types";
import { ResourceTileSkeleton } from "./ResourceTileSkeleton";
import { LabelColor } from "../../Common/Labels/LabelColor.csharp";

export const ResourceTile = ({
  resource,
  pictureProfile,
  size = "medium",
  shouldFocusOnMount = false,
}: ResourceTileProps) => {
  const pictureProps = {
    profile: pictureProfile,
    model: resource.picture,
  } as ResponsivePictureProps;

  const ref = useAutoFocus<HTMLAnchorElement>(shouldFocusOnMount);

  return (
    <article
      className={classNames("ResourceTile", `ResourceTile--${size}`)}
      itemScope
      itemType="https://schema.org/Article"
    >
      {resource.label && (
        <div className="ResourceTile__label">
          <Label>{resource.label.label}</Label>
        </div>
      )}

      <a ref={ref} href={resource.url} className="ResourceTile__link" itemProp="url">
        <PictureThumbnail
          className="ResourceTile__thumbnail"
          picture={pictureProps}
          thumbnail={resource.pictureThumbnail}
          itemScope
          itemType="https://schema.org/ImageObject"
          itemProp="thumbnail"
          imageProps={{ itemProp: "contentUrl" }}
        />

        <h3 className="ResourceTile__title" itemProp="headline">
          {resource.title}
        </h3>
      </a>

      {resource.excerpt && (
        <p className="ResourceTile__excerpt" itemProp="description">
          {resource.excerpt}
        </p>
      )}

      {resource.tags?.length > 0 && (
        <div className="ResourceTile__tags" itemProp="keywords">
          {resource.tags.map((tag) => (
            <Label
              key={tag}
              size="small"
              appearance="light"
              color={LabelColor.Neutral}
              itemScope
              itemType="https://schema.org/DefinedTerm"
            >
              {tag}
            </Label>
          ))}
        </div>
      )}
    </article>
  );
};

ResourceTile.Skeleton = ResourceTileSkeleton;
