import classNames from "classnames";
import React, { CSSProperties } from "react";
import { useCheckbox } from "../hooks/useCheckbox";
import { Checkmark } from "../../Discovery/Icons/Checkmark";

export interface CheckboxStyleProps {
  size?: "normal" | "small" | "large";
  gap?: string;
}

export interface CheckboxProps extends CheckboxStyleProps {
  name: string;
  value: string;
  label: string;
  checked?: boolean;
  onChange?: (value: string, checked: boolean) => void;
  className?: string;
}

export interface CheckboxProperties extends CSSProperties {
  "--gap": string;
}

export const Checkbox = ({
  className,
  size = "normal",
  gap,
  label,
  name,
  value,
  checked,
  onChange,
}: CheckboxProps) => {
  const styles = {
    "--gap": gap,
  } as CheckboxProperties;

  const { handleChange } = useCheckbox({ value, onChange });

  return (
    <div className={classNames("Checkbox", `Checkbox--${size}`, className)} style={styles}>
      <label className="Checkbox__label">
        <input
          className="Checkbox__input"
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={handleChange}
        />
        <span className="Checkbox__inner">
          <span className="Checkbox__box">
            <Checkmark className="Checkbox__checkmark" />
          </span>
          <span className="Checkbox__labelText">{label}</span>
        </span>
      </label>
    </div>
  );
};
