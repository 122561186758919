import isAboveMobile from "../../../Animations/isAboveMobile";

document.addEventListener("DOMContentLoaded", function () {
    const contentAreaSection = <HTMLElement>document.querySelector(".ContentAreaSection--stories");

    function moveAsideToBottomOnMobile(textContainers:NodeListOf<HTMLElement>, asides:NodeListOf<HTMLElement>) {
        textContainers.forEach((textContainer, index) => {
            let asideContainer = <HTMLElement>textContainer.querySelector(".StoryTextContent__aside");

            if (asides.length > 0 && contentAreaSection) {
                if (!isAboveMobile().matches) {
                    asides.forEach(aside => contentAreaSection.appendChild(aside));
                } else {
                    if (asides[index]) {
                        asideContainer.appendChild(asides[index])
                    }
                }
            }
        });
    }

    if(contentAreaSection) {
        const textContainers = <NodeListOf<HTMLElement>>contentAreaSection.querySelectorAll(".StoryTextContent__inner");
        const asides = <NodeListOf<HTMLElement>>contentAreaSection.querySelectorAll("aside");

        moveAsideToBottomOnMobile(textContainers, asides);
        window.addEventListener("resize", function () {moveAsideToBottomOnMobile(textContainers, asides)}, false);
    }

}, false);