import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import classNames from "classnames";
import { ResponsivePicture } from "../../react-components/responsivePicture/responsivePicture";
import { RichVideoViewModel } from "./RichVideoViewModel.csharp";
import { ComponentProps } from "../ComponentProps.csharp";
import { useIsEpiEditMode } from "../../react-components/useIsEpiEditMode";
import { AnimatedComponent } from "../../react-components/AnimatedComponent/AnimatedComponent";
import { fadeInMoveTransition } from "../../react-components/AnimatedComponent/AnimatedComponent.animations";
import { useIsInView } from "../../Microsite/Utils/useIsInView";

export const RichVideo: FC<ComponentProps<RichVideoViewModel>> = ({
  model: {
    videoMp4Url,
    youTubeVideoId,
    vimeoVideoId,
    autoPlay = false,
    title,
    body,
    posterImage,
    pictureProfile,
    shouldStopOnScroll = true,
    hiddenLabel = true,
    shouldAnimate = true,
  },
}) => {
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const [pauseRequest, setPauseRequest] = useState(-1);
  const [initialRender, setIsInitialRender] = useState(true);
  const isEpiEditMode = useIsEpiEditMode();
  const videoRef = useRef(null);
  const { intersectionEntry } = useIsInView(videoRef, {
    threshold: 1,
    root: null,
    rootMargin: "0px",
  });
  const [isInteracted, setIsInteracted] = useState(false);

  const isAnimated = shouldAnimate && !isEpiEditMode;

  // When video is played there is also fired pause and buffer event which causes unwanted showing of div with text.
  // Below code detects if there is actual pause event or "buffer pause" event

  // if the Buffer event follows after Pause it means the user seeked and not really paused
  // so we cancel the timeout
  const handleBuffer = () => {
    pauseRequest && window.clearTimeout(pauseRequest);
  };

  const handlePlay = useCallback(() => {
    setIsInitialRender(false);
    pauseRequest && window.clearTimeout(pauseRequest);
    if (!isPlaying && !initialRender) {
      setIsPlaying(true);
    }
  }, [initialRender, isPlaying]);

  const handlePaused = useCallback(() => {
    // This callback is fired at the initial render.
    // This variable is needed to not interfere with autoplay
    setIsInitialRender(false);
    if (isPlaying && !initialRender) {
      setIsPlaying(false);
    }
  }, [isPlaying, initialRender]);

  const handlePauseOnScroll = () => {
    if (!intersectionEntry?.isIntersecting) handlePaused();
  };

  const handleEnded = () => {
    if (!autoPlay) setIsPlaying(false);
  };

  const handlePauseRequest = () => {
    // clear pending timeout if there is any
    handleBuffer();
    // if there is no Buffer event following the Pause event, the user actually paused
    setPauseRequest(window.setTimeout(handlePaused, 250));
  };

  const setFirstInteraction = () => setIsInteracted(true);

  const handleOverlayClick = () => {
    setFirstInteraction();
    handlePlay();
  };

  useEffect(() => {
    if (shouldStopOnScroll) {
      window.addEventListener("scroll", handlePauseOnScroll);
    }

    return function () {
      window.removeEventListener("scroll", handlePauseOnScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldStopOnScroll]);

  useEffect(() => {
    if (!intersectionEntry?.isIntersecting) {
      handlePauseRequest();
    } else if (autoPlay && !isInteracted && !!intersectionEntry.isIntersecting) {
      handlePlay();
    }
  }, [intersectionEntry?.isIntersecting]);

  const playVideoButton = (
    <button className="RichVideo__btn Btn--playVideo">
      <span
        className={classNames("RichVideo__btnLabel", {
          visuallyHidden: hiddenLabel,
        })}
      >
        Watch video
      </span>
    </button>
  );

  return (
    <>
      <AnimatedComponent
        tag="div"
        initial={isAnimated ? "outOfScreenY" : undefined}
        whileInView={isAnimated ? "moveFromBottom" : undefined}
        viewport={{ once: true }}
        animationProps="80px"
        overrideTransition={fadeInMoveTransition}
        className={classNames("RichVideo__text", {
          "RichVideo__text--isPlaying": isPlaying,
        })}
        onClick={handleOverlayClick}
        onKeyUp={setFirstInteraction}
      >
        {playVideoButton}
        {!!title && <h2 className="RichVideo__title">{title}</h2>}
        {!!body && <div className="RichVideo__intro" dangerouslySetInnerHTML={{ __html: body }} />}
      </AnimatedComponent>

      <div
        className={classNames("RichVideo__playerWrapper", {
          "RichVideo__playerWrapper--isPlaying": isPlaying,
        })}
        ref={videoRef}
      >
        {playVideoButton}
        <ReactPlayer
          url={youTubeVideoId || vimeoVideoId || videoMp4Url}
          playing={isPlaying}
          className="RichVideo__player"
          width="100%"
          height="100%"
          onPlay={handlePlay}
          onBuffer={handleBuffer}
          onPause={handlePauseRequest}
          onEnded={handleEnded}
          muted={autoPlay}
          controls={!!youTubeVideoId || !!vimeoVideoId || !!isPlaying}
          loop={autoPlay}
          onClick={setFirstInteraction}
          onKeyUp={setFirstInteraction}
        />
        {posterImage?.url && pictureProfile && (
          <div
            className={classNames("RichVideo__poster", {
              "RichVideo__poster--visible": initialRender && !isPlaying,
            })}
          >
            <ResponsivePicture model={posterImage} profile={pictureProfile} />
          </div>
        )}
      </div>
    </>
  );
};
