import React, { FC, useState } from "react";
import classNames from "classnames";

import { AnimatedComponent } from "../../react-components/AnimatedComponent/AnimatedComponent";
import { ImageWithBlur } from "../ImageWithBlur/ImageWithBlur";
import { ProductFeatures } from "./ProductFeatures/ProductFeatures";
import { ProductGallery } from "./ProductGallery/ProductGallery";
import { AnimatedCloseButton } from "../AnimatedCloseButton/AnimatedCloseButton";
import { ProductImage } from "./ProductImage/ProductImage";
import { ProductVideo } from "./ProductVideo/ProductVideo";

import { MicrositeProductViewModel } from "./MicrositeProductViewModel.csharp";
import { MicrositeProductHotSpotType } from "../../../Models/Microsite/MicrositeProductHotSpotType.csharp";
import { useMicrositeContext } from "../Utils/micrositeContext";
import { ResponsivePictureProps } from "../../Images/ResponsivePictureProps.csharp";

interface ProductOverlayProps extends MicrositeProductViewModel {
  hideOverlay: () => void;
  goToHomePage: () => void;
  backgroundImage: ResponsivePictureProps
}

export const ProductOverlay: FC<ProductOverlayProps> = ({
  hideOverlay,
  heading,
  description,
  features,
  imageUrl,
  backgroundImage,
  imageGallery,
  imageGalleryPictureProfile,
  readMoreLink,
  hotSpots,
  hotSpotsPictureProfile,
  videoUrl,
}) => {
  const [areFeaturesVisible, setAreFeaturesVisible] = useState(false);
  const [areMediaVisible, setAreMediaVisible] = useState(false);
  const [currentMediaType, setCurrentMediaType] = useState<MicrositeProductHotSpotType>();
  const { theme } = useMicrositeContext();

  const toggleFeatureBanner = (visibility?: boolean) =>
    setAreFeaturesVisible(visibility ?? !areFeaturesVisible);

  const toggleMedia = (type?: MicrositeProductHotSpotType) => {
    setCurrentMediaType(type);

    const shouldSwitch = areMediaVisible
      ? !areMediaVisible
      : type != undefined &&
        type == MicrositeProductHotSpotType.Gallery
        ? imageGallery && imageGallery.length > 0
        : !!videoUrl;

    setAreMediaVisible(shouldSwitch)
  };

  const shouldRenderMedia =  imageGallery && imageGallery.length > 0 || !!videoUrl;

  return (
    <AnimatedComponent
      tag="div"
      className="MicrositeSection__product"
      initial="fadedOut"
      entry="moveInOverCustomZ"
      exit="fadeOutToDisplayNone"
      animationProps={10}
    >
      <div className="MicrositeProduct">
        <ImageWithBlur
          imageClassName="MicrositeProduct__backgroundImage"
          backgroundImage={backgroundImage}
        />
        <div className={classNames("MicrositeProduct__outer", `MicrositeProduct__outer--${theme}`)}>
          <AnimatedComponent
            tag="div"
            className="MicrositeProduct__inner"
            isOn={!areMediaVisible}
            initial="fadedOut"
            exit='fadeOutToDisplayNone'
            entry='fadeInToCustomDisplay'
            animationProps="flex"
          >
            <div className="MicrositeProduct__content">
              <h2 className="MicrositeProduct__heading">{heading}</h2>
              {readMoreLink && (
                <a className="MicrositeProduct__link" href={readMoreLink.href}>{readMoreLink.text}</a>
              )}
            </div>
            <ProductImage
              toggleMedia={toggleMedia}
              imageUrl={imageUrl}
              shouldBlur={areFeaturesVisible}
              hotSpots={hotSpots}
              hotSpotsPictureProfile={hotSpotsPictureProfile}
            />
            <AnimatedCloseButton
              className="MicrositeProduct__button"
              onClick={hideOverlay}
              label="Close modal"
              animationProps="flex"
            />
            <ProductFeatures
              theme={theme}
              isVisible={areFeaturesVisible}
              handleBannerClick={toggleFeatureBanner}
              description={description}
              features={features}
            />
          </AnimatedComponent>
          {shouldRenderMedia && (
            <AnimatedComponent
              tag='div'
              isOn={areMediaVisible}
              exit='fadeOutToDisplayNone'
              entry='fadeInToCustomDisplay'
              className="MicrositeProduct__gallery"
            >
              {currentMediaType == MicrositeProductHotSpotType.Gallery && (
                <ProductGallery
                  theme={theme}
                  onClose={() => toggleMedia()} 
                  pictures={imageGallery}
                  profile={imageGalleryPictureProfile}
                />
              )}
              {currentMediaType == MicrositeProductHotSpotType.Video && (
                <ProductVideo onClose={toggleMedia} videoUrl={videoUrl} />
              )}
            </AnimatedComponent>
          )}
        </div>
      </div>
    </AnimatedComponent>
  );
};
