import React, { FC } from "react";
import { ComponentProps } from "../ComponentProps.csharp";
import { ProductBrowserViewModel } from "./ProductBrowserViewModel.csharp";
import { ArrowRight } from "../../react-components/Icons/ArrowRight";

export const ProductBrowser: FC<ComponentProps<ProductBrowserViewModel>> = ({
  model: { heading, introduction, linksToCategories },
}) => {
  const hasHeading = heading || introduction;

  return (
    <>
      <section className="Section Section--fullWidth Section--verticalPaddings ProductBrowser">
        <div className="wrapper">
          {hasHeading && (
            <div className="ProductBrowser__headingsWrapper">
              {heading && <h2 className="ProductBrowser__heading">{heading}</h2>}
              {introduction && <p className="ProductBrowser__subHeading">{introduction}</p>}
            </div>
          )}
          <ul className="ProductBrowser__list">
            {linksToCategories.map((item, idx) => {
              return (
                <li key={idx} className="ProductBrowser__item">
                  <h3 className="ProductBrowser__itemTitle">
                    <a
                      className="ProductBrowser__itemButton"
                      title={item.title}
                      href={item.href}
                      target={item.target}
                    >
                      <span>{item.text}</span>
                      <div className="ProductBrowser__itemIconWrapper">
                        <ArrowRight className="ProductBrowser__itemIcon" />
                      </div>
                    </a>
                  </h3>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </>
  );
};
