import React from "react";
import { Chevron } from "../../Icons/Chevron";

export const GalleryModalArrows = () => (
  <div className="GalleryModal__arrows splide__arrows">
    <button className="GalleryModal__arrow splide__arrow splide__arrow--prev">
      <Chevron />
    </button>
    <button className="GalleryModal__arrow splide__arrow splide__arrow--next">
      <Chevron />
    </button>
  </div>
);
