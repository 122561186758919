import React, { ReactElement } from "react";
import { useSwipeable, SwipeableProps as SwipeableOptions } from "react-swipeable";

interface SwipeableProps extends SwipeableOptions {
  children: ReactElement;
  innerRef?: (ref: HTMLElement | null) => void;
  className?: string;
}

export const Swipeable = ({ children, innerRef, className, ...props }: SwipeableProps) => {
  const handlers = useSwipeable(props);
  const refCallback = (ref: HTMLElement | null) => {
    handlers.ref(ref);
    innerRef && innerRef(ref);
  };

  return (
    <div className={className} {...handlers} ref={refCallback}>{children}</div>
  );
};
