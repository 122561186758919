import React, { FC } from "react";
import { ComponentProps } from "../../../ComponentProps.csharp";
import { FactBoxTileViewModel } from "./FactBoxTileViewModel.csharp";
import { Tile, TileLink } from "../../../../react-components/Discovery/Tile/Tile";

export const FactBoxTile: FC<ComponentProps<FactBoxTileViewModel>> = ({
  model: { title, content, iconSvg, theme, tileSize, links, buttonLink },
}) => {
  const underLinedLinks: TileLink[] = links.map(l => ({
    label: l.text,
    url: l.href
  }));

  const buttonProps = buttonLink.text ? {
    title: buttonLink.text,
    href: buttonLink.href
  } : {};

  return (
    <Tile
      title={title}
      description={content}
      iconSvg={iconSvg}
      color={theme}
      size={tileSize}
      links={underLinedLinks}
      buttonProps={buttonProps}
    />
  );
};
