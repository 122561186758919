(function (window) {
    function setupVideo() {
        const videoList: NodeListOf<HTMLVideoElement> = document.querySelectorAll(".CallToAction video") || null;
        
        for (let i = 0; i < videoList.length; i++) {
            let video = videoList[i];
            video.addEventListener('mouseover', function () {
                this.controls = true;
            }, false);
            video.addEventListener('mouseout', function () {
                this.controls = false;
            }, false);
        }
    }

    window.addEventListener('load', setupVideo, false);
})(window);