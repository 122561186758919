import React, { FC } from "react";
import { useIsBelowScreenSize } from "../../useIsBelowScreenSize";
import { SidePanelModal } from "../SidePanelModal/SidePanelModal";
import { CatalogItemViewModel } from "../../../Partials/Catalog/List/CatalogItemViewModel.csharp";
import { Modal } from "../Modal";

interface SolutionModalProps extends CatalogItemViewModel {
  isOpen?: boolean;
  onClose?: () => void;
}

export const SolutionModal: FC<SolutionModalProps> = ({
  title,
  picture,
  isOpen = false,
  onClose,
}) => {
  const isMobile = useIsBelowScreenSize("tablet");

  if (!isMobile)
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Close />
        <Modal.Body>
          <Modal.Header picture={picture}>
            <Modal.Lead>Dialog</Modal.Lead>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Content>test</Modal.Content>
        </Modal.Body>
      </Modal>
    );

  return (
    <SidePanelModal
      className="SolutionModal"
      picture={picture}
      title={title}
      lead="Digital"
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};
