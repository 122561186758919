import React, { memo } from "react";
import { OfficeLocationsListWithMapListItemTranslations } from "../../OfficeLocationsListWithMapListItemTranslations.csharp";

interface OfficeLocationsListItemSiteManagerProps {
  siteManager?: string;
  translations: OfficeLocationsListWithMapListItemTranslations;
}

export const OfficeLocationsListItemSiteManager = memo<OfficeLocationsListItemSiteManagerProps>(function OfficeLocationsListItemSiteManager({
  siteManager,
  translations,
}) {
  if (!siteManager) return null;

  return (
    <div className="OfficeLocationsListItem__detailsSection">
      <p><strong>{translations.siteManager}</strong></p>
      <p>{siteManager}</p>
    </div>
  );
});
