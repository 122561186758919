import classNames from 'classnames';
import React, { FC, useState } from 'react';
import ReactPlayer from 'react-player';

import { AnimatedCloseButton } from '../../AnimatedCloseButton/AnimatedCloseButton';

interface ProductVideoProps {
  videoUrl: string
  onClose: () => void;
}

export const ProductVideo: FC<ProductVideoProps> = ({
  videoUrl,
  onClose
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClose = () => {
    setIsPlaying(false);
    onClose();
  }

  const togglePlay = (playing = true) => {
    setIsPlaying(playing);
  }

  return (
    <div className="MicrositeProductVideo">
      {!isPlaying && (
        <div className="RichVideo__text">
          <button className="RichVideo__btn Btn--playVideo MicrositeProductVideo__button"
            onClick={() => togglePlay()}
          />
        </div>
      )}
      <div className={classNames("RichVideo__playerWrapper",
        {
          "RichVideo__playerWrapper--isPlaying": isPlaying,
        }
      )}>
        <ReactPlayer
          url={videoUrl}
          playing={isPlaying}
          onPause={() => togglePlay(false)}
          onEnded={() => togglePlay(false)}
          className='RichVideo__player'
          width='100%'
          height='100%'
          controls={false}
        />
      </div>
      <AnimatedCloseButton
        className="MicrositeProductVideo__close"
        label="Close video"
        onClick={handleClose}
        overrideTheme="dark"
        animationProps="flex"
      />
    </div>
  )
};
