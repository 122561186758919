import React from "react";
import { ComponentProps } from "../../Partials/ComponentProps.csharp";
import { ProductBanner } from "../../react-components/ProductBanner/ProductBanner";
import { ProductState } from "../../../Models/Pages/ProductState.csharp";
import { ProductPageStatusViewModel } from "../ProductPageStatusViewModel.csharp";
import { CustomProductPageStatusBanner } from "./CustomProductPageStatusBanner";

const messages: {
  [key: string]: { title: string; description: string };
} = {
  New: {
    title: "New product!",
    description: "We have just added this product to our product portfolio.",
  },
  PhasedOut: {
    title: "Product is being phased out",
    description: "We will no longer sell this product and it is being phased out.",
  },
  NoLongerSold: {
    title: "Product no longer sold, but supported",
    description: "We will no longer sell this product and it is being phased out.",
  },
};

export const ProductPageStatus = ({
  model,
  containerId,
}: ComponentProps<ProductPageStatusViewModel>) => {
  const message = messages[ProductState[model.state]];

  if (model.state === ProductState.Custom && model.customBody) {
    return <CustomProductPageStatusBanner body={model.customBody} />;
  }

  if (!message) return null;

  const showReplacementProduct =
    !!model.replacementUrl &&
    (model.state === ProductState.PhasedOut || model.state === ProductState.NoLongerSold);

  return (
    <ProductBanner
      title={message.title}
      description={message.description}
      containerId={containerId}
      replacementName={showReplacementProduct ? model.replacementName.toLowerCase() : undefined}
      replacementUrl={showReplacementProduct ? model.replacementUrl : undefined}
      color={model.state === ProductState.New ? "green" : "orange"}
    />
  );
};
