//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ContentCardOptions {
	None = 0,
	WithPublicationDate = 1,
	WithExcerpt = 2,
	WithCapitalizedTitle = 4,
	TextOnly = 6,
	Default = 7,
	WithMoreButton = 8,
	WithVisibleLink = 16,
	WithHiddenImage = 32,
	Simplified = 54
}
