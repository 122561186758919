import React, { FC } from 'react';
import classNames from 'classnames';
import { ImageWithBlur } from '../ImageWithBlur/ImageWithBlur';
import { AnimatedComponent } from '../../react-components/AnimatedComponent/AnimatedComponent';
import { ResponsivePictureProps } from "../../Images/ResponsivePictureProps.csharp";

interface BlurredBackgroundImageProps {
  backgroundImage: ResponsivePictureProps;
  isOpen: boolean;
  className?: string;
  imageClassName?: string;
}

export const animationsName = {
  entry: 'blurredImageEntry',
  exit: 'blurredImageExit',
}

export const BlurredBackgroundImage: FC<BlurredBackgroundImageProps> = ({
  backgroundImage,
  isOpen,
  className,
  imageClassName,
  children,
}) => {
  return (
    <AnimatedComponent
      tag='div'
      isOn={isOpen}
      className={classNames("BlurredBackground", className)}
      initial={false}
      conductor={{
        customAnimation: {
          entry: { name: animationsName.entry, target: 'blurredContainerEntry' },
          exit: { name: animationsName.exit, target: 'blurredContainerExit' }
        }
      }}
    >
      <div className="BlurredBackground__container">
        <div className="BlurredBackground__outer">
          <AnimatedComponent
            tag='div'
            className="BlurredBackground__backgroundWrapper"
            orchestrationChild={{
              entry: { name: animationsName.entry, target: 'blurredBackgroundEntry' },
              exit: { name: animationsName.exit, target: 'blurredBackgroundExit' }
            }}
          >
            <ImageWithBlur backgroundImage={backgroundImage} className={imageClassName} />
          </AnimatedComponent>
          {children}
        </div>
      </div>
    </AnimatedComponent>
  )
};
