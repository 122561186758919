import { Thumbnail } from "./LightBoxScrollList";
import { ResponsivePictureViewModel } from "../../Images/ResponsivePictureViewModel.csharp";

function getMinValue(arr: number[]){
    const initialValue = arr[0];
    return arr.reduce((min, currentWidth) => Math.min(min, currentWidth), initialValue);
}

export function calculateSizes(images: ResponsivePictureViewModel[], maxHeight: number): Thumbnail {

    const minWidth = getMinValue(images.map(x => x.responsiveImageData.width));
    const scaledHeights = images.map(x => x.responsiveImageData)
                         .map(x => x.width / x.height)
                         .map(ratio => ratio * minWidth);
    const minHeight = getMinValue(scaledHeights);
    const scale = maxHeight / minHeight;
    return (
        {
            size: Math.floor(minHeight * scale),
        }
    );
}