import { LabelColor } from "../../../../Common/Labels/LabelColor.csharp";
import { ProductListingResult } from "./ProductListingResult.csharp";

export const MockedResponse: ProductListingResult[] = [
  {
    url: "/Common/noImage.png",
    name: "cNODE modems",
    tagline: "Communication link for underwater acoustic positioning systems",
    labels: [
      {
        text: "Range of products",
        color: LabelColor.Default,
      },
    ],
    image: {
      url: "/Common/noImage.png",
      alt: "",
      responsiveImageData: {
        focalPoint: {
          x: 0.5,
          y: 0.5,
        },
        width: 333,
        height: 333,
      },
      imageLink: "200420",
    },
    pictureProfile: {
      format: 0,
      defaultWidth: 333,
      sources: [
        {
          sourceType: "PictureSource",
          width: 333,
          height: 333,
          quality: 99,
          mode: 1,
          mediaCondition: "(max-aspect-ratio: 3/5)",
        },
      ],
    },
  },
  {
    url: "https://localhost:53650/discovery/navigation-positioning/cnode-modems/cnode---modem-mantis/",
    name: "cNODE Mantis",
    tagline: "Acoustic video modem",
    labels: [],
    image: {
      url: "/Common/noImage.png",
      alt: "",
      responsiveImageData: {
        focalPoint: {
          x: 0.5,
          y: 0.5,
        },
        width: 333,
        height: 333,
      },
      imageLink: "200409",
    },
    pictureProfile: {
      format: 0,
      defaultWidth: 333,
      sources: [
        {
          sourceType: "PictureSource",
          width: 333,
          height: 333,
          quality: 99,
          mode: 1,
          mediaCondition: "(max-aspect-ratio: 3/5)",
        },
      ],
    },
  },
  {
    url: "https://localhost:53650/discovery/navigation-positioning/cnode-transponder/cnode-minis/",
    name: "cNODE MiniS",
    tagline: "Transponder",
    labels: [],
    image: {
      url: "/Common/noImage.png",
      alt: "",
      responsiveImageData: {
        focalPoint: {
          x: 0.5,
          y: 0.5,
        },
        width: 333,
        height: 333,
      },
      imageLink: "200417",
    },
    pictureProfile: {
      format: 0,
      defaultWidth: 333,
      sources: [
        {
          sourceType: "PictureSource",
          width: 333,
          height: 333,
          quality: 99,
          mode: 1,
          mediaCondition: "(max-aspect-ratio: 3/5)",
        },
      ],
    },
  },
];
