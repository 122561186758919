import React, { FC } from "react";
import classNames from "classnames";
import { FooterCopyright } from "./FooterCopyright";
import { TopFooter } from "./TopFooter";
import { ComponentProps } from "../ComponentProps.csharp";
import { FooterViewModel } from "./FooterViewModel.csharp";
import { FooterSection } from "./FooterSection.csharp";
import { ExternalLinkIcon } from "../../react-components/Discovery/Icons/ExternalLinkIcon";
import { NavList, NavListSection, NavListSectionItem } from "../NavList/NavList";
import { SiteLogoHorizontalKog } from "../Header/Partials/SiteLogoHorizontalKog";
import { SiteLogoVerticalKog } from "../Header/Partials/SiteLogoVerticalKog";
import { FooterButtonStyle } from "../../../Models/Blocks/FooterButtonStyle.csharp";
import { ButtonFill } from "../../react-components/Buttons/ButtonFill";
import { ButtonOutlined } from "../../react-components/Buttons/ButtonOutlined";
import { SiteLogoKsat } from "../Header/Partials/SiteLogoKsat";

const formatColumnsOnMobile = (columns: FooterSection[]): NavListSection[] => {
  return columns.map((column) => ({
    header: column.heading,
    items: column.items.map(
      (columnItem): NavListSectionItem => ({
        title: columnItem.title,
        linkUrl: columnItem.linkUrl,
        linkTarget: columnItem.linkTarget,
        withIcon: !!columnItem.linkTarget,
      }),
    ),
  }));
};

const getFreeTextColumnContent = (model: FooterViewModel): React.ReactNode => {
  const renderFreeTextPrimaryButton =
    model.freeTextPrimaryButtonLabel && model.freeTextPrimaryButtonLinkUrl;

  const renderFreeTextSecondaryButton =
    model.freeTextSecondaryButtonLabel && model.freeTextSecondaryButtonLinkUrl;

  const renderFreeTextColumn =
    model.freeTextSectionHeading &&
    (model.freeTextSectionText || renderFreeTextPrimaryButton || renderFreeTextSecondaryButton);

  const PrimaryButtonTag =
    model.freeTextPrimaryButtonStyle === FooterButtonStyle.Fill ? ButtonFill : ButtonOutlined;

  const SecondaryButtonTag =
    model.freeTextSecondaryButtonStyle === FooterButtonStyle.Fill ? ButtonFill : ButtonOutlined;

  return (
    renderFreeTextColumn && (
      <div className="Footer__freeTextColumnContent">
        {model.freeTextSectionText && (
          <p className="Footer__columnCaption">{model.freeTextSectionText}</p>
        )}
        {(renderFreeTextPrimaryButton || renderFreeTextSecondaryButton) && (
          <div className="Footer__freeTextButtonsWrapper">
            {renderFreeTextPrimaryButton && (
              <PrimaryButtonTag
                as="a"
                href={model.freeTextPrimaryButtonLinkUrl}
                color="base"
                mode="dark"
              >
                {model.freeTextPrimaryButtonLabel}
              </PrimaryButtonTag>
            )}
            {renderFreeTextSecondaryButton && (
              <SecondaryButtonTag
                as="a"
                href={model.freeTextSecondaryButtonLinkUrl}
                color="base"
                mode="dark"
              >
                {model.freeTextSecondaryButtonLabel}
              </SecondaryButtonTag>
            )}
          </div>
        )}
      </div>
    )
  );
};

export const Footer: FC<ComponentProps<FooterViewModel>> = ({ model }) => {
  const validColumns = model.columns.filter((column) => column.heading && !!column.items.length);
  const formattedColumnsOnMobile = formatColumnsOnMobile(validColumns);

  const freeTextColumnContent = getFreeTextColumnContent(model);

  if (freeTextColumnContent) {
    const freeTextColumnMobileData: NavListSection = {
      header: model.freeTextSectionHeading,
      renderCustomContent: () => freeTextColumnContent,
    };
    formattedColumnsOnMobile.unshift(freeTextColumnMobileData);
  }

  return (
    <footer className="Footer">
      <TopFooter model={model} />
      <section className="Footer__mainSection">
        <div className="Footer__mainSectionInner">
          <div className="Footer__columnsAndLogoContainer">
            <div className="Footer__mainNavWrapper">
              <NavList
                sections={formattedColumnsOnMobile}
                isVisible
                textColor="light"
                sectionContentPadding="big"
                className="Footer__mobileNavList"
              />
              <ul
                className={classNames("Footer__columnsList", {
                  "Footer__columnsList--narrowColumnsOnSmallDesktop":
                    freeTextColumnContent && model.columns.length === 3,
                })}
              >
                {freeTextColumnContent && (
                  <li className="Footer__column">
                    <h2 className="Footer__columnHeading">{model.freeTextSectionHeading}</h2>
                    {freeTextColumnContent}
                  </li>
                )}
                {validColumns.map((column, columnIdx) => (
                  <li className="Footer__column" key={columnIdx}>
                    <h2 className="Footer__columnHeading">{column.heading}</h2>
                    <ul className="Footer__columnList">
                      {column.items.map((columnItem, columnItemIdx) => {
                        const linkTargetProps = columnItem.linkTarget
                          ? { target: columnItem.linkTarget, rel: "noopener" }
                          : {};

                        return (
                          <li className="Footer__columnListItem" key={columnItemIdx}>
                            <a
                              className="Footer__columnListItemLink"
                              href={columnItem.linkUrl}
                              {...linkTargetProps}
                            >
                              <span>{columnItem.title}</span>
                              {columnItem.linkTarget && <ExternalLinkIcon />}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={classNames("Footer__logoAndInfoWrapper", {
                "Footer__logoAndInfoWrapper--columnWidth": !!model.content?.additionalBody,
              })}
            >
              <div className="Footer__logoWrapper">
                <SiteLogoHorizontalKog className="Footer__horizontalLogoKog" />
                <SiteLogoVerticalKog
                  className={classNames("Footer__verticalLogoKog", {
                    "Footer__verticalLogoKog--small": !!model.content?.additionalBody,
                  })}
                />
                <SiteLogoKsat className="Footer__logoKsat" />
              </div>
              {model.content?.additionalBody && (
                <div
                  className="Footer__richTextInfo"
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  dangerouslySetInnerHTML={{ __html: model.content.additionalBody }}
                />
              )}
            </div>
          </div>
          <FooterCopyright data={model.bottomSection} />
        </div>
      </section>
    </footer>
  );
};
