import React from "react";
import NoResults from "../../../../../../wwwroot/Static/Images/Icons/NoResult.svg";

export const NoResultsFound = () => (
  <div className="NoResultsFound__container">
    <NoResults aria-hidden="true" />
    <h3 className="NoResultsFound__header">Sorry, no matches were found.</h3>
    <p className="NoResultsFound__description">Try a new search or use suggestions.</p>
  </div>
);
