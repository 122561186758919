import React, {
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  ComponentType,
  ElementType,
  PropsWithChildren,
  forwardRef,
} from "react";
import classNames from "classnames";
import { upperFirst } from "lodash";
import { ButtonFillProps } from "./ButtonFill";
import { ButtonOutlinedProps } from "./ButtonOutlined";
import { ButtonGhostProps } from "./ButtonGhost";

export type ButtonSize = "default" | "small";
export type ButtonType = "normal" | "icon";
export type ButtonMode = "light" | "dark";
export type ButtonIconPosition = "left" | "right";
export type ButtonColor = "base" | "blue" | "teal" | "destructive";
export type ButtonProps<C extends ElementType> =
  | ButtonFillProps<C>
  | ButtonOutlinedProps<C>
  | ButtonGhostProps<C>;

export interface ButtonBaseCommonProps<C extends ElementType> {
  title?: string;
  className?: string;
  size?: ButtonSize;
  type?: ButtonType;
  mode?: ButtonMode;
  color?: ButtonColor;
  iconPosition?: ButtonIconPosition;
  icon?: ComponentType;
  as?: C;
}

export interface ButtonBaseWithBaseClassNameProps<C extends ElementType>
  extends ButtonBaseCommonProps<C> {
  baseClassName: string;
}

export type ButtonBaseProps<C extends ElementType> = PropsWithChildren<
  ButtonBaseWithBaseClassNameProps<C>
> &
  Omit<ComponentPropsWithoutRef<C>, keyof ButtonBaseWithBaseClassNameProps<C>> & {
    ref?: ComponentPropsWithRef<C>["ref"];
  };

export type ButtonBaseComponent = <C extends ElementType = "button">(
  props: ButtonBaseProps<C>,
) => React.ReactElement | null;

export const ButtonBase: ButtonBaseComponent = forwardRef(function ButtonBase<
  C extends ElementType = "button",
>(
  {
    baseClassName,
    className,
    title,
    size = "default",
    type = "normal",
    mode = "light",
    color = "base",
    iconPosition = "left",
    icon,
    as,
    children,
    ...props
  }: ButtonBaseProps<C>,
  ref?: ComponentPropsWithRef<C>["ref"],
) {
  const ButtonComponent = as || "button";

  const modifiers = {
    [`${baseClassName}--small`]: size === "small",
    [`${baseClassName}--icon`]: type === "icon",
    [`${baseClassName}--icon${upperFirst(iconPosition)}`]:
      type === "normal" && iconPosition && icon,
    [`${baseClassName}--darkMode`]: mode === "dark",
    [`${baseClassName}--${color}`]: color !== "base",
  };

  const IconComponent = icon;

  const buttonContent = (
    <>
      {IconComponent && iconPosition === "left" ? <IconComponent /> : null}
      {title || children}
      {IconComponent && iconPosition === "right" ? <IconComponent /> : null}
    </>
  );

  return (
    <ButtonComponent
      className={classNames(baseClassName, modifiers, className)}
      {...props}
      ref={ref}
    >
      {buttonContent}
    </ButtonComponent>
  );
});
