import React, { ReactNode } from "react";
import classNames from "classnames";
import { Close } from "../../Icons/Close";
import { ResetFilters } from "../../Buttons/ResetFilters/ResetFilters";
import { Count, CountProps } from "../../Atoms/Count/Count";

interface FiltersModalHeaderProps {
  titleId: string;
  resetFiltersButton: ((onClearFilters: () => void) => ReactNode) | boolean;
  onClearFilters: () => void;
  onClose: () => void;
  filtersCount: CountProps;
}

export const FiltersModalHeader = ({
  titleId,
  resetFiltersButton,
  onClearFilters,
  onClose,
  filtersCount,
}: FiltersModalHeaderProps) => {
  return (
    <div className="FiltersModal__header">
      <div className="FiltersModal__headerRow">
        <div className="FiltersModal__title">
          <h2
            id={titleId}
            className={classNames("FiltersModal__titleLabel", {
              visuallyHidden: !!resetFiltersButton,
            })}
          >
            Select Filters
          </h2>

          {!!resetFiltersButton &&
            (resetFiltersButton === true ? (
              <ResetFilters onClearFilters={onClearFilters} />
            ) : (
              resetFiltersButton(onClearFilters)
            ))}
        </div>

        <button className="FiltersModal__close" aria-label="Close" onClick={onClose}>
          <Close />
        </button>
      </div>

      {filtersCount && <Count {...filtersCount} />}
    </div>
  );
};
