import { ChangeEventHandler } from "react";

interface CheckboxHookArgs {
  value: string;
  onChange?: (value: string, checked: boolean) => void;
}

export const useCheckbox = ({ value, onChange }: CheckboxHookArgs) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (onChange) {
      onChange(value, event.target.checked);
    }
  };

  return {
    handleChange,
  };
};
