import classNames from "classnames";
import React from "react";
import { Pill } from "../Pill/Pill";
import { useCheckboxGroup } from "../hooks/useCheckboxGroup";
import { CheckboxGroupOption } from "../CheckboxGroup/CheckboxGroup";

interface PillGroupProps {
  name: string;
  options: CheckboxGroupOption[];
  currentValues?: CheckboxGroupOption[];
  onChange?: (newValues: CheckboxGroupOption[]) => void;
  className?: string;
  hiddenLabel?: string;
}

export const PillGroup = ({
  name,
  options,
  currentValues,
  onChange,
  className,
  hiddenLabel,
}: PillGroupProps) => {
  const { isChecked, handleChange } = useCheckboxGroup({ currentValues, options, onChange });

  return (
    <div className={classNames("PillGroup", className)}>
      <fieldset className="PillGroup__fieldset" aria-label={hiddenLabel}>
        {options.map(({ value, label }) => (
          <Pill
            key={value}
            name={name}
            value={value}
            label={label}
            checked={isChecked(value)}
            onChange={handleChange}
          />
        ))}
      </fieldset>
    </div>
  );
};
