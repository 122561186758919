import React from "react";
import classNames from "classnames";

import { ResponsivePicture } from "../../../react-components/responsivePicture/responsivePicture";
import { MicrositeLandingHotSpot } from "../MicrositeLandingHotSpot/MicrositeLandingHotSpot";
import { MicrositeHotSpotViewModel } from "../MicrositeLandingHotSpot/MicrositeHotSpotViewModel.csharp";
import { useMicrositeContext } from "../../Utils/micrositeContext";
import { ResponsivePictureProps } from "../../../Images/ResponsivePictureProps.csharp";

interface MicrositeHeroProps {
  pictureProps: ResponsivePictureProps;
  hotSpots: MicrositeHotSpotViewModel[];
  goToSection: (id: number) => void;
}

export const MicrositeHero: React.FC<MicrositeHeroProps> = ({
  pictureProps,
  hotSpots,
  goToSection,
}) => {
  const { theme } = useMicrositeContext();
  return (
    <div className={classNames(
      'MicrositeHero',
      'MicrositeHero--lowerOpacity',
      `MicrositeHero--${theme}`,
    )}>
      {hotSpots.length > 0 && (
        <ul className="MicrositeHero__list">
          {hotSpots.map((hotSpot, index) => (
            <li
              key={`hotSpot-${index}`}
              className="MicrositeHero__listItem"
              style={{ left: `${hotSpot.x}%`, top: `${hotSpot.y}%` }}
            >
              <MicrositeLandingHotSpot {...hotSpot} goToSection={goToSection} pulseDelay={index * .5} />
            </li>
          ))}
        </ul>
      )}
      <div className="MicrositeHero__image">
        <ResponsivePicture {...pictureProps} />
      </div>
    </div>
  );
};
