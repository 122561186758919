import React, { ReactNode, createContext, useCallback, useReducer } from "react";
import { ToastProps, ToastVariant } from "./Toast";
import { ToastReducerActionType, toastReducer } from "./useToast";
import { ToastsContainer } from "./ToastsContainer";

export interface ToastContextState {
  toasts: ToastProps[];
  toast: (message: ReactNode, id?: number | string) => void;
  info: (message: ReactNode, id?: number | string) => void;
  success: (message: ReactNode, id?: number | string) => void;
  alert: (message: ReactNode, id?: number | string) => void;
  error: (message: ReactNode, id?: number | string) => void;
  remove: (id: number | string) => void;
  index: number;
}

export const initialState = {
  toasts: [],
  toast: () => {},
  info: () => {},
  success: () => {},
  alert: () => {},
  error: () => {},
  remove: () => {},
  index: 0,
};

export const ToastContext = createContext<ToastContextState>(initialState);

interface ToastContextProviderProps {
  children: ReactNode;
}

export const ToastContextProvider = ({ children }: ToastContextProviderProps) => {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  const addToast = useCallback(
    (variant: ToastVariant, message: ReactNode, id?: number | string) => {
      dispatch({
        type: ToastReducerActionType.ADD_TOAST,
        toast: {
          variant: variant,
          children: message,
          toastId: id,
        },
      });
    },
    [],
  );

  const toast = useCallback(
    (message: ReactNode, id?: number | string) => addToast(ToastVariant.Default, message, id),
    [addToast],
  );
  const info = useCallback(
    (message: ReactNode, id?: number | string) => addToast(ToastVariant.Informative, message, id),
    [addToast],
  );
  const success = useCallback(
    (message: ReactNode, id?: number | string) => addToast(ToastVariant.Success, message, id),
    [addToast],
  );
  const alert = useCallback(
    (message: ReactNode, id?: number | string) => addToast(ToastVariant.Alert, message, id),
    [addToast],
  );
  const error = useCallback(
    (message: ReactNode, id?: number | string) => addToast(ToastVariant.Error, message, id),
    [addToast],
  );
  const remove = useCallback(
    (id: number | string) =>
      dispatch({
        type: ToastReducerActionType.DELETE_TOAST,
        id,
      }),
    [],
  );

  const value = { ...initialState, toast, info, success, alert, error, remove };

  return (
    <ToastContext.Provider value={value}>
      <ToastsContainer toasts={state.toasts} />
      {children}
    </ToastContext.Provider>
  );
};
