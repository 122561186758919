import React from "react";
import { Reset } from "../../Discovery/Icons/Reset";

interface ResetFiltersProps {
  onClearFilters: () => void;
}

export const ResetFilters = ({ onClearFilters }: ResetFiltersProps) => (
  <button className="ResetFilters" onClick={onClearFilters}>
    <Reset />
    Reset filters
  </button>
);
