document.addEventListener('DOMContentLoaded', function () {
  var figureElements: NodeListOf<HTMLElement> = document.querySelectorAll('.ImageFullWidth');
  if (!figureElements.length) return;

  figureElements.forEach(function (figureElement: HTMLElement) {
    if (figureElement.classList.contains('ImageFullWidth')) {
      var widthOfScrollbar = window.innerWidth - document.documentElement.clientWidth;
      figureElement.style.setProperty('width', 'calc(100vw - ' + widthOfScrollbar + 'px)', 'important');
    }
  });
});
