import React, { useState } from "react";
import { DiscoveryDownloadsSectionViewModel } from "./DiscoveryDownloadsSectionViewModel.csharp";
import {
  TableContent,
  TableContentSectionType,
} from "../../../../react-components/Discovery/TableContent/TableContent";
import { Attachment } from "../../../../react-components/Discovery/Icons/Attachment";
import { ExternalLinkIcon } from "../../../../react-components/Discovery/Icons/ExternalLinkIcon";
import classNames from "classnames";
import { UrlHelper } from "../../../../react-components/urlUtils";
import { DownloadsMultipleItems } from "./Items/DownloadsMultipleItems";

interface DownloadsSectionProps {
  model: DiscoveryDownloadsSectionViewModel;
  layout?: "narrow" | "wide";
  inSeparateSection?: boolean;
}

export const DownloadsSection = ({
  model,
  layout = "wide",
  inSeparateSection = true,
}: DownloadsSectionProps) => {
  // TODO Update links to show dropdown when there's more than 1 sublinks under links root
  const sections: TableContentSectionType[] = model.sections.map((section) => ({
    title: section.heading,
    renderContent: () => (
      <ul className={`DownloadsSection__linksList DownloadsSection__linksList--${layout}`}>
        {section.linkRoots.map((link, idx) => {
          const { rootText, links } = link;
          const { href, target, title } = links[0];
          const isFile = UrlHelper.isFileLink(href);
          const areManyLinks = links.length > 1;

          const content = (
            <div className="DownloadsSection__linksItemLinkContent">
              <div
                className={classNames("DownloadsSection__iconBtn", {
                  "DownloadsSection__iconBtn--attachment": isFile,
                  "DownloadsSection__iconBtn--external": !isFile,
                })}
              >
                {isFile ? <Attachment /> : <ExternalLinkIcon />}
              </div>
              <span>{rootText}</span>
            </div>
          );

          const linkComponent = areManyLinks ? (
            <DownloadsMultipleItems links={links} title={title}>
              {content}
            </DownloadsMultipleItems>
          ) : (
            <a
              className="DownloadsSection__linksItemLink"
              href={href}
              target={target}
              title={title}
            >
              {content}
            </a>
          );

          return <li key={idx}>{linkComponent}</li>;
        })}
      </ul>
    ),
  }));

  return (
    <TableContent
      title={model.heading}
      sections={sections}
      size={layout}
      expandableInViewport={inSeparateSection ? "all" : "mobile"}
      expandableSectionId="Table_Content_Downloads_Section"
    />
  );
};
