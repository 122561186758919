import React from "react";
import { LabeledHotSpotBlockViewModel } from "./LabeledHotSpotBlockViewModel.csharp";
import { LabeledHotSpotColor } from "../../../../Models/Blocks/ImageHotSpots/HotSpots/LabeledHotSpotColor.csharp";
import classNames from "classnames";

export const HotSpot: React.FC<LabeledHotSpotBlockViewModel> = ({
  targetUrl,
  text,
  color,
  wrapText
}) => {

  return (
    <a className="LabeledHotSpot__Link" href={targetUrl}>
      <span className={classNames("LabeledHotSpot__Text", LabeledHotSpotColor[color], { "Wrap": wrapText })}>{text}</span>
    </a>
  );
};
