import React, {useState} from 'react';

declare type Pages = number | any[];

declare type StickyPosition = "outside" | number

interface Options {
    factor?: number;
    pages: Pages;
}

export const useStickyroll = ({factor = 1, pages}: Options): [React.MutableRefObject<HTMLElement | undefined>, { pageCount: number | false, currentPage: number, position: StickyPosition, height: string }] => {
    const ref = React.useRef<HTMLElement>();
    const [position, setPosition] = React.useState<StickyPosition>(0);
    const pageCount = typeof pages === "number" ? pages : Array.isArray(pages) && pages.length;
    const handleScroll = React.useCallback(() => {
        const {current} = ref;
        if (current !== undefined) {
            const end = window.innerHeight * -pageCount * factor;
            // @ts-ignore
            const {top} = current.getBoundingClientRect();
            if (top <= 0 && top >= end) {
                const time = -top / window.innerHeight / factor;
                const nextPageIndex = Math.floor(time);
                setPosition(nextPageIndex);
            } else if (top < end) {
                setPosition("outside");
            } else if (top > 0) {
                setPosition(0);
            }
        }
    }, [ref, setPosition, pageCount, factor]);
    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);
    // @ts-ignore
    const height = React.useMemo(() => `${pageCount * factor * 100 + 100}vh`, [
        pageCount,
        factor
    ]);
    const props = {
        pageCount,
        currentPage: pageCount ? (position == "outside" ? pageCount : position + 1) : 1,
        position,
        height
    };
    return [ref, props];
};