import React, { FC, useState } from "react";
import classNames from "classnames";
import { formatDate } from "../../react-components/dateUtil";
import ClockIcon from "../../../wwwroot/Static/Images/Icons/Clock.svg";
import PlusIcon from "../../../wwwroot/Static/Images/Icons/PlusOutline.svg";
import MinusIcon from "../../../wwwroot/Static/Images/Icons/MinusOutline.svg";
import { AgendaItemViewModel } from "./AgendaItemViewModel.csharp";
import { Link } from "../../react-components/Link/Link";

export const eventDurationFormat = "HH.mm";

export interface AgendaItemProps extends AgendaItemViewModel {
  key: string;
  speakerLabel: string;
  readMoreLabel: string;
  readLessLabel: string;
  timeZoneToDisplay: string | null;
}

export const AgendaItem: FC<AgendaItemProps> = ({
  title,
  startAtString,
  endAtString,
  speakerName,
  description,
  speakerLabel,
  readLessLabel,
  readMoreLabel,
  timeZoneToDisplay,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <li className={classNames("Agenda__tile", { ["Agenda__tile--expanded"]: isExpanded })}>
      <h3 className="Agenda__title">{title}</h3>
      <time className="Agenda__time">
        <ClockIcon aria-hidden />
        {`${formatDate(startAtString, "en", eventDurationFormat, timeZoneToDisplay)}-${formatDate(
          endAtString,
          "en",
          eventDurationFormat,
          timeZoneToDisplay,
        )}`}
      </time>
      {speakerName && (
        <p className="Agenda__speaker">
          {speakerLabel}: {speakerName}
        </p>
      )}
      {description && <p className="Agenda__description">{description}</p>}
      {description &&
        (isExpanded ? (
          <Link
            as="button"
            onClick={() => setIsExpanded(false)}
            className="Agenda__expandButton"
            aria-hidden
          >
            {readLessLabel} <MinusIcon aria-hidden />
          </Link>
        ) : (
          <Link
            as="button"
            onClick={() => setIsExpanded(true)}
            className="Agenda__expandButton"
            aria-hidden
          >
            {readMoreLabel} <PlusIcon aria-hidden />
          </Link>
        ))}
    </li>
  );
};
