import React, { FC } from "react";
import { ComponentProps } from "../../Partials/ComponentProps.csharp";
import { ResourcesViewModel } from "../ResourcesPage/ViewModels/ResourcesViewModel.csharp";
import { APIProvider } from "../../react-components/api/APIProvider";
import { Resources } from "./Resources";
import { ToastContextProvider } from "../../react-components/Toast/ToastContextProvider";

type ResourcesBlockProps = ComponentProps<ResourcesViewModel>;

export const ResourcesBlock: FC<ResourcesBlockProps> = ({ model }) => {
  return (
    <APIProvider>
      <ToastContextProvider>
        <Resources {...model} />
      </ToastContextProvider>
    </APIProvider>
  );
};
