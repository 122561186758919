import React, { Dispatch, SetStateAction, useEffect } from "react";
import { FiltersModal } from "../../../../react-components/Modal/FiltersModal/FiltersModal";
import { ProductsListingFiltersList } from "./ProductsListingFiltersList";
import { Filters, FiltersState } from "../ProductsListing.types";
import { CheckboxGroupOption } from "../../../../react-components/Inputs/CheckboxGroup/CheckboxGroup";
import { ButtonOutlined } from "../../../../react-components/Buttons/ButtonOutlined";
import { SortBy } from "../../../../react-components/Icons/SortBy";
import { Count } from "../../../../react-components/Atoms/Count/Count";

interface ProductsListingFiltersControllerProps {
  filters: Filters;
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  filtersState: FiltersState;
  onFiltersChange: (key: string, newValues: CheckboxGroupOption[]) => void;
  filtersCount: number;
  onClearFilters: () => void;
  isMobile: boolean;
  showFiltersPanel: boolean;
  productsCount: number;
}

export const ProductsListingFiltersController = ({
  filters,
  showFilters,
  setShowFilters,
  filtersState,
  filtersCount,
  onClearFilters,
  onFiltersChange,
  isMobile,
  showFiltersPanel,
  productsCount,
}: ProductsListingFiltersControllerProps) => {
  const toggleFilters = () => setShowFilters((filters) => !filters);

  const contents = (
    <ProductsListingFiltersList
      isMobile={isMobile}
      filters={filters}
      filtersState={filtersState}
      onFiltersChange={onFiltersChange}
    />
  );

  useEffect(() => {
    if (!isMobile && showFilters) {
      toggleFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, showFilters]);

  if (isMobile)
    return (
      <>
        <div className="ProductsListingFilters__header">
          <ButtonOutlined
            className="ProductsListingFilters__showFilters"
            icon={SortBy}
            iconPosition="right"
            onClick={toggleFilters}
          >
            Show Filters
            <Count count={filtersCount} variant="pill" />
          </ButtonOutlined>

          <Count count={productsCount} label="Products" variant="bold" />
        </div>

        <FiltersModal
          id="ProductsListingFiltersModal"
          isOpen={isMobile && showFilters}
          onClose={toggleFilters}
          resetFiltersButton={!!filtersCount}
          onClearFilters={onClearFilters}
          filtersCount={{ label: "products", count: productsCount, variant: "bold" }}
        >
          {contents}
        </FiltersModal>
      </>
    );

  if (!showFiltersPanel) return null;

  return contents;
};
