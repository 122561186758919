import * as React from "react";
import {Checkbox} from "../Checkbox/Checkbox";

export interface OfficeLocationContactType {
    value: number;
    displayName: string;
}

export interface OfficeLocationsContactTypesProps {
    contactTypes: OfficeLocationContactType[];
    onSelectionChanged: (selectedValues: number[]) => void | undefined;
}

export interface OfficeLocationsContactTypesState {
    selectedValues: number[];
}

export class OfficeLocationsContactTypes extends React.Component<OfficeLocationsContactTypesProps, OfficeLocationsContactTypesState> {
    constructor(props: OfficeLocationsContactTypesProps) {
        super(props);

        this.state = {
            selectedValues: []
        };

        this.onCheckedChanged = this.onCheckedChanged.bind(this);
    }

    private onCheckedChanged(contactType: number, isChecked: boolean) {
        this.setState(prevState => {
            OfficeLocationsContactTypes.getNewSelectedValues(prevState.selectedValues, contactType, isChecked)
        }, () => {
            if (this.props.onSelectionChanged) {
                this.props.onSelectionChanged(this.state.selectedValues);
            }
        })
    }

    private static getNewSelectedValues(currentSelectedValues: number[], contactType: number, isChecked: boolean): number[] {
        let selectedValues = currentSelectedValues;
        if (isChecked) {
            selectedValues.push(contactType);
        } else {
            const indexOf = selectedValues.indexOf(contactType);
            if (indexOf > -1) {
                selectedValues.splice(indexOf, 1)
            }
        }
        return selectedValues;
    }

    private isSelected(contactType: number) {
        return this.state.selectedValues.indexOf(contactType) !== -1;
    }

    render() {
        return (
            <div className="OfficeLocationsTypes">
                <fieldset className="OfficeLocationsTypes__container">
                    <legend className="OfficeLocationsTypes__legend">
                        <span className="OfficeLocationsTypes__title">Contact type</span>
                    </legend>
                    {this.props.contactTypes.map((type, index) => (
                        <div key={type.value}>
                            <Checkbox
                                checked={this.isSelected(type.value)}
                                id={`checkbox_${index}`}
                                text={type.displayName}
                                onCheckedChanged={checked => this.onCheckedChanged(type.value, checked)}
                            />
                        </div>
                    ))}
                </fieldset>
            </div>
        )
    }
}