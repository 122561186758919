import React, { FC, useState } from "react";
import { AnimatePresence } from "framer-motion";
import classNames from "classnames";

import { ResponsivePicture } from "../../react-components/responsivePicture/responsivePicture";
import { AnimatedComponent } from "../../react-components/AnimatedComponent/AnimatedComponent";
import { MicrositeSectionContent } from "./SectionContent/MicrositeSectionContent";
import { ProductOverlay } from "../Product/ProductOverlay";

import { MicrositeSectionViewModel } from "./MicrositeSectionViewModel.csharp";
import { MicrositeProductViewModel } from "../Product/MicrositeProductViewModel.csharp";
import { useMicrositeContext } from "../Utils/micrositeContext";

interface MicrositeSectionProps extends MicrositeSectionViewModel {
  goBack: () => void;
}

export const MicrositeSection: FC<MicrositeSectionProps> = ({
  heading,
  description,
  products,
  goBack,
  pictureProps,
}) => {
  const [currentProduct, setCurrentProduct] =
    useState<MicrositeProductViewModel | null>(null);

  const [isIntersecting, setIntersecting] = useState(false);
  const { theme, setIsProductVisible } = useMicrositeContext();

  const hideProductOverlay = () => {
    setIsProductVisible(false);
    setCurrentProduct(null);
  };

  const handleProductClick = (product: MicrositeProductViewModel): void => {
    setCurrentProduct(product);
    setIsProductVisible(true);
  }
  return (
    <AnimatedComponent
      tag="div"
      className={classNames("MicrositeSection", {
        'MicrositeSection--productVisible': currentProduct != null
      })}
      initial="fadedOut"
      entry="moveInOver"
      exit="fadeOutToDisplayNone"
    >
      <AnimatePresence>
        {currentProduct && (
          <ProductOverlay
            key={currentProduct.id}
            backgroundImage={pictureProps}
            hideOverlay={hideProductOverlay}
            goToHomePage={goBack}
            {...currentProduct}
          />
        )}
      </AnimatePresence>
      <div className={classNames("MicrositeSection__hero", {
        "MicrositeSection__hero--blurred": !isIntersecting
      })}>
        <div className={classNames("MicrositeHero", `MicrositeHero--${theme}`)}>
          <div className="MicrositeHero__image">
            <ResponsivePicture {...pictureProps} />
          </div>
        </div>
      </div>
      <div className="MicrositeSection__container">
        <MicrositeSectionContent
          heading={heading}
          description={description}
          products={products}
          setProduct={handleProductClick}
          setIntersection={setIntersecting}
          backButtonClick={goBack}
        />
      </div>
    </AnimatedComponent >
  );
};
