import { useEffect, useState } from "react";
import { SelectOption } from "../SelectOption";

interface UseValuesHook {
  currentValues: SelectOption[];
  handleSave?: (newValues: SelectOption[]) => void;
  handleChange?: (oldValues: SelectOption[]) => SelectOption[];
}

export const useSelectValues = ({ currentValues, handleSave, handleChange }: UseValuesHook) => {
  const [savedOptions, setSavedOptions] = useState(currentValues);
  const [values, setValues] = useState(currentValues);

  const saveOptions = () => {
    setSavedOptions(values);

    if (handleSave) {
      handleSave(values);
    }
  };

  const clearOptions = () => {
    const newValues = [] as SelectOption[];

    setValues(newValues);
  };

  const changeOptions = (option: SelectOption, checked: boolean) => {
    const newValues = [...values];

    if (checked) {
      newValues.push(option);
    } else {
      const foundOptionIndex = newValues.findIndex(({ value }) => value === option.value);

      if (foundOptionIndex > -1) {
        newValues.splice(foundOptionIndex, 1);
      }
    }

    setValues(newValues);

    if (handleChange) {
      handleChange(newValues);
    }
  };

  useEffect(() => {
    setValues(currentValues);
    setSavedOptions(currentValues);
  }, [currentValues]);

  return {
    savedOptions,
    setSavedOptions,
    values,
    setValues,
    saveOptions,
    clearOptions,
    changeOptions,
  };
};
