import React, { FC } from 'react';

import { BlurredBackgroundImage } from '../../BlurredBackgroundImage/BlurredBackgroundImage';
import { AnimatedComponent } from '../../../react-components/AnimatedComponent/AnimatedComponent';
import { MicrositeMenuModalItem } from "./MicrositeMenuModalItem";
import { MicrositeSectionViewModel } from "../../Section/MicrositeSectionViewModel.csharp";
import { ResponsivePictureProps } from "../../../Images/ResponsivePictureProps.csharp";

interface MicrositeMenuModalProps {
  isOpen: boolean;
  sections: MicrositeSectionViewModel[];
  backgroundImage: ResponsivePictureProps;
  goToSection: (id: number) => void;
  itemSingularLabel: string;
  itemPluralLabel: string;
}

export const MicrositeMenuModal: FC<MicrositeMenuModalProps> = ({
  sections,
  isOpen,
  backgroundImage,
  goToSection,
  itemSingularLabel,
  itemPluralLabel,
}) => {

  return (
    <BlurredBackgroundImage isOpen={isOpen} backgroundImage={backgroundImage} imageClassName="MicrositeMenuBar__image">
      <AnimatedComponent
        tag='div'
        className="MicrositeMenuModal__contentOuter"
        initial={false}
      >
        <div className="MicrositeMenuModal__contentInner">
          <ul className="MicrositeMenuBar__list">
            {sections?.map((section, id) => (
              <li key={`modal-item-${id}`}>
                <MicrositeMenuModalItem
                  isOpen={isOpen}
                  section={section}
                  itemIndex={id}
                  goToSection={goToSection}
                  itemSingularLabel={itemSingularLabel}
                  itemPluralLabel={itemPluralLabel}
                />
              </li>
            ))}
          </ul>
        </div>
      </AnimatedComponent>
    </BlurredBackgroundImage>
  )
};
