import React, { FC, useState } from "react";
import { usePopper } from "react-popper";
import classNames from "classnames";
import ClickAwayListener from "react-click-away-listener";

import { AnimatedComponent } from "../../react-components/AnimatedComponent/AnimatedComponent";
import { useMicrositeContext } from "../Utils/micrositeContext";

export type IconType = "arrow" | "gallery" | "video";

interface MicrositeHotSpotProps {
  title: string;
  onPreviewClick: () => void;
  mobileBehavior?: "visible" | "hidden";
  onHotSpotClick?: () => void;
  shouldDisplayPreviewAfterClick?: boolean;
  popUpClassName?: string;
  popUpContentClassName?: string;
  icon?: IconType;
  size?: "xSmall" | "small" | "medium" | "large";
  variant?: "clear" | "white" | "bronze";
  fadeDelay?: number;
  pulseDelay?: string;
}

export const MicrositeHotSpot: FC<MicrositeHotSpotProps> = ({
  title,
  onPreviewClick,
  popUpClassName,
  popUpContentClassName,
  children,
  onHotSpotClick,
  variant = "white",
  mobileBehavior = "hidden",
  shouldDisplayPreviewAfterClick = true,
  icon = "arrow",
  size = "xSmall",
  fadeDelay = 300,
  pulseDelay = "0",
}) => {
  const [containerElementRef, setContainerElementRef] = useState<HTMLButtonElement | null>(null);
  const [hotSpotElementRef, setHotSpotElementRef] = useState<HTMLDivElement | null>(null);
  const [isHotSpotVisible, setIsHotSpotVisible] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { theme } = useMicrositeContext();

  const { styles, attributes } = usePopper(containerElementRef, hotSpotElementRef, {
    placement: "right",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 24],
        },
      },
    ],
  });

  const handleHotSpotClick = () => {
    if (onHotSpotClick) {
      onHotSpotClick();
    }
    if (shouldDisplayPreviewAfterClick) {
      const hotSpotVisibility = !isHotSpotVisible;
      setIsHotSpotVisible(hotSpotVisibility);
      setTimeout(() => setIsPopoverVisible(!isPopoverVisible), !hotSpotVisibility ? fadeDelay : 0);
    }
  };

  const cleanUp = () => {
    setIsHotSpotVisible(false);
    setTimeout(() => setIsPopoverVisible(false), fadeDelay);
  };

  const handlePreviewClick = () => {
    onPreviewClick();
    cleanUp();
  };

  return (
    <ClickAwayListener onClickAway={cleanUp}>
      <div>
        <button
          onClick={handleHotSpotClick}
          ref={setContainerElementRef}
          className={classNames(
            "MicrositeHotSpot",
            `MicrositeHotSpot--${theme}`,
            `MicrositeHotSpot--${size}`,
            `MicrositeHotSpot--${variant}`,
            `MicrositeHotSpot--${mobileBehavior}Mobile`,
            {
              "MicrositeHotSpot--visible": isHotSpotVisible,
            },
          )}
        >
          <span className="MicrositeHotSpot__pulseRing" style={{ animationDelay: pulseDelay }} />
          <span className="visuallyHidden">{title}</span>
          <span
            className={classNames("MicrositeHotSpot__icon", `MicrositeHotSpot__icon--${icon}`)}
          />
          <span className="MicrositeHotSpot__pulseDot" style={{ animationDelay: pulseDelay }} />
        </button>
        {isPopoverVisible && (
          <div
            {...attributes.popper}
            style={styles.popper}
            className={classNames("MicrositeHotSpot__popOver", popUpClassName)}
            ref={setHotSpotElementRef}
          >
            <AnimatedComponent
              tag="button"
              onClick={handlePreviewClick}
              animationProps={"flex"}
              entry="fadeInToCustomDisplay"
              exit="fadeOutToDisplayNone"
              isOn={isHotSpotVisible}
              className={popUpContentClassName}
            >
              {children}
            </AnimatedComponent>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
