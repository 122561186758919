import * as React from "react";
import classNames from "classnames";
import {NextPageSectionButtonProps} from "../../nextPageSectionButton/NextPageSectionButtonProps.csharp";
import {ComponentProps} from "../../Partials/ComponentProps.csharp";
import {debounce} from "throttle-debounce";
import {getBrowserHandler, IBrowserHandler} from "../browserHandlers";

export interface NextPageSectionState {
    sections: HTMLElement[];
    nextSection: string;
    shouldJumpToTop: boolean;
    isHidden: boolean;
    scrollPosition: number;
    topOffset: number;
}

export class NextPageSectionButton extends React.Component<ComponentProps<NextPageSectionButtonProps>, NextPageSectionState> {
    scrollHandler: debounce<() => void>;
    browserHandler: IBrowserHandler | undefined;

    constructor(props: ComponentProps<NextPageSectionButtonProps>) {
        super(props);

        this.scrollHandler = debounce(50, this.updateNextSection);

        this.state = {
            nextSection: "",
            shouldJumpToTop: false,
            sections: [],
            isHidden: false,
            scrollPosition: 0,
            topOffset: 0
        };
    }

    componentDidMount(): void {
        this.browserHandler = getBrowserHandler();
        
        const sections = this.props.model.sectionCssSelectors
            .map((selector: string) => document.querySelectorAll<HTMLElement>(selector))
            .map((nodeList: NodeListOf<HTMLElement>) => Array.from(nodeList))
            .reduce((c: HTMLElement[], i: HTMLElement[]) => c.concat(i), []);

        sections.forEach(NextPageSectionButton.addMissingId);

        const element = document.getElementById("NextPageSectionButton")!;
        const topOffset = element.getBoundingClientRect().top;

        this.setState({
            sections: sections,
            scrollPosition: this.browserHandler.getScrollYOffset(),
            topOffset
        }, () => {
            this.updateNextSection();
        });

        this.browserHandler.addEventListener("scroll", this.scrollHandler);
    }

    componentWillUnmount(): void {
        this.browserHandler!.removeEventListener("scroll", this.scrollHandler);
    }

    private getSectionOffsets = () => {
        return this.state.sections.map(x => x.offsetTop);
    };

    private setNextSection = () => {
        const state = history.state;
        location.replace('#' + this.state.nextSection);
        history.replaceState(state, document.title, location.href)
    };

    private updateNextSection = () => {
        var scrollYOffset = this.browserHandler!.getScrollYOffset();
        const nextSectionItem = this.getSectionOffsets()
            .map((offset, index) => {
                return {offset, index}
            })
            .filter(x => x.offset > this.state.topOffset + scrollYOffset)[0];

        const nextSectionIndex = !nextSectionItem ? 0 : nextSectionItem.index;
        const nextSection = this.state.sections[nextSectionIndex].id;

        this.setState(state => ({
            nextSection: nextSection,
            scrollPosition: scrollYOffset,
            isHidden: scrollYOffset < state.scrollPosition,
            shouldJumpToTop: nextSectionIndex == 0
        }));
    };

    render() {
        return <a onClick={this.setNextSection}>
            <div id="NextPageSectionButton"
                 className={classNames({
                     "NextPageSectionButton": true,
                     "NextPageSectionButton--backToTop": this.state.shouldJumpToTop,
                     "NextPageSectionButton--hide": this.state.isHidden
                 })}>
            </div>
        </a>;
    }

    private static addMissingId(section: Element, i: number) {
        if (!section.id) {
            section.id = "page-section-" + i;
        }
    }
}