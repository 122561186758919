export interface LatLng {
  lat: number;
  lng: number;
}

// https://henry-rossiter.medium.com/calculating-distance-between-geographic-coordinates-with-javascript-5f3097b61898 - Spherical Law of Cosines
export function calculateGeoDistanceBetween(point1: LatLng, point2: LatLng) {
    const earthRadiusInKm = 6371;
    const p1 = degToRad(point1.lat);
    const p2 = degToRad(point2.lat);
    const deltaP = p2 - p1;
    const deltaLon = point2.lng - point1.lng;
    const deltaLambda = degToRad(deltaLon);
    const a = Math.sin(deltaP/2) * Math.sin(deltaP/2) +
              Math.cos(p1) * Math.cos(p2) *
              Math.sin(deltaLambda/2) * Math.sin(deltaLambda/2);
    const distance = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)) * earthRadiusInKm;
    return distance;
}

function degToRad(deg: number) {
  return deg * (Math.PI / 180);
}
