import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { Chevron } from "../../react-components/Icons/Chevron";
import { ExternalLinkIcon } from "../../react-components/Discovery/Icons/ExternalLinkIcon";

export interface NavListSectionItem {
  title: string;
  linkUrl: string;
  linkTarget?: string;
  withIcon?: boolean;
  description?: string;
}

export interface NavListSection {
  header: string;
  linkUrl?: string;
  linkTarget?: string;
  items?: NavListSectionItem[];
  additionalLinksHeader?: string;
  additionalLinksDescription?: string;
  additionalLinks?: NavListSectionItem[];
  renderCustomContent?: () => React.ReactNode;
}

interface NavListProps {
  sections: NavListSection[];
  textColor: "light" | "dark";
  isVisible: boolean;
  sectionContentPadding?: "normal" | "big";
  a11yHidden?: boolean;
  className?: string;
}

export const NavList: FC<NavListProps> = ({
  sections,
  isVisible,
  textColor,
  sectionContentPadding = "normal",
  className = "",
  a11yHidden = false,
}) => {
  const [openedSectionsIds, setOpenedSectionsIds] = useState<number[]>([]);

  const toggleSection = (sectionIdx: number) => {
    setOpenedSectionsIds((prevState) => {
      if (prevState.includes(sectionIdx)) {
        return prevState.filter((id) => id !== sectionIdx);
      } else {
        return prevState.concat(sectionIdx);
      }
    });
  };

  useEffect(() => {
    if (!isVisible) {
      setOpenedSectionsIds([]);
    }
  }, [isVisible]);

  return (
    <ul className={classNames("NavList", className, `NavList--${textColor}`)}>
      {sections.map((section, sectionIdx) => {
        const hasMainItems = !!section.items?.length;
        const hasAdditionalLinks = !!section.additionalLinks?.length;
        const hasContent = hasMainItems || hasAdditionalLinks || section.renderCustomContent;
        const topLinkTargetProps = section.linkTarget
          ? { target: section.linkTarget, rel: "noopener" }
          : {};
        const isCurrentSubmenuOpen = openedSectionsIds.includes(sectionIdx);
        const subListId = `NavList_${section.header.split(/\s+/).join("")}_${sectionIdx}`;

        return (
          <li className="NavList__topItem" key={sectionIdx}>
            <div className="NavList__topItemContent">
              {hasContent ? (
                <button
                  className="NavList__topItemLink NavList__topItemLink--asButton"
                  onClick={() => toggleSection(sectionIdx)}
                  aria-controls={subListId}
                  aria-expanded={isCurrentSubmenuOpen}
                >
                  <span>{section.header}</span>
                  <Chevron
                    className={classNames("NavList__topItemIcon", {
                      "NavList__topItemIcon--rotated": isCurrentSubmenuOpen,
                    })}
                    aria-hidden
                  />
                </button>
              ) : (
                <a
                  className="NavList__topItemLink"
                  href={section.linkUrl}
                  {...topLinkTargetProps}
                  tabIndex={a11yHidden ? -1 : undefined}
                >
                  <span>{section.header}</span>
                </a>
              )}
            </div>
            {hasContent && (
              <div
                className={classNames("NavList__subListWrapper", {
                  "NavList__subListWrapper--isOpened": isCurrentSubmenuOpen,
                })}
                id={subListId}
                aria-hidden={!isCurrentSubmenuOpen}
              >
                <div className="NavList__subListInner">
                  {section.renderCustomContent && section.renderCustomContent()}
                  {hasMainItems && (
                    <ul className="NavList__subList">
                      {section.items?.map((sectionItem, sectionItemIdx) => {
                        const subLinkTargetProps = sectionItem.linkTarget
                          ? { target: sectionItem.linkTarget, rel: "noopener" }
                          : {};

                        return (
                          <li className="NavList__subItem" key={sectionItemIdx}>
                            <a
                              className={`NavList__subItemLink NavList__subItemLink--${sectionContentPadding}Padding`}
                              href={sectionItem.linkUrl}
                              {...subLinkTargetProps}
                              tabIndex={a11yHidden || !isCurrentSubmenuOpen ? -1 : undefined}
                            >
                              <div className="NavList__subItemLinkTitle">
                                <span>
                                  {sectionItem.title}
                                  {sectionItem.description && (
                                    <span className="visuallyHidden">:</span>
                                  )}
                                </span>
                                {sectionItem.withIcon && (
                                  <ExternalLinkIcon className="NavList__subItemIcon" aria-hidden />
                                )}
                              </div>
                              {sectionItem.description && (
                                <div className="NavList__subItemLinkDescription">
                                  {sectionItem.description}
                                </div>
                              )}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}

                  {hasAdditionalLinks && (
                    <div className="NavList__additionalLinks">
                      <div className="NavList__additionalLinksInner">
                        {section.additionalLinksHeader && (
                          <div className="NavList__additionalLinksHeader">
                            {section.additionalLinksHeader}
                          </div>
                        )}
                        {section.additionalLinksDescription && (
                          <div className="NavList__additionalLinksDescription">
                            {section.additionalLinksDescription}
                          </div>
                        )}
                      </div>

                      <ul className="NavList__additionalLinksList">
                        {section.additionalLinks?.map((link, idx) => {
                          const subLinkTargetProps = link.linkTarget
                            ? { target: link.linkTarget, rel: "noopener" }
                            : {};

                          return (
                            <li key={idx} className="NavList__additionalLinksItem">
                              <a
                                className={`NavList__additionalLinksItemLink NavList__additionalLinksItemLink--${sectionContentPadding}Padding`}
                                href={link.linkUrl}
                                {...subLinkTargetProps}
                                tabIndex={a11yHidden || !isCurrentSubmenuOpen ? -1 : undefined}
                              >
                                <div className="NavList__additionalLinksItemLinkTitle">
                                  {link.description}
                                </div>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="NavList__subListPadding" aria-hidden />
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};
