import React from "react";
import { GroupBase, ValueContainerProps } from "react-select";
import { RadioSelectOption } from "./RadioSelect.types";

export const ValueContainer = ({ children, innerProps }: ValueContainerProps<RadioSelectOption, false, GroupBase<RadioSelectOption>>) => {
  return (
    <div className="RadioSelect__valueContainer" {...innerProps}>
      {children}
    </div>
  )
};
