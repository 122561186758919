import React, { useMemo } from "react";
import { GroupBase, MenuListProps } from "react-select";
import { Drawer } from "./Drawer";
import { RadioSelectOption } from "./RadioSelect.types";

export const MenuList = ({
  children,
  innerProps,
  innerRef,
  selectProps: {
    title,
    isMobile,
    ...selectProps
  },
  ...props
}: MenuListProps<RadioSelectOption, false, GroupBase<RadioSelectOption>>) => {
  const menuContent = useMemo(() => (
    <div
      className="RadioSelect__menuList"
      {...isMobile ? innerProps : {}}
      ref={isMobile ? innerRef : undefined}
    >
      {title && isMobile && (
        <div className="RadioSelect__menuTitle">{title}</div>
      )}
      <div className="RadioSelect__menuListOptions">
        {children}
      </div>
    </div>
  ), [title, children, isMobile, innerProps]);

  if (!isMobile) return menuContent;

  return (
    <Drawer // Have to be inside MenuList, otherwise every click outside the list make menu close
      innerProps={innerProps}
      innerRef={innerRef}
      selectProps={{ title, isMobile, ...selectProps }}
      {...props}
    >
      {menuContent}
    </Drawer>
  )
};
