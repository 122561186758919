import React, { createContext, FC, ReactNode, useContext } from "react";

export interface ModalContext {
  onClose?: () => void;
}

export const modalContext = createContext<ModalContext | undefined>(undefined);

const ModalContextProvider = modalContext.Provider;

interface ModalProviderProps extends ModalContext {
  children: ReactNode;
}

export const ModalProvider: FC<ModalProviderProps> = ({ children, ...contextValues }) => {
  return <ModalContextProvider value={contextValues}>{children}</ModalContextProvider>;
};

export const useModalContext = () => {
  const context = useContext(modalContext);

  if (!context) {
    throw new Error("useModalContext cannot be used outside of ModalProvider");
  }

  return context;
};
