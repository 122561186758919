import { immer } from "zustand/middleware/immer";
import { Immer } from "./types";

interface ProductBannerState {
  height: number;
  setHeight: (height: number) => void;
}

interface ProductNavbarState {
  height: number;
  setHeight: (height: number) => void;
}

interface ProductState {
  banner: ProductBannerState;
  navbar: ProductNavbarState;
}

export interface ProductSlice {
  product: ProductState;
}

export const createProductSlice: Immer<ProductSlice> = immer((set) => ({
  product: {
    banner: {
      height: 0,
      setHeight: (height) =>
        set((state) => {
          state.product.banner.height = height;
        }),
    },
    navbar: {
      height: 0,
      setHeight: (height) =>
        set((state) => {
          state.product.navbar.height = height;
        }),
    },
  },
}));
