import React, { memo } from "react";
import { Heading } from "../../../../react-components/Heading";

interface OfficeLocationsListNoOfficesReasonProps {
  title: string;
  description: string;
}

export const OfficeLocationsListNoOfficesReason = memo<OfficeLocationsListNoOfficesReasonProps>(function OfficeLocationsListNoOfficesReason({
  title,
  description,
}) {
  return (
    <div className="OfficeLocationsList__noOffices">
      <Heading
        className="OfficeLocationsList__noOfficesTitle"
        level={2}
      >
        {title}
      </Heading>
      <p className="OfficeLocationsList__noOfficesDescription">
        {description}
      </p>
    </div>
  );
});
