import React from 'react';
import { TableContent, TableContentSectionType } from '../../react-components/Discovery/TableContent/TableContent';
import classNames from 'classnames';
import { Tooltip } from '../../react-components/Discovery/Tooltip/Tooltip';
import { TooltipTrigger } from '../../react-components/Discovery/Tooltip/TooltipTrigger';
import { TooltipContent } from '../../react-components/Discovery/Tooltip/TooltipContent';
import { Info as InfoIcon } from '../../react-components/Discovery/Icons/Info';
import { TechnicalSpecificationsViewModel } from './TechnicalSpecificationsViewModel.csharp';

interface TechnicalSpecificationsProps {
  model: TechnicalSpecificationsViewModel;
  layout?: 'narrow' | 'wide';
  inSeparateSection?: boolean;
}

export const TechnicalSpecifications = ({ model, layout = 'wide', inSeparateSection = true }: TechnicalSpecificationsProps) => {
  const sections: TableContentSectionType[] = model.specificationGroups.map((specificationGroup, groupIdx) => ({
    title: specificationGroup.title,
    renderContent: () => (
      <dl className={classNames("TechnicalSpecifications__specList", `TechnicalSpecifications__specList--${layout}`, {
        "TechnicalSpecifications__specList--lastGroup": groupIdx === model.specificationGroups.length - 1,
      })}>
        {specificationGroup.specifications.map((specification, specIdx) => {
          const unit = specification.unit ? ` ${specification.unit}` : '';
          const customUnit = specification.customUnit ? ` ${specification.customUnit}` : '';

          return (
            <div className="TechnicalSpecifications__specItem" key={specIdx}>
              <dt className="TechnicalSpecifications__specItemLabel" title={specification.title}>
                <span>{specification.title}:</span>
                {specification.tooltip && (
                  <Tooltip>
                    <TooltipTrigger className="TechnicalSpecifications__tooltipTrigger">
                      <InfoIcon className="TechnicalSpecifications__tooltipTriggerIcon" />
                    </TooltipTrigger>
                    <TooltipContent maxWidth={164} className="TechnicalSpecifications__tooltipContent">
                      {specification.tooltip}
                    </TooltipContent>
                  </Tooltip>
                )}
              </dt>
              <dd className="TechnicalSpecifications__specItemValue">{`${specification.value}${unit}${customUnit}`}</dd>
            </div>
          );
        })}
      </dl>
    )
  }));

  return (
    <TableContent
      title={model.title}
      sections={sections}
      size={layout}
      expandableInViewport={inSeparateSection ? 'all' : 'mobile'}
      expandableSectionId="Table_Content_Technical_Specifications"
    />
  );
};
