import React, { FC } from "react";
import { FooterSection } from "./FooterSection.csharp";

interface FooterCopyright {
  data: FooterSection;
}

export const FooterCopyright: FC<FooterCopyright> = ({ data }) => {
  return (
    <div className="Footer__copyrightData">
      {data?.heading && <h2 className="visuallyHidden">{data.heading}</h2>}
      {(data?.text || !!data?.items?.length) && (
        <ul className="Footer__copyrightList">
          {data?.text && <li className="Footer__copyrightListItem">{data.text}</li>}
          {data.items.map((item, idx) => {
            const linkTargetProps = item.linkTarget
              ? { target: item.linkTarget, rel: "noopener" }
              : {};

            return (
              <li className="Footer__copyrightListItem" key={idx}>
                <a href={item.linkUrl} {...linkTargetProps}>
                  {item.title}
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
