import React, { SVGProps, memo } from "react";
export const Search = memo(function Search(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 16.4372C17.5 16.7229 17.386 16.9968 17.1833 17.198C16.9766 17.3952 16.6996 17.5017 16.4141 17.4936C16.1285 17.4856 15.858 17.3637 15.6628 17.1551L12.8038 14.2944C12.708 14.2133 12.5676 14.2133 12.4718 14.2944C9.72101 16.117 6.03762 15.5586 3.94991 13.0023C1.8622 10.4461 2.04886 6.72305 4.38178 4.38872C6.71471 2.05439 10.4355 1.86761 12.9902 3.95658C15.5449 6.04556 16.103 9.73117 14.2815 12.4837C14.2004 12.5795 14.2004 12.7199 14.2815 12.8158L17.1833 15.6765C17.386 15.8777 17.5 16.1516 17.5 16.4372ZM8.92755 4.65152C6.56205 4.65152 4.64443 6.5703 4.64443 8.93724C4.64443 10.0739 5.09568 11.164 5.89892 11.9677C6.70217 12.7714 7.79159 13.223 8.92755 13.223C11.2931 13.223 13.2107 11.3042 13.2107 8.93724C13.2107 6.5703 11.2931 4.65152 8.92755 4.65152Z"
      />
    </svg>
  );
});
