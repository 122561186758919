import React, { FC, useState } from "react";
import { SelectOption } from "../../react-components/Inputs/SelectOption";
import { useUniqueId } from "../../react-components/hooks/useUniqueId";
import { CheckboxSelectDropdown } from "../../react-components/Inputs/CheckboxSelect/CheckboxSelectDropdown";
import classNames from "classnames";
import { ButtonDropdown } from "../../react-components/Buttons/ButtonDropdown/ButtonDropdown";
import { FocusOn } from "react-focus-on";
import { Tooltip } from "../../react-components/Discovery/Tooltip/Tooltip";
import { TooltipTrigger } from "../../react-components/Discovery/Tooltip/TooltipTrigger";
import { TooltipContent } from "../../react-components/Discovery/Tooltip/TooltipContent";

interface VacancyFilterSelectProps {
  label: string;
  name: string;
  options: SelectOption[];
  currentValue?: SelectOption;
  onChange: (newValue: SelectOption) => void;
  placeholder: string;
  placeholderAsFirstOption: boolean;
  heading: string;
}

export const VacancyFilterSelect: FC<VacancyFilterSelectProps> = ({
  label,
  name,
  options: initialOptions,
  currentValue,
  onChange,
  placeholder,
  placeholderAsFirstOption,
  heading,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const uniqueId = useUniqueId();

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  const onValueChanged = (newValue: SelectOption) => {
    handleDropdownClose();
    onChange(newValue);
  };

  const firstOption = { label: placeholder, value: "" } as SelectOption;
  const options = placeholderAsFirstOption ? [firstOption, ...initialOptions] : initialOptions;

  const htmlId = `CheckboxSelect${uniqueId}`;

  const renderDropdown = () => {
    if (!isDropdownOpen) return null;

    return (
      <CheckboxSelectDropdown
        id={`${htmlId}Dropdown`}
        name={name}
        values={currentValue ? [currentValue] : []}
        options={options}
        handleChange={onValueChanged}
        singleSelection={true}
      />
    );
  };

  return (
    <>
      <label htmlFor={htmlId} className="VacancyFilter__label">
        {heading}
      </label>
      <FocusOn
        enabled={isDropdownOpen}
        onEscapeKey={handleDropdownClose}
        onClickOutside={handleDropdownClose}
        scrollLock={false}
        returnFocus
      >
        <div id={htmlId} className={classNames("CheckboxSelect", "VacancyFilter__select")}>
          <ButtonDropdown
            isOpened={isDropdownOpen}
            onToggle={setDropdownOpen}
            buttonProps={{ "aria-controls": `${htmlId}Dropdown`, "aria-expanded": isDropdownOpen }}
          >
            <Tooltip>
              <TooltipTrigger className="VacancyFiltersList__tooltipTrigger">
                <span id={`${htmlId}Label`} className="VacancyFilter__select--label">
                  {label}
                </span>
              </TooltipTrigger>
              <TooltipContent maxWidth={250} className="TechnicalSpecifications__tooltipContent">
                {label}
              </TooltipContent>
            </Tooltip>
          </ButtonDropdown>
          {renderDropdown()}
        </div>
      </FocusOn>
    </>
  );
};
