import * as React from "react";
import {ComponentProps} from "../ComponentProps.csharp";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {ReportsContainerViewModel} from "./ReportsContainerViewModel.csharp";
import {ReportViewModel} from "./ReportViewModel.csharp";
import {ReportTabContainer} from "./ReportTabContainer";
import {ReportType} from "./ReportTab";
import {Dictionary, NullableDictionary} from "../../react-components/Dictionary";

export interface ReportsContainerState {
    selectedReports: NullableDictionary<ReportViewModel>;
    selectedDates: Dictionary<Date>;
}

export class ReportsContainer extends React.Component<ComponentProps<ReportsContainerViewModel>, ReportsContainerState> {
    constructor(props: Readonly<ComponentProps<ReportsContainerViewModel>>) {
        super(props);

        let initialSelectedReports: Dictionary<ReportViewModel> = {};
        let initialSelectedDates: Dictionary<Date> = {};

        this.props.model.reports.forEach((report: ReportViewModel, reportType: number) => {
            initialSelectedReports[reportType] = report;
            initialSelectedDates[reportType] = new Date(report.validFrom);
        });

        this.state = {
            selectedReports: initialSelectedReports,
            selectedDates: initialSelectedDates,
        };
    }

    render() {
        const {selectedReports, selectedDates} = this.state;
        return (
            <div className="Reports">
                <Tabs>
                    <div className="wrapper">
                        <TabList>
                            {this.props.model.reports.map((report, index) => (
                                <Tab key={`report-title-${index}`}>
                                    <span>{report.title}</span>
                                </Tab>
                            ))}
                        </TabList>
                    </div>
                    {
                        Object.keys(selectedReports).map((key, index) => (
                            <TabPanel key={`report-content-${index}`}>
                                <div className="wrapper">
                                    <ReportTabContainer reportType={index} report={selectedReports[index]}
                                                        calendarVisible={this.props.model.allowChangingDates}
                                                        selectedDate={selectedDates[index]}
                                                        onReportChanged={(date, report) => this.onReportChanged(index, date, report)}
                                                        translations={this.props.model.translations}/>
                                </div>
                            </TabPanel>
                        ))
                    }
                </Tabs>
            </div>
        );
    }

    private onReportChanged(type: ReportType, date: Date, report: ReportViewModel | undefined): void {
        let {selectedDates, selectedReports} = this.state;

        selectedDates[type] = date;
        selectedReports[type] = report;

        this.setState({
            selectedDates: selectedDates,
            selectedReports: selectedReports
        })
    }
}