enum Slide {
    lazy = 'SlidesNavigation__slide--lazy',
    up = "SlidesNavigation__slide--up",
    down = "SlidesNavigation__slide--down",
    current = "SlidesNavigation__slide--current"
}

export const setSlidesAnimation = (section: HTMLElement, index: number, slidesLength: number, currentPage: number) => {
    const slideNumber = index + 1;
    const sectionClassList = section.classList;
    section.style.zIndex = `${slidesLength - slideNumber}`;

    //need to reset max slide number because of buggy sticky roll
    const currentSlide = currentPage > slidesLength
        ? slidesLength
        : currentPage < 1
            ? 1
            : currentPage;

    if (currentSlide == slideNumber) {
        sectionClassList.remove(Slide.lazy);
        sectionClassList.remove(Slide.up);
        sectionClassList.remove(Slide.down);
        sectionClassList.add(Slide.current);
    } else {
        if (sectionClassList.contains(Slide.current)) {
            sectionClassList.remove(Slide.current);
        }
        if (currentSlide > slideNumber && slideNumber !== slidesLength) {
            sectionClassList.remove(Slide.down);
            sectionClassList.add(Slide.up);
        }
        if (currentSlide < slideNumber) {
            sectionClassList.remove(Slide.up);
            sectionClassList.add(Slide.down);
        }
        if (currentSlide == slideNumber + 1 || currentSlide == slideNumber - 1) {
            sectionClassList.remove(Slide.lazy);
        }
    }
}

export const clamp = (value: number, min: number, max: number) => Math.max(Math.min(max, value), min)
