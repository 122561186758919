import { useContext } from "react";
import { ToastContext, ToastContextState } from "./ToastContextProvider";
import { ToastProps } from "./Toast";

export enum ToastReducerActionType {
  ADD_TOAST = "ADD_TOAST",
  DELETE_TOAST = "DELETE_TOAST",
}

export type ToastReducerAction =
  | {
      type: ToastReducerActionType.ADD_TOAST;
      toast: Optional<ToastProps, "toastId">;
    }
  | {
      type: ToastReducerActionType.DELETE_TOAST;
      id: number | string;
    };

export const toastReducer = (
  state: ToastContextState,
  action: ToastReducerAction,
): ToastContextState => {
  switch (action.type) {
    case ToastReducerActionType.ADD_TOAST: {
      const toastId = action.toast.toastId ?? state.index + 1;

      const foundToast = state.toasts.find((toast) => toast.toastId === toastId);

      if (foundToast) {
        return { ...state };
      }

      const toast = {
        ...action.toast,
        toastId: toastId,
      } as ToastProps;

      return {
        ...state,
        toasts: [...state.toasts, toast],
        index: typeof toastId === `number` ? toastId : state.index + 1,
      };
    }

    case ToastReducerActionType.DELETE_TOAST: {
      return {
        ...state,
        toasts: [...state.toasts.filter((toast) => toast.toastId !== action.id)],
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const useToast = () => useContext(ToastContext);
