import React, { SVGProps, memo } from "react";
export const ArrowRight = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12.75L5 12.75L5 11.25L20 11.25V12.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7626 11.8232L10.4697 3.53033L11.5303 2.46967L19.8232 10.7626C20.5066 11.446 20.5066 12.554 19.8232 13.2374L11.5303 21.5303L10.4697 20.4697L18.7626 12.1768C18.8602 12.0791 18.8602 11.9208 18.7626 11.8232Z"
      fill="currentColor"
    />
  </svg>
));

ArrowRight.displayName = "ArrowRight";
