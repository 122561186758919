import React from "react";
import { LinkViewModel } from "../../../Links/LinkViewModel.csharp";

export const DescriptionLink: React.FC<LinkViewModel> = ({
  href,
  text,
  target,
  title,
}) => {
  return (
    <a
      className="SideTextHotSpotsImage__DescriptionLink"
      href={href}
      target={target}
      title={title}
    >
      <span className="SideTextHotSpotsImage__DescriptionLinkArrow"/>
      <span className="SideTextHotSpotsImage__DescriptionLinkText">
          {text}
      </span>
    </a>
  );
};
