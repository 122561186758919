import { MomentInput } from "moment";
import moment from "moment-timezone";

export function formatDate(
  date: MomentInput,
  culture = "en-gb",
  format: string = "DD MMM YYYY",
  tz: string | null = null,
): string {
  moment.locale(mapCultureFromEpiServer(culture));
  if (tz) {
    return moment(date).tz(tz).format(format);
  }

  return moment(date).format(format);
}

export function mapCultureFromEpiServer(culture: string): string {
  switch (culture) {
    case "no":
      return "nb";
    case "en":
      return "en-gb";
    default:
      return culture;
  }
}

export function formatMonthsPeriod(start: Date, end?: Date): string {
  const startMoment = moment(start);
  const endMoment = moment(end);

  if (!end) return startMoment.format("D MMMM");

  return startMoment.month() === endMoment.month()
    ? formatSameMonthPeriod(startMoment, endMoment)
    : `${startMoment.format("D MMMM")} - ${endMoment.format("D MMMM")}`;
}

function formatSameMonthPeriod(start: moment.Moment, end: moment.Moment): string {
  return start.date() === end.date()
    ? `${end.date()} ${end.format("MMMM")}`
    : `${start.date()} - ${end.date()} ${end.format("MMMM")}`;
}
