import React, { FormEvent } from "react";
import { OfficeLocationsFiltersLocateMeButton } from "./OfficeLocationsFiltersLocateMeButton";
import { OfficeLocationsFiltersSearchInput } from "./OfficeLocationsFiltersSearchInput";
import { RadioSelect } from "../../../../react-components/Discovery/Inputs/RadioSelect/RadioSelect";
import {
  CheckboxGroup,
  CheckboxGroupOption,
} from "../../../../react-components/Inputs/CheckboxGroup/CheckboxGroup";
import { RadioSelectOption } from "../../../../react-components/Discovery/Inputs/RadioSelect/RadioSelect.types";
import { OnChangeValue } from "react-select";
import { OfficeLocationsListWithMapFiltersTranslations } from "../../OfficeLocationsListWithMapFiltersTranslations.csharp";
import classNames from "classnames";

interface OfficeLocationsFiltersProps {
  types: CheckboxGroupOption[];
  categories: RadioSelectOption[];
  translations: OfficeLocationsListWithMapFiltersTranslations;
  onAutocompleteLocationSelection: (place: google.maps.places.PlaceResult) => void;
  locateMeHandler: () => Promise<void>;
  selectedCategory?: RadioSelectOption;
  setSelectedCategory?: (category: RadioSelectOption) => void;
  selectedTypes?: CheckboxGroupOption[];
  setSelectedTypes?: (newValues: CheckboxGroupOption[]) => void;
  searchQuery?: string;
  setSearchQuery?: (searchQuery: string) => void;
  locationSearchHandler?: (searchQuery: string) => void;
  loaded?: boolean;
  userLocationDisabled?: boolean;
  useAdvancedFilters?: boolean;
}

export const OfficeLocationsFilters = ({
  types,
  categories,
  translations,
  onAutocompleteLocationSelection,
  selectedCategory,
  setSelectedCategory,
  selectedTypes,
  setSelectedTypes,
  searchQuery,
  setSearchQuery,
  locateMeHandler,
  locationSearchHandler,
  loaded,
  userLocationDisabled,
  useAdvancedFilters = true,
}: OfficeLocationsFiltersProps) => {
  const onCategoryChange = (newValue: OnChangeValue<RadioSelectOption, false>) => {
    if (setSelectedCategory && newValue) setSelectedCategory(newValue);
  };

  const handleSearchSubmit = (event?: FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    if (searchQuery?.length && locationSearchHandler) {
      locationSearchHandler(searchQuery);
    }
  };

  const categoryFilterComponent = categories.length > 0 && (
    <RadioSelect
      name="category"
      className="OfficeLocationsFilters__categoryFilter"
      title={translations.partners.title}
      options={categories}
      placeholder={translations.partners.placeholder}
      hiddenLabel={translations.partners.label}
      currentValue={selectedCategory}
      onChange={onCategoryChange}
    />
  );

  return (
    <form
      className={classNames(
        "OfficeLocationsFilters",
        useAdvancedFilters && "OfficeLocationsFilters--hasAdvancedFilters",
      )}
      onSubmit={handleSearchSubmit}
    >
      <div className="OfficeLocationsFilters__searchRow">
        <OfficeLocationsFiltersSearchInput
          onAutocompleteLocationSelection={onAutocompleteLocationSelection}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearchSubmit={handleSearchSubmit}
          translations={translations.searchBox}
        />
        <OfficeLocationsFiltersLocateMeButton
          label={translations.locateMe}
          locateMeHandler={locateMeHandler}
          loaded={loaded}
          userLocationDisabled={userLocationDisabled}
          cannotAccessLocation={translations.cannotAccessLocation}
          noLocationPermission={translations.noLocationPermission}
        />
        {!useAdvancedFilters && categoryFilterComponent}
      </div>
      {useAdvancedFilters && (
        <div className="OfficeLocationsFilters__filtersRow">
          {categoryFilterComponent}
          <CheckboxGroup
            name="type"
            className="OfficeLocationsFilters__typesFilter"
            options={types}
            currentValues={selectedTypes}
            onChange={setSelectedTypes}
            inline
            hiddenLabel={translations.selectOfficeTypes}
          />
        </div>
      )}
    </form>
  );
};
