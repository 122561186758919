import React, { FC, useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";

import { MicrositeMenuBar } from "./MenuBar/MicrositeMenuBar";
import { MicrositeLandingPage } from "./LandingPage/MicrositeLandingPage";
import { MicrositeSection } from "./Section/MicrositeSection";
import { MicrositeLogo } from "./Logo/MicrositeLogo";
import { MicrositeFooter } from "./LandingPage/Footer/MicrositeFooter";

import { MicrositeSectionViewModel } from "./Section/MicrositeSectionViewModel.csharp";
import { MicrositeParams } from "./MicrositeParams.csharp";
import { ComponentProps } from "../Partials/ComponentProps.csharp";
import { ResponsivePictureProps } from "../Images/ResponsivePictureProps.csharp";
import { MicrositeContext } from "./Utils/micrositeContext";
import { delayedCallback } from "./Utils/delayedCallback";

export const Microsite: FC<ComponentProps<MicrositeParams>> = ({
  model: { landingPage, qrCodeImageUrl, sections, theme },
}) => {
  const [currentSection, setCurrentSection] = useState<MicrositeSectionViewModel | null>(null);
  const [currentBackground, setCurrentBackground] = useState<ResponsivePictureProps>(landingPage.pictureProps);

  useEffect(() => {
    goToLandingPage();
  }, []);

  const goToLandingPage = () => {
    setCurrentSection(null);
    delayedCallback(() => setCurrentBackground(landingPage.pictureProps))
  };

  const goToSection = (id: number) => {
    const section = sections.find((section) => section.id == id);
    if (section) {
      setCurrentSection(section);
      delayedCallback(() => setCurrentBackground(section.pictureProps));
    }
  };

  return (
    <MicrositeContext theme={theme}>
      <MicrositeLogo className="Microsite__logo" mobileOnly />
      <MicrositeMenuBar
        sections={sections}
        goToSection={goToSection}
        backgroundImage={currentBackground}
        isLandingPage={currentSection == null}
        itemSingularLabel={landingPage.itemSingularLabel}
        itemPluralLabel={landingPage.itemPluralLabel}
      />
      <div className="Microsite__inner">
        <MicrositeLandingPage {...landingPage} goToSection={goToSection} />
        <AnimatePresence>
          {currentSection && currentSection.id && (
            <MicrositeSection
              {...currentSection}
              key={currentSection.id}
              goBack={goToLandingPage}
            />
          )}
        </AnimatePresence>
      </div>
      <MicrositeFooter
        qrCodeUrl={qrCodeImageUrl}
        isLandingPage={currentSection == null}
      />
    </MicrositeContext>
  );
};
