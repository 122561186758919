import { useState } from "react";

export const useClipboard = () => {
  const [copiedData, setCopiedData] = useState<string | null>(null);

  const handleError = () => {
    setCopiedData(null);
  };

  const copy = (data: string) => {
    const handler = async () => {
      try {
        await navigator.clipboard.writeText(data);
        setCopiedData(data);
      } catch (error) {
        handleError();
      }
    };

    handler().catch(handleError);
  };

  return { copiedData, copy };
};
