import { FloatingContext, ElementProps } from "@floating-ui/react";
import { useCallback, useMemo } from "react";

interface UseDismissAfterDelayProps {
  enabled?: boolean;
  delayMs: number;
}

export const useDismissAfterDelay = (
  context: FloatingContext,
  { enabled = true, delayMs }: UseDismissAfterDelayProps,
): ElementProps => {
  const handleClick = useCallback(() => {
    if (!enabled) return;

    setTimeout(() => {
      context.onOpenChange(false);
    }, delayMs);
  }, [context, delayMs, enabled]);

  const referenceProps = useMemo(
    () => ({
      onClick: handleClick,
    }),
    [handleClick],
  );

  return useMemo(
    () => ({
      reference: referenceProps,
    }),
    [referenceProps],
  );
};
