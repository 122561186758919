import React, { FC } from "react";
import { ComponentProps } from "../../ComponentProps.csharp";
import { CatalogListViewModel } from "./CatalogListViewModel.csharp";
import { ButtonFill } from "../../../react-components/Buttons/ButtonFill";
import { HeroTile } from "../../../react-components/TileGrid/Tiles/HeroTile/HeroTile";
import { CatalogItemViewModel } from "./CatalogItemViewModel.csharp";

interface CatalogListProps extends ComponentProps<CatalogListViewModel> {
  onClick?: (item: CatalogItemViewModel) => void;
}

export const CatalogList: FC<CatalogListProps> = ({ model: { catalogItems, cta }, onClick }) => {
  return (
    <div className="CatalogList wrapper">
      <ul className="CatalogList__list">
        {catalogItems.map((item, index) => (
          <li key={index} className="CatalogList__listItem">
            <HeroTile
              href={item.productCatalogItemUrl}
              title={item.title}
              description={item.description}
              picture={item.picture}
              onClick={onClick ? () => onClick(item) : undefined}
            />
          </li>
        ))}
      </ul>
      <div className="wrapper ContentListTilesBlock__cta">
        {cta && cta.href && cta.text && (
          <ButtonFill
            as="a"
            color={cta.color ?? "teal"}
            target={cta.target ?? "_blank"}
            href={cta.href}
          >
            {cta.text}
          </ButtonFill>
        )}
      </div>
    </div>
  );
};
