/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from "react";
import { throttle } from "lodash";
import classNames from "classnames";
import { ComponentProps } from "../ComponentProps.csharp";
import { NavigationRootType } from "../../Navigation/NavigationRootType.csharp";
import { PageType } from "../../Navigation/PageType.csharp";
import { HeaderViewModel } from "./HeaderViewModel.csharp";
import { TopNav } from "./TopNav";
import { Nav } from "../Nav/Nav";
import { MainBreadcrumbs } from "../Breadcrumbs/MainBreadcrumbs/MainBreadcrumbs";
import { SiteLogoHorizontalKog } from "./Partials/SiteLogoHorizontalKog";
import { SiteLogoKsat } from "./Partials/SiteLogoKsat";
import { useGlobalStore } from "../../stores/globalStore";
import { useComputedStyle } from "../../react-components/hooks/useComputedStyle";

const THRESHOLD_FOR_TRANSPARENCY_STATE_CHANGE = 50;

export const Header: FC<ComponentProps<HeaderViewModel>> = ({ model }) => {
  const logoRef = useRef<HTMLAnchorElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const { setHeight } = useGlobalStore((state) => state.layout.header);

  const [isBackdropVisible, setIsBackdropVisible] = useState<boolean>(false);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState<boolean>(false);
  const [isAtTop, setIsAtTop] = useState<boolean>(true);

  useComputedStyle(headerRef, (styles) => setHeight(parseInt(styles.height)));

  const { navigation, breadcrumbs } = model;
  const isKsat = navigation?.navigationRootType === NavigationRootType.Ksat;

  const scrollHandle = (): void => {
    let scrollTop;
    if (document.body && document.documentElement) {
      const scrollTopBody = document.body.scrollTop;
      const scrollTopDocumentElement = document.documentElement.scrollTop;
      scrollTop = Math.max(scrollTopBody, scrollTopDocumentElement);
    } else {
      scrollTop = (document.body || document.documentElement).scrollTop;
    }
    setIsAtTop(scrollTop <= THRESHOLD_FOR_TRANSPARENCY_STATE_CHANGE);
  };

  useEffect(() => {
    if (typeof window === "undefined") return;
    scrollHandle();
    window.addEventListener("scroll", throttle(scrollHandle, 125));
    return (): void => window.removeEventListener("scroll", throttle(scrollHandle, 125));
  }, []);

  if (!navigation?.sections?.length) {
    return (
      <div className="Header" ref={headerRef}>
        <div className="Header__main">
          <div className="Header__mainInner">
            <div className="Header__logoAndNav">
              <a className="Header__logo" href="#">
                {isKsat ? <SiteLogoKsat /> : <SiteLogoHorizontalKog />}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const isHomePageBase = navigation.pageVariant === PageType.HomePageBase;
  const renderBreadcrumbs = !isHomePageBase && !!breadcrumbs.ancestors?.length;
  const isTransparent =
    isHomePageBase && navigation.isHeroVisible && !isMobileMenuOpened && isAtTop;

  return (
    <div
      className={classNames("Header", {
        "Header--isTransparent": isTransparent,
        "Header--hasBreadcrumbs": renderBreadcrumbs,
        "Header--withoutTopNavOnMobile": !navigation.hasTopNavTitle,
      })}
      ref={headerRef}
    >
      <div
        className={classNames("Header__backdrop", {
          "Header__backdrop--visible": isBackdropVisible,
        })}
      />
      <a className="SkipToContent" href="#maincontent">
        Skip to main content
      </a>
      <TopNav model={navigation} />
      <div className="Header__main">
        <div className="Header__mainInner">
          <div className="Header__logoAndNav">
            <a className="Header__logo" href={navigation.businessAreaUrl} ref={logoRef}>
              {navigation.siteLogoUrl && navigation.siteLogoInvertedUrl ? (
                <>
                  <img
                    className={classNames("Header__logoInner", {
                      "Header__logoInner--visible": isTransparent,
                    })}
                    src={navigation.siteLogoUrl}
                    alt="logo"
                  />
                  <img
                    className={classNames("Header__logoInner", {
                      "Header__logoInner--visible": !isTransparent,
                    })}
                    src={navigation.siteLogoInvertedUrl}
                    alt="logo"
                  />
                </>
              ) : isKsat ? (
                <SiteLogoKsat />
              ) : (
                <SiteLogoHorizontalKog />
              )}
              <span className="visuallyHidden">{navigation.businessAreaName}</span>
            </a>
            <Nav
              model={navigation}
              isMobileMenuOpened={isMobileMenuOpened}
              setIsMobileMenuOpened={setIsMobileMenuOpened}
              setIsBackdropVisible={setIsBackdropVisible}
              ref={logoRef}
              isTransparent={isTransparent}
            />
          </div>
        </div>
      </div>
      {renderBreadcrumbs && <MainBreadcrumbs model={breadcrumbs} />}
    </div>
  );
};
