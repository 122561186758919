import React, { ComponentPropsWithRef, ComponentType, ElementType, forwardRef } from "react";
import { ComponentAs, ComponentAsProps } from "../../types/ComponentAs";
import { upperFirst } from "lodash";
import classNames from "classnames";

export type LinkType = "normal" | "icon";
export type LinkMode = "light" | "dark";
export type LinkIconPosition = "left" | "right";

interface LinkBaseProps<C extends ElementType = "a"> extends ComponentAsProps<C> {
  title?: string;
  className?: string;
  type?: LinkType;
  mode?: LinkMode;
  iconPosition?: LinkIconPosition;
  icon?: ComponentType;
}

export type LinkProps<C extends ElementType> = ComponentAs<C, LinkBaseProps<C>>;

export type LinkComponent = <C extends ElementType = "a">(
  props: LinkProps<C>,
) => React.ReactElement | null;

export const Link: LinkComponent = forwardRef(function Link<C extends ElementType = "a">(
  {
    className,
    title,
    type = "normal",
    mode = "light",
    iconPosition = "left",
    icon,
    as,
    children,
    ...props
  }: LinkProps<C>,
  ref?: ComponentPropsWithRef<C>["ref"],
) {
  const baseClassName = "Link";
  const Component = as || "a";

  const modifiers = {
    [`${baseClassName}--icon`]: type === "icon",
    [`${baseClassName}--icon${upperFirst(iconPosition)}`]:
      type === "normal" && iconPosition && icon,
    [`${baseClassName}--darkMode`]: mode === "dark",
  };

  const IconComponent = icon;

  const linkContent = (
    <>
      {IconComponent && iconPosition === "left" ? <IconComponent /> : null}
      {title || children}
      {IconComponent && iconPosition === "right" ? <IconComponent /> : null}
    </>
  );

  return (
    <Component className={classNames(baseClassName, modifiers, className)} {...props} ref={ref}>
      {linkContent}
    </Component>
  );
});
