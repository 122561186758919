import React, { useState } from 'react';
import { FullScreenVideoModal } from '../../react-components/Modal/FullScreenVideoModal';
import { ComponentProps } from '../ComponentProps.csharp';
import { HeroVideoViewModel } from './HeroVideoViewModel.csharp';

export const HeroVideo = ({ model }: ComponentProps<HeroVideoViewModel>) => {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);

  const openModal = () => setVideoModalOpen(true);

  const closeModal = () => setVideoModalOpen(false);

  return (
    <>
      <button className="Hero__playVideoButton" onClick={openModal}>See the film</button>
      <FullScreenVideoModal 
        model={model}
        isOpen={isVideoModalOpen}
        onClose={closeModal}
      />
    </>
  )
};
