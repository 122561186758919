import React from "react";
import { InteractiveHeroHotSpotViewModel } from "./InteractiveHeroHotSpotViewModel.csharp";

export const HotSpot: React.FC<InteractiveHeroHotSpotViewModel> = ({
  targetUrl,
  text,
  iconSvg,
}) => {
  return (
    <a className="InteractiveHeroBlock__Link" href={targetUrl}>
      <span className="InteractiveHeroBlock__Text">{text}</span>
      <div className="InteractiveHeroBlock__Icon">
        <div className="InteractiveHeroBlock__Inner">
          <span dangerouslySetInnerHTML={{ __html: iconSvg }} />
        </div>
      </div>
    </a>
  );
};
