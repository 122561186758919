import React, { useEffect, useRef, useState } from "react";

interface FocusControlProps {
  onLoseFocus: () => void;
  children: React.ReactNode;
  className?: string;
}

export const FocusControl = ({ onLoseFocus, children, className }: FocusControlProps) => {
  const _timeoutID = useRef<ReturnType<typeof setTimeout>>();

  const [isManagingFocus, setIsManagingFocus] = useState<boolean>(false);

  const _onBlur = () => {
    _timeoutID.current = setTimeout(() => {
      if (isManagingFocus) {
        setIsManagingFocus(false);
        onLoseFocus();
      }
    }, 0);
  };

  const _onFocus = () => {
    if (_timeoutID.current) {
      clearTimeout(_timeoutID.current);
    }
    if (!isManagingFocus) {
      setIsManagingFocus(true);
    }
  };

  const _onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      onLoseFocus();
    }
  };

  useEffect(() => {
    return () => {
      if (_timeoutID.current) {
        clearTimeout(_timeoutID.current);
      }
    }
  }, []);

  return (
    <div
      onBlur={_onBlur}
      onFocus={_onFocus}
      onKeyDown={_onKeyDown}
      className={className}
    >
      {children}
    </div>
  );
}