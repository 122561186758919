//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ArrowDirection {
	RightUp = 0,
	LeftUp = 1,
	RightDown = 2,
	LeftDown = 3
}
