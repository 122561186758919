import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType, MicrositeHotSpot } from '../../HotSpot/MicrositeHotSpot';

import { MicrositeProductHotSpotType } from '../../../../Models/Microsite/MicrositeProductHotSpotType.csharp';
import { MicrositeProductHotSpotViewModel } from '../MicrositeProductHotSpotViewModel.csharp';
import { ResponsivePicture } from '../../../react-components/responsivePicture/responsivePicture';
import { useIsBelowScreenSize } from '../../../react-components/useIsBelowScreenSize';
import { PictureProfileDto } from "../../../Images/Resizing/PictureProfileDto.csharp";

interface ProductHotSpotsProps {
  hotSpots: MicrositeProductHotSpotViewModel[];
  profile: PictureProfileDto;
  toggleMedia: (type: MicrositeProductHotSpotType) => void;
}

export const ProductHotSpots: FC<ProductHotSpotsProps> = ({
  hotSpots,
  toggleMedia,
  profile
}) => {
  const isBellowTablet = useIsBelowScreenSize('tablet');
  
  return (
    <ul className="MicrositeProduct__list">
      {hotSpots.map((hotSpot, index) => (
        <li
          className="MicrositeProduct__listItem"
          key={`hotSpot-${index}`}
          style={{ top: `${hotSpot.y}%`, left: `${hotSpot.x}%` }}
        >
          <MicrositeHotSpot
            title={MicrositeProductHotSpotType[hotSpot.type]}
            shouldDisplayPreviewAfterClick={!isBellowTablet}
            onHotSpotClick={isBellowTablet ? () => toggleMedia(hotSpot.type) : undefined}
            onPreviewClick={() => toggleMedia(hotSpot.type)}
            icon={MicrositeProductHotSpotType[hotSpot.type].toLowerCase() as IconType}
            popUpContentClassName="MicrositeProductHotSpot"
            variant='clear'
            size='small'
            mobileBehavior="visible"
            pulseDelay={`-${index}s`}
          >
            <span className="MicrositeProductHotSpot__inner">
              <span className="MicrositeProductHotSpot__content">
                <span className="MicrositeProductHotSpot__preview">
                  {hotSpot.previewLabel}
                </span>
                <span
                  className={classNames(
                    "MicrositeProductHotSpot__contentIcon",
                    `MicrositeProductHotSpot__contentIcon--${MicrositeProductHotSpotType[hotSpot.type].toLowerCase()}`,
                  )} />
              </span>
              {hotSpot.previewImage && hotSpot.previewImage.url && (
                <span className="MicrositeProductHotSpot__image">
                  <ResponsivePicture model={{ ...hotSpot.previewImage }} profile={profile} />
                </span>
              )}
            </span>
          </MicrositeHotSpot>
        </li>
      ))}
    </ul>
  )
};
