import * as React from "react";
import * as imageSizeCalculator from "./imageSizeCalculator";
import { SourceType, getResizedImageUrl } from "../../react-components/responsivePicture/responsivePicture";
import { ResponsivePictureViewModel } from "../../Images/ResponsivePictureViewModel.csharp";
import {ScaleModeDto} from "../../Images/Resizing/ScaleModeDto.csharp";
import {PictureSourceDto} from "../../Images/Resizing/PictureSourceDto.csharp";
import {ResizedImageFormatDto} from "../../Images/Resizing/ResizedImageFormatDto.csharp";
import { Swipeable } from "../../react-components/Swipeable/Swipeable";

export interface LightBoxScrollListState {
    imageIndex: number,
    thumbnail: Thumbnail
}

export interface Thumbnail {
    size: number,
}

export interface LightBoxScrollListProps {
    images: ResponsivePictureViewModel[],
    pageSize: number,
    maxHeight: number,
    onImageOpen: (url: string, index: number) => void,
    noImagesMessage: string
}

export default class LightBoxScrollList extends React.Component<LightBoxScrollListProps, LightBoxScrollListState> {
    constructor(props: LightBoxScrollListProps) {
        super(props);

        const thumbnail = imageSizeCalculator.calculateSizes(props.images, props.maxHeight);

        this.state = {
            imageIndex: 0,
            thumbnail
        };
        this.showPreviousImage = this.showPreviousImage.bind(this);
        this.showNextImage = this.showNextImage.bind(this);
        this.getResponsiveUrl = this.getResponsiveUrl.bind(this);
    }

    private showPreviousImage(): void {
        const { imageIndex } = this.state;
        const { images:{length:imageCount}, pageSize } = this.props;

        const newIndex = Math.max(imageIndex - pageSize, 0);
        this.setState({
            imageIndex: newIndex,
        });
    }

    private showNextImage(): void {
        const { imageIndex } = this.state;
        const { images:{length:imageCount}, pageSize } = this.props;
        
        const delta = Math.min(pageSize, imageCount - (imageIndex+pageSize));
        this.setState({
            imageIndex: this.state.imageIndex + delta,
        });
    }

    private getResponsiveUrl(image: ResponsivePictureViewModel): string {
        const { thumbnail } = this.state;
        const cropSource: PictureSourceDto = {
            width: thumbnail.size,
            height: thumbnail.size,
            // props below are not used, filling to match method definition
            mediaCondition: '', mode: 0, quality: 0,
            sourceType: SourceType.PictureSource,
        };
        return getResizedImageUrl(image.url, ResizedImageFormatDto.Jpeg, thumbnail.size, thumbnail.size, ScaleModeDto.Crop, 50, image.responsiveImageData.focalPoint);
    }

    render() {
        const { images, pageSize, onImageOpen, noImagesMessage, maxHeight } = this.props;
        const { imageIndex } = this.state;

        if (!images || images.length < 1) {
            return <p>{noImagesMessage}</p>;
        }
        const endIndex = imageIndex + pageSize;
        const imagesToDisplay = images.slice(imageIndex, endIndex);

        const canGetPreviousPage = imageIndex > 0;
        const canGetNextPage = endIndex < images.length;
    
        const swipeConfig = {
            trackMouse: true,
            delta: 30,
            onSwipedRight: () =>{
                if (canGetPreviousPage){
                    this.showPreviousImage()
                }
            },
            onSwipedLeft: () =>{
                if (canGetNextPage){
                    this.showNextImage()
                }
            }
        };
        return (
                <Swipeable {...swipeConfig} className="Lightbox__container">
                    <div className="Lightbox__container-items">
                        <div className="Lightbox__container-images">
                            {
                                imagesToDisplay.map((image, i) =>
                                    <div className="Lightbox__image" onClick={() => onImageOpen(image.url, i)}
                                         key={i + imageIndex} style={{maxWidth: maxHeight}}>
                                        <img alt={image.alt || ''} src={this.getResponsiveUrl(image)}
                                             draggable={false}/>
                                    </div>)
                            }
                        </div>
                    </div>
                </Swipeable>
            );
    }
}