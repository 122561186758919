import React, { ComponentType, ReactNode, useState } from "react";
import classNames from "classnames";
import { ButtonOutlined } from "../ButtonOutlined";
import { ButtonProps } from "../ButtonBase";
import { Chevron } from "../../Icons/Chevron";

interface ButtonDropdownProps {
  children?: ReactNode;
  buttonProps?: ButtonProps<"button">;
  ButtonComponent?: ComponentType<ButtonProps<"button">>;
  renderButton?: ReactNode;
  isOpened?: boolean;
  onToggle?: (isOpened: boolean) => void;
}

export const ButtonDropdown = ({
  children,
  buttonProps,
  ButtonComponent = ButtonOutlined,
  renderButton,
  isOpened,
  onToggle,
}: ButtonDropdownProps) => {
  const [__isOpened, setOpened] = useState(isOpened);

  const handleClick = () => {
    if (isOpened === undefined) {
      setOpened((opened) => {
        const newValue = !opened;

        if (onToggle) {
          onToggle(newValue);
        }

        return newValue;
      });
    } else if (onToggle) {
      const newValue = !isOpened;

      onToggle(newValue);
    }
  };

  const buttonContents = () => {
    if (renderButton) {
      return renderButton;
    }

    const defaultProps = {
      className: classNames("ButtonDropdown", { "ButtonDropdown--opened": isOpened ?? __isOpened }),
      icon: Chevron,
      iconPosition: "right",
      children,
      onClick: handleClick,
    } as ButtonProps<"button">;

    const newButtonProps = { ...defaultProps, ...buttonProps };

    return <ButtonComponent as="button" {...newButtonProps} />;
  };

  return <>{buttonContents()}</>;
};
