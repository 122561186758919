import * as React from 'react';
import classNames from 'classnames';
import {ComponentProps} from "../../Partials/ComponentProps.csharp";
import {ClosePageButtonProps} from "../../closePageButton/ClosePageButtonProps.csharp";
import {getBrowserHandler, IBrowserHandler} from "../browserHandlers";
import {debounce} from "throttle-debounce";

interface ClosePageButtonState {
    isHidden: boolean;
    scrollPosition: number;
}

export class ClosePageButton extends React.Component <ComponentProps<ClosePageButtonProps>, ClosePageButtonState> {
    scrollHandler: debounce<() => void>;
    browserHandler: IBrowserHandler | undefined;
    
    constructor(props: ComponentProps<ClosePageButtonProps>) {
        super(props);
        this.scrollHandler = debounce(50, this.onScroll);
        
        this.state = {
            isHidden: false,
            scrollPosition: 0
        };
    }
    
    componentDidMount(): void {
        
        // opened in new tab
        if (history.length === 1){
            history.replaceState({newTab:true}, document.title, location.href)
        }
        
        this.browserHandler = getBrowserHandler();
        this.setState({
            scrollPosition: window.pageYOffset
        });
        
        this.browserHandler!.addEventListener('scroll', this.scrollHandler);
    }
    
    componentWillUnmount(): void {
        this.browserHandler!.removeEventListener('scroll', this.scrollHandler);
    }

    private onClick = () => {
        if (history.state && history.state.newTab){
            location.href = this.props.model.fallbackUrl;
        }
        if (document.referrer.indexOf(document.location.origin) === 0) {
            window.history.back();
        } else {
            location.href = this.props.model.fallbackUrl;
        }
    };
    
    private onScroll = () => {
        const scrollYOffset = this.browserHandler!.getScrollYOffset();
        this.setState(state => ({
            scrollPosition: scrollYOffset,
            isHidden: scrollYOffset > state.scrollPosition
        }));
    };
    
    render() {
        return <a className={classNames("ClosePage__button", this.state.isHidden && "ClosePage__button--hide")} onClick={this.onClick}/>;
    }
}