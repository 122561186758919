import * as React from "react";
import {ComponentProps} from "../ComponentProps.csharp";
import {ReportSectionViewModel} from "./ReportSectionViewModel.csharp";
import {ChartTile} from "./ChartTile";
import {PieChart} from "./PieChart";

export class ReportSection extends React.Component<ComponentProps<ReportSectionViewModel>> {
    constructor(props: Readonly<ComponentProps<ReportSectionViewModel>>) {
        super(props);
    }

    render() {
        return (
            <section className="ReportsSection Section--marginBottom">
                <h4 className="ReportsSection__Title">{this.props.model.sectionName}</h4>

                <ul className="cardsList__3col">
                    {this.props.model.tiles.map((tile, index) => (
                            <li key={`tile-${index}`}>
                                <ChartTile model={tile}>
                                    <PieChart model={tile}/>
                                </ChartTile>
                            </li>
                        )
                    )}
                </ul>
            </section>
        );
    }
}