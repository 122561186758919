import React, { FC } from "react";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { KeyFeaturesSectionViewModel } from "./KeyFeaturesSectionViewModel.csharp";

export const KeyFeaturesBlock: FC<ComponentProps<KeyFeaturesSectionViewModel>> = ({
  model: { features },
}) => {
  return (
    <ul className="KeyFeaturesBlock">
      {features.map((feature, idx) => (
        <li key={idx} className="KeyFeaturesBlock__item">
          {feature}
        </li>
      ))}
    </ul>
  );
};
