import React, { FC } from "react";
import { ButtonFill } from "../../../../react-components/Buttons/ButtonFill";
import { EventCampaignPageHeaderButtonsProps } from "./EventCampaignPageHeaderButtonsProps.csharp";
import { ComponentProps } from "../../../../Partials/ComponentProps.csharp";

export const EventCampaignPageHeaderButtons: FC<
  ComponentProps<EventCampaignPageHeaderButtonsProps>
> = ({ model: { signUpText } }) => {
  return (
    <div className="EventCampaignPageHeader__buttons">
      <ButtonFill color="blue">{signUpText}</ButtonFill>
    </div>
  );
};
