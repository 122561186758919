import Splide, { ComponentConstructor, Options } from "@splidejs/splide";
import { merge } from "lodash";
import { ArrowsPosition } from "./ArrowsPosition.csharp";

export abstract class ContentListBlockSliderBase<T extends HTMLElement> {
  private static __id = 0;
  public static SLIDER_ELEMENT = ".ContentListSlider";
  public static SPLIDE_ELEMENT = ".ContentListSlider__slider";

  private instanceId;
  private __splide: Splide | null = null;
  protected splideElement: HTMLElement | null = null;
  protected slideWidth = 0;
  protected arrowsPosition = ArrowsPosition.Bottom;

  protected TRANSITION_SPEED = 300;
  private DEFAULT_OPTIONS: Options = {
    type: "slide",
    speed: this.TRANSITION_SPEED,
    mediaQuery: "min",
    classes: {
      arrows: "splide__arrows ContentListSlider__arrows",
      arrow: "splide__arrow ContentListSlider__arrow",
      prev: "splide__arrow--prev ContentListSlider__arrow--prev",
      next: "splide__arrow--next ContentListSlider__arrow--next",
      pagination: "splide__pagination ContentListSlider__pagination",
      page: "splide__pagination__page ContentListSlider__page",
    },
    omitEnd: true,
    flickPower: 500,
    focus: 0,
  };

  protected options: Options = this.DEFAULT_OPTIONS;

  public constructor(splideElement: T | null, arrowsPosition?: ArrowsPosition) {
    this.splideElement = splideElement;

    if (arrowsPosition) {
      this.arrowsPosition = arrowsPosition;
    }

    this.instanceId = this.getNextInstanceId();

    this.initializeSlider();
  }

  protected initializeSlider(instance?: Splide) {
    if (instance) {
      this.__splide = instance;
    } else if (this.splideElement) {
      this.__splide = new Splide(this.splideElement, this.getOptions());
    }
  }

  protected get splide() {
    if (!this.__splide)
      throw new Error("Cannot access 'splide' before calling 'initializeSlider()'!");

    return this.__splide;
  }

  private getNextInstanceId() {
    return ++ContentListBlockSliderBase.__id;
  }

  public getSliderId() {
    return `ContentListBlockSlider${this.instanceId}`;
  }

  public mount() {
    this.splide.mount(this.getExtensions());
  }

  protected isInitialized() {
    const splide = this.__splide;

    return (
      !!splide && (splide.state.is(Splide.STATES.MOUNTED) || splide.state.is(Splide.STATES.IDLE))
    );
  }

  public getOptions() {
    if (this.isInitialized() && this.splide.state.is(Splide.STATES.IDLE)) {
      return this.splide.options;
    }

    if (this.options === this.getDefaultOptions()) return this.options;

    return merge({}, this.getDefaultOptions(), this.options);
  }

  private getDefaultOptions() {
    return this.DEFAULT_OPTIONS;
  }

  protected saveOptions(options: Options) {
    if (this.isInitialized()) {
      this.splide.options = options;
    } else {
      this.options = merge({}, this.getOptions(), options);
    }
  }

  public getExtensions() {
    return {
      ContentListBlockSlider: this.getComponent(this),
    };
  }

  protected abstract getComponent(slider: this): ComponentConstructor;

  protected handleResize() {
    if (this.arrowsPosition === ArrowsPosition.Center) {
      this.setArrowsPosition();
    }
  }

  protected getSlides(excludeClones = false) {
    return this.splide.Components.Slides.get(excludeClones);
  }

  protected getSlide(index = 0) {
    let slide = undefined;

    if (this.splide.length > 0) {
      slide = this.splide.Components.Slides.getAt(index);
    }

    return slide;
  }

  protected getSlideHeight(index = 0) {
    if (this.splide.length > 0) {
      const slide = this.getSlide(index);

      if (slide) return slide.slide.clientHeight;
    }

    return 0;
  }

  protected calculateArrowsPosition() {
    if (this.splide.length > 0) {
      const slideHeight = this.getSlideHeight();

      if (slideHeight) return slideHeight / 2;
    }

    return 0;
  }

  public setArrowsPosition() {
    const arrowsHeight = this.calculateArrowsPosition();

    if (arrowsHeight > 0 && this.splide.Components.Elements.arrows) {
      this.splide.Components.Elements.arrows.style.top = `${arrowsHeight}px`;
    }
  }
}
