import React, { SVGProps, memo } from "react";
export const Reset = memo(function Reset(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 25 25"
      {...props}
    >
      <path
        d="M18.6832 12.038C18.6832 15.681 15.73 18.6342 12.0871 18.6342C8.44416 18.6342 5.49097 15.681 5.49097 12.038C5.49097 8.39508 8.44416 5.44189 12.0871 5.44189C14.4846 5.44189 16.5833 6.72098 17.7381 8.63397"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M13.3328 9.08984L17.932 9.08985C18.1529 9.08985 18.332 8.91076 18.332 8.68985L18.332 4.09059"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
});
