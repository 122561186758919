import * as React from "react";
import classNames from "classnames";

export const Loader = (props: { color?: loaderColor }) => {
    let loaderStyles = props.color ? props.color : loaderColor.Blue;
    return (
        <div className={'Loader ' + loaderStyles}>
            <div></div>
            <div></div>
            <div></div>
            <span className="visuallyHidden" aria-live="assertive">Please wait, populating data</span>
        </div>
    )
};

export enum loaderColor {
    Blue = 'Loader__blue',
    Navy = 'Loader__navy',
}
