import React, { FormEvent, useState } from "react";
import { CheckboxSelect } from "../../react-components/Inputs/CheckboxSelect/CheckboxSelect";
import { SelectOption } from "../../react-components/Inputs/SelectOption";
import { useIsBelowScreenSize } from "../../react-components/useIsBelowScreenSize";
import { ButtonOutlined } from "../../react-components/Buttons/ButtonOutlined";
import { ResourcesFiltersModal } from "./ResourcesFiltersModal";
import { SortBy } from "../../react-components/Icons/SortBy";
import { FiltersCount } from "../../react-components/FiltersCount/FiltersCount";
import { ButtonGhost } from "../../react-components/Buttons/ButtonGhost";
import { Reset } from "../../react-components/Discovery/Icons/Reset";
import { ResourcesFiltersViewModel } from "./ViewModels/ResourcesFiltersViewModel.csharp";

interface ResourcesFiltersProps {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  selectedCategories: SelectOption[];
  setSelectedCategories: (newValues: SelectOption[]) => void;
  selectedTopics: SelectOption[];
  setSelectedTopics: (newValues: SelectOption[]) => void;
  selectedBusinessUnits: SelectOption[];
  setSelectedBusinessUnits: (newValues: SelectOption[]) => void;
  filters: ResourcesFiltersViewModel;
}

export const ResourcesFilters = ({
  handleSubmit,
  selectedCategories,
  setSelectedCategories,
  selectedTopics,
  setSelectedTopics,
  selectedBusinessUnits,
  setSelectedBusinessUnits,
  filters: { category: categoryFilters, topic: topicFilters, businessUnit: businessUnitFilters },
}: ResourcesFiltersProps) => {
  const [showFilters, setShowFilters] = useState(false);
  const isMobile = useIsBelowScreenSize("mobile");

  const toggleModal = () => setShowFilters((filters) => !filters);

  const handleClearFilters = () => {
    setSelectedCategories([]);
    setSelectedTopics([]);
    setSelectedBusinessUnits([]);
  };

  const resetFiltersButton = (onClearFilters: () => void) => (
    <ButtonGhost iconPosition="left" icon={Reset} mode="light" onClick={onClearFilters}>
      Reset filters
    </ButtonGhost>
  );

  const filtersCount =
    selectedCategories.length + selectedTopics.length + selectedBusinessUnits.length;

  return (
    <div className="Resources__filters">
      <form className="Resources__form" onSubmit={handleSubmit}>
        {isMobile ? (
          <>
            <ButtonOutlined
              className="Resources__filtersButton"
              icon={SortBy}
              iconPosition="right"
              onClick={toggleModal}
            >
              Show filters
              <FiltersCount count={selectedCategories.length + selectedTopics.length} />
            </ButtonOutlined>
            <ResourcesFiltersModal
              isOpen={isMobile && showFilters}
              setOpen={setShowFilters}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              selectedTopics={selectedTopics}
              setSelectedTopics={setSelectedTopics}
              categoryFilters={categoryFilters}
              topicFilters={topicFilters}
              resetFiltersButton={resetFiltersButton}
            />
          </>
        ) : (
          <>
            <div className="Resources__filtersColumn">
              <CheckboxSelect
                label={categoryFilters.title}
                placeholder={categoryFilters.title}
                name="category"
                className="Resources__filter"
                options={categoryFilters.optionViewModel}
                currentValues={selectedCategories}
                onChange={setSelectedCategories}
              />
              <CheckboxSelect
                label={topicFilters.title}
                placeholder={topicFilters.title}
                name="topic"
                className="Resources__filter"
                options={topicFilters.optionViewModel}
                currentValues={selectedTopics}
                onChange={setSelectedTopics}
              />
              <CheckboxSelect
                label={businessUnitFilters.title}
                placeholder={businessUnitFilters.title}
                name="businessUnit"
                className="Resources__filter"
                options={businessUnitFilters.optionViewModel}
                currentValues={selectedBusinessUnits}
                onChange={setSelectedBusinessUnits}
              />
            </div>
            {!!filtersCount && (
              <div className="Resources__filtersColumn">
                {resetFiltersButton(handleClearFilters)}
              </div>
            )}
          </>
        )}
      </form>
    </div>
  );
};
