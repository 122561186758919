import React, { forwardRef, useEffect, useRef } from "react";
import { ResponsivePicture } from "../../../react-components/responsivePicture/responsivePicture";
import { ProductContainsItemProps } from "../ProductContainsItemProps.csharp";
import { AssetHelper } from "../../../react-components/assetHelper";

interface ItemProps extends ProductContainsItemProps {
  isHidden?: boolean;
  isFocused?: boolean;
}

export const ProductContainsItem = forwardRef<HTMLLIElement, ItemProps>(
  function ProductContainsItem(
    { title, description, url, image, isHidden = false, isFocused = false },
    ref,
  ) {
    const linkRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
      if (isFocused && linkRef.current) {
        linkRef.current.focus({ preventScroll: true });
      }
    }, [isFocused]);

    return (
      <li className="ProductContains__item" aria-hidden={isHidden} ref={ref}>
        <a
          href={url}
          className="ProductContains__itemLink"
          tabIndex={isHidden ? -1 : 0}
          ref={linkRef}
        >
          <div className="ProductContains__itemImage">
            {image ? (
              <ResponsivePicture profile={image.profile} model={image.model} />
            ) : (
              <img src={AssetHelper.images.placeholder} alt="" />
            )}
          </div>
          {title && <h3 className="ProductContains__itemName">{title}</h3>}
          {description && <p className="ProductContains__itemDescription">{description}</p>}
        </a>
      </li>
    );
  },
);
