import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {SlidesNavigationProps} from "../../SlidesNavigation/SlidesNavigationProps.csharp";
import {useStickyroll} from "./useStickyRollHook";
import classNames from "classnames";
import {useWheel} from 'react-use-gesture'
import {Lethargy} from "lethargy";
import {clamp, setSlidesAnimation} from "./SlidesNavigation.animationUtils";
import {getScrollPosition} from "./SlidesNavigation.scrollUtils";
import isAboveTablet from "../../Stories/Animations/isAboveTablet";
import isIE from "../../Stories/Animations/isIE";
import isFirefox from "../../Stories/Animations/isFirefox";
import { useIsEpiEditMode } from '../useIsEpiEditMode';

interface ISlidesNavigation {
    model: SlidesNavigationProps
}

const lethargy = new Lethargy();

export const SlidesNavigation: FC<ISlidesNavigation> = ({model: {wrapperId, sectionCssSelectors}}) => {
    const [slidesLength, setSlidesLength] = useState(0);
    const [destinationPageIndex, setDestinationPageIndex] = useState<number | null>(null)
    const slidesRef = useRef<HTMLElement[]>();
    const windowRef = useRef<Window>()

    const [isMobile, setIsMobile] = useState(false);
    const handleResize = useCallback(() => setIsMobile(!isAboveTablet().matches), []);
    const isEpiEditMode = useIsEpiEditMode();

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    const [wrapper, {currentPage, position, height}] = useStickyroll({
        pages: slidesLength,
        factor: 2
    });

    const [wait, setWait] = useState(false);
    
    useEffect(() => {
        // lethargy needs this to kick in
        if (wait) {
            const t = setTimeout(() => setWait(false), 300);
            return () => clearTimeout(t)
        }
    }, [wait]);

    useEffect(() => {
        // initial setup: css classes, get page elements
        wrapper.current = document.getElementById(wrapperId) as HTMLElement;
        if (isIE() || isMobile || isEpiEditMode) {
            wrapper.current.style.height = "auto";
            return;
        }
        wrapper.current.style.height = height;
        const slides =
            sectionCssSelectors
                .map((selector: string) => document.querySelectorAll<HTMLElement>(selector))
                .map((nodeList: NodeListOf<HTMLElement>) => Array.from(nodeList))
                .reduce((acc: HTMLElement[], x: HTMLElement[]) => acc.concat(x), []);
        setSlidesLength(slides.length)
        slides.forEach((sectionEl: HTMLElement, index: number) => setSlidesAnimation(sectionEl, index, slides.length, currentPage));
        slidesRef.current = slides
        windowRef.current = window
    }, [isMobile, isEpiEditMode, slidesLength, slidesRef, windowRef]);

    // Check current slide for scrollable inner element.
    // The user should still be able to scroll it even if we capture wheel events for the purpose of changing slides.
    const scrollableInnerContent = useRef<HTMLElement>()

    useEffect(() => {
        // If the current slide has a scrollable inner element we save a reference to it.
        if (isIE() || position == "outside") return;
        if (slidesRef.current && slidesRef.current.length > 0) {
            const current = slidesRef.current[position];
            if (!current) return;
            const content = current.querySelector<HTMLElement>(".SlidesNavigation__innerScrollContent");
            scrollableInnerContent.current = (content && content.parentElement && content.scrollHeight > content.parentElement.clientHeight)
                ? content
                : undefined
        }
    }, [currentPage]);

    useEffect(() => {
        // set css animation for slides when current page changes
        if (isIE() || isMobile || !slidesRef.current || slidesLength == 0) return;
        slidesRef.current.forEach((sectionEl: HTMLElement, index: number) => setSlidesAnimation(sectionEl, index, slidesLength, currentPage));
    }, [currentPage]);

    useWheel(({event, direction}) => {
            // The purpose of this section is to detect user intention to change slide and ignore non-intentional events.
            if (isIE() || isMobile || isEpiEditMode) return;

            const wheelDirection = lethargy.check(event);

            // If there is a scrollable element inside a slide, wheel event will pass through and cause scrolling,
            // unless the content is already scrolled to the edge. On that occasion we handle the event to switch slides. 
            // @ts-ignore
            const path = event.path || (event.composedPath && event.composedPath());
            if (scrollableInnerContent.current && path.includes(scrollableInnerContent.current)) {
                const slideInnerContentScrollPosition = getScrollPosition(scrollableInnerContent.current);
                if (slideInnerContentScrollPosition == 0 || slideInnerContentScrollPosition == direction[1]) return;
            }

            // if user scrolls outside slideshow switch to normal browser scroll behaviour
            if (position == "outside") return;

            // prevent normal scroll and switch to slide scroll mode
            event.preventDefault();
            event.stopPropagation();

            if (!wheelDirection || wait) return;

            setDestinationPageIndex(clamp(position - wheelDirection, 0, slidesLength));
            setWait(true);
        },
        // we need to use a ref to be able to get non passive events and be able
        // to call preventDefault()
        {domTarget: windowRef, eventOptions: {passive: false}}
    );

    useEffect(() => {
            if (isMobile || destinationPageIndex === null || isEpiEditMode) return;
            const intHeight = parseInt(height);
            const slidesContainerHeight = intHeight * window.innerHeight / 100;
            const destinationScrollPosition = slidesContainerHeight / slidesLength * destinationPageIndex;
            window.scrollTo({left: 0, top: destinationScrollPosition});
        }, [isEpiEditMode, destinationPageIndex]
    )

    useEffect(() => {
        if (isMobile) return;
        if (isFirefox()) document.documentElement.style.scrollBehavior = position == "outside" ? "smooth" : "auto";
        setDestinationPageIndex(null);
    }, [position])
    
    const dotButton = (element: number, id: number) => {
        const calculatePosition = position == "outside" 
            ? 0 
            : element + position;
        
        const activateDot = calculatePosition == position;
        const disableDot = calculatePosition < 0 ||
            calculatePosition > slidesLength - 1 ||
            position == "outside";
        const bottomValue = activateDot ? 97 + 40 * id : 100 + 40 * id;
        
            return (
            <span>
                <div className={classNames("SlidesNavigation__buttonDot", {
                    "SlidesNavigation__buttonDot--active": activateDot,
                    "SlidesNavigation__buttonDot--disabled": disableDot
                })} style={{bottom: `${bottomValue}px`}}>

                    <span className="visuallyHidden">go to next slide</span>
                </div>
            </span>
        )
    };
    const buttons = [2, 1, 0, -1, -2].map((el, i) => dotButton(el, i));
    return (
        <div className="SlidesNavigation__stickyRoll">
            <div className={classNames("SlidesNavigation__buttonsContainer", {
                "SlidesNavigation__buttonsContainer--hidden": position == "outside"
            })}>
                {buttons}
            </div>
            <a onClick={() => setDestinationPageIndex(position == "outside" ? 0 : position + 1)}>
                <div className={classNames("SlidesNavigation__button", {
                    ["SlidesNavigation__button--backToTop"]: position == "outside",
                })}>
                    <span className="visuallyHidden">go to next slide</span>
                </div>
            </a>
        </div>
    )
};