import React, { CSSProperties, ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { ResponsivePictureProps } from "../../Images/ResponsivePictureProps.csharp";
import { ResponsivePicture } from "../responsivePicture/responsivePicture";
import { AssetHelper } from "../assetHelper";

interface PictureThumbnailProps extends ComponentPropsWithoutRef<"div"> {
  picture: ResponsivePictureProps;
  thumbnail?: string;
  imageProps?: ComponentPropsWithoutRef<"img">;
}

export const PictureThumbnail = ({
  className,
  picture,
  thumbnail,
  imageProps,
  ...props
}: PictureThumbnailProps) => {
  const thumbnailStyles = {
    backgroundImage: `url(${thumbnail})`,
  } as CSSProperties;

  return (
    <div className={classNames("PictureThumbnail", className)} {...props}>
      {thumbnail && <div className="PictureThumbnail__thumbnail" style={thumbnailStyles} />}
      <div className="PictureThumbnail__picture">
        {picture.model.url !== null ? (
          <ResponsivePicture {...picture} {...imageProps} />
        ) : (
          <img src={AssetHelper.images.placeholder} alt="" {...imageProps} />
        )}
      </div>
    </div>
  );
};
