import React, { HTMLAttributes, MouseEventHandler, memo, useCallback } from "react";
import { LocateMe } from "../../../../react-components/Discovery/Icons/LocateMe";
import classNames from "classnames";
import { Tooltip } from "../../../../react-components/Discovery/Tooltip/Tooltip";
import { TooltipTrigger } from "../../../../react-components/Discovery/Tooltip/TooltipTrigger";
import { TooltipContent } from "../../../../react-components/Discovery/Tooltip/TooltipContent";
import { useToast } from "../../../../react-components/Toast/useToast";

interface OfficeLocationsFiltersLocateMeButtonProps extends HTMLAttributes<HTMLButtonElement> {
  label: string;
  locateMeHandler: () => Promise<void>;
  loaded?: boolean;
  userLocationDisabled?: boolean;
  cannotAccessLocation?: string;
  noLocationPermission?: string;
}

export const OfficeLocationsFiltersLocateMeButton = memo(
  function OfficeLocationsFiltersLocateMeButton({
    className,
    label,
    locateMeHandler,
    loaded,
    userLocationDisabled,
    cannotAccessLocation,
    noLocationPermission,
    ...props
  }: OfficeLocationsFiltersLocateMeButtonProps) {
    const isDisabled = !loaded || userLocationDisabled;

    const { toast } = useToast();

    const locateMeButtonClickHandler = useCallback<MouseEventHandler<HTMLButtonElement>>(
      (event) => {
        event.preventDefault();

        if (!isDisabled) {
          locateMeHandler().catch(() => toast(noLocationPermission));
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [locateMeHandler, isDisabled],
    );

    return (
      <Tooltip>
        <TooltipTrigger
          className={classNames("OfficeLocationsFiltersLocateMeButton", className)}
          onClick={locateMeButtonClickHandler}
          aria-disabled={isDisabled}
          {...props}
        >
          <span className="OfficeLocationsFiltersLocateMeButton__text">{label}</span>
          <LocateMe stroke="currentColor" />
        </TooltipTrigger>

        {isDisabled && <TooltipContent maxWidth={233}>{cannotAccessLocation}</TooltipContent>}
      </Tooltip>
    );
  },
);
