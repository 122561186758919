import React, { SVGProps, memo } from "react";
export const SortBy = memo(function SortBy(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 25 24"
      {...props}
    >
      <path d="M6.01562 6.66064H21.4181" stroke="currentColor" strokeWidth="1.5" />
      <path d="M2.87891 6.66064H4.48254" stroke="currentColor" strokeWidth="1.5" />
      <path d="M6.01562 12.1919H21.4181" stroke="currentColor" strokeWidth="1.5" />
      <path d="M2.87891 12.1919H4.48254" stroke="currentColor" strokeWidth="1.5" />
      <path d="M6.01562 17.3394H21.4181" stroke="currentColor" strokeWidth="1.5" />
      <path d="M2.87891 17.3394H4.48254" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  );
});
