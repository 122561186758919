import { DependencyList, useCallback, useEffect, useState } from "react";

export const useIsElementVisible = (
  element: HTMLElement | null,
  container: HTMLElement | null,
  deps: DependencyList
) => {
  const [isVisible, setVisible] = useState(false);

  const callbackFn = useCallback(() => {
    if (element && container) {
      setVisible(
        element.offsetTop >= container.scrollTop &&
        element.offsetTop + element.clientHeight <= container.scrollTop + container.clientHeight
      );
    }
  }, [element, container]);

  useEffect(callbackFn, deps);

  return isVisible;
};
