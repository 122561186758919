import React, { FC, KeyboardEvent } from "react";
import { ProductListingResult } from "../ProductListingResult.csharp";
import { ResponsivePicture } from "../../../../../react-components/responsivePicture/responsivePicture";
import classNames from "classnames";
import { Label } from "../../../../../react-components/Atoms/Label/Label";

interface ProductSuggestionsProps {
  searchResults?: ProductListingResult[];
  isExpanded: boolean;
  onKeyUp: (event: KeyboardEvent<HTMLUListElement>) => void;
}

const ProductSuggestions: FC<ProductSuggestionsProps> = ({
  searchResults,
  isExpanded,
  onKeyUp,
}) => {
  return (
    <div
      className={classNames("ProductSuggestions__dropdown", {
        ["ProductSuggestions__dropdown--expanded"]: isExpanded,
      })}
      id="suggestionDropdown"
    >
      <h3 className="ProductSuggestions__title">Products</h3>
      <ul
        role="menu"
        id="suggestionDropdown"
        onKeyUp={onKeyUp}
        className="ProductSuggestions__resultList"
      >
        {searchResults?.map(({ name, labels, url, pictureProfile, image, tagline }) => (
          <li key={name} className="ProductSuggestions__result">
            <a className="ProductSuggestions__resultContent" href={url}>
              <ResponsivePicture
                profile={pictureProfile}
                model={image}
                className="ProductSuggestions__picture"
                usePlaceholder
              />
              <p className="ProductSuggestions__name">{name}</p>
              <div className="ProductSuggestions__labels">
                {labels.map((label) => (
                  <Label key={label.text} color={label.color} size="small">
                    {label.text}
                  </Label>
                ))}
              </div>
              <p className="ProductSuggestions__tagline">{tagline}</p>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductSuggestions;
