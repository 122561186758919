import React, { memo, SVGProps } from "react";

export const CloseCircle = memo(function CloseCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00066 13.9087C11.2791 13.9087 13.9369 11.2428 13.9369 7.95435C13.9369 4.66585 11.2791 2 8.00066 2C4.72218 2 2.06445 4.66585 2.06445 7.95435C2.06445 11.2428 4.72218 13.9087 8.00066 13.9087ZM8.00066 12.7087C10.6129 12.7087 12.7369 10.5836 12.7369 7.95435C12.7369 5.32514 10.6129 3.2 8.00066 3.2C5.38838 3.2 3.26445 5.32514 3.26445 7.95435C3.26445 10.5836 5.38838 12.7087 8.00066 12.7087Z"
        fill="currentColor"
      />
      <path
        d="M10.5462 5.40887C10.7806 5.64318 10.7806 6.02308 10.5462 6.25739L8.84919 7.95445L10.5462 9.65151C10.7806 9.88582 10.7806 10.2657 10.5462 10.5C10.3119 10.7343 9.93203 10.7343 9.69772 10.5L8.00066 8.80298L6.30361 10.5C6.06929 10.7343 5.68939 10.7343 5.45508 10.5C5.22076 10.2657 5.22076 9.88582 5.45508 9.65151L7.15213 7.95445L5.45508 6.25739C5.22077 6.02308 5.22077 5.64318 5.45508 5.40887C5.68939 5.17455 6.06929 5.17455 6.30361 5.40887L8.00066 7.10592L9.69772 5.40887C9.93203 5.17455 10.3119 5.17455 10.5462 5.40887Z"
        fill="currentColor"
      />
    </svg>
  );
});
