import React, { FC } from "react";
import classNames from "classnames";

import { MicrositeOverviewButton } from "../OverView/MicrositeOverviewButton";

import { MicrositeLandingPageViewModel } from "../MicrositeLandingPageViewModel.csharp";
import { useMicrositeContext } from "../../Utils/micrositeContext";

type MicrositeHeaderProps = Pick<
  MicrositeLandingPageViewModel,
  "heading" | "subheading" | "overviewViewModel" | "pictureProps"
>;

export const MicrositeHeader: FC<MicrositeHeaderProps> = ({
  heading,
  subheading,
  overviewViewModel,
  pictureProps,
}) => {
  const { theme } = useMicrositeContext();
  return (
    <div className={classNames("MicrositeHeader", `MicrositeHeader--${theme}`)}>
      <h1 className="MicrositeHeader__heading">{heading}</h1>
      <h2 className="MicrositeHeader__subheading">{subheading}</h2>
      <MicrositeOverviewButton
        className="MicrositeHeader__button"
        overviewBackground={pictureProps}
        {...overviewViewModel}
      />
    </div>
  );
};
