import * as React from "react";
import {Chart} from "react-google-charts";
import {Loader, loaderColor} from "../Search/Loader";
import {ComponentProps} from "../ComponentProps.csharp";
import {ChartTileViewModel} from "./ChartTileViewModel.csharp";
import classNames from "classnames";

export class PieChart extends React.Component<ComponentProps<ChartTileViewModel>> {
    constructor(props: ComponentProps<ChartTileViewModel>) {
        super(props);
    }

    render() {
        const {completed, requested, translations} = this.props.model;
        const failed = requested - completed;

        const succeededPercent = Math.round(completed / requested * 100 * 10) / 10;
        const failedPercent = Math.round(failed / requested * 100 * 10) / 10;

        const labelSuccess = requested
            ? `${completed} of ${requested} (${succeededPercent}%)`
            : '';
        const dataSuccess = requested ? completed : 0;
        const labelFailure = requested
            ? `${failed} of ${requested} ${translations.failedText} (${failedPercent}%)`
            : '';
        const dataFailure = requested ? failed : 0;

        return (
            <div className={
                classNames('Chart',
                    {'Chart--svgFixUp': succeededPercent > 50 && succeededPercent < 100},
                    {'Chart--svgFixDown': succeededPercent > 0 && succeededPercent < 50}
                )
            }>
                {requested
                    ? <Chart className="PieChart"
                             chartType="PieChart"
                             loader={<Loader color={loaderColor.Navy}/>}
                             data={[
                                 ['Place', 'Number of passes', {role: "tooltip", type: "string", p: {html: false}}],
                                 [labelSuccess, dataSuccess, labelSuccess],
                                 [labelFailure, dataFailure, labelFailure],
                             ]}
                             options={{
                                 legend: 'none',
                                 slices: {
                                     0: {color: '#334561'},
                                     1: {color: '#FFFFFF'},
                                 },
                                 pieStartAngle: 345,
                                 backgroundColor: 'transparent',
                                 chartArea: {width: '75%', height: '75%'},
                                 animation: {
                                     startup: true,
                                     duration: 1000,
                                     easing: 'out',
                                 },
                             }}
                             rootProps={{'data-testid': '1'}}
                    />
                    : <div>
                        <svg viewBox="0 0 400 200" xmlns="http://www.w3.org/2000/svg" width="400" height="200">
                            <ellipse cx="200" cy="100" rx="75" ry="75" stroke="#ffffff" strokeWidth="1" fill="#d7153a"/>
                            <text x="50%" y="50%" textAnchor="middle" alignmentBaseline="middle" fontFamily="Arial"
                                  fontSize="15"
                                  stroke="none" strokeWidth="0" fill="#ffffff">
                                {translations.noDataText}
                            </text>
                        </svg>

                       
                    </div>
                }
            </div>
        );
    }
}




