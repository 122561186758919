import React, { memo } from "react";
import { OfficeLocationsListItemLink } from "./OfficeLocationsListItemLink";
import { OfficeLocationsListItemAddress } from "./OfficeLocationsListItemAddress";
import { AddressViewModel } from "../../AddressViewModel.csharp";
import { GetDirections } from "../../../../react-components/GoogleMaps/GetDirections";
import { ExternalLinkIcon } from "../../../../react-components/Discovery/Icons/ExternalLinkIcon";
import { OfficeLocationsListWithMapListItemTranslations } from "../../OfficeLocationsListWithMapListItemTranslations.csharp";

interface OfficeLocationsListItemContactInfoProps {
  emailAddress: string;
  phoneNumber: string;
  address: AddressViewModel;
  lat: number;
  lng: number;
  destination: string;
  translations: OfficeLocationsListWithMapListItemTranslations;
}

export const OfficeLocationsListItemContactInfo = memo<OfficeLocationsListItemContactInfoProps>(function OfficeLocationsListItemContactInfo({
  emailAddress,
  phoneNumber,
  address,
  destination,
  lat,
  lng,
  translations,
}) {
  return (
    <div className="OfficeLocationsListItem__contactInfo">
      <OfficeLocationsListItemLink href={`mailto:${emailAddress}`}>
        {emailAddress}
      </OfficeLocationsListItemLink>

      <OfficeLocationsListItemLink href={`tel:${phoneNumber}`}>
        {phoneNumber}
      </OfficeLocationsListItemLink>

      <OfficeLocationsListItemAddress
        street={address.street}
        city={address.city}
        country={address.country}
      />

      {(destination || (lat && lng)) && (
        <GetDirections
          className="OfficeLocationsListItem__locationDirectionsLink"
          lat={lat}
          lng={lng}
          destination={destination}
        >
          <span>{translations.getDirections}</span>
          <ExternalLinkIcon />
        </GetDirections>
      )}
    </div>
  );
});
