import React, { FC, SVGProps } from "react";
import classNames from "classnames";

interface ChevronIconProps extends SVGProps<SVGSVGElement> {
  isOpen?: boolean;
}

export const Chevron: FC<ChevronIconProps> = ({ className, isOpen, ...props }) => {
  return (
    <svg
      className={classNames("Chevron", { "Chevron--isOpen": isOpen }, className)}
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M4.0658 8.0329L10.5858 14.5529C11.3668 15.3339 12.6332 15.3339 13.4142 14.5529L19.9342 8.0329"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};
