import { useCallback, useEffect, useState } from "react";

export const useIsKeyboardUsed = () => {
  const [isKeyboardUsed, setKeyboardUsed] = useState(false);

  const handleKeyUp = useCallback(() => {
    setKeyboardUsed(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setKeyboardUsed(false);
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener("keyup", handleKeyUp);
      window.addEventListener("mouseup", handleMouseUp);

      return () => {
        window.removeEventListener("keyup", handleKeyUp);
        window.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [handleKeyUp, handleMouseUp]);

  return isKeyboardUsed;
};
