import classNames from "classnames";
import React, { FC, ReactNode } from "react";
import { ButtonGhost } from "../Buttons/ButtonGhost";
import PlusIcon from "../../../wwwroot/Static/Images/Icons/PlusOutline.svg";

interface TabProps {
  isActive: boolean;
  idString: string;
  key: string;
  showMoreItemsButton?: {
    showMoreItems: () => void;
    showMoreItemsLabel: string;
    allItemsShown: boolean;
  };
}

const Tab: FC<TabProps> = ({ isActive, idString, showMoreItemsButton, children }) => {
  return (
    <div
      hidden={!isActive}
      role="tabpanel"
      id={`${idString}-tab`}
      className={classNames("Tabs__tab", {
        ["Tabs__tab--active"]: isActive,
      })}
      aria-labelledby={`${idString}-label`}
    >
      {children}
      {showMoreItemsButton && showMoreItemsButton.allItemsShown && (
        <ButtonGhost
          onClick={showMoreItemsButton.showMoreItems}
          className="Tabs__showMoreItemsButton"
          aria-hidden
        >
          {showMoreItemsButton.showMoreItemsLabel} <PlusIcon aria-hidden />
        </ButtonGhost>
      )}
    </div>
  );
};

interface LabelProps {
  isSingleTab: boolean;
  isActive: boolean;
  idString: string;
  key: string;
  onClick: () => void;
}

const Label: FC<LabelProps> = ({ isSingleTab, isActive, idString, onClick, children }) => (
  <button
    className={classNames("Tabs__label", {
      ["Tabs__label--multipleDays"]: !isSingleTab,
      ["Tabs__label--active"]: !isSingleTab && isActive,
    })}
    id={`${idString}-label`}
    aria-selected={isActive}
    aria-controls={`${idString}-tab`}
    role="tab"
    onClick={onClick}
  >
    {children}
  </button>
);

interface TabsProps {
  children: {
    labels: ReactNode;
    tabs: ReactNode;
  };
}

type Tabs = FC<TabsProps> & { Label: typeof Label; Tab: typeof Tab };

export const Tabs: Tabs = ({ children }) => {
  return (
    <div className="Tabs">
      <div className="Tabs__labels" role="tablist">
        {children.labels}
      </div>
      <div className="Tabs__tabpanels">{children.tabs}</div>
    </div>
  );
};

Tabs.Label = Label;
Tabs.Tab = Tab;
