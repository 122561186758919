import React, { memo } from "react";
import { OfficeLocationDepartment } from "./OfficeLocationsListItem.utils";
import { OfficeLocationsListItemDepartment } from "./OfficeLocationsListItemDepartment";
import { chunk } from "lodash";

interface OfficeLocationsListItemDepartmentsProps {
  departments: OfficeLocationDepartment[];
}

const departamentsPerRow = 2;

export const OfficeLocationsListItemDepartments = memo<OfficeLocationsListItemDepartmentsProps>(function OfficeLocationsListItemDepartments({
  departments,
}) {
  const departmentsRows = chunk(departments, departamentsPerRow);

  return (
    <div className="OfficeLocationsListItem__detailsSection OfficeLocationsListItem__departments">
      {departmentsRows.map((departmentsChunk) => (
        <div className="OfficeLocationsListItem__departmentsRow">
          {departmentsChunk.map((department) => (
            <OfficeLocationsListItemDepartment
              key={department.title}
              title={department.title}
              email={department.email}
              phoneNumber={department.phoneNumber}
            />
          ))}
        </div>
      ))}
    </div>
  );
});
