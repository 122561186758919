import React, { SVGProps, memo } from "react";
export const Attachment = memo(function Attachment(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.2479 19.8198L4.84134 11.4132C3.14005 9.71194 3.14005 6.95361 4.84133 5.25232C6.54262 3.55104 9.30095 3.55104 11.0022 5.25232L19.5533 13.8034C20.6875 14.9376 20.6875 16.7765 19.5533 17.9107C18.4191 19.0449 16.5802 19.0449 15.446 17.9107L12.2521 14.7167L6.89495 9.35961C6.32785 8.79251 6.32785 7.87306 6.89495 7.30597C7.46204 6.73887 8.38149 6.73887 8.94859 7.30597L17.3551 15.7126"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
});
