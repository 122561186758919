import isIE from "../Stories/Animations/isIE";

export interface IBrowserHandler{
    addEventListener: (eventName: string, callback: () => void) => void,
    removeEventListener: (eventName: string, callback: () => void) => void,
    getScrollYOffset : () => number;
}

export function getBrowserHandler(): IBrowserHandler{
    return isIE() ? new InternetExplorerHandler() : new DefaultBrowserHandler();
}

class InternetExplorerHandler implements IBrowserHandler{
    addEventListener = (eventName: string, callback: () => void) => {
        document.body.addEventListener(eventName, callback, false);
    };
    getScrollYOffset = () => {
        return document.body.scrollTop;
    };
    removeEventListener = (eventName: string, callback: () => void) => {
        document.body.removeEventListener(eventName, callback, false);
    };
}

class DefaultBrowserHandler implements IBrowserHandler{
    addEventListener = (eventName: string, callback: () => void) => {
        window.addEventListener(eventName, callback, false);
    };
    getScrollYOffset = () => {
        return window.pageYOffset;
    };
    removeEventListener = (eventName: string, callback: () => void) => {
        window.removeEventListener(eventName, callback, false);
    };
}
