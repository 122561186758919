import React, { FC } from "react";
import classNames from "classnames";
import { ComponentProps } from "../ComponentProps.csharp";
import { FooterViewModel } from "./FooterViewModel.csharp";
import { NavigationColor } from "../../Navigation/NavigationColor.csharp";

export const TopFooter: FC<ComponentProps<FooterViewModel>> = ({ model }) => {
  const renderTopFooter = !!model.topHeader || !!model.topSubheader || !!model.content?.mainBody;

  if (!renderTopFooter) return null;

  return (
    <section
      className={classNames("Footer__topSection", {
        "Footer__topSection--blue":
          model.navigationColor === NavigationColor.Undefined ||
          model.navigationColor === NavigationColor.Blue,
        "Footer__topSection--green": model.navigationColor === NavigationColor.Green,
      })}
    >
      <div className="Footer__topSectionContent">
        <div
          className={classNames("Footer__topHeaderWrapper", {
            "Footer__topHeaderWrapper--noContent": !model.topHeader && !model.topSubheader,
          })}
        >
          {model.topHeader && <h2 className="Footer__topHeader">{model.topHeader}</h2>}
          {model.topSubheader && <p className="Footer__topSubheader">{model.topSubheader}</p>}
        </div>

        {model.content?.mainBody && (
          <div
            className="Footer__richTextInfo"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            dangerouslySetInnerHTML={{ __html: model.content.mainBody }}
          />
        )}
      </div>
    </section>
  );
};
