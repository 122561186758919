import React, { ReactNode } from "react";
import ReactModal from "react-modal";
import classNames from "classnames";
import { FiltersModalHeader } from "./FiltersModalHeader";
import { CountProps } from "../../Atoms/Count/Count";

interface FiltersModalProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  resetFiltersButton: ((onClearFilters: () => void) => ReactNode) | boolean;
  onClearFilters: () => void;
  children: ReactNode;
  filtersCount: CountProps;
  className?: string;
}

export const FiltersModal = ({
  id,
  isOpen,
  onClose,
  resetFiltersButton,
  onClearFilters,
  children,
  filtersCount,
  className,
}: FiltersModalProps) => {
  return (
    <ReactModal
      id={id}
      isOpen={isOpen}
      className={classNames("FiltersModal", className)}
      ariaHideApp={false}
      onRequestClose={onClose}
      aria={{ labelledby: `${id}Title` }}
    >
      <FiltersModalHeader
        titleId={`${id}Title`}
        resetFiltersButton={resetFiltersButton}
        onClearFilters={onClearFilters}
        onClose={onClose}
        filtersCount={filtersCount}
      />

      <div className="FiltersModal__content">{children}</div>
    </ReactModal>
  );
};
