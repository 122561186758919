import React from "react";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { NoLongerSoldViewModel } from "./NoLongerSoldViewModel.csharp";
import { ProductBanner } from "../../../react-components/ProductBanner/ProductBanner";

export const NoLongerSold = ({
  model: { translations },
  containerId,
}: ComponentProps<NoLongerSoldViewModel>) => {
  return (
    <ProductBanner
      title={translations.title}
      description={translations.description}
      containerId={containerId}
    />
  );
};
