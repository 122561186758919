import React from "react";
import classNames from "classnames";
import Chevron from "../../../wwwroot/Static/Images/Icons/Discovery/Chevron.svg";

interface SplideArrowsProps {
  className?: string;
  arrowClassName?: string;
  renderPagination?: boolean;
}

export const SplideArrows = ({
  className,
  arrowClassName,
  renderPagination = false,
}: SplideArrowsProps) => (
  <div className={classNames("splide__arrows", className)}>
    <button
      className={classNames(
        "splide__arrow",
        "splide__arrow--prev",
        arrowClassName,
        arrowClassName && `${arrowClassName}--prev`,
      )}
    >
      <Chevron />
    </button>

    {renderPagination && <ul className="splide__pagination ContentListSlider__pagination" />}

    <button
      className={classNames(
        "splide__arrow",
        "splide__arrow--next",
        arrowClassName,
        arrowClassName && `${arrowClassName}--next`,
      )}
    >
      <Chevron />
    </button>
  </div>
);
