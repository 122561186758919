import React from "react";
// eslint-disable-next-line import/no-unresolved
import { MenuPortalProps } from "react-select/dist/declarations/src/components/Menu";
import { Portal } from "../../../Portal";
import { BackgroundOverlay } from "../../BackgroundOverlay/BackgroundOverlay";
import { useScrollLock } from "../../../useScrollLock";
import { RadioSelectOption } from "./RadioSelect.types";
import { GroupBase } from "react-select";

export const MenuPortal = ({
  children,
  innerProps,
}: MenuPortalProps<RadioSelectOption, false, GroupBase<RadioSelectOption>>) => {
  useScrollLock();

  return (
    <Portal>
      <div className="RadioSelect__menuPortal" {...innerProps}>
        <BackgroundOverlay>{children}</BackgroundOverlay>
      </div>
    </Portal>
  );
};
