import React from "react";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { Tab, TabSection, TabsListing } from "../../../react-components/TabsListing/TabsListing";
import { ProductSupportListingProps } from "../../ProductSupportListing/ProductSupportListingProps.csharp";

const AVAILABLE_SEGMENT_TAB_ID = "AvailableProductSupportListing_Segment_Tab";
const AVAILABLE_SEGMENT_SECTION_ID = "AvailableProductSupportListing_Segment_Section";

const SUPPORTED_SEGMENT_TAB_ID = "SupportedProductSupportListing_Segment_Tab";
const SUPPORTED_SEGMENT_SECTION_ID = "SupportedProductSupportListing_Segment_Section";

export const ProductSupportListing = ({ model }: ComponentProps<ProductSupportListingProps>) => {
  const availableProducts: TabSection[] = model.availableProductsLinks.map((l) => ({
    title: l.letter,
    items: l.items,
  }));

  const supportedProducts: TabSection[] = model.supportedProductsLinks.map((l) => ({
    title: l.letter,
    items: l.items,
  }));

  const tabs: Tab[] = [
    {
      id: AVAILABLE_SEGMENT_TAB_ID,
      sectionId: AVAILABLE_SEGMENT_SECTION_ID,
      label: model.availableProductsTabName,
      sections: availableProducts,
      isCollapsibleOnMobile: false,
    },
    {
      id: SUPPORTED_SEGMENT_TAB_ID,
      sectionId: SUPPORTED_SEGMENT_SECTION_ID,
      label: model.supportedProductsTabName,
      sections: supportedProducts,
      isCollapsibleOnMobile: false,
    },
  ];

  return <TabsListing tabs={tabs} displaySectionsAsList />;
};
