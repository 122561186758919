import React from "react";
import { ButtonOutlined } from "../../Buttons/ButtonOutlined";
import { ButtonFill } from "../../Buttons/ButtonFill";

interface CheckboxSelectControlsBarProps {
  handleClear?: () => void;
  handleSave?: () => void;
}

export const CheckboxSelectControlsBar = ({
  handleClear,
  handleSave,
}: CheckboxSelectControlsBarProps) => {
  return (
    <div className="CheckboxSelect__controlsBar">
      <ButtonOutlined className="CheckboxSelect__clearButton" onClick={handleClear}>
        Clear
      </ButtonOutlined>
      <ButtonFill className="CheckboxSelect__saveButton" onClick={handleSave}>
        Save
      </ButtonFill>
    </div>
  );
};
