import React, { FC } from 'react'
import classNames from 'classnames';
import ClickAwayListener from 'react-click-away-listener';

import { AnimatedComponent } from '../../../react-components/AnimatedComponent/AnimatedComponent';

interface ProductFeaturesProps {
  theme: string
  description: string;
  features: string;
  isVisible: boolean
  handleBannerClick: (visibility?: boolean) => void;
}

export const ProductFeatures: FC<ProductFeaturesProps> = ({
  theme,
  description,
  features,
  isVisible,
  handleBannerClick
}) => {

  return (
    <ClickAwayListener onClickAway={() => handleBannerClick(false)}>
      <AnimatedComponent
        tag='div'
        className="MicrositeProductFeatures"
        isOn={isVisible}
        animationProps={isVisible ? '-50vh' : '0px'}
        entry='moveFromBottomToCustom'
        exit='moveOutToBottom'
      >
        <div className={classNames("MicrositeProductFeatures__inner",
          `MicrositeProductFeatures__inner--${theme}`, {
          "MicrositeProductFeatures__inner--opened": isVisible
        })}>
          <button
            onClick={() => handleBannerClick()}
            className={classNames("MicrositeProductFeatures__button", {
              "MicrositeProductFeatures__button--opened": isVisible
            })}
          >
            <span className="MicrositeProductFeatures__buttonInner">
              Product Info
            </span>
          </button>
          <div className="MicrositeProductFeatures__content">
            <div
              className="MicrositeProductFeatures__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div
              className="MicrositeProductFeatures__features"
              dangerouslySetInnerHTML={{ __html: features }}
            />
          </div>
        </div>
      </AnimatedComponent>
    </ClickAwayListener>
  )
};
