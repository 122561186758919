import React, { ComponentPropsWithRef, ComponentPropsWithoutRef, ElementType, ForwardedRef, PropsWithChildren, ReactElement, forwardRef } from "react";
import { ButtonBase, ButtonBaseCommonProps, ButtonBaseProps } from "./ButtonBase";

export type ButtonOutlinedProps<C extends ElementType> = PropsWithChildren<ButtonBaseCommonProps<C>> & Omit<ComponentPropsWithoutRef<C>, keyof ButtonBaseCommonProps<C>> & { ref?: ComponentPropsWithRef<C>["ref"] };

export type ButtonOutlinedComponent = <C extends ElementType = "button">(
  props: ButtonOutlinedProps<C>,
  ref: ForwardedRef<C>
) => ReactElement | null;

export const ButtonOutlined: ButtonOutlinedComponent = forwardRef(function ButtonOutlined<C extends ElementType>({
  baseClassName,
  ...props
}: ButtonOutlinedProps<C>, ref: ForwardedRef<C>) {
  const buttonProps = {
    baseClassName: "ButtonOutlined",
    ...props,
  } as ButtonBaseProps<C>;

  return (
    <ButtonBase
      {...buttonProps}
      ref={ref}
    />
  );
});
