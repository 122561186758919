import React, { ReactNode, memo, useEffect, useRef } from "react";
import classNames from "classnames";
import { lowerFirst } from "lodash";
import { useToast } from "./useToast";
import { Info } from "../Discovery/Icons/Info";
import { Close } from "../Icons/Close";

export enum ToastVariant {
  Default = "default",
  Informative = "informative",
  Success = "Success",
  Alert = "Alert",
  Error = "Error",
}

export interface ToastProps {
  toastId: number | string;
  children: ReactNode;
  icon?: ReactNode;
  variant?: ToastVariant;
  dismissAfter?: number;
  hiddenCloseLabel?: string;
}

export const Toast = memo<ToastProps>(function Toast({
  variant = ToastVariant.Default,
  icon,
  children,
  toastId,
  dismissAfter = 5000,
  hiddenCloseLabel = "Dismiss",
}) {
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const endTime = useRef(new Date().getTime() + dismissAfter);
  const remainingTime = useRef(dismissAfter);

  const toast = useToast();

  const getRemainingTime = () => {
    const now = new Date().getTime();
    const remainingTime = endTime.current - now;

    return remainingTime > 0 ? remainingTime : 0;
  };

  const handleDismiss = () => toast.remove(toastId);

  const handleMouseEnter = () => {
    if (timerId.current) clearTimeout(timerId.current);
    remainingTime.current = getRemainingTime();
  };

  const handleMouseLeave = () => {
    timerId.current = setTimeout(handleDismiss, remainingTime.current);
  };

  useEffect(() => {
    timerId.current = setTimeout(handleDismiss, dismissAfter);

    return () => (timerId.current ? clearTimeout(timerId.current) : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames("Toast", { [`Toast--${lowerFirst(variant)}`]: variant })}
      role="status"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="Toast__icon">{icon || <Info />}</div>
      <p className="Toast__content">{children}</p>
      <button className="Toast__closeButton" onClick={handleDismiss}>
        <Close className="Toast__closeButtonIcon" />
        <span className="visuallyHidden">{hiddenCloseLabel}</span>
      </button>
    </div>
  );
});
