import React, { SVGProps, memo } from "react";
export const LocateMe = memo(function LocateMe(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M12 2.7v5m0 8.6v5m9.3-9.235h-5m-8.6 0h-5M18.8 12a6.8 6.8 0 1 1-13.6 0 6.8 6.8 0 0 1 13.6 0Z"
        strokeWidth="1.5"
      />
    </svg>
  );
});
