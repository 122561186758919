import React, { useEffect, useState, forwardRef, useRef } from "react";
import classNames from "classnames";
import { FocusOn } from "react-focus-on";
import { ComponentProps } from "../ComponentProps.csharp";
import { NavigationViewModel } from "../../Navigation/NavigationViewModel.csharp";
import { NavSearchInput } from "./NavSearchInput";
import { NavSubmenu } from "./NavSubmenu";
import { ChevronDesktopMenu } from "../../react-components/Discovery/Icons/ChevronDesktopMenu";
import { FocusControl } from "./FocusControl";
import { NavMobile } from "./NavMobile";
import { useIsBelowScreenSize } from "../../react-components/useIsBelowScreenSize";
import { NavigationRootType } from "../../Navigation/NavigationRootType.csharp";
import { NavSubmenuKsat } from "./NavSubmenuKsat";

interface NavProps extends ComponentProps<NavigationViewModel> {
  isMobileMenuOpened: boolean;
  setIsMobileMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setIsBackdropVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isTransparent: boolean;
}

export const Nav = forwardRef<HTMLAnchorElement, NavProps>(
  (
    { model, isMobileMenuOpened, setIsMobileMenuOpened, setIsBackdropVisible, isTransparent },
    logoRef,
  ) => {
    const [currentOpenSubmenuIdx, setCurrentOpenSubmenuIdx] = useState<number | undefined>();
    const isMobile = useIsBelowScreenSize("tablet");
    const cookieBannerRef = useRef<HTMLDivElement | null>(null);

    const { sections, searchInput, translations } = model;

    const mobileMenuText = isMobileMenuOpened
      ? translations.menuCloseText
      : translations.menuOpenText;

    const onMouseOver = (submenuIdx: number) => {
      setCurrentOpenSubmenuIdx(submenuIdx);
    };

    const onMouseOut = (submenuIdx: number) => {
      setCurrentOpenSubmenuIdx((prevState) => {
        return prevState === submenuIdx ? undefined : prevState;
      });
    };

    const onFocus = (submenuIdx: number) => {
      setCurrentOpenSubmenuIdx(submenuIdx);
    };

    const onLoseFocus = () => {
      setCurrentOpenSubmenuIdx(undefined);
    };

    useEffect(() => {
      if (currentOpenSubmenuIdx !== undefined) {
        setIsBackdropVisible(!!sections[currentOpenSubmenuIdx].items.length);
      } else {
        setIsBackdropVisible(false);
      }
    }, [currentOpenSubmenuIdx]);

    useEffect(() => {
      if (!isMobile) {
        setIsMobileMenuOpened(false);
      }
    }, [isMobile]);

    useEffect(() => {
      const cookieBanner = document.querySelector("#onetrust-banner-sdk");
      if (cookieBanner) {
        cookieBannerRef.current = cookieBanner as HTMLDivElement;
      }
    });

    return (
      <nav
        className={classNames("Nav", {
          "Nav--isTransparent": isTransparent,
          "Nav--relative": model.navigationRootType === NavigationRootType.Ksat,
        })}
      >
        <FocusControl onLoseFocus={onLoseFocus} className="Nav__menuListWrapper">
          <ul className="Nav__menuList">
            {sections.map((section, sectionIdx) => {
              const hasSubmenu = !!section.items.length;
              const linkTargetProps = section.linkTarget
                ? { target: section.linkTarget, rel: "noopener" }
                : {};
              const isCurrentSubmenuOpen = sectionIdx === currentOpenSubmenuIdx && hasSubmenu;

              return (
                <li
                  className={classNames("Nav__menuItem", {
                    "Nav__menuItem--relative": model.navigationRootType !== NavigationRootType.Ksat,
                  })}
                  onMouseOver={() => onMouseOver(sectionIdx)}
                  onFocus={() => onFocus(sectionIdx)}
                  onMouseLeave={() => onMouseOut(sectionIdx)}
                  key={sectionIdx}
                >
                  {hasSubmenu ? (
                    <button
                      className={classNames("Nav__menuItemLink", {
                        "Nav__menuItemLink--isOpened": isCurrentSubmenuOpen,
                      })}
                      aria-disabled
                      type="button"
                    >
                      <span>{section.header}</span>
                      <ChevronDesktopMenu
                        className={classNames("Nav__menuItemIcon", {
                          "Nav__menuItemIcon--rotated": isCurrentSubmenuOpen,
                        })}
                        aria-hidden
                      />
                    </button>
                  ) : (
                    <a className="Nav__menuItemLink" href={section.linkUrl} {...linkTargetProps}>
                      <span>{section.header}</span>
                    </a>
                  )}

                  {hasSubmenu &&
                    (model.navigationRootType === NavigationRootType.Ksat ? (
                      <NavSubmenuKsat
                        submenuItems={section.items}
                        isSubmenuOpen={isCurrentSubmenuOpen}
                        additionalLinks={section.additionalLinks}
                        additionalLinksHeader={section.additionalLinksHeader}
                        additionalLinksDescription={section.additionalLinksDescription}
                      />
                    ) : (
                      <NavSubmenu
                        submenuItems={section.items}
                        isSubmenuOpen={isCurrentSubmenuOpen}
                        fullWidth={false}
                      />
                    ))}
                </li>
              );
            })}
          </ul>
        </FocusControl>
        <div className="Nav__searchInputWrapper">
          <NavSearchInput model={searchInput} color={isTransparent ? "light" : "dark"} />
        </div>
        <FocusOn
          enabled={isMobileMenuOpened}
          shards={[logoRef as React.RefObject<HTMLAnchorElement>, cookieBannerRef]}
          onEscapeKey={() => setIsMobileMenuOpened(false)}
          scrollLock={isMobileMenuOpened}
          className="Nav__mobileFocusLock"
        >
          <button
            className={classNames("Nav__hamburger", {
              "Nav__hamburger--active": isMobileMenuOpened,
            })}
            aria-label={mobileMenuText}
            aria-expanded={isMobileMenuOpened}
            onClick={() => setIsMobileMenuOpened((prevState) => !prevState)}
          >
            <span className="Nav__hamburgerLine" />
          </button>
          <NavMobile model={model} isOpened={isMobileMenuOpened} />
        </FocusOn>
      </nav>
    );
  },
);

Nav.displayName = "Nav";
