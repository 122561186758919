import classNames from "classnames";
import React from "react";
import ReactModal from "react-modal";
import { Close } from "../../Icons/Close";
import { ButtonFill } from "../../Buttons/ButtonFill";
import { useClipboard } from "../../hooks/useClipboard";
import { isBrowser } from "../../../environmentHelpers";
import { Tooltip } from "../../Discovery/Tooltip/Tooltip";
import { TooltipContent } from "../../Discovery/Tooltip/TooltipContent";
import { TooltipTrigger } from "../../Discovery/Tooltip/TooltipTrigger";
import { ShareModalTranslations } from "../../../Partials/Share/ShareModalTranslations.csharp";

interface ShareModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
  translation: ShareModalTranslations;
}

export const ShareModal = ({
  isOpen,
  onClose,
  className,
  translation: { title, copyLink, linkCopied },
}: ShareModalProps) => {
  const { copy } = useClipboard();

  if (!isOpen) return null;

  const linkToCopy = isBrowser ? location.href : "";

  const handleShareButtonClick = () => {
    copy(linkToCopy);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={classNames("ShareModal", className)}
      className="ShareModal__modal"
      ariaHideApp={false}
      onRequestClose={onClose}
      aria={{ labelledby: "ShareModalTitle" }}
    >
      <div className="ShareModal__header">
        <h2 id="ShareModalTitle" className="ShareModal__title">
          {title}!
        </h2>

        <button className="ShareModal__close" aria-label="Close window" onClick={onClose}>
          <Close />
        </button>
      </div>
      <div className="ShareModal__content">
        <div className="ShareModal__copyLink">
          <div id="ShareModalCopyLinkLabel" className="ShareModal__copyLinkText">
            {copyLink}
          </div>
          <div className="ShareModal__copyLinkInputWithButton">
            <input
              className="ShareModal__copyLinkInput"
              type="text"
              value={linkToCopy}
              disabled
              aria-labelledby="ShareModalCopyLinkLabel"
            />
            <Tooltip trigger="click">
              <TooltipTrigger asChild>
                <ButtonFill
                  className="ShareModal__copyLinkButton"
                  color="blue"
                  onClick={handleShareButtonClick}
                >
                  {copyLink}
                </ButtonFill>
              </TooltipTrigger>
              <TooltipContent>{linkCopied}!</TooltipContent>
            </Tooltip>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
