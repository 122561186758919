import * as React from "react";

export interface CheckboxProps {
    id: string;
    checked: boolean;
    text: string;
    onCheckedChanged: (checked: boolean) => void;
}

export const Checkbox = (props: CheckboxProps) => {
    return ( 
        <span className="CustomCheckbox">
            <input id={props.id} type={"checkbox"} checked={props.checked} onChange={event => props.onCheckedChanged(event.target.checked)}/>
            <label htmlFor={props.id}>{props.text}</label>
        </span>
    )
};