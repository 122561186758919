//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ButtonColor {
	Base = "base",
	Teal = "teal",
	Blue = "blue",
	Destructive = "destructive"
}
