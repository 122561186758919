import React, { useState, useEffect, useRef } from "react";
import { ComponentProps } from "../ComponentProps.csharp";
import { CounterViewModel } from "./CounterViewModel.csharp";
import classNames from "classnames";
import { Richtext } from "../RichtextArea/Richtext";
import { extractNumberFromString } from "../../react-components/stringHelper";

const timeout = 100;

export const Counter: React.FC<ComponentProps<CounterViewModel>> = ({ model: { text, value } }) => {
  const valueParts = extractNumberFromString(value) || 0;
  const numberValue = valueParts.number || 0;
  const initialValue = Math.max(numberValue - 15, 0);
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const [currentValue, setCurrentValue] = useState<number>(initialValue);
  const [isTextVisible, setIsTextVisible] = useState<boolean>(false);
  const interval = useRef<ReturnType<typeof setInterval>>();
  const observer = useRef<IntersectionObserver | null>(null);

  const increment = () => {
    setCurrentValue((currentValue) => (currentValue < numberValue ? ++currentValue : currentValue));
  };

  const clearCounter = () => {
    setCurrentValue(initialValue);
    setIsTextVisible(false);
  };

  const startInterval = () => {
    interval.current = setInterval(increment, timeout);
  };

  const stopInterval = () => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  const intersectionOptions: IntersectionObserverInit = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  const intersectionCallback: IntersectionObserverCallback = (entries) => {
    if (entries[0].isIntersecting) {
      startInterval();
      return;
    }
    stopInterval();
    clearCounter();
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      observer.current = new IntersectionObserver(intersectionCallback, intersectionOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentElement = element;
    const currentObserver = observer ? observer.current : null;

    if (currentElement && currentObserver) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement && currentObserver) {
        stopInterval();
        currentObserver.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  useEffect(() => {
    if (currentValue === numberValue) {
      stopInterval();
      setIsTextVisible(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <div className="Counter" ref={(e) => setElement(e)}>
      <Richtext
        className={classNames("Counter__text", {
          ["Counter__text--visible"]: isTextVisible || !numberValue,
        })}
      >
        {text}
      </Richtext>
      <div className="Counter__value">
        {currentValue ? currentValue : ""}
        {valueParts.restOfString}
      </div>
    </div>
  );
};
