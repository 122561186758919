import classNames from "classnames";
import React, { CSSProperties, ComponentPropsWithoutRef, memo, useRef } from "react";

interface SkeletonProps extends ComponentPropsWithoutRef<"span"> {
  inline?: boolean;
  width?: string | number;
  height?: string | number;
  fontSize?: string | number;
  rounded?: boolean;
}

export const Skeleton = memo<SkeletonProps>(function Skeleton({
  className,
  inline = false,
  width,
  height,
  fontSize,
  rounded = false,
  ...props
}) {
  const ref = useRef<HTMLSpanElement>(null);

  const skeletonClassNames = classNames(
    "Skeleton",
    {
      "Skeleton--inline": inline,
      "Skeleton--rounded": rounded,
    },
    className,
  );

  const styles = {
    fontSize: fontSize,
  };

  const innerStyles = {
    width: width,
    height: height,
  } as CSSProperties;

  return (
    <span className={skeletonClassNames} aria-busy="true" style={styles} {...props} ref={ref}>
      <span className="Skeleton__inner" style={innerStyles} />
    </span>
  );
});
