import React, { HTMLAttributes, ReactNode } from "react";

export type HeadingLevel = 1 | 2 | 3 | 4 | 5| 6;

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  level?: HeadingLevel;
  children: ReactNode;
}

export const Heading = ({ level = 1, children, ...props }: HeadingProps) => {
  const Tag = `h${level}`;

  return <Tag {...props}>{children}</Tag>
};