import React, { useRef } from "react";
import classNames from "classnames";
import { NavigationItemViewModel } from "../../Navigation/NavigationItemViewModel.csharp";

interface NavSubmenuProps {
  submenuItems: NavigationItemViewModel[];
  isSubmenuOpen: boolean;
  fullWidth: boolean;
}

export const NavSubmenu = ({ submenuItems, isSubmenuOpen, fullWidth }: NavSubmenuProps) => {
  const navSubmenuRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classNames("NavSubmenu", {
        "NavSubmenu--visible": isSubmenuOpen,
        "NavSubmenu--fullWidth": fullWidth,
      })}
      ref={navSubmenuRef}
    >
      <div className="NavSubmenu__wrapper">
        <div className="NavSubmenu__inner">
          <ul className="NavSubmenu__list">
            {submenuItems.map((menuItem, idx) => {
              const linkTargetProps = menuItem.linkTarget
                ? { target: menuItem.linkTarget, rel: "noopener" }
                : {};

              return (
                <li key={`submenuItem${idx}`}>
                  <a className="NavSubmenu__itemLink" href={menuItem.linkUrl} {...linkTargetProps}>
                    <span>{menuItem.title}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
