import React from "react";
import classNames from "classnames";
import { lowerFirst } from "lodash";
import { HighlightViewModel } from "./HighlightViewModel.csharp";
import { Richtext } from "../../../../Partials/RichtextArea/Richtext";
import { ResponsivePicture } from "../../../../react-components/responsivePicture/responsivePicture";

interface HighlightProps extends HighlightViewModel {
  index: number;
  onThumbnailClick: (index: number) => void;
}

export const Highlight = ({
  body,
  heading,
  layoutVariant,
  picture,
  pictureProfile,
  index,
  onThumbnailClick,
}: HighlightProps) => (
  <section
    className={classNames(
      "Highlight",
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      `Highlight--${lowerFirst(layoutVariant)}`,
    )}
  >
    {picture.url && (
      <div className="Highlight__imageSection">
        <div className="Highlight__imageContainer">
          <button
            className="Highlight__image"
            onClick={() => onThumbnailClick(index)}
            aria-label="Open in full screen gallery"
          >
            <ResponsivePicture model={picture} profile={pictureProfile} />
          </button>
        </div>
      </div>
    )}
    <div className="Highlight__textSection">
      <h2 className="Highlight__title">{heading}</h2>
      <Richtext className="Highlight__body">{body}</Richtext>
    </div>
  </section>
);
