import * as React from "react";
import {ProductCatalogItemCard} from "../ProductCatalogItemCard/ProductCatalogItemCard";
import {ProductCatalogItemViewModel} from "./ProductCatalogItemViewModel.csharp";

export interface ProductCatalogItemListProps {
    catalogItems: ProductCatalogItemViewModel[];
    vesselTypeFilters: number[];
    solutionFilters: number[];
}

export class ProductCatalogItemList extends React.Component<ProductCatalogItemListProps> {
    constructor(props: ProductCatalogItemListProps) {
        super(props);
    }

    render() {
        return (
            <div className="FilterableProductCatalog__itemsList wrapper">
                <ul className="cardsList__2col">
                    {this.props.catalogItems.filter(this.isProductCatalogItemVisible)  .map(x=> {
                        return this.makeProductCatalogItemCard(x);
                    })}
                </ul>
            </div>
        );
    }
    
    private makeProductCatalogItemCard = (model: ProductCatalogItemViewModel) => {

        let productCatalogItemUrl = model.productCatalogItemUrl;
        
        let hasVesselTypeFilters = this.props.vesselTypeFilters && this.props.vesselTypeFilters.length > 0;
        let hasSolutionFilters = this.props.solutionFilters && this.props.solutionFilters.length > 0;
        
        let shouldAppendQueryStringToUrl = hasVesselTypeFilters || hasSolutionFilters;
        if(shouldAppendQueryStringToUrl) {

            let queryStringParts: string[] = [];
            
            if(hasVesselTypeFilters) {
                queryStringParts.push("vesselType=" + this.props.vesselTypeFilters[0]);
            }
            
            if(hasSolutionFilters) {
                queryStringParts.push("solution=" + this.props.solutionFilters[0]);
            }
            
            if(productCatalogItemUrl.indexOf("?") === -1){
                productCatalogItemUrl += "?";
            }
            
            if(queryStringParts.length > 0){
                productCatalogItemUrl += queryStringParts.join("&");
            }
            
        }

        return <li key={productCatalogItemUrl}>
            <ProductCatalogItemCard model={{...model, productCatalogItemUrl: productCatalogItemUrl}} />
        </li>
        
    };

    private isProductCatalogItemVisible = (model: ProductCatalogItemViewModel) => {
        
        let isVisible = true;
        
        let relatedContentFilters = this.props.vesselTypeFilters.concat(this.props.solutionFilters);
        relatedContentFilters.forEach(value => {
            isVisible = isVisible && model.relatedContentIds.indexOf(value) !== -1;
        });
        
        return isVisible;
    }
}

