import React, { FC, useRef } from "react";
import classNames from "classnames";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { Options as SplideOptions } from "@splidejs/splide";
import { ButtonGhost } from "../Buttons/ButtonGhost";
import { useSections } from "./useSections";
import { SectionsNavbarSplide } from "./SectionsNavbarSplide";
import { SectionsNavbarSliderArrows } from "./SectionsNavbarSliderArrows";
import { IProductNavbarItemViewModel } from "../../Commerce/Product/ProductNavbar/Items/IProductNavbarItemViewModel.csharp";
import { NavbarTranslations } from "../../Common/Navbar/NavbarTranslations.csharp";
import { useGlobalStore } from "../../stores/globalStore";
import { useComputedStyle } from "../hooks/useComputedStyle";

interface SectionsNavbarSliderProps {
  sections: IProductNavbarItemViewModel[];
  translations: NavbarTranslations;
  itemsColor: "base" | "teal";
  containerId?: string;
}

const SECTIONS_CONTENT_ID = "SectionsContent";

export const SectionsNavbar: FC<SectionsNavbarSliderProps> = ({
  sections,
  translations,
  itemsColor,
  containerId,
  children,
}) => {
  const splideOptions = {
    label: translations.menuSlider.label,
    type: "slide",
    speed: 300,
    arrows: false,
    pagination: false,
    gap: "8px",
    autoWidth: true,
    width: "100%",
    padding: "1.5rem",
    focus: 1,
    updateOnMove: true,
    omitEnd: true,
    trimSpace: "move",
    mediaQuery: "min",
    breakpoints: {
      1025: {
        padding: 0,
      },
    },
    i18n: {
      prev: translations.menuSlider.prev,
      next: translations.menuSlider.next,
    },
  } as SplideOptions;

  const sliderRef = useRef<Splide>(null);
  const sectionsNavbarRef = useRef<HTMLElement>(null);

  const { setHeight } = useGlobalStore((state) => state.product.navbar);

  const changeSlide = (slideIndex: number) => {
    sliderRef.current?.go(slideIndex);
  };

  const filteredSections = sections.filter((section) => section.exists);

  const activeSections = useSections(filteredSections, changeSlide, containerId);

  useComputedStyle(sectionsNavbarRef, (styles) => setHeight(parseInt(styles.height)), {
    observeMutations: false,
  });

  return (
    <>
      <a className="SkipToContent" href={`#${SECTIONS_CONTENT_ID}`}>
        {translations.skipToContent}
      </a>
      <div className="wrapperStretched">
        <nav
          className="SectionsNavbar"
          aria-label={translations.navbarLabel}
          ref={sectionsNavbarRef}
        >
          <div className="SectionsNavbar__sliderWrapper">
            <Splide
              id="SectionsNavbarSplide"
              className="SectionsNavbar__slider"
              hasTrack={false}
              options={splideOptions}
              extensions={{ SectionsNavbarSplide }}
              tag="section"
              ref={sliderRef}
            >
              <SplideTrack>
                {filteredSections.map(({ label, anchor }, idx) => (
                  <SplideSlide key={idx} className="SectionsNavbar__slide">
                    <ButtonGhost
                      as="a"
                      href={`#${anchor}`}
                      mode={itemsColor === "base" ? "light" : "dark"}
                      color={itemsColor}
                      className={classNames("SectionsNavbar__slideLink", {
                        "SectionsNavbar__slideLink--active": activeSections.includes(anchor),
                      })}
                    >
                      {label}
                    </ButtonGhost>
                  </SplideSlide>
                ))}
              </SplideTrack>
              <SectionsNavbarSliderArrows color={itemsColor} />
            </Splide>
          </div>
          {children && <div className="SectionsNavbar__actionButtons">{children}</div>}
        </nav>
      </div>
    </>
  );
};
