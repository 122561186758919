import React, { FC } from "react";
import { GoogleMarkerClusterer } from "@react-google-maps/api";
import { OfficeLocationsMapMarker } from "../OfficeLocationsMapMarker";
import { OfficeLocationViewModel } from "../../OfficeLocationViewModel.csharp";
import { renderToStaticMarkup } from "react-dom/server";
import ClusterIcon from "../../../../../wwwroot/Static/Images/Icons/GoogleMapsCluster.svg";

interface OfficeLocationsClustererProps {
  locations: OfficeLocationViewModel[];
  markerUrl: string;
  selectedMarkerUrl: string;
  selectedOfficeId?: string;
  onMarkerSelect?: (selectedOffice: OfficeLocationViewModel, e?: google.maps.MapMouseEvent) => void;
  onMarkerDeselect?: () => void;
}

const OfficeLocationsClusterer: FC<OfficeLocationsClustererProps> = ({
  locations,
  markerUrl,
  selectedMarkerUrl,
  selectedOfficeId,
  onMarkerSelect,
  onMarkerDeselect,
}) => {
  const clusterIconSvg = window.btoa(`${renderToStaticMarkup(<ClusterIcon />)}`);

  return (
    <GoogleMarkerClusterer
      options={{
        renderer: {
          render: ({ count, position }) =>
            new google.maps.Marker({
              icon: {
                url: `data:image/svg+xml;base64,${clusterIconSvg}`,
                origin: new google.maps.Point(-2, -2),
              },
              position,
              zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
              label: {
                text: String(count),
                color: "#FFFFFF",
                fontSize: "12px",
                fontWeight: "600",
              },
            }),
        },
      }}
    >
      {(clusterer) => (
        <div>
          {locations.map(({ id, ...location }) => (
            <OfficeLocationsMapMarker
              key={id}
              location={{ id, ...location }}
              isSelected={id === selectedOfficeId}
              markerUrl={markerUrl}
              selectedMarkerUrl={selectedMarkerUrl}
              onSelect={onMarkerSelect}
              onDeselect={onMarkerDeselect}
              clusterer={clusterer}
            />
          ))}
        </div>
      )}
    </GoogleMarkerClusterer>
  );
};

export default OfficeLocationsClusterer;
