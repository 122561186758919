import React, { memo } from "react";
import { OfficeLocationsListItemAddressLine } from "./OfficeLocationsListItemAddressLine";
import { formatAddress } from "./OfficeLocationsListItem.utils";

interface OfficeLocationsListItemAddressProps {
  street?: string;
  city?: string;
  country?: string;
}

export const OfficeLocationsListItemAddress = memo<OfficeLocationsListItemAddressProps>(function OfficeLocationsListItemAddress({
  street,
  city,
  country,
}) {
  const showAddress = !!(street || city || country);

  return (
    showAddress ? (
      <p className="OfficeLocationsListItem__locationAddress">
        <OfficeLocationsListItemAddressLine>
          {formatAddress(street) + `,`}
        </OfficeLocationsListItemAddressLine>

        <OfficeLocationsListItemAddressLine>
          {city && country ? (
            formatAddress(city, country)
          ) : city}
        </OfficeLocationsListItemAddressLine>
      </p>
    ) : null
  );
});
