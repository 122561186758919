import { useCallback, useRef } from "react";

export const useAutoFocus = <T extends HTMLElement = HTMLElement>(enabled = true) => {
  const triggeredRef = useRef(false);

  const ref = useCallback(
    (element: T) => {
      if (element && enabled && !triggeredRef.current) {
        triggeredRef.current = true;
        element.focus();
      }
    },
    [enabled],
  );

  return ref;
};
