import React, { HTMLProps, cloneElement, forwardRef, isValidElement } from "react";
import { useTooltipContext } from "./useTooltip";
import { useMergeRefs } from "@floating-ui/react";

interface TooltipTriggerProps extends HTMLProps<HTMLButtonElement> {
  asChild?: boolean;
}

export const TooltipTrigger = forwardRef<HTMLButtonElement, TooltipTriggerProps>(function TooltipTrigger({
  children,
  asChild = false,
  ...props
}, propRef) {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
      })
    );
  }

  return (
    <button
      ref={ref}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});
