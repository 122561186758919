import { useState } from "react";

export const useAccordion = () => {
  const [isExpanded, setExpanded] = useState(false);

  const expand = () => setExpanded(true);
  const hide = () => setExpanded(false);
  const toggle = () => setExpanded((expanded) => !expanded);

  return {
    isExpanded,
    expand,
    hide,
    toggle,
  };
};
