import React from "react";
import { Toast, ToastProps } from "./Toast";
import { AnimatePresence } from "framer-motion";
import { Portal } from "../Portal";
import { AnimatedComponent } from "../AnimatedComponent/AnimatedComponent";
import { easeTransition } from "../AnimatedComponent/AnimatedComponent.animations";

interface ToastsContainerProps {
  toasts: ToastProps[];
}

export const ToastsContainer = ({ toasts }: ToastsContainerProps) => {
  return (
    <Portal>
      <div className="ToastsContainer">
        <AnimatePresence>
          {toasts.map((toast) => (
            <AnimatedComponent
              key={toast.toastId}
              tag="div"
              initial="fadedOut"
              entry="fadeIn"
              exit="fadeOut"
              overrideTransition={easeTransition}
            >
              <Toast {...toast} />
            </AnimatedComponent>
          ))}
        </AnimatePresence>
      </div>
    </Portal>
  );
};
