import React from "react";
import { Heading } from "../../../../react-components/Heading";
import { OfficeLocationsListItems } from "./OfficeLocationsListItems";
import { OfficeLocationsListProps } from "./OfficeLocationsList";
import { OfficeLocationsListNoOfficesReason } from "./OfficeLocationsListNoOfficesReason";

export const OfficeLocationsListNearbyLocations = ({
  locations,
  translations,
  selectedOfficeId,
  onListItemClick
}: OfficeLocationsListProps) => (
  <>
    <OfficeLocationsListNoOfficesReason
      title={translations.noResults.noOfficesInYourLocation.title}
      description={translations.noResults.noOfficesInYourLocation.description}
    />

    <Heading
      className="OfficeLocationsList__nearestOfficesTitle"
      level={2}
    >
      {translations.noResults.nearest}:
    </Heading>

    <OfficeLocationsListItems
      locations={locations}
      selectedOfficeId={selectedOfficeId}
      onListItemClick={onListItemClick}
      headingLevel={3}
      translations={translations}
    />
  </>
);
