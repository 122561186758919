import React, { ReactNode, memo } from "react";

interface OfficeLocationsListItemAddressLineProps {
  children: ReactNode;
}

export const OfficeLocationsListItemAddressLine = memo<OfficeLocationsListItemAddressLineProps>(function OfficeLocationsListItemAddressLine({
  children,
}) {
  return (
    children ? (
      <span className="OfficeLocationsListItem__locationAddressLine">
        {children}
      </span>
    ) : null
  );
});
