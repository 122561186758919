import React, {FC, useMemo, useState} from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";
import { ImageWithFactsSingleFact } from "./ImageWithFactsSingleFact";
import { ComponentProps } from "../ComponentProps.csharp";
import { ImageAndFactViewModel } from "../../Commerce/Product/ImageAndFactViewModel.csharp";
import { ImagesAndFactsViewModel } from "../../Commerce/Product/ImagesAndFactsViewModel.csharp";
import { ResponsivePicture } from "../../react-components/responsivePicture/responsivePicture";

interface ImageWithFact extends ImageAndFactViewModel {
  id: string;
}

export const ImageWithFactsViewer: FC<ComponentProps<ImagesAndFactsViewModel>> = ({
  model: { facts, mainImage, mainLabel },
}) => {
  const [selectedFact, setSelectedFact] = useState<ImageWithFact | null>(null);

  const factsList = useMemo(() => facts.map(fact => ({
    ...fact,
    id: uniqueId("ImageWithFactsViewerSingleFact")
  })), []);

  const getActiveImage = () => {
      if (selectedFact === null || selectedFact.image === null){
          if (mainImage !== null) {
              return <ResponsivePicture
                  model={mainImage.model}
                  profile={mainImage.profile}
              />
          } else {
              return null
          }
      }

      return <ResponsivePicture
          model={selectedFact.image.model}
          profile={selectedFact.image.profile}
      />
  }

  return (
    <div className="ImageWithFactsViewer">
      <ul className="ImageWithFactsViewer__list">
        {mainLabel && <li className="ImageWithFactsViewer__listItem ImageWithFactsViewer__listItem--mainItem">
          <button
            className={classNames(
              "ImageWithFactsViewer__button",
              "ImageWithFactsViewer__button--mainView",
              {
                "ImageWithFactsViewer__button--active": selectedFact === null,
              },
            )}
            onClick={() => setSelectedFact(null)}
          >
              {mainLabel}
          </button>
        </li>}

        {factsList.map((fact) => (
          <li key={fact.id} className="ImageWithFactsViewer__listItem">
            <ImageWithFactsSingleFact
              id={fact.id}
              title={fact.title}
              description={fact.description}
              isSelected={fact.id === selectedFact?.id}
              onSelect={() => setSelectedFact(fact)}
              onDeselect={() => setSelectedFact(null)}
            />
          </li>
        ))}
      </ul>

      <div className="ImageWithFactsViewer__imageContainer">
          {getActiveImage()}
      </div>
    </div>
  );
};
