import React, { FC } from "react";
import { Link } from "../../react-components/Link/Link";
import { ShareModal } from "../../react-components/Modal/ShareModal/ShareModal";
import { useModal } from "../../react-components/Modal/useModal";
import { ShareProps } from "./ShareProps.csharp";
import { ComponentProps } from "../ComponentProps.csharp";
import ShareIcon from "./../../../wwwroot/Static/Images/Icons/Share.svg";

export const Share: FC<ComponentProps<ShareProps>> = ({
  model: { shareButtonText, shareModalTranslations },
}) => {
  const { isOpen, toggleModal } = useModal();

  return (
    <>
      <Link
        className="EventCampaignPageHeader__link"
        icon={ShareIcon}
        iconPosition="right"
        mode="dark"
        as="button"
        onClick={toggleModal}
      >
        {shareButtonText}
      </Link>

      <ShareModal isOpen={isOpen} onClose={toggleModal} translation={shareModalTranslations} />
    </>
  );
};
