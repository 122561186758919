import classNames from "classnames";
import React, { FC } from "react";
import { useMicrositeContext } from "../../Utils/micrositeContext";

interface BackButtonProps {
  onClick: () => void;
  type?: 'outline' | 'solid';
  className?: string;
  tabIndex?: number;
  mobileOnly?: boolean;
  tabletPlusOnly?: boolean;
}

export const BackButton: FC<BackButtonProps> = ({
  onClick,
  className,
  tabIndex,
  mobileOnly,
  tabletPlusOnly,
  type = 'outline'
}) => {
  const { theme } = useMicrositeContext();
  return (
    <button
      tabIndex={tabIndex}
      className={classNames(
        'MicrositeSection__backButton',
        `MicrositeSection__backButton--${theme}`,
        `MicrositeSection__backButton--${type}`,
        className, {
        "MicrositeSection__backButton--mobileOnly": mobileOnly,
        "MicrositeSection__backButton--tabletPlus": tabletPlusOnly,
      }
      )}
      onClick={onClick}
    >
      <span className="MicrositeSection__buttonOutline" />
      <span className="visuallyHidden">Go back</span>
    </button>
  );
};
