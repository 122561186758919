import React from "react";

interface FiltersCount {
  count: number;
}

export const FiltersCount = ({ count }: FiltersCount) =>
  count > 0 ? (
    <div className="FiltersCount" aria-label={`Number of selected filters: ${count}`}>
      {count}
    </div>
  ) : null;
