import * as React from 'react';
import { ProductsAccordionItemViewModel } from './ProductsAccordionItemViewModel.csharp';
import { ProductCatalogItemCard } from '../ProductCatalogItemCard/ProductCatalogItemCard';
import { ProductCatalogItemViewModel } from '../FilterableProductCatalogItemList/ProductCatalogItemViewModel.csharp';

interface ProductsAccordionItemProps {
    item: ProductsAccordionItemViewModel; 
    index: number;
    sortFunction: (a: ProductCatalogItemViewModel, b: ProductCatalogItemViewModel) => number;
}

export class ProductsAccordionItem extends React.Component<ProductsAccordionItemProps> {
    render() {
        const { item, index } = this.props;
        return (!item.extended && item.cards && item.cards.length > 0)
            ? this.renderCollapsed(item, index)
            : this.renderExtended(item);
    }
    
    private renderExtended(item: ProductsAccordionItemViewModel) {
        return (
            <React.Fragment key={item.heading || item.intro || item.url}>
                <div className='ProductsAccordionList__extendedItem'>
                    {item.heading && <h4>{item.heading}</h4>}
                    {item.intro && <div dangerouslySetInnerHTML={{ __html: item.intro }}/>}
                    {item.url && (
                        <a href={item.url}>
                            <span className='Btn--explore'>{item.urlText}</span>
                        </a>
                    )}
                    {item.cards && item.cards.length > 0 && 
                        <ul className='cardsList__2col'>
                            {this.renderSortedProductList(item.cards)}
                        </ul>
                    }
                </div>
            </React.Fragment>
        );
    }
    
    private renderCollapsed(item: ProductsAccordionItemViewModel, index: number) {
        return <React.Fragment key={index}>{this.renderSortedProductList(item.cards)}</React.Fragment>;
    }
    
    private renderSortedProductList(cards: ProductCatalogItemViewModel[]) {
        return cards.sort(this.props.sortFunction).map(x => (<li key={x.title}>
            <ProductCatalogItemCard model={x} />
        </li>));
    }
}