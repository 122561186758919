import { ComponentConstructor, EventInterface, EventMap } from "@splidejs/splide";

export const SectionsNavbarSplide: ComponentConstructor = (Splide) => {
  const { on } = EventInterface(Splide);

  const handleOverflow: EventMap["overflow"] = (isOverflow) => {
    if (isOverflow) {
      Splide.options = {
        arrows: true,
        drag: true,
        focus: 1,
      };
    } else {
      Splide.options = {
        arrows: false,
        drag: false,
        focus: 0,
      };
    }
  };

  on("overflow", handleOverflow);

  return {};
};
