import isAboveTablet from "../../../Animations/isAboveTablet";
import isIE from "../../../Animations/isIE";

function darkenOnLeave() {
    function createObserver() {
        let thresholds = [];

        for (let i = 0; i <= 1; i += 0.01) {
            thresholds.push(i);
        }

        const options = {
            threshold: thresholds
        };

        const observer = new IntersectionObserver(handleSectionIntersect, options);
        const overlays: NodeListOf<HTMLElement> = document.querySelectorAll(".StoryTextContent__overlay");

        for (let i = 0; i < overlays.length; i++) {
            observer.observe(overlays[i]);
        }
        
        return { observer, overlays };
    }

    function handleSectionIntersect(entries: IntersectionObserverEntry[]) {
        for (let entry of entries) {
            let overlay = <HTMLElement>entry.target;
            if (overlay) {
                overlay.style.opacity = `${changeOpacity(entry.boundingClientRect.bottom, 350, 50, easeInOutQuad)}`;
            }
        }
    }

    function changeOpacity(bottom: number, lowerBound: number, upperBound: number, easeFunc: any): number {
        let result =
            (bottom > lowerBound) ? 1 :
                (bottom < upperBound) ? 0 :
                    ((bottom - upperBound) / (lowerBound - upperBound));
        return easeFunc(result);
    }

    function easeInOutQuad(t: any) {
        return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }
    
    let observer : IntersectionObserver;
    let overlays : NodeListOf<HTMLElement>;
    
    function toggleAnimations() {
        if (isAboveTablet().matches) {
            ({ observer, overlays } = createObserver());
        } else {
            if (observer && overlays) {
                overlays.forEach(item => item.style.opacity = '1');
                observer.disconnect();
            }
        }
    }    
   
    toggleAnimations();

    if (!isIE()) {
        isAboveTablet().addListener(toggleAnimations);
    }
}

document.addEventListener("DOMContentLoaded", darkenOnLeave, false);