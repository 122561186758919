import React, { FC, ReactNode, useState } from "react";
import { ButtonOutlined } from "../../../react-components/Buttons/ButtonOutlined";
import { ButtonFill } from "../../../react-components/Buttons/ButtonFill";
import { AssetHelper, WorldMapInteractiveKey } from "../../../react-components/assetHelper";
import Copy from "../../../../wwwroot/Static/Images/Icons/Copy.svg";
import { useIsBelowScreenSize } from "../../../react-components/useIsBelowScreenSize";
import { useClipboard } from "../../../react-components/hooks/useClipboard";
import { Tooltip } from "../../../react-components/Discovery/Tooltip/Tooltip";
import { TooltipTrigger } from "../../../react-components/Discovery/Tooltip/TooltipTrigger";
import { TooltipContent } from "../../../react-components/Discovery/Tooltip/TooltipContent";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { ContactSectionHeroProps } from "../../ContactSectionHeroProps.csharp";

interface ContactSectionHeroTooltipProps {
  label: string;
  children: ReactNode;
}

const ContactSectionHeroTooltip = ({ children, label }: ContactSectionHeroTooltipProps) => (
  <Tooltip trigger="click" dismissAfterMs={3000}>
    <TooltipTrigger asChild>{children}</TooltipTrigger>
    <TooltipContent zIndex={1}>{label}</TooltipContent>
  </Tooltip>
);

export const ContactSectionHero: FC<ComponentProps<ContactSectionHeroProps>> = ({ model }) => {
  const [activeMap, setActiveMap] = useState<WorldMapInteractiveKey>("none");
  const isMobile = useIsBelowScreenSize("tablet");
  const { copy } = useClipboard();

  const onMouseOver = (key: WorldMapInteractiveKey) => {
    if (!isMobile) {
      setActiveMap(key);
    }
  };

  const onMouseOut = () => {
    if (!isMobile) {
      setActiveMap("none");
    }
  };

  return (
    <div className="ContactSectionHero">
      <div className="ContactSectionHero__wrapper wrapper">
        <div className="ContactSectionHero__map">
          <img
            className="ContactSectionHero__mapImage"
            src={AssetHelper.worldMapInteractive(activeMap)}
            alt=""
          />
        </div>
        <div className="ContactSectionHero__content">
          {model.title && <h1 className="ContactSectionHero__title">{model.title}</h1>}

          <dl className="ContactSectionHero__contactsList">
            {model.globalPhone && (
              <>
                <dt>{model.translations.telephone}:</dt>
                <dd>
                  <ContactSectionHeroTooltip label={model.translations.copied}>
                    <ButtonOutlined
                      color="blue"
                      icon={Copy}
                      iconPosition="right"
                      onMouseOver={() => onMouseOver("global")}
                      onMouseOut={onMouseOut}
                      onClick={() => copy(model.globalPhone)}
                    >
                      {model.globalPhone}
                    </ButtonOutlined>
                  </ContactSectionHeroTooltip>
                </dd>
              </>
            )}

            {model.emeaContact && (
              <>
                <dt>{model.translations.emea}:</dt>
                <dd>
                  <ContactSectionHeroTooltip label={model.translations.copied}>
                    <ButtonOutlined
                      color="blue"
                      icon={Copy}
                      iconPosition="right"
                      onMouseOver={() => onMouseOver("europe-middle-east-africa")}
                      onMouseOut={onMouseOut}
                      onClick={() => copy(model.emeaContact)}
                    >
                      {model.emeaContact}
                    </ButtonOutlined>
                  </ContactSectionHeroTooltip>
                </dd>
              </>
            )}

            {model.asiaPacificContact && (
              <>
                <dt>{model.translations.asiaPacific}:</dt>
                <dd>
                  <ContactSectionHeroTooltip label={model.translations.copied}>
                    <ButtonOutlined
                      color="blue"
                      icon={Copy}
                      iconPosition="right"
                      onMouseOver={() => onMouseOver("asia-pacific")}
                      onMouseOut={onMouseOut}
                      onClick={() => copy(model.asiaPacificContact)}
                    >
                      {model.asiaPacificContact}
                    </ButtonOutlined>
                  </ContactSectionHeroTooltip>
                </dd>
              </>
            )}

            {model.americasContact && (
              <>
                <dt>{model.translations.americas}:</dt>
                <dd>
                  <ContactSectionHeroTooltip label={model.translations.copied}>
                    <ButtonOutlined
                      color="blue"
                      icon={Copy}
                      iconPosition="right"
                      onMouseOver={() => onMouseOver("americas")}
                      onMouseOut={onMouseOut}
                      onClick={() => copy(model.americasContact)}
                    >
                      {model.americasContact}
                    </ButtonOutlined>
                  </ContactSectionHeroTooltip>
                </dd>
              </>
            )}

            {model.globalEmail && (
              <>
                <dt>{model.translations.email}:</dt>
                <dd>
                  <ContactSectionHeroTooltip label={model.translations.copied}>
                    <ButtonOutlined
                      color="blue"
                      icon={Copy}
                      iconPosition="right"
                      onMouseOver={() => onMouseOver("global")}
                      onMouseOut={onMouseOut}
                      onClick={() => copy(model.globalEmail)}
                    >
                      {model.globalEmail}
                    </ButtonOutlined>
                  </ContactSectionHeroTooltip>
                </dd>
              </>
            )}
          </dl>

          {model.cta && (
            <ButtonFill
              className="ContactSectionHero__button"
              color="blue"
              as="a"
              href={model.cta.href}
            >
              {model.cta.text}
            </ButtonFill>
          )}
        </div>
      </div>
    </div>
  );
};
