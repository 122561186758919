import React, { FC } from "react";
import { ContactTileViewModel } from "../ContactTileViewModel.csharp";
import { ResponsivePicture } from "../../../../react-components/responsivePicture/responsivePicture";
import { ComponentProps } from "../../../ComponentProps.csharp";
import FbIcon from "../../../../../wwwroot/Static/Images/Icons/SocialMedia/Facebook.svg";
import IgIcon from "../../../../../wwwroot/Static/Images/Icons/SocialMedia/Instagram.svg";
import LiIcon from "../../../../../wwwroot/Static/Images/Icons/SocialMedia/LinkedIn.svg";
import ThreadsIcon from "../../../../../wwwroot/Static/Images/Icons/SocialMedia/Threads.svg";
import XIcon from "../../../../../wwwroot/Static/Images/Icons/SocialMedia/X.svg";
import { LectureTimePlace } from "../LectureTimePlace/LectureTimePlace";
import { Plus } from "../../../../react-components/Icons/Plus";

export const ContactTile: FC<ComponentProps<ContactTileViewModel>> = ({
  model: { name, photo, title, socialMedia },
}) => {
  return (
    <section className="ContactTile__container">
      <h3 className="ContactTile__name">{name}</h3>
      <p className="ContactTile__position">{title}</p>
      {photo && <ResponsivePicture model={photo.model} profile={photo.profile} />}
      <ul className="ContactTile__socials">
        {socialMedia.facebookUrl && (
          <li className="ContactTile__social">
            <a href={socialMedia.facebookUrl} target="_blank" rel="noreferrer">
              <FbIcon aria-label="Facebook" />
            </a>
          </li>
        )}
        {socialMedia.instagramUrl && (
          <li className="ContactTile__social">
            <a href={socialMedia.instagramUrl} target="_blank" rel="noreferrer">
              <IgIcon aria-label="Instagram" />
            </a>
          </li>
        )}
        {socialMedia.linkedInUrl && (
          <li className="ContactTile__social">
            <a href={socialMedia.linkedInUrl} target="_blank" rel="noreferrer">
              <LiIcon aria-label="LinkedIn" />
            </a>
          </li>
        )}
        {socialMedia.threadsUrl && (
          <li className="ContactTile__social">
            <a href={socialMedia.threadsUrl} target="_blank" rel="noreferrer">
              <ThreadsIcon aria-label="Threads" />
            </a>
          </li>
        )}
        {socialMedia.xUrl && (
          <li className="ContactTile__social">
            <a href={socialMedia.xUrl} target="_blank" rel="noreferrer">
              <XIcon aria-label="X" />
            </a>
          </li>
        )}
      </ul>
      <LectureTimePlace />
      <a href="http://test.com" className="ContactTile__readMore">
        read more <Plus aria-hidden="true" />
      </a>
    </section>
  );
};
