import React, { FC } from "react";
import { FactBoxTileViewModel } from "../../../Partials/FactBox/Variants/Tiles/FactBoxTileViewModel.csharp";
import { ComponentProps } from "../../../Partials/ComponentProps.csharp";
import { Tile } from "../../../react-components/Discovery/Tile/Tile";
import { AssetHelper } from "../../../react-components/assetHelper";

export const ProductTile: FC<ComponentProps<FactBoxTileViewModel>> = ({
  model: { title, content, picture, tileSize, linkUrl },
}) => {
  return (
    <Tile
      className="ProductTile"
      description={content}
      title={title}
      picture={picture}
      fallbackImageUrl={AssetHelper.images.placeholder}
      href={linkUrl}
      size={tileSize}
    />
  );
};
