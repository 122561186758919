import React from "react";
import { GroupBase, PlaceholderProps } from "react-select";
import { RadioSelectOption } from "./RadioSelect.types";

export const Placeholder = ({ children, innerProps }: PlaceholderProps<RadioSelectOption, false, GroupBase<RadioSelectOption>>) => {
  return (
    <div className="RadioSelect__placeholder" {...innerProps}>
      {children}
    </div>
  )
};
