import React, { FC, useEffect, useRef } from "react";
import classNames from "classnames";

import { useIsInView } from "../../Utils/useIsInView";
import { BackButton } from "../SectionBackButton/BackButton";

import { MicrositeProductViewModel } from "../../Product/MicrositeProductViewModel.csharp";
import { useMicrositeContext } from "../../Utils/micrositeContext";


interface MicrositeSectionContentProps {
  heading: string;
  description: string;
  products: MicrositeProductViewModel[];
  setProduct: (product: MicrositeProductViewModel) => void;
  setIntersection: (value: boolean) => void;
  backButtonClick: () => void;
}

export const MicrositeSectionContent: FC<MicrositeSectionContentProps> = ({
  heading,
  description,
  products,
  setProduct,
  setIntersection,
  backButtonClick
}) => {
  const viewerRef = useRef(null);
  const { intersectionEntry } = useIsInView(viewerRef, {});
  const { theme, setIsProductBackButtonSticky } = useMicrositeContext();

  useEffect(() => {
    const value = intersectionEntry?.isIntersecting ?? true;
    const shouldHideLogo = window.matchMedia('(max-width: 765px').matches
    shouldHideLogo && setIsProductBackButtonSticky(!value)
    setIntersection(value)
  }, [intersectionEntry?.isIntersecting])

  const listRenderer = (product: MicrositeProductViewModel) => (
    <li className="MicrositeSectionContent__listItem" key={product.id}>
      <button className="MicrositeSectionContent__button" onClick={() => setProduct(product)}>
        {product.heading}
      </button>
    </li>
  )

  return (
    <div className={classNames("MicrositeSectionContent", `MicrositeSectionContent--${theme}`)}>
      <div className="MicrositeSectionContent__outer">
        <BackButton
          onClick={backButtonClick}
          className="MicrositeSectionContent__backButton"
          tabletPlusOnly
        />
        <div className="MicrositeSectionContent__inner">
          <BackButton
            onClick={backButtonClick}
            className="MicrositeSectionContent__backButton"
            type={intersectionEntry?.isIntersecting ? 'outline' : 'solid'}
            mobileOnly
          />
          <h2 className="MicrositeSectionContent__heading">{heading}</h2>
          <div
            className="MicrositeSectionContent__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {products.length > 0 && (
            <ul className="MicrositeSectionContent__list">
              {products.map(listRenderer)}
            </ul>
          )}
          <div ref={viewerRef} className="MicrositeSectionContent__observer" />
        </div>
      </div>
    </div>
  );
};
