import * as React from "react";
import {OfficeLocationViewModel} from "./OfficeLocationViewModel.csharp";
import {OfficeLocationsListTranslations} from "./OfficeLocationsListTranslations.csharp";

export interface OfficeLocationsListProps {
    officeLocations: OfficeLocationViewModel[];
    loadMoreButtonVisible: boolean;
    onShowMore: () => void;
    translations: OfficeLocationsListTranslations;
}

export const OfficeLocationsList = (props: OfficeLocationsListProps) => (
    <>
        <ul className="ResultsList" aria-live="polite" aria-relevant="additions">
            {props.officeLocations.map(officeLocation => {
                const email = officeLocation.emailAddress && officeLocation.emailAddress.split('@');
                
                return (
                    <li className="ResultsList__item" key={officeLocation.readMoreUrl}>
                        <div className="OfficeLocationItem">
                            <h2 className="OfficeLocationItem__title">{officeLocation.officeName}</h2> 
                            <span className="OfficeLocationItem__location">{officeLocation.address.country}, {officeLocation.address.city}</span> 
                            <span className="OfficeLocationItem__contact">  
                                {officeLocation.phoneNumber && <span>{props.translations.phoneText} {officeLocation.phoneNumber}</span>}
                                {email && email.length === 2 && <a href={`mailto:${officeLocation.emailAddress}`}>{email[0]}<wbr />@{email[1]}</a>}
                            </span>
                            <span className="OfficeLocationItem__button"><a href={officeLocation.readMoreUrl} className="Btn--secondary">{props.translations.readMoreText}</a></span>
                        </div>
                    </li>
                );
            })}
        </ul>
        {props.loadMoreButtonVisible && <div className="OfficeLocation__buttons"><button className="Btn--secondary" onClick={props.onShowMore}>{props.translations.showMoreText}</button></div>}
    </>
);