import classNames from "classnames";
import React from "react";

export interface RadioProps {
  name?: string;
  value?: string;
  hasInput?: boolean;
  selected?: boolean;
  disabled?: boolean;
  focused?: boolean;
}

export const Radio = ({
  hasInput = true,
  selected,
  disabled,
  focused,
  name,
  value,
}: RadioProps) => {
  return (
    <label className={classNames("Radio", {
      "Radio--selected": selected && !disabled,
      "Radio--disabled": disabled,
      "Radio--focused": focused && !disabled,
    })}>
      {hasInput && (
        <input
          className="Radio__input"
          type="radio"
          name={name}
          value={value}
        />
      )}
      <span className="Radio__inner" />
    </label>
  );
};
