import * as React from "react";
import {ResponsivePicture} from "../../react-components/responsivePicture/responsivePicture";
import classNames from "classnames";
import {ContentCardVariant} from "./ContentCardVariant.csharp";
import {ContentCardProps} from "./ContentCardProps.csharp";
import {formatDate} from "../../react-components/dateUtil";
import { CategoryViewModel } from "../../Pages/NewsArchivePage/CategoryViewModel.csharp";

const variantClassModifiers : { [k : number] : string } = {};
variantClassModifiers[ContentCardVariant.Standard] = "standard";
variantClassModifiers[ContentCardVariant.Featured] = "featured";
variantClassModifiers[ContentCardVariant.Wide] = "wide";
variantClassModifiers[ContentCardVariant.WideAlignBottom] = "wideAlignBottom";

interface ContentCardPropsExtended extends ContentCardProps {
    onCategoryClick?: (categoryId: number) => void;
    selectableCategoryIds?: number[];
}

export class ContentCard extends React.Component<ContentCardPropsExtended> {
    private readonly linkRef = React.createRef<HTMLAnchorElement>();

    constructor(props: Readonly<ContentCardPropsExtended>) {
        super(props);
        this.linkRef = React.createRef();
    }

    componentDidMount(): void {
        let link = this.linkRef.current;
        if(link && this.props.isFocused) {
            link.focus();
        }
    }

    onCategoryButtonClick(e: React.MouseEvent<HTMLElement>, category: CategoryViewModel) {
        e.preventDefault();
        this.props.onCategoryClick?.(category.id)
    }

    public render() {
        const { model, variant, isDateVisible, isMoreButtonVisible, readMoreText, isLinkVisible, isPictureVisible, isCategoryVisible = false, isTitleCapitalized, isExcerptVisible } = this.props;
        const dateValue = formatDate(model.publishDate, 'en-gb', 'YYYY-MM-DD');

        const publishDate = isDateVisible &&
            (<div className="ContentCard__date">
                <time dateTime={dateValue}>{formatDate(new Date(model.publishDate), model.currentCulture)}</time>
            </div>);

        const showMoreLink = isMoreButtonVisible &&
            (
                <div className="ContentCard__link">
                    <span className="ButtonPrimary">{readMoreText}</span>
                </div>
            );

        const renderPicture = isPictureVisible &&
            (
                <div className="ContentCard__pictureContainer">
                    <div className="ContentCard__picture">
                        <ResponsivePicture {...this.props.picture}/>
                    </div>
                </div>
            );

        const renderLink = isLinkVisible &&
            (
                <span className="ContentCard__textLink">{model.contentUrl}</span>
            );

        const showCategories = isCategoryVisible && model.categories.length > 0 &&
            (
                <ul className="ContentCard__categories" aria-label="Assigned categories">
                    {model.categories.map((category) => {
                        const disabled = !this.props.selectableCategoryIds?.find((id) => id === category.id)

                        return (
                            <li key={category.id} className="ContentCard__category">
                                <button
                                    className={classNames("ContentCard__categoryButton", { ["ContentCard__categoryButton--disabled"]: disabled })}
                                    onClick={(e) => this.onCategoryButtonClick(e, category)}
                                    disabled={disabled}
                                >
                                    {category.name.toLocaleLowerCase()}
                                </button>
                            </li>
                        )
                    })}
                </ul>
            );

        const showExcerpt = isExcerptVisible && model.text.length > 0 && 
            (
                <p>{model.text}</p>
            );

        return (<div className={`ContentCard ContentCard--${variantClassModifiers[variant]}`} >
            <a href={model.contentUrl} ref={this.linkRef}>
                <div className="ContentCard__body">
                    {renderPicture}
                    <div className={isPictureVisible ? `ContentCard__text` : `ContentCard__text ContentCard__text--noImage`}>
                        {variant === ContentCardVariant.WideAlignBottom && publishDate}
                        <div className="ContentCard__textInner">
                            {showCategories}
                            <h3 className={classNames('ContentCard__title', { 'ContentCard__title--capitalized' : isTitleCapitalized})}>
                                {model.title}
                            </h3>
                            {showExcerpt}
                            {(variant === ContentCardVariant.Featured || variant === ContentCardVariant.Wide) && publishDate}
                            {renderLink}
                            {showMoreLink && (variant === ContentCardVariant.WideAlignBottom ||  variant === ContentCardVariant.Featured) && showMoreLink}
                            {variant === ContentCardVariant.WideAlignBottom && (
                                <span className={"ContentCard__arrow"} />
                            )}
                        </div>
                    </div>
                </div>
                {variant !== ContentCardVariant.WideAlignBottom &&  variant !== ContentCardVariant.Featured && (isPictureVisible || showMoreLink || publishDate) && 
                    <div className="ContentCard__footer">
                        {variant === ContentCardVariant.Standard && publishDate}
                        <div className={"ContentCard__footer-link"}>
                            {showMoreLink}
                            <span className={"ContentCard__arrow"}/>
                        </div>
                    </div>
                }
                
            </a>
        </div>)
    }
}
