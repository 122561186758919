function animateBackgroundImage() {
    const firstTextAndInherited = <HTMLElement>document.querySelector(".StoryPageSection:first-child .StoryTextContent--inherited");

    let nextSection: HTMLElement;

    if (firstTextAndInherited != null) {
        nextSection = <HTMLElement>document.querySelector("#page-section-1");
    } else {
        nextSection = <HTMLElement>document.querySelector("#page-section-0");
    }

    const backgroundImage = <HTMLElement>document.querySelector("#StoryHeaderInfo__background");
    const height = window.innerHeight;

    function update() {
        let sectionBottom = nextSection.getBoundingClientRect().bottom;

        if (sectionBottom > 0) {
            if (sectionBottom < height) {
                backgroundImage.style.height = `${sectionBottom}px`;
            } else {
                backgroundImage.style.height = `${height}px`;
            }
        } else {
            backgroundImage.style.height = `0`;
        }

        window.requestAnimationFrame(update);
    }

    if (backgroundImage) {
        backgroundImage.style.backgroundSize = `auto ${height}px`;
        backgroundImage.style.backgroundPositionY = `top`;
        backgroundImage.style.overflow = `hidden`;
        window.requestAnimationFrame(update);
    }
}

document.addEventListener("DOMContentLoaded", animateBackgroundImage, false);
document.addEventListener("resize", animateBackgroundImage, false);
