import Splide, {
  EventInterface,
  EventMap,
  SlideComponent,
  Options,
  Components,
} from "@splidejs/splide";
import { ContentListBlockSliderBase } from "./ContentListBlockSliderBase";

export class ContentListBlockSliderCarousel<
  T extends HTMLElement,
> extends ContentListBlockSliderBase<T> {
  protected currentSlideIndex = 0;

  protected TRANSITION_TIMING_FUNCTION = "ease-in-out";
  protected TRANSITION_SPEED = 600;
  protected HIDDEN_SLIDE_OPACITY = 0.2;
  protected VISIBLE_SLIDE_OPACITY = 1;

  protected options: Options = {
    perPage: 1,
    autoWidth: false,
    type: "loop",
    gap: 8,
    breakpoints: {
      768: {
        gap: 10,
      },
    },
    speed: this.TRANSITION_SPEED,
    easing: this.TRANSITION_TIMING_FUNCTION,
    drag: false,
    clones: 3,
    waitForTransition: true,
  };

  public constructor(splideElement: T) {
    super(splideElement);

    this.initializeSlider();
  }

  protected getComponent(slider: ContentListBlockSliderCarousel<T>) {
    return (Splide: Splide, Components: Components) => {
      const { on } = EventInterface(Splide);

      const handleClick: EventMap["click"] = (slide, event) => {
        if (Splide.index !== slide.index) {
          event.preventDefault();

          Splide.go(slide.index);
        }
      };

      const handleMove: EventMap["move"] = (newIndex, prevIndex, destIndex) => {
        if (newIndex === prevIndex || newIndex === this.currentSlideIndex) return;

        const slidesCount = Components.Slides.getLength(true);
        const lastSlideIndex = slidesCount - 1;

        const newSlide = slider.getSlide(newIndex);
        const prevSlide = slider.getSlide(prevIndex);

        let newSlideClone: SlideComponent | undefined;
        let prevSlideClone: SlideComponent | undefined;
        let slidesBetween: SlideComponent[] = [];

        // if user moves from last to first slide
        if (newIndex === 0 && prevIndex === lastSlideIndex) {
          newSlideClone = slider.getSlide(0);
          prevSlideClone = slider.getSlide(-1);
          // if user moves from first to last slide
        } else if (newIndex === lastSlideIndex && prevIndex === 0) {
          newSlideClone = slider.getSlide(lastSlideIndex);
          prevSlideClone = slider.getSlide(lastSlideIndex + 1);
        }

        // if user moves more than one slide
        if (Math.abs(newIndex - prevIndex) > 1 && destIndex > -1 && destIndex < slidesCount) {
          slidesBetween = slider.getSlides(true).filter((_, index) => {
            if (newIndex > prevIndex) {
              return index < newIndex && index > prevIndex;
            } else {
              return index > newIndex && index < prevIndex;
            }
          });
        }

        if (prevSlideClone) {
          slider.animateSlideOpacity(prevSlideClone, "exit");
        }

        if (newSlideClone) {
          slider.animateSlideOpacity(newSlideClone, "entry");
        }

        if (newSlide) {
          slider.animateSlideOpacity(newSlide, "entry");
        }

        if (prevSlide) {
          slider.animateSlideOpacity(prevSlide, "exit");
        }

        if (slidesBetween.length > 0) {
          slidesBetween.forEach((slide) => {
            slider.animateSlideOpacity(slide, "both");
          });
        }
      };

      const handleMoved = () => {
        this.currentSlideIndex = this.splide.index;
      };

      const handleResize = () => {
        slider.handleResize();
      };

      const mount = () => {
        this.currentSlideIndex = this.splide.index;

        handleResize();

        slider.getSlides().forEach((slide) => {
          if (slide.index !== this.splide.index) {
            slider.setOpacity(slide, this.HIDDEN_SLIDE_OPACITY);
          }
        });
      };

      on("resize", handleResize);
      on("click", handleClick);
      on("move", handleMove);
      on("moved", handleMoved);

      return {
        mount,
      };
    };
  }

  protected setOpacity(slide: SlideComponent, opacity: number) {
    slide.style("opacity", opacity);
  }

  protected getSlideOpacityKeyframes() {
    const keyframes = [{ opacity: 0.2 }, { opacity: 1.0 }];

    return keyframes;
  }

  protected getSlideOpacityAnimation(
    slide: SlideComponent,
    reverse = false,
    speed = this.TRANSITION_SPEED,
  ) {
    const animation = slide.slide.animate(this.getSlideOpacityKeyframes(), {
      duration: speed,
      easing: this.TRANSITION_TIMING_FUNCTION,
      direction: reverse ? "reverse" : "normal",
      fill: "forwards",
    });

    return animation;
  }

  protected animateSlideOpacity(
    slide: SlideComponent,
    direction: "entry" | "exit" | "both" = "entry",
  ) {
    let animation;
    let speed = this.TRANSITION_SPEED;

    if (direction === "both") {
      speed /= 2;
    }

    if (direction !== "exit") {
      animation = this.getSlideOpacityAnimation(slide, false, speed);
      animation.play();
    }

    if (direction !== "entry") {
      if (animation) {
        animation.onfinish = () => {
          animation = this.getSlideOpacityAnimation(slide, true, speed);
          animation.play();
        };
      } else {
        animation = this.getSlideOpacityAnimation(slide, true, speed);
        animation.play();
      }
    }
  }
}
