import React, { FC } from "react";
import { MicrositeLandingPageViewModel } from "./MicrositeLandingPageViewModel.csharp";
import { MicrositeHero } from "./Hero/MicrositeHero";
import { MicrositeHeader } from "./Header/MicrositeHeader";

interface MicrositeLandingPageProps extends MicrositeLandingPageViewModel {
  goToSection: (id: number) => void;
}

export const MicrositeLandingPage: FC<MicrositeLandingPageProps> = ({
  heading,
  subheading,
  overviewViewModel,
  hotSpots,
  pictureProps,
  goToSection,
}) => {
  return (
    <>
      <div className="Microsite__content">
        <MicrositeHeader
          heading={heading}
          subheading={subheading}
          overviewViewModel={overviewViewModel}
          pictureProps={pictureProps}
        />
      </div>
      <MicrositeHero
        goToSection={goToSection}
        hotSpots={hotSpots}
        pictureProps={pictureProps}
      />
    </>
  );
};
