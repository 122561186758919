import React from "react";

interface CustomProductPageStatusBannerProps {
  body: string;
}

export const CustomProductPageStatusBanner = ({ body }: CustomProductPageStatusBannerProps) => {
  return (
    <div className="ProductBanner__component ProductBanner__component--yellow">
      <div className="ProductBanner__inner" dangerouslySetInnerHTML={{ __html: body }}></div>
    </div>
  );
};
