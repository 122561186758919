import React from "react";

interface RichtextProps {
  children: string;
  className?: string;
}

export const Richtext = ({ className = "RichtextArea", children }: RichtextProps) => {
  return <div className={className} dangerouslySetInnerHTML={{ __html: children }} />;
};
