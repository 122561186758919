import React, { FC, useEffect, useState } from "react";
import { DiscoveryListingItemProps } from "../DiscoveryListingItemProps.csharp";
import classNames from "classnames";
import { RadioSelect } from "../../../../../react-components/Discovery/Inputs/RadioSelect/RadioSelect";
import { RadioSelectOption } from "../../../../../react-components/Discovery/Inputs/RadioSelect/RadioSelect.types";
import { DiscoveriesListTranslations } from "./DiscoveriesListTranslations.csharp";
import { useIsBelowScreenSize } from "../../../../../react-components/useIsBelowScreenSize";

const allCategoriesOption = { label: "All Categories", value: "" };
interface DiscoveriesListProps {
  discoveries: DiscoveryListingItemProps[];
  value: string | null;
  onSelect: (id: string | null) => void;
  translations: DiscoveriesListTranslations;
}

export const DiscoveriesList: FC<DiscoveriesListProps> = ({
  discoveries,
  value,
  onSelect,
  translations,
}) => {
  const [selectedOption, setSelectedOption] = useState<RadioSelectOption>(allCategoriesOption);
  const isMobile = useIsBelowScreenSize("mobile");
  const radioSelectOptions: RadioSelectOption[] = discoveries.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  useEffect(() => {
    const selectedOption = radioSelectOptions.find((option) => option.value === value);
    setSelectedOption(selectedOption ?? allCategoriesOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const selectOption = (option: RadioSelectOption | null) => {
    onSelect(!option?.value?.length ? null : option.value);
  };

  const selectFilter = (id: string | null) => {
    onSelect(id);
  };

  return (
    <>
      <div className="DiscoveriesList__filters--mobile">
        {isMobile && (
          <RadioSelect
            name="category"
            title={translations.selectDiscoveries}
            options={radioSelectOptions}
            currentValue={selectedOption}
            hiddenLabel={translations.selectDiscoveries}
            onChange={selectOption}
            placeholder={allCategoriesOption.label}
          />
        )}
      </div>
      <div className="DiscoveriesList__filters--desktop">
        <h2 className="DiscoveriesList__header">{translations.selectDiscoveries}</h2>
        <ul className="DiscoveriesList__filtersList">
          <li>
            <button
              className={classNames("DiscoveriesList__input", {
                ["DiscoveriesList__input--selected"]: value === null,
              })}
              onClick={() => selectFilter(null)}
            >
              {translations.allCategories}
            </button>
          </li>
          {discoveries.map((discovery) => (
            <li key={discovery.id}>
              <button
                className={classNames("DiscoveriesList__input", {
                  ["DiscoveriesList__input--selected"]: value === discovery.id,
                })}
                onClick={() => selectFilter(discovery.id)}
              >
                {discovery.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
