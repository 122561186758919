import React from "react";
import { InteractiveHeroHotSpotViewModel } from "./InteractiveHeroHotSpotViewModel.csharp";
import { HotSpot } from "./HotSpot";

interface InteractiveHeroHotSpotImageProps {
  hotSpots: InteractiveHeroHotSpotViewModel[];
}

export const InteractiveHeroHotSpotImage: React.FC<InteractiveHeroHotSpotImageProps> = ({
  hotSpots,
}) => {
  return (
    <>
      {hotSpots.map((hotSpot, index) => (
        <div
          key={index}
          style={{
            left: `${hotSpot.x}%`,
            top: `${hotSpot.y}%`,
            position: "absolute",
          }}
        >
          <HotSpot {...hotSpot} />
        </div>
      ))}
    </>
  );
};
