import React, { ComponentPropsWithRef, ComponentPropsWithoutRef, ElementType, ForwardedRef, PropsWithChildren, ReactElement, forwardRef } from "react";
import { ButtonBase, ButtonBaseCommonProps, ButtonBaseProps } from "./ButtonBase";

export type ButtonFillProps<C extends ElementType> = PropsWithChildren<ButtonBaseCommonProps<C>> & Omit<ComponentPropsWithoutRef<C>, keyof ButtonBaseCommonProps<C>> & { ref?: ComponentPropsWithRef<C>["ref"] };

export type ButtonFillComponent = <C extends ElementType = "button">(
  props: ButtonFillProps<C>,
  ref: ForwardedRef<C>
) => ReactElement | null;

export const ButtonFill: ButtonFillComponent = forwardRef(function ButtonFill<C extends ElementType>({
  baseClassName,
  ...props
}: ButtonFillProps<C>, ref: ForwardedRef<C>) {
  const buttonProps = {
    baseClassName: "ButtonFill",
    ...props,
  } as ButtonBaseProps<C>;

  return (
    <ButtonBase
      {...buttonProps}
      ref={ref}
    />
  );
});
