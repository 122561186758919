import * as React from "react";

import {FacetFilter} from "./FacetFilter";
import {FacetFilterViewModel} from "./FacetFilterViewModel.csharp";

export interface SearchResultsFacetFiltersProps {
    facets: FacetFilterViewModel[];
    onFacetChanged: (value: string) => void;
}

interface SearchResultsFacetFiltersState {
    currentFacetIndex: number;
    currentFacet: FacetFilterViewModel;
}

export class SearchResultsFacetFilters extends React.Component<SearchResultsFacetFiltersProps, SearchResultsFacetFiltersState> {
    constructor(props: SearchResultsFacetFiltersProps, state: SearchResultsFacetFiltersState) {
        super(props, state);

        this.state = {
            currentFacetIndex: 0,
            currentFacet: this.props.facets[0],
        };

        this.handleOnFacetFilterChange = this.handleOnFacetFilterChange.bind(this);
    }

    render() {
        return (
            <>
                <span role="status" className="visuallyHidden">
                    {`Applied '${this.state.currentFacet.facetDisplayName}' filter with ${this.state.currentFacet.totalHitsCount} results`}
                </span>
                <fieldset className="FacetFilters">
                    <legend className="visuallyHidden">Filters</legend>
                    {this.props.facets.map((value, i) => (
                        <FacetFilter
                            key={value.facetName}
                            facet={value}
                            index={i}
                            isSelected={this.state.currentFacetIndex === i}
                            text={`${value.facetDisplayName} (${value.totalHitsCount})`}
                            onChange={this.handleOnFacetFilterChange}
                        />
                    ))}
                </fieldset>
            </>
        )
    }

    private handleOnFacetFilterChange(facet: FacetFilterViewModel, i: number) {
        this.setState({
            currentFacetIndex: i,
            currentFacet: facet,
        });
        this.props.onFacetChanged(facet.facetName);
    }
}