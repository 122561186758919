import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";

import { MicrositeOverview } from "./MicrositeOverview";

import { ResponsivePictureProps } from "../../../Images/ResponsivePictureProps.csharp";
import { MicrositeOverviewViewModel } from "./MicrositeOverviewViewModel.csharp";
import { useMicrositeContext } from "../../Utils/micrositeContext";
import { delayedCallback } from "../../Utils/delayedCallback";

interface MicrositeOverviewButtonProps extends MicrositeOverviewViewModel {
  overviewBackground: ResponsivePictureProps
  className?: string;
}

export const MicrositeOverviewButton: FC<MicrositeOverviewButtonProps> = ({
  buttonLabel,
  className,
  ...modalProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme, isMenuVisible } = useMicrositeContext();

  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    isMenuVisible && delayedCallback(() => setIsOpen(false), 500);
  }, [isMenuVisible])

  return (
    <>
      <button
        className={classNames("MicrositeOverviewButton",
          `MicrositeOverviewButton--${theme}`,
          className, {
          "MicrositeOverviewButton--open": isOpen
        })}
        onClick={toggleModal}
      >
        <span className="MicrositeOverviewButton__label">
          {buttonLabel}
        </span>
      </button>
      <MicrositeOverview
        {...modalProps}
        toggle={toggleModal}
        isOpen={isOpen}
      />
    </>
  );
};
