import React, { FC } from 'react'
import { ProductHotSpots } from '../ProductHotSpots/ProductHotSpots';
import { MicrositeProductHotSpotViewModel } from '../MicrositeProductHotSpotViewModel.csharp';
import { MicrositeProductHotSpotType } from '../../../../Models/Microsite/MicrositeProductHotSpotType.csharp';
import { PictureProfileDto } from "../../../Images/Resizing/PictureProfileDto.csharp";

interface ProductImageProps {
  imageUrl: string;
  hotSpots: MicrositeProductHotSpotViewModel[];
  hotSpotsPictureProfile: PictureProfileDto;
  shouldBlur: boolean;
  toggleMedia: (type: MicrositeProductHotSpotType) => void;
}

export const ProductImage: FC<ProductImageProps> = ({
  imageUrl,
  shouldBlur,
  toggleMedia,
  hotSpots,
  hotSpotsPictureProfile
}) => {
  const shouldRenderHotSpots = hotSpots && hotSpots.length > 0;

  return (
    <div className="MicrositeProductImage">
      <div className="MicrositeProductImage__pictureOuter">
        <img
          className="MicrositeProductImage__picture"
          src={imageUrl}
        />
        {shouldRenderHotSpots && (
          <ProductHotSpots
            hotSpots={hotSpots}
            profile={hotSpotsPictureProfile}
            toggleMedia={toggleMedia}
          />
        )}
      </div>
    </div>
  )
};
