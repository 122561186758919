//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ResizedImageFormatDto {
	Preserve = 0,
	Jpeg = 1,
	Png = 2,
	Gif = 3,
	Bmp = 4
}
