export const assetsPath = `/Static/Images/Common`;

export type WorldMapInteractiveKey =
  | "none"
  | "global"
  | "asia-pacific"
  | "europe-middle-east-africa"
  | "americas";

export const AssetHelper = {
  images: {
    placeholder: `${assetsPath}/noImage.png`,
  },
  worldMapInteractive: (key: WorldMapInteractiveKey) => {
    return `${assetsPath}/world-map-interactive-${key}.svg`;
  },
};
