import { FC, useMemo, useState } from "react";
import { VacancyFilterInput } from "./VacancyFilterInput";
import { ButtonOutlined } from "../../react-components/Buttons/ButtonOutlined";
import Share from "../../../wwwroot/Static/Images/Icons/Share.svg";
import * as React from "react";
import { VacancyListState } from "./VacancyList";
import { useIsBelowScreenSize } from "../../react-components/useIsBelowScreenSize";
import { SortBy } from "../../react-components/Icons/SortBy";
import { VacancyListFiltersModal } from "./VacancyListFiltersModal";
import { Tooltip } from "../../react-components/Discovery/Tooltip/Tooltip";
import { TooltipTrigger } from "../../react-components/Discovery/Tooltip/TooltipTrigger";
import { TooltipContent } from "../../react-components/Discovery/Tooltip/TooltipContent";
import { ButtonGhost } from "../../react-components/Buttons/ButtonGhost";
import { Count } from "../../react-components/Atoms/Count/Count";
import { VacancyFilterSelect } from "./VacancyFilterSelect";
import { SelectOption } from "../../react-components/Inputs/SelectOption";
import { Reset } from "../../react-components/Discovery/Icons/Reset";
import { useClipboard } from "../../react-components/hooks/useClipboard";

interface VacancyListFiltersProps {
  positionOptions: SelectOption[];
  departmentOptions: SelectOption[];
  regionOptions: SelectOption[];
  onSearchPhraseChange: (value: string) => void;
  onSelectedRegion: (value: SelectOption) => void;
  onSelectedPositionType: (value: SelectOption) => void;
  onSelectedDepartment: (value: SelectOption) => void;
  vacancyFilters: VacancyListState;
  vacanciesCount: number;
  filtersCount: number;
  resetFilters: () => void;
}

export const VacancyListFilters: FC<VacancyListFiltersProps> = ({
  positionOptions,
  departmentOptions,
  regionOptions,
  onSearchPhraseChange,
  onSelectedDepartment,
  onSelectedPositionType,
  onSelectedRegion,
  vacancyFilters,
  vacanciesCount,
  filtersCount,
  resetFilters,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [isShareTooltipVisible, setIsShareTooltipVisible] = useState<boolean>(false);
  const isMobile = useIsBelowScreenSize("tablet");
  const { copy } = useClipboard();

  const toggleModal = () => setShowFilters((filters) => !filters);

  const copyUrlToClipboard = () => {
    copy(location.href);
    setIsShareTooltipVisible(true);

    setTimeout(() => {
      setIsShareTooltipVisible(false);
    }, 3000);
  };

  const currentRegion = useMemo(() => {
    if (vacancyFilters.selectedRegionId.length === 0) {
      return undefined;
    }

    return regionOptions.find((option) => option.value === vacancyFilters.selectedRegionId);
  }, [vacancyFilters.selectedRegionId]);

  const currentPosition = useMemo(() => {
    if (vacancyFilters.selectedPositionTypeId.length === 0) {
      return undefined;
    }

    return positionOptions.find((option) => option.value === vacancyFilters.selectedPositionTypeId);
  }, [vacancyFilters.selectedPositionTypeId]);

  const currentDepartment = useMemo(() => {
    if (vacancyFilters.selectedDepartmentId.length === 0) {
      return undefined;
    }

    return departmentOptions.find((option) => option.value === vacancyFilters.selectedDepartmentId);
  }, [vacancyFilters.selectedDepartmentId]);

  const showResetFiltersButton =
    currentRegion || currentPosition || currentDepartment || vacancyFilters.searchPhrase?.length;

  return (
    <div className="Filters Filters--sand">
      {!isMobile ? (
        <ul className="VacancyFiltersList">
          <li className="VacancyFiltersList__item">
            <VacancyFilterInput
              label="Search"
              value={vacancyFilters.searchPhrase}
              onValueChanged={onSearchPhraseChange}
            />
          </li>
          <li className="VacancyFiltersList__item">
            <VacancyFilterSelect
              label={currentRegion ? currentRegion.label : "All Locations"}
              name="location"
              options={regionOptions}
              onChange={onSelectedRegion}
              placeholder="All locations"
              placeholderAsFirstOption
              currentValue={currentRegion}
              heading="Location"
            />
          </li>
          <li className="VacancyFiltersList__item">
            <VacancyFilterSelect
              label={currentPosition ? currentPosition.label : "All Positions"}
              name="position"
              options={positionOptions}
              onChange={onSelectedPositionType}
              placeholder="All positions"
              placeholderAsFirstOption
              currentValue={currentPosition}
              heading="Position"
            />
          </li>
          <li className="VacancyFiltersList__item">
            <VacancyFilterSelect
              label={currentDepartment ? currentDepartment.label : "All Business Areas"}
              name="business-area"
              options={departmentOptions}
              onChange={onSelectedDepartment}
              placeholder="All Business Areas"
              placeholderAsFirstOption
              currentValue={currentDepartment}
              heading="Business Area"
            />
          </li>
          <li className="VacancyFiltersList__item">
            <Tooltip trigger="click">
              <TooltipTrigger
                className="VacancyFiltersList__tooltipTrigger"
                onClick={copyUrlToClipboard}
              >
                <ButtonOutlined
                  color="blue"
                  icon={Share}
                  iconPosition="right"
                  title="Share"
                  className="VacancyFiltersList__item--share"
                  as="div"
                />
              </TooltipTrigger>
              {isShareTooltipVisible && (
                <TooltipContent maxWidth={164} className="TechnicalSpecifications__tooltipContent">
                  Copied
                </TooltipContent>
              )}
            </Tooltip>
          </li>
          {!!showResetFiltersButton && (
            <li className="VacancyFiltersList__item">
              <ButtonGhost
                iconPosition="right"
                icon={Reset}
                mode="light"
                color="blue"
                onClick={resetFilters}
                className="VacancyFiltersList__resetBtn"
              >
                Reset filters
              </ButtonGhost>
            </li>
          )}
        </ul>
      ) : (
        <>
          <ButtonOutlined
            className="VacancyListingFilters__showFilters"
            icon={SortBy}
            iconPosition="right"
            onClick={toggleModal}
          >
            Show filters
            <Count count={filtersCount} variant="pill" />
          </ButtonOutlined>
          <Tooltip trigger="click">
            <TooltipTrigger className="TechnicalSpecifications__tooltipTrigger">
              <ButtonGhost
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={copyUrlToClipboard}
                color="blue"
                icon={Share}
                iconPosition="right"
                title="Share"
                className="VacancyFiltersList__item--share"
              />
            </TooltipTrigger>
            {isShareTooltipVisible && (
              <TooltipContent maxWidth={164} className="TechnicalSpecifications__tooltipContent">
                Copied
              </TooltipContent>
            )}
          </Tooltip>
          <VacancyListFiltersModal
            isOpen={isMobile && showFilters}
            setOpen={setShowFilters}
            positionOptions={positionOptions}
            regionOptions={regionOptions}
            departmentOptions={departmentOptions}
            onSearchPhraseChange={onSearchPhraseChange}
            onSelectedRegion={onSelectedRegion}
            onSelectedPositionType={onSelectedPositionType}
            onSelectedDepartment={onSelectedDepartment}
            region={currentRegion}
            position={currentPosition}
            department={currentDepartment}
            searchPhrase={vacancyFilters.searchPhrase}
            filtersCount={{ label: "results", count: vacanciesCount, variant: "bold" }}
            resetFilters={resetFilters}
            showResetFiltersButton={!!showResetFiltersButton}
          />
        </>
      )}
    </div>
  );
};
