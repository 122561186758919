import React, { useState } from "react";
import { ProductsListingFiltersController } from "./ProductsListingFiltersController";
import { Filters, FiltersState } from "../ProductsListing.types";
import { CheckboxGroupOption } from "../../../../react-components/Inputs/CheckboxGroup/CheckboxGroup";

interface ProductsListingFiltersProps {
  filters: Filters;
  filtersState: FiltersState;
  filtersCount: number;
  onFiltersChange: (key: string, newValues: CheckboxGroupOption[]) => void;
  onClearFilters: () => void;
  isMobile: boolean;
  showFiltersPanel: boolean;
  productsCount: number;
}

export const ProductsListingFilters = ({
  filters,
  filtersState,
  onFiltersChange,
  filtersCount,
  onClearFilters,
  isMobile,
  showFiltersPanel,
  productsCount,
}: ProductsListingFiltersProps) => {
  const [showFilters, setShowFilters] = useState(false);

  return (
    <aside className="ProductsListingFilters">
      <ProductsListingFiltersController
        filters={filters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        filtersState={filtersState}
        onFiltersChange={onFiltersChange}
        filtersCount={filtersCount}
        onClearFilters={onClearFilters}
        isMobile={isMobile}
        showFiltersPanel={showFiltersPanel}
        productsCount={productsCount}
      />
    </aside>
  );
};
