import React, { FC } from "react";
import classNames from "classnames";
import { ComponentProps } from "../ComponentProps.csharp";
import { TeaserBlockViewModel } from "./TeaserBlockViewModel.csharp";
import { ResponsivePicture } from "../../react-components/responsivePicture/responsivePicture";
import _ from "lodash";
import { ButtonFill } from "../../react-components/Buttons/ButtonFill";
import { BlockColorVariantExtended } from "../../../Models/Blocks/BlockColorVariantExtended.csharp";

export const Teaser: FC<ComponentProps<TeaserBlockViewModel>> = ({ model }) => {
  if (!model.href) return null;

  const isNoneBackground = model.backgroundColor === BlockColorVariantExtended.None;
  const backgroundClassName = `Teaser--backgroundColor${_.upperFirst(model.backgroundColor)}`;
  const buttonColorClassName = `Teaser--buttonColor${_.upperFirst(model.buttonColor)}`;

  const areDetails = model.title || model.subTitle || model.buttonText;

  return (
    <section
      className={classNames(
        "Teaser",
        {
          "Teaser--noHeading": !model.heading,
          "Teaser--transparent": isNoneBackground,
        },
        backgroundClassName,
        buttonColorClassName,
      )}
    >
      {model.heading && <h2 className="Teaser__headingDesktop">{model.heading}</h2>}
      {model.href && model.image && (
        <a href={model.href} className="Teaser__imageLink">
          <div className="Teaser__imageWrapper">
            <ResponsivePicture {...model.image} />
            {areDetails && (
              <div className="Teaser__details">
                <div className="Teaser__detailsText">
                  {model.title && <h3 className="Teaser__title">{model.title}</h3>}
                  {model.subTitle && <h4 className="Teaser__subTitle">{model.subTitle}</h4>}
                </div>
                {model.buttonText && (
                  <ButtonFill className="Teaser__button" color="teal">
                    {model.buttonText}
                  </ButtonFill>
                )}
              </div>
            )}
          </div>
        </a>
      )}
    </section>
  );
};
