import React from "react";

interface ImageViewerFullScreenCaptionProps {
  captionHtml?: string;
}

export const ImageViewerFullScreenCaption = ({ captionHtml }: ImageViewerFullScreenCaptionProps) =>
  captionHtml ? (
    <figcaption
      className="ImageViewerFullScreen__caption"
      dangerouslySetInnerHTML={{ __html: captionHtml }}
    />
  ) : null;
