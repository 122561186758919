import React, { SVGProps, memo } from "react";
export const HamburgerClose = memo(function HamburgerClose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 25 25"
      {...props}
    >
      <path
        d="M6.09033 6.21861L18.9099 19.0392"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="square"
      />
      <path
        d="M18.9094 6.21875L6.08984 19.0393"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="square"
      />
    </svg>
  );
});
