import { Variants, Variant, TargetAndTransition } from "framer-motion";
import { EntryAnimations, ExitAnimations, InitialAnimations, SpecialAnimationsEntry, SpecialAnimationsExit } from './AnimatedComponent.animations';
import { AnimationConductor, OrchestratedChild } from "./AnimatedComponent.types";

const isFunction = (variant: Variant) => typeof variant == 'function';

export const ModifyAnimationObject = (entryObj: Variants, exitObj: Variants): Variants => {
  let animationObject = {};
  const AddStagger = ({ value, when }: { value: Variant & TargetAndTransition, when: 'afterChildren' | 'beforeChildren' }) => {
    return {
      ...value,
      transition: {
        ...value.transition,
        staggerChildren: 0.3,
        when,
      }
    }
  }

  Object.entries(entryObj).map(([name, value]: [string, Variant]) => {
    animationObject = {
      ...animationObject,
      [name]: AddStagger({ value, when: 'beforeChildren' })
    }
  })

  Object.entries(exitObj).map(([name, value]: [string, Variant]) => {
    animationObject = {
      ...animationObject,
      [name]: AddStagger({ value, when: 'afterChildren' })
    }
  })

  return animationObject;
}

export const useSynchronizedAnimations = (
  conductor?: AnimationConductor | boolean,
  orchestrationChild?: OrchestratedChild
) => {
  const AnimationsStagger = {
    ...SpecialAnimationsEntry,
    ...SpecialAnimationsExit,
    ...InitialAnimations,
    ...ModifyAnimationObject(EntryAnimations, ExitAnimations)
  };
  const Animations: Variants = {
    ...SpecialAnimationsEntry,
    ...SpecialAnimationsExit,
    ...InitialAnimations,
    ...EntryAnimations,
    ...ExitAnimations
  };

  let variantsChildElement: { [key: string]: Variant } | undefined;
  let variantsConductor: Variants;

  if (conductor && typeof conductor == 'object') {
    variantsConductor = {
      ...AnimationsStagger,
      [`${conductor.customAnimation.entry.name}`]: {
        ...AnimationsStagger[conductor.customAnimation.entry.target],
      },
      [`${conductor.customAnimation.exit.name}`]: {
        ...AnimationsStagger[conductor.customAnimation.exit.target],
      }
    }

  } else if (conductor) {
    variantsConductor = AnimationsStagger;
  } else {
    variantsConductor = Animations;
  }

  if (orchestrationChild) {
    const {
      target: childEntryTarget,
      name: childEntryName
    } = orchestrationChild.entry

    const {
      target: childExitTarget,
      name: childExitName
    } = orchestrationChild.exit

    const childEntry = isFunction(Animations[childEntryTarget])
      ? Animations[childEntryTarget]
      : { ...Animations[childEntryTarget] }

    const childExit = isFunction(Animations[childExitTarget])
      ? Animations[childExitTarget]
      : { ...Animations[childExitTarget] }

    variantsChildElement = {
      [childEntryName]: childEntry,
      [childExitName]: childExit
    }
  }

  return { ...variantsConductor, ...variantsChildElement };
}
