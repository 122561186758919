import React, { SVGProps, memo } from "react";
export const Close = memo(function Close(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 17 16"
      {...props}
    >
      <path
        d="M4.22681 3.72647L12.7732 12.2735"
        stroke="currentColor"
        strokeWidth="0.9"
        strokeLinecap="square"
      />
      <path
        d="M12.7729 3.72656L4.22656 12.2736"
        stroke="currentColor"
        strokeWidth="0.9"
        strokeLinecap="square"
      />
    </svg>
  );
});
