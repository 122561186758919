import React, { ReactElement, useEffect, useState } from "react";
import { HotSpot as HotSpotVariantA } from "./VariantA/HotSpot";
import { HotSpot as HotSpotVariantB } from "./VariantB/HotSpot";
import { DescriptionBox } from "./DescriptionBox";
import classNames from "classnames";
import { HotSpotType } from "../HotSpotType";
import { MediaPosition } from "../../../../Models/Blocks/CallToAction/MediaPosition.csharp";
import { SideTextHotSpotViewModel } from "./SideTextHotSpotViewModel.csharp";
import { SideTextHotSpotVariantAViewModel } from "./VariantA/SideTextHotSpotVariantAViewModel.csharp";
import { MobileDescriptionBox } from "./MobileDescriptionBox";

interface SideTextHotSpotsImage {
  hotSpots: SideTextHotSpotViewModel[];
  viewType: HotSpotType;
  scrollToImage: () => void,
  setMobileDescriptionBox: (element: ReactElement) => void;
}

export const SideTextHotSpotsImage: React.FC<SideTextHotSpotsImage> = ({
  hotSpots,
  viewType,
  scrollToImage,
  setMobileDescriptionBox,
}) => {
  const [
    currentLeftHotSpot,
    setCurrentLeftHotSpot,
  ] = useState<SideTextHotSpotViewModel | null>(null);
  const [
    currentRightHotSpot,
    setCurrentRightHotSpot,
  ] = useState<SideTextHotSpotViewModel | null>(null);
  const [
    currentMobileHotSpot,
    setCurrentMobileHotSpot,
  ] = useState<SideTextHotSpotViewModel | null>(null);
  const [showLeftDescription, setShowLeftDescription] = useState(false);
  const [showRightDescription, setShowRightDescription] = useState(false);

  useEffect(() => {
    setMobileDescriptionBox(
      <MobileDescriptionBox
        hotSpots={hotSpots}
        activeHotSpot={currentMobileHotSpot}
        setActiveHotSpot={setCurrentMobileHotSpot}
      />
    );
  }, [currentMobileHotSpot]);

  const showDescriptionBox = (newHotSpot: SideTextHotSpotViewModel) => {
    setShowLeftDescription(false);
    setShowRightDescription(false);
    switch (newHotSpot.descriptionSide) {
      case MediaPosition.Right:
        updateDescriptionData(
          currentRightHotSpot,
          newHotSpot,
          setCurrentRightHotSpot,
          setShowRightDescription,
          showRightDescription
        );
        break;
      case MediaPosition.Left:
        updateDescriptionData(
          currentLeftHotSpot,
          newHotSpot,
          setCurrentLeftHotSpot,
          setShowLeftDescription,
          showLeftDescription
        );
        break;
    }
    scrollToImage()
  };

  const updateDescriptionData = (
    currentHotSpot: SideTextHotSpotViewModel | null,
    newHotSpot: SideTextHotSpotViewModel | null,
    setCurrent: (hotSpot: SideTextHotSpotViewModel | null) => void,
    setShowCurrent: (show: boolean) => void,
    isVisible: boolean
  ) => {
    if (currentHotSpot == null) {
      setCurrent(newHotSpot);
      setShowCurrent(true);
    } else if (isVisible && currentHotSpot === newHotSpot) {
      setShowCurrent(false);
      setTimeout(() => setCurrent(null), 500);
    } 
    else {
      setShowLeftDescription(false);
      setShowRightDescription(false);
      
      setTimeout(() => {
        setCurrent(newHotSpot);
        setShowCurrent(true);
      }, 600);
    }
  };

  const isHotSpotActive = (hotSpot: SideTextHotSpotViewModel) =>
    currentLeftHotSpot === hotSpot ||
    currentRightHotSpot === hotSpot ||
    (currentLeftHotSpot === null && currentRightHotSpot === null);

  const getHotSpots = () => {
    switch (viewType) {
      case "SideTextVariantA":
        return hotSpots.map((hotSpot, index) => (
          <HotSpotVariantA
            key={index}
            viewModel={hotSpot as SideTextHotSpotVariantAViewModel}
            onClick={() => showDescriptionBox(hotSpot)}
            isActive={isHotSpotActive(hotSpot)}
            mobilePreviewText={`${index + 1}.`}
            mobileOnClick={() => setCurrentMobileHotSpot(hotSpot)}
          />
        ));
      case "SideTextVariantB":
        return hotSpots.map((hotSpot, index) => (
          <HotSpotVariantB
            key={index}
            viewModel={hotSpot as SideTextHotSpotVariantAViewModel}
            onClick={() => showDescriptionBox(hotSpot)}
            mobilePreviewText={`${index + 1}.`}
            mobileOnClick={() => setCurrentMobileHotSpot(hotSpot)}
          />
        ));
    }
  };

  const hideLeftDescription = () => {
    if (showLeftDescription) {
      setShowLeftDescription(false);
      setTimeout(() => setCurrentLeftHotSpot(null), 500);
    }
  };

  const hideRightDescription = () => {
    if (showRightDescription) {
      setShowRightDescription(false);
      setTimeout(() => setCurrentRightHotSpot(null), 500);
    }
  };

  return (
    <>
      {getHotSpots()}

      <DescriptionBox
        text={currentLeftHotSpot?.description}
        linkViewModel={currentLeftHotSpot?.descriptionLink}
        onClose={hideLeftDescription}
        className={classNames("SideTextHotSpotsImage__Description--left", {
          ["SideTextHotSpotsImage__Description--leftHidden"]: !showLeftDescription,
        })}
      />

      <DescriptionBox
          text={currentRightHotSpot?.description}
          linkViewModel={currentRightHotSpot?.descriptionLink}
          onClose={hideRightDescription}
          className={classNames("SideTextHotSpotsImage__Description--right", {
            ["SideTextHotSpotsImage__Description--rightHidden"]: !showRightDescription,
          })}
      />
    </>
  );
};
