import { CheckboxGroupOption } from "../../../react-components/Inputs/CheckboxGroup/CheckboxGroup";
import { RadioSelectOption } from "../../../react-components/Discovery/Inputs/RadioSelect/RadioSelect.types";
import { LatLng } from "../../../react-components/distanceUtils";
import { OfficeLocationsDisplayType } from "./OfficeLocationsWithMapListing.utils";
import { OfficeLocationViewModel } from '../OfficeLocationViewModel.csharp';

export interface OfficeLocationsState {
  loaded: boolean;
  officeLocations: OfficeLocationViewModel[];
  filters: OfficeLocationsFiltersState;
  displayType: OfficeLocationsDisplayType;
  latestAction: OfficeLocationsReducerActionType | undefined;
  initialSearch: boolean;
  selectedOfficeId: string | undefined;
  userLocationDisabled: boolean;
}

export interface OfficeLocationsFiltersState {
  searchedPlace: google.maps.places.PlaceResult | undefined;
  selectedCategory: RadioSelectOption | undefined;
  selectedTypes: CheckboxGroupOption[];
  searchQuery: string;
  userCoords: LatLng | undefined;
  searchNearby: boolean;
}

export const officeLocationsReducerInitialState = {
  loaded: false,
  officeLocations: [],
  filters: {
    searchedPlace: undefined,
    selectedCategory: undefined,
    selectedTypes: [],
    searchQuery: "",
    userCoords: undefined,
    searchNearby: false,
  },
  displayType: OfficeLocationsDisplayType.Default,
  latestAction: undefined,
  initialSearch: false,
  selectedOfficeId: undefined,
  userLocationDisabled: false,
} as OfficeLocationsState;

export enum OfficeLocationsReducerActionType {
  LOAD = 'LOAD',
  SET_OFFICE_LOCATIONS = 'SET_OFFICE_LOCATIONS',
  SET_SELECTED_OFFICE = 'SET_SELECTED_OFFICE',
  CLEAR_SELECTED_OFFICE = 'CLEAR_SELECTED_OFFICE',
  SET_SEARCH_QUERY = 'SET_SEARCH_QUERY',
  SET_SELECTED_TYPES = 'SET_SELECTED_TYPES',
  SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY',
  SHOW_NEAREST = 'SHOW_NEAREST',
  SHOW_DEFAULT = 'SHOW_DEFAULT',
  SHOW_NO_OFFICES = 'SHOW_NO_OFFICES',
  CLEAR_CURRENT_LOCATION = 'CLEAR_CURRENT_LOCATION',
  LOCATE_USER = 'LOCATE_USER',
  SEARCH_PLACE = 'SEARCH_PLACE',
  CLEAR_INITIAL_SEARCH = 'CLEAR_INITIAL_SEARCH',
  DISABLE_USER_LOCATION = 'DISABLE_USER_LOCATION',
}

export type OfficeLocationsReducerAction =
  | {
    type: OfficeLocationsReducerActionType.SET_OFFICE_LOCATIONS,
    officeLocations: OfficeLocationViewModel[],
  }
  | {
      type: OfficeLocationsReducerActionType.SET_SELECTED_OFFICE,
      selectedOfficeId: OfficeLocationsState["selectedOfficeId"]
    }
  | {
      type: OfficeLocationsReducerActionType.SET_SEARCH_QUERY,
      searchQuery: OfficeLocationsFiltersState["searchQuery"]
    }
  | {
      type: OfficeLocationsReducerActionType.SET_SELECTED_TYPES,
      selectedTypes: OfficeLocationsFiltersState["selectedTypes"]
    }
  | {
      type: OfficeLocationsReducerActionType.SET_SELECTED_CATEGORY,
      selectedCategory: OfficeLocationsFiltersState["selectedCategory"]
    }
  | {
      type: OfficeLocationsReducerActionType.LOCATE_USER,
      userCoords: OfficeLocationsFiltersState["userCoords"],
      removeSearchedPlace: boolean,
    }
  | {
      type: OfficeLocationsReducerActionType.SEARCH_PLACE,
      searchedPlace: OfficeLocationsFiltersState["searchedPlace"],
      searchQuery: OfficeLocationsFiltersState["searchQuery"]
    }
  | { type: OfficeLocationsReducerActionType.LOAD }
  | { type: OfficeLocationsReducerActionType.DISABLE_USER_LOCATION }
  | { type: OfficeLocationsReducerActionType.CLEAR_SELECTED_OFFICE }
  | { type: OfficeLocationsReducerActionType.SHOW_NEAREST }
  | { type: OfficeLocationsReducerActionType.SHOW_DEFAULT }
  | { type: OfficeLocationsReducerActionType.SHOW_NO_OFFICES }
  | { type: OfficeLocationsReducerActionType.CLEAR_CURRENT_LOCATION }
  | { type: OfficeLocationsReducerActionType.CLEAR_INITIAL_SEARCH };

export const officeLocationsReducer = (
  state: OfficeLocationsState,
  action: OfficeLocationsReducerAction
): OfficeLocationsState => {
  switch (action.type) {
    case OfficeLocationsReducerActionType.LOAD:
      return {
        ...state,
        loaded: true,
        latestAction: action.type,
      };

    case OfficeLocationsReducerActionType.DISABLE_USER_LOCATION:
      return {
        ...state,
        userLocationDisabled: true,
        latestAction: action.type,
      };

    case OfficeLocationsReducerActionType.SET_OFFICE_LOCATIONS:
      return {
        ...state,
        officeLocations: action.officeLocations,
        latestAction: action.type,
      };

    case OfficeLocationsReducerActionType.SET_SELECTED_OFFICE:
      return {
        ...state,
        selectedOfficeId: action.selectedOfficeId,
        latestAction: action.type,
      };

    case OfficeLocationsReducerActionType.CLEAR_SELECTED_OFFICE:
      return {
        ...state,
        selectedOfficeId: undefined,
        latestAction: action.type,
      };

    case OfficeLocationsReducerActionType.SET_SEARCH_QUERY:
      return {
        ...state,
        filters: {
          ...state.filters,
          searchQuery: action.searchQuery,
        },
        latestAction: action.type,
      };

    case OfficeLocationsReducerActionType.SET_SELECTED_TYPES:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedTypes: action.selectedTypes,
        },
        latestAction: action.type,
      };

    case OfficeLocationsReducerActionType.SET_SELECTED_CATEGORY:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategory: action.selectedCategory,
        },
        latestAction: action.type,
      };

    case OfficeLocationsReducerActionType.SHOW_NEAREST:
      return {
        ...state,
        initialSearch: true,
        displayType: OfficeLocationsDisplayType.Nearby,
        filters: {
          ...state.filters,
          searchNearby: true,
        },
        latestAction: action.type,
      }

    case OfficeLocationsReducerActionType.SHOW_DEFAULT:
      return {
        ...state,
        initialSearch: true,
        displayType: OfficeLocationsDisplayType.Default,
        filters: {
          ...state.filters,
          searchNearby: false,
        },
        latestAction: action.type,
      }

    case OfficeLocationsReducerActionType.SHOW_NO_OFFICES:
      return {
        ...state,
        displayType: OfficeLocationsDisplayType.NoOffices,
        latestAction: action.type,
      }

    case OfficeLocationsReducerActionType.CLEAR_CURRENT_LOCATION:
      return {
        ...state,
        initialSearch: false,
        filters: {
          ...state.filters,
          userCoords: undefined,
          searchedPlace: undefined,
          searchNearby: false,
        },
        displayType: OfficeLocationsDisplayType.Default,
        selectedOfficeId: undefined,
        latestAction: action.type,
      };

    case OfficeLocationsReducerActionType.LOCATE_USER:
      return {
        ...state,
        initialSearch: true,
        filters: {
          ...state.filters,
          userCoords: action.userCoords,
          searchedPlace: action.removeSearchedPlace ? undefined : state.filters.searchedPlace,
          searchNearby: false,
        },
        displayType: OfficeLocationsDisplayType.Default,
        latestAction: action.type,
      };

    case OfficeLocationsReducerActionType.SEARCH_PLACE:
      return {
        ...state,
        initialSearch: true,
        filters: {
          ...state.filters,
          searchedPlace: action.searchedPlace,
          searchQuery: action.searchQuery,
          searchNearby: false,
        },
        latestAction: action.type,
      };

    case OfficeLocationsReducerActionType.CLEAR_INITIAL_SEARCH:
      return {
        ...state,
        initialSearch: false,
        latestAction: action.type,
      };

    default:
      return { ...state };
  }
};
